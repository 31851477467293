import Input from 'components/Input';
import { Button } from '@laminar-product/client-commons-core/web';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { CalculatePriceCouponResponse } from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import { checkForCouponApplied } from 'utils/price';
import styles from './index.module.scss';

interface CouponDetailsProps {
  promoCode: string;
  setPromoCode: (code: string) => void;
  onPromoCodeApply: (code: string) => void;
  resetCoupon: () => void;
  calculatedPriceCoupon?: CalculatePriceCouponResponse;
  couponError?: string;
}

const CouponDetails = ({
  promoCode,
  setPromoCode,
  calculatedPriceCoupon,
  resetCoupon,
  onPromoCodeApply,
  couponError,
}: CouponDetailsProps) => {
  const { t } = useTranslation();

  const getPromoCodeEndIcon = useCallback(() => {
    if (!calculatedPriceCoupon) return {};

    const Icon = checkForCouponApplied(calculatedPriceCoupon) ? (
      <CheckIcon className={styles.promoCodeSuffixIcon} />
    ) : (
      <CancelIcon
        className={cn(styles.promoCodeSuffixIcon, styles.cancelIcon)}
        onClick={resetCoupon}
      />
    );

    return { endIcon: Icon };
  }, [calculatedPriceCoupon, resetCoupon]);

  return (
    <>
      <div className={styles.promoCode}>
        <Input
          light
          className={styles.promoCodeInput}
          id="promoCode"
          label={t('orderSummary.promoCode')}
          placeholder={t('orderSummary.promoCodePlaceholder')}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value.trim())}
          disabled={!!calculatedPriceCoupon}
          {...getPromoCodeEndIcon()}
        />
        {calculatedPriceCoupon ? (
          <Button
            className={styles.applyPromoCodeButton}
            onClick={() => resetCoupon()}
            variant="secondary"
          >
            {t('common.cancel')}
          </Button>
        ) : (
          <Button
            className={styles.applyPromoCodeButton}
            onClick={() => onPromoCodeApply(promoCode)}
            variant="secondary"
            disabled={promoCode.length <= 1}
          >
            {t('common.apply')}
          </Button>
        )}
      </div>
      {couponError && <div className={styles.errorMessage}>{couponError}</div>}
    </>
  );
};

export default CouponDetails;

import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

const LinearProfileWrapper = ({
  children,
  contentClassName,
}: {
  children: React.ReactNode;
  contentClassName?: string;
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default LinearProfileWrapper;

import {
  ComponentsPage,
  Config,
  PartnerUserProvider,
  PlatformLanguages,
  User,
} from '@laminar-product/client-commons-core/core';
import api from 'api';
import { getAuth, signOut } from 'firebase/auth';
import { PAGE_COMPONENTS_PAGINATION_LIMIT } from 'utils/constants';

interface GetComponentsPageProps {
  pageUuid: string;
  skip?: number;
}

export const getConfiguration = async () => {
  const { data } = await api().get<Config>('/settings');
  return data;
};

export const getComponentsPage = async ({
  pageUuid,
  skip,
}: GetComponentsPageProps) => {
  const { data } = await api().get<ComponentsPage>(
    `/page/${pageUuid}/components?skip=${skip}&limit=${PAGE_COMPONENTS_PAGINATION_LIMIT}`
  );

  return data;
};

// note: this endpoint in combination with users/provider is used instead of users/me
export const getUserData = async () => {
  try {
    const { data, headers } = await api().get<User>('/user/current');

    return { data, country: headers['x-country'] };
  } catch (e) {
    const auth = getAuth();

    signOut(auth);
    return Promise.reject(e);
  }
};

export const getUserProvider = async () => {
  const { data } = await api().get<PartnerUserProvider>('/users/provider');

  return data;
};

export const registerUser = async () => await api().post('/users/register');

export const registerDevice = async (code: string) =>
  await api().post(`users/pair`, { code });

export const fetchPlatformLanguages = async () => {
  const { data } = await api().get<PlatformLanguages>('/platform-language');

  return data;
};

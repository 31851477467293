import shaka from 'shaka-player/dist/shaka-player.ui'; //Temporary: Incorrect export from shaka-package - https://gist.github.com/Security2431/2b28f17e11870bb4b0e347673e16d5ba
import { t } from 'i18next';

class VideoPlayerLiveIndicator extends shaka.ui.Element {
  containerEl_: HTMLDivElement | null = null;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.containerEl_ = document.createElement('div');
    this.containerEl_.classList.add('laminar-shaka-live-indicator');
    const spanEl = document.createElement('span');
    spanEl.classList.add('laminar-shaka-live-indicator__dot');
    const textEl = document.createElement('div');
    textEl.classList.add('laminar-shaka-live-indicator__text');
    textEl.innerText = t('common.live');

    this.containerEl_.appendChild(spanEl);
    this.containerEl_.appendChild(textEl);
    this.parent?.appendChild(this.containerEl_);
  }
}

class VideoPlayerLiveIndicatorFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new VideoPlayerLiveIndicator(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(
  'video_player_live_indicator',
  new VideoPlayerLiveIndicatorFactory()
);

export default VideoPlayerLiveIndicator;

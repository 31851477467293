import { AuthenticationFailedProps } from 'components/SocialLogin';
import { getSSOProviderUrl } from 'actions/sso';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { useTranslation } from 'react-i18next';
import { SSOProviderType } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';
import {
  getErrorMessage,
  getSSOProviderIcon,
  getSSOProviderName,
} from './utils';

interface SSOProviderProps {
  provider: SSOProviderType;
  setAuthenticationFailed: (value: AuthenticationFailedProps) => void;
  setIsAuthenticating: (value: boolean) => void;
}

const SSOProvider = ({
  provider,
  setAuthenticationFailed,
  setIsAuthenticating,
}: SSOProviderProps) => {
  const { t } = useTranslation();

  const handleOnProviderClick = async () => {
    setIsAuthenticating(true);

    try {
      const ssoProviderUrl = await getSSOProviderUrl(
        getSSOProviderName(provider)
      );

      if (!ssoProviderUrl.link) {
        setAuthenticationFailed({
          isFailed: true,
          errorMessage: getErrorMessage({ t, provider }),
        });
        return;
      }

      window.location.replace(ssoProviderUrl.link);
    } catch (error: any) {
      setAuthenticationFailed({
        isFailed: true,
        errorMessage: getErrorMessage({ t, error, provider }),
      });
      captureError(
        error,
        `handleSSOProviderClick: ${provider}`,
        Severity.Error
      );
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <div
      className={styles.iconWrapper}
      onClick={handleOnProviderClick}
      data-testid="SSO_PROVIDER"
    >
      <img
        src={getSSOProviderIcon(provider)}
        alt={getSSOProviderName(provider)}
        className={styles.icon}
      />
    </div>
  );
};

export default SSOProvider;

import { Button } from '@laminar-product/client-commons-core/web';
import { Severity } from 'types/errors';
import { cancelSubscription } from 'actions/payments';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { captureError } from 'utils/captureError';
import { Trans, useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface CancelMembershipProps {
  subscriptionEndDate?: string;
  onCancelMembership: () => void;
}

const CancelMembership = ({
  onCancelMembership,
  subscriptionEndDate,
}: CancelMembershipProps) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [cancelInProgress, toggleCancelInProgress] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      toggleCancelInProgress(true);
      await cancelSubscription();
      onCancelMembership();
      toggleCancelInProgress(false);
      push('/settings');
    } catch (e) {
      captureError(
        e as AxiosError,
        'CancelMembership/handleCancelSubscription',
        Severity.Error
      );
      setErrorMessage('Something went wrong. Please contact support');
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>{t('cancelSubscription.heading')}</h1>
      <div className={styles.description}>
        {t('cancelSubscription.description')}
      </div>
      <ul className={styles.options}>
        <li>
          <Trans
            i18nKey="cancelSubscription.cancelationPeriod"
            values={{ subscriptionEndDate }}
          />
        </li>
        <li>{t('cancelSubscription.restartMembershipDescription')}</li>
      </ul>
      {errorMessage && (
        <p
          data-testid="CancelMembership__errorMessage"
          className={styles.errorMessage}
        >
          {errorMessage}
        </p>
      )}
      <div className={styles.buttonGroup}>
        <Button
          data-testid="CancelMembership__cancelButton"
          variant="primary-settings"
          onClick={handleCancelSubscription}
          loading={cancelInProgress}
        >
          {t('cancelSubscription.confirmButton')}
        </Button>
        <Button variant="secondary-settings" onClick={() => push('/settings')}>
          {t('cancelSubscription.cancelButton')}
        </Button>
      </div>
    </div>
  );
};

export default CancelMembership;

import LinearProfileWrapper from 'components/LinearProfileWrapper';
import { usePlayerValidationError } from 'utils/player';
import { Button } from '@laminar-product/client-commons-core/web';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface VideoPlayerErrorProps {
  onClose?: () => void;
  errorCode: string;
}

const VideoPlayerError = ({ onClose, errorCode }: VideoPlayerErrorProps) => {
  const {
    playerErrorMessage: { header, message },
  } = usePlayerValidationError(errorCode);
  const { t } = useTranslation();

  return (
    <LinearProfileWrapper>
      <div className={styles.wrapper} data-testid="VideoPlayer__Error">
        <p className={styles.header}>{header}</p>
        <p className={styles.message}>{message}</p>

        {onClose && (
          <Button variant="primary" onClick={onClose}>
            {t('common.OK')}
          </Button>
        )}
      </div>
    </LinearProfileWrapper>
  );
};

export default VideoPlayerError;

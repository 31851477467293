import { getAsset, getAssetWatchDetails } from 'actions/assets';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppData } from 'store/app/actions';
import { selectRootAsset } from 'store/app/selectors';
import { Severity } from 'types/errors';
import { setAsset } from 'store/assets/actions';
import { captureError } from './captureError';

interface UseFetchNextEpisodeProps {
  assetId?: string;
  onNextEpisodeFetch?: (nextEpisodeId: string) => void;
}

const useFetchNextEpisode = ({
  assetId,
  onNextEpisodeFetch,
}: UseFetchNextEpisodeProps) => {
  const currentAssetRoot = useSelector(selectRootAsset(assetId));
  const dispatch = useDispatch();

  useEffect(() => {
    //Fetch watch details of current asset if episode
    if (!assetId || !!currentAssetRoot) {
      return;
    }

    getAssetWatchDetails(assetId)
      .then((root) => dispatch(setAppData('assetRoot', assetId, root)))
      .catch((e) =>
        captureError(e, 'useFetchNextEpisode/getWatchDetails', Severity.Error)
      );
  }, [assetId, currentAssetRoot, dispatch]);

  useEffect(() => {
    //Fetch details of next episode if available
    if (!currentAssetRoot?.nextAsset) {
      return;
    }

    const { uuid } = currentAssetRoot.nextAsset;

    getAsset(uuid)
      .then((asset) => {
        dispatch(setAsset(uuid, asset));
        onNextEpisodeFetch?.(asset.id);
      })
      .catch((e) =>
        captureError(
          e,
          'useFetchNextEpisode/getNextEpisodeDetails',
          Severity.Error
        )
      );
  }, [currentAssetRoot, dispatch, onNextEpisodeFetch]);
};

export default useFetchNextEpisode;

import shaka from 'shaka-player/dist/shaka-player.ui'; //Temporary: Incorrect export from shaka-package - https://gist.github.com/Security2431/2b28f17e11870bb4b0e347673e16d5ba
import SkipBackward from 'assets/icons/skipBackward.svg';
import { VIDEO_PLAYER_SEEK_TIME } from 'utils/constants';

class VideoPlayerSkipLeftButton extends shaka.ui.Element {
  button_: HTMLButtonElement | null = null;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button_ = document.createElement('button');
    this.button_.classList.add('shaka-small-skip-left-button');
    const img = document.createElement('img');
    img.src = SkipBackward;

    this.button_.appendChild(img);
    this.parent?.appendChild(this.button_);

    this.eventManager?.listen(this.button_, 'click', () => {
      if (!this.video) {
        return;
      }

      this.video.currentTime = this.video.currentTime - VIDEO_PLAYER_SEEK_TIME;
    });
  }
}

class VideoPlayerSkipLeftButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new VideoPlayerSkipLeftButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(
  'video_player_skip_left_button',
  new VideoPlayerSkipLeftButtonFactory()
);

export default VideoPlayerSkipLeftButton;

import React, { ReactElement } from 'react';
import styles from './index.module.scss';

interface ResetPasswordInfoProps {
  info: string;
  icon: ReactElement;
  title: string;
  footer?: string | ReactElement;
}

const ResetPasswordInfo = ({
  info,
  icon,
  title,
  footer,
}: ResetPasswordInfoProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>{icon}</div>
      <h1 className={styles.heading}>{title}</h1>
      <p className={styles.description}>{info}</p>
      {!!footer && footer}
    </div>
  );
};

export default ResetPasswordInfo;

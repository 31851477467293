import { Profile } from '@laminar-product/client-commons-core/core';
import CloseProfileModal from 'components/CloseProfileModal';
import LinearProfileWrapper from 'components/LinearProfileWrapper';
import ProfileAvatar from 'components/ProfileAvatar';
import ProfileModalHeader from 'components/ProfileModalHeader';
import { useTranslation } from 'react-i18next';
import { ProfileConfirmationMethods } from 'types/profiles';
import { checkIsKidsProfile } from 'utils/profiles';
import ConfirmationMethodButton from '../ConfirmationMethodButton';
import styles from './index.module.scss';

interface ConfirmationMethodSelectorProps {
  onClose: () => void;
  onSelect: (method: ProfileConfirmationMethods) => void;
  profile: Profile;
  allowedConfirmationMethods?: ProfileConfirmationMethods[];
}

const ConfirmationMethodSelector = ({
  onClose,
  onSelect,
  profile,
  allowedConfirmationMethods,
}: ConfirmationMethodSelectorProps) => {
  const { t } = useTranslation();

  const getButtonText = (method: ProfileConfirmationMethods) => {
    switch (method) {
      case ProfileConfirmationMethods.PASSWORD:
        return t('profile.confirmWithPassword');
      case ProfileConfirmationMethods.PHONE:
        return t('profile.confirmWithPhone');
      case ProfileConfirmationMethods.SOCIAL:
        return t('profile.confirmWithSocial');
      default:
        return '';
    }
  };

  return (
    <LinearProfileWrapper>
      <div className={styles.avatar}>
        <ProfileAvatar
          name={profile.name}
          isKidsProfile={checkIsKidsProfile(profile)}
        />
      </div>
      <CloseProfileModal onClose={onClose} />

      <ProfileModalHeader text={t('profile.confirmationRequired')} />

      <p className={styles.note}>{t('profile.confirmationRequiredNote')}</p>

      <div className={styles.buttons}>
        {allowedConfirmationMethods?.map((method) => (
          <ConfirmationMethodButton
            onClick={() => onSelect(method)}
            text={getButtonText(method)}
          />
        ))}
      </div>
    </LinearProfileWrapper>
  );
};

export default ConfirmationMethodSelector;

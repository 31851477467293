import React from 'react';
import {
  CalculatePriceCouponResponse,
  Price,
} from '@laminar-product/client-commons-core/core';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.scss';
import DiscountSummary from './DiscountSummary';
import TotalInformation from './TotalInformation';

export interface PaymentSummaryProps {
  price: Price;
  calculatedPriceCoupon?: CalculatePriceCouponResponse;
  taxAmount?: number;
}

const PaymentSummary = ({
  price,
  calculatedPriceCoupon,
  taxAmount,
}: PaymentSummaryProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DiscountSummary
        price={price}
        calculatedPriceCoupon={calculatedPriceCoupon}
      />

      {!!taxAmount && (
        <div className={styles.taxAmount}>
          <span>{t('orderSummary.estimatedTax')}</span>
          {parseFloat((taxAmount / 100).toFixed(2))} {price.currency}
        </div>
      )}

      <div className={styles.couponApplied}>
        <div className={styles.total}>
          <span>
            {calculatedPriceCoupon || price.promotion
              ? t('orderSummary.todayTotal')
              : t('orderSummary.total')}{' '}
            {taxAmount ? t('orderSummary.taxIncluded') : ''}
          </span>
        </div>
        <div className={styles.couponApplied}>
          <span className={styles.total}>
            <TotalInformation
              price={price}
              calculatedPriceCoupon={calculatedPriceCoupon}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default PaymentSummary;

import { MutableRefObject, useEffect, useState } from 'react';

const useHoverExited = (elementRef: MutableRefObject<any>, timeout = 2500) => {
  const [hoverExited, toggleHoverExited] = useState(true);

  useEffect(() => {
    let mouseMoveTimeout: NodeJS.Timeout;
    function onMouseMove(event: Event) {
      clearTimeout(mouseMoveTimeout);
      toggleHoverExited(false);

      mouseMoveTimeout = setTimeout(() => {
        const isHoverElement = elementRef?.current?.contains(
          event.target as Node
        );
        if (!isHoverElement) {
          toggleHoverExited(true);
        }
      }, timeout);
    }

    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [elementRef, timeout]);

  return hoverExited;
};

export default useHoverExited;

import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface HamburgerButtonProps {
  isOpen: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const HamburgerButton = ({ isOpen, onClick }: HamburgerButtonProps) => {
  return (
    <div
      data-testid="hamburger"
      className={cn(styles.burger, {
        [styles.open]: isOpen,
      })}
      onClick={onClick}
    >
      <span className={styles.line}></span>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
    </div>
  );
};

export default HamburgerButton;

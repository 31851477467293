import { AssetPlaylist } from '@laminar-product/client-commons-core/core';
import { useVideoPlaylist } from '@laminar-product/client-commons-core/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssetWatchingProgress } from 'store/app/selectors';
import { setPlayerPlaylistActiveElement } from 'store/player/actions';
import {
  selectPlayerAssetId,
  selectPlayerPlaylist,
  selectPlayerPlaylistActiveElement,
  selectPlayerVideoElement,
} from 'store/player/selectors';

//This is wrapper for useVideoPlaylist for proper defining playlist props. UseVideoPlaylist is used both for shaka and videoJS player
const useVideoPlayerPlaylistController = () => {
  const playerPlaylist = useSelector(selectPlayerPlaylist);
  const assetId = useSelector(selectPlayerAssetId);
  const assetContinueWatchingProgress = useSelector(
    selectAssetWatchingProgress(assetId)
  );
  const videoElement = useSelector(selectPlayerVideoElement);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const activePlaylist = useSelector(selectPlayerPlaylistActiveElement);
  const dispatch = useDispatch();

  const onPlayerSeek = useCallback(
    (time: number) => {
      if (!videoElement) {
        return;
      }

      videoElement.currentTime = time;
    },
    [videoElement]
  );

  const onActivePlaylistChange = useCallback(
    (playlist: AssetPlaylist) =>
      dispatch(setPlayerPlaylistActiveElement(playlist)),
    [dispatch]
  );

  //Handles proper transitions between ads & content
  useVideoPlaylist({
    assetPlaylists: playerPlaylist,
    currentSecond: playerCurrentTime,
    setVideoTime: onPlayerSeek,
    setActivePlaylist: onActivePlaylistChange,
    activePlaylist,
    isFinished: assetContinueWatchingProgress?.finished,
  });

  //Set default
  useEffect(() => {
    if (!playerPlaylist?.length) {
      return;
    }

    dispatch(setPlayerPlaylistActiveElement(playerPlaylist[0]));
  }, [dispatch, playerPlaylist]);

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    const updateCurrentPlayerTime = () =>
      setPlayerCurrentTime(videoElement.currentTime);

    videoElement.addEventListener('timeupdate', updateCurrentPlayerTime);

    return () =>
      videoElement.removeEventListener('timeupdate', updateCurrentPlayerTime);
  }, [videoElement]);
};

export default useVideoPlayerPlaylistController;

import { Button } from '@laminar-product/client-commons-core/web';
import Input from 'components/Input';
import { PromptEmailForm } from 'components/PromptEmailModal';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthInputType, validateAuthInput } from 'utils/auth';
import styles from '../../index.module.scss';

interface PromptEmailProps {
  onSubmit: (email: string) => void;
}

const PromptEmail = ({ onSubmit }: PromptEmailProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PromptEmailForm>();

  const onContinueClick = ({ email }: PromptEmailForm) => onSubmit(email);

  return (
    <form onSubmit={handleSubmit(onContinueClick)}>
      <h1 className={styles.heading}>{t('settings.promptEmailHeaderPay')}</h1>
      <div className={styles.description}>
        {t('settings.promptEmailMessage')}
      </div>

      <Controller
        control={control}
        name="email"
        defaultValue=""
        rules={{
          validate: (value) => validateAuthInput(value, AuthInputType.EMAIL),
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={(e) => onChange(e.target.value.trim())}
            placeholder={t('common.placeholders.emailAddress')}
          />
        )}
      />

      <p className={styles.errorMessage}>{errors.email?.message}</p>

      <Button variant="primary" className={styles.button} type="submit">
        {t('common.continue')}
      </Button>
    </form>
  );
};

export default PromptEmail;

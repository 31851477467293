import React, { FC } from 'react';
import { TransitionEffect } from '@laminar-product/client-commons-core/web';
import styles from './index.module.scss';

interface FadeInTransitionProps extends React.PropsWithChildren {
  className?: string;
  isOpen: boolean;
}

const FadeInTransition: FC<FadeInTransitionProps> = ({
  className,
  children,
  isOpen,
}) => {
  return (
    <TransitionEffect
      isOpen={isOpen}
      children={children}
      className={className}
      styles={styles}
      timeout={0.12}
      unmountOnExit={true}
    />
  );
};

export default FadeInTransition;

import { useCallback, useMemo } from 'react';
import { Filters } from 'types/filters';
import { toArray } from 'utils/toArray';
import { useHistory, useLocation } from 'react-router-dom';
import useGetSearchParams from './useGetSearchParams';
import { MoveToListingParams, useMoveToListing } from './useMoveToListing';

interface UseSubheaderProps {
  assetType?: string;
  title?: string;
}

const useSubheader = ({ assetType, title }: UseSubheaderProps) => {
  const { replace } = useHistory();
  const searchParams = useGetSearchParams() as MoveToListingParams;
  const moveToListing = useMoveToListing();
  const { pathname } = useLocation();

  const { sort, bcpath, bc, type, audios, subtitles, mood } = searchParams;

  const filters: Filters = useMemo(
    () => ({
      audios: toArray(audios),
      subtitles: toArray(subtitles),
      mood: toArray(mood),
    }),
    [audios, mood, subtitles]
  );

  const pageType = assetType ? assetType.toLocaleLowerCase() : type;
  const nextBcPath = bcpath || pathname;
  const subheaderTitle = bc || title;

  const onGenreSelect = useCallback(
    (genre: string) => {
      if (!pageType) {
        return;
      }

      moveToListing(pageType, {
        ...searchParams,
        genre,
        bc: subheaderTitle,
        bcpath: nextBcPath,
        type: pageType,
      });
    },
    [subheaderTitle, moveToListing, nextBcPath, pageType, searchParams]
  );

  //If any of filters is applied - clear genres; otherwise go back to previous page without genres
  const onGenreReset = useCallback(() => {
    if (sort || Object.values(filters).find((f) => f.length > 0)) {
      return onGenreSelect('');
    }

    if (bcpath) {
      return replace(bcpath);
    }
  }, [bcpath, filters, onGenreSelect, replace, sort]);

  return {
    searchParams,
    filters,
    pageType,
    shouldShowPageOptions: !!pageType,
    nextBcPath,
    subheaderTitle,
    onGenreSelect,
    onGenreReset,
  };
};

export default useSubheader;

import { Asset, Collection } from '@laminar-product/client-commons-core/core';
import {
  CarouselType,
  isContinueWatchingCarousel,
} from 'utils/carouselHelpers';
import useRedirectToRootAsset, {
  shouldRedirectToRoot,
} from 'utils/useRedirectToRootAsset';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import useRedirectOnWatch from 'utils/useRedirectOnPosterWatch';
import { useCallback } from 'react';
import Carousel from '../Carousel';

interface CarouselProps {
  carouselType?: CarouselType;
  collection?: Collection;
  id?: string;
  testId?: string;
}

const CarouselWrapper = ({
  carouselType,
  collection,
  id,
  testId,
}: CarouselProps) => {
  const moveToAsset = useMoveToAssetDetails();
  const redirectToRootAsset = useRedirectToRootAsset();
  const redirectToPlayer = useRedirectOnWatch();

  const onItemClick = useCallback(
    async (item: Asset) => {
      if (isContinueWatchingCarousel(carouselType)) {
        return redirectToPlayer(item);
      }

      return shouldRedirectToRoot(item.type)
        ? redirectToRootAsset({ asset: item, type: 'Details' })
        : moveToAsset(item);
    },
    [carouselType, moveToAsset, redirectToPlayer, redirectToRootAsset]
  );

  return (
    <Carousel
      onItemClick={onItemClick}
      carouselType={carouselType}
      collection={collection}
      strataMetadata={{
        source_type: !isContinueWatchingCarousel(carouselType)
          ? 'collection'
          : 'continue_watching',
        source_id: id,
      }}
      testId={testId}
    />
  );
};

export default CarouselWrapper;

import Carousel, { StrataMetadata } from 'library/Carousel/Carousel';
import { CarouselType } from 'utils/carouselHelpers';
import useRecommendation from 'utils/useRecommendation';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import { useHistory } from 'react-router-dom';
import { Button } from '@laminar-product/client-commons-core/web';
import { Asset } from '@laminar-product/client-commons-core/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftCurved } from 'assets/icons/arrowLeftCurved.svg';
import cn from 'classnames';
import { useMemo } from 'react';
import { isMobile } from 'utils/isMobileOrTablet';
import styles from './index.module.scss';

interface VideoRecommendationOverlayProps {
  setRecommendationOverlayVisible: (visible: boolean) => void;
  asset: Asset;
  overlayClassName?: string;
}

export const VideoRecommendationOverlay = ({
  setRecommendationOverlayVisible,
  asset,
  overlayClassName,
}: VideoRecommendationOverlayProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { recommendationData = [] } = useRecommendation();
  const moveToAsset = useMoveToAssetDetails();

  const strataMetadata: StrataMetadata = useMemo(
    () => ({
      source_type: 'recommendation_more_like',
      source_referrer_item: {
        asset_id: asset.id,
        asset_name: asset.administrativeName,
      },
    }),
    [asset]
  );

  return (
    <div className={cn(styles.recommendationOverlay, overlayClassName)}>
      <div className={styles.carouselContainer}>
        <Carousel
          carouselType={
            isMobile() ? CarouselType.CAROUSEL : CarouselType.POSTER_SCALE_UP
          }
          collection={{
            assets: recommendationData,
            title: t('player.recommendationOverlay.watchMoreLikeThis'),
            administrativeName: 'Recommendation',
          }}
          onItemClick={moveToAsset}
          strataMetadata={strataMetadata}
        />
        <div className={styles.buttonsContainer}>
          <Button
            data-testid="recommendation_overlay_watch_credits"
            onClick={() => {
              setRecommendationOverlayVisible(false);
            }}
            className={styles.watchCreditsButton}
          >
            {t('player.recommendationOverlay.watchCredits')}
          </Button>
          <Button
            data-testid="recommendation_overlay_go_home"
            onClick={() => history.push('/')}
            className={styles.goHomeButton}
          >
            <ArrowLeftCurved className={styles.goHomeButtonIcon} />
            {t('player.recommendationOverlay.goHome')}
          </Button>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import AuthenticationMenu from 'components/AuthenticationMenu';
import AuthPageContainer from '../AuthPageContainer';
import styles from './index.module.scss';

interface AuthenticationContainerProps {
  children?: React.ReactNode;
  onLogoClick?: () => void;
}

const AuthenticationContainer = ({
  children,
  onLogoClick,
}: AuthenticationContainerProps) => {
  return (
    <AuthPageContainer>
      <div className={styles.loginRoot}>
        <AuthenticationMenu variant="signIn" onLogoClick={onLogoClick} />
        <div className={styles.content}>{children}</div>
      </div>
    </AuthPageContainer>
  );
};

export default AuthenticationContainer;

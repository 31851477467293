import Input from 'components/Input';
import cn from 'classnames';
import styles from '../index.module.scss';

interface BillingInputProps {
  label: string;
  addMargin?: boolean;
  error: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
}

const BillingInput = ({
  label,
  addMargin,
  testId,
  ...props
}: BillingInputProps) => (
  <Input
    light
    label={label}
    className={cn(styles.input, { [styles.rowInput]: addMargin })}
    data-testid={testId}
    {...props}
  />
);

export default BillingInput;

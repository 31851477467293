import AppLoadingIndicator from 'components/AppLoadingIndicator';
import Popup from 'components/Popup';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectSSOAuthenticationProviders,
  selectSocialAuthenticationProviders,
} from 'store/app/selectors';
import { SocialAuthenticationProviders } from 'utils/constants';
import SSOProvider from 'components/SSO';
import { SSOProviderType } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';
import SocialItem from './SocialItem';

interface SocialLoginProps {
  customAuthenticationAllowed: boolean;
  register?: boolean;
}

export interface AuthenticationFailedProps {
  isFailed: boolean;
  socialType?: SocialAuthenticationProviders;
  errorMessage: string;
}

const SocialLogin = ({
  customAuthenticationAllowed,
  register,
}: SocialLoginProps) => {
  const [authenticationFailed, setAuthenticationFailed] =
    useState<AuthenticationFailedProps>();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { t } = useTranslation();
  const allowedSocialAuthenticationProviders = useSelector(
    selectSocialAuthenticationProviders
  );
  const ssoAuthenticationProviders = useSelector(
    selectSSOAuthenticationProviders
  ) as SSOProviderType[];

  if (
    !allowedSocialAuthenticationProviders?.length &&
    !ssoAuthenticationProviders.length
  )
    return null;

  return (
    <>
      <div>
        {customAuthenticationAllowed && (
          <div className={styles.separator}>
            <span className={styles.separatorText}>
              {t('common.orContinueWith')}
            </span>
          </div>
        )}

        <div className={styles.socialsContainer}>
          {ssoAuthenticationProviders?.map((provider) => (
            <SSOProvider
              provider={provider}
              setAuthenticationFailed={setAuthenticationFailed}
              setIsAuthenticating={setIsAuthenticating}
            />
          ))}
          {allowedSocialAuthenticationProviders?.map((social) => (
            <SocialItem
              type={social}
              setAuthenticationFailed={setAuthenticationFailed}
              setIsAuthenticating={setIsAuthenticating}
              register={register}
            />
          ))}
        </div>
      </div>

      {authenticationFailed?.isFailed && (
        <Popup
          header={
            register ? t('errors.registrationFailed') : t('errors.loginFailed')
          }
          message={authenticationFailed.errorMessage}
          actionButtons={
            <Button
              variant="primary"
              onClick={() =>
                setAuthenticationFailed({ isFailed: false, errorMessage: '' })
              }
            >
              OK
            </Button>
          }
        />
      )}

      {isAuthenticating && (
        <Modal wrapperId="socialLoginModal" isOpen={isAuthenticating}>
          <AppLoadingIndicator />
        </Modal>
      )}
    </>
  );
};

export default SocialLogin;

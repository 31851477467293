import { Asset, Watchable } from '@laminar-product/client-commons-core/core';
import {
  EpisodeList as DesktopEpisodeList,
  MobileEpisodeList,
  Modal,
  Select,
} from '@laminar-product/client-commons-core/web';
import api from 'api';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import _get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAppData } from 'store/app/actions';
import {
  selectAppTheme,
  selectSeasonSortedChildren,
} from 'store/app/selectors';
import { RootState } from 'store/reducer';
import { AssetLocationState } from 'types/location';
import useEpisodesProgresses from 'utils/useEpisodesProgresses';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import useUpgradeToWatch from 'utils/useUpgradeToWatch';
import styles from './index.module.scss';

interface EpisodesListModalProps {
  asset: Asset;
  open: boolean;
  seriesName?: string;
  onClose: () => void;
  isAnonymous?: boolean;
  parentElement?: HTMLElement | null;
}

interface OptionType {
  label: string;
  value: string;
}

const EpisodesListModal = ({
  open,
  asset,
  seriesName,
  onClose,
  isAnonymous,
  parentElement,
}: EpisodesListModalProps) => {
  const dispatch = useDispatch();
  const location = useLocation<AssetLocationState>();
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const seasons = useSelector<RootState, Asset[]>((state) =>
    _get(state, `app.assetRoot.${asset.id}.root.children`, [])
  );
  const options = seasons
    .sort((first, second) => {
      if (!first.order || !second.order) return 0;
      return first.order - second.order;
    })
    .map((s: Asset) => ({
      label: s.name,
      value: s.id,
    }));
  const [selectedSeason, selectSeason] = useState<OptionType>();
  const { handleUpgradeToWatch } = useUpgradeToWatch();

  const seasonEpisodes = useSelector(
    selectSeasonSortedChildren(selectedSeason?.value)
  );

  const appTheme = useSelector(selectAppTheme);
  const { episodesProgresses } = useEpisodesProgresses({
    seasonId: selectedSeason?.value,
    skipFetch: false,
  });
  const { isTabletWindowSize } = useWindowSize();

  const EpisodeList = isTabletWindowSize
    ? MobileEpisodeList
    : DesktopEpisodeList;

  useEffect(() => {
    if (seasons.length && !selectedSeason) {
      const currentSeason = options.find(
        (o: OptionType) => o.value === asset.parentId
      );
      selectSeason(currentSeason);
    }
  }, [asset.parentId, options, seasons, selectedSeason]);

  useEffect(() => {
    if (selectedSeason) {
      api()
        .get(`/asset/v2/${selectedSeason.value}/children`)
        .then(({ data }) => {
          dispatch(setAppData('assetChildren', selectedSeason.value, data));
        });
    }

    return () => {};
  }, [selectedSeason, dispatch]);

  const handleRedirectToPlayer = useCallback(
    ({ id, type }: Asset) => {
      onClose();

      if (id !== asset.id) {
        moveToAssetPlayer(id, type, seriesName || '', {
          state: {
            showId: location.state?.showId,
          },
        });
      }
    },
    [asset.id, location.state?.showId, moveToAssetPlayer, onClose, seriesName]
  );

  const onEpisodeClick = useCallback(
    (episode: Asset) => {
      switch (episode.watchable) {
        case Watchable.YES:
        case Watchable.YES_ANONYMOUS_ACCESS:
          return handleRedirectToPlayer(episode);
        case Watchable.PLAN_UPGRADE_REQUIRED:
          return handleUpgradeToWatch();
        default:
          return;
      }
    },

    [handleUpgradeToWatch, handleRedirectToPlayer]
  );

  if (!open) return null;

  return (
    <Modal
      parentEl={parentElement}
      className={styles.root}
      wrapperId="episodesListModal"
      isOpen={open}
    >
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <span className={styles.name}>{seriesName}</span>
          <Close className={styles.closeIcon} onClick={onClose} />
        </div>

        <Select
          appTheme={appTheme}
          options={options}
          value={selectedSeason}
          onChange={(value) => {
            selectSeason(value as OptionType);
          }}
          className={styles.seasonSelector}
        />

        <EpisodeList
          seasonSelector={
            <Select
              appTheme={appTheme}
              width="200px"
              options={options}
              value={selectedSeason}
              onChange={(value) => {
                selectSeason(value as OptionType);
              }}
            />
          }
          episodes={seasonEpisodes}
          episodesProgresses={episodesProgresses}
          currentEpisode={asset}
          isAnonymous={isAnonymous}
          onEpisodeClick={onEpisodeClick}
        />
      </div>
    </Modal>
  );
};

export default EpisodesListModal;

import { useCallback, useEffect } from 'react';
import {
  SchemaVersion,
  TrackEvent,
} from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import {
  selectPlayer,
  selectPlayerCurrentFrameIsAd,
  selectPlayerFrames,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { AdTrackEventPayload } from 'types/player';
import { useStrataStateMachine } from 'state-machines/strata/context';
import { StrataPlayerState } from 'state-machines/strata/types';
import { VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL } from './constants';
import { checkIfAssetFinished } from './player';

const useVideoPlayerStrataEvents = () => {
  const strataPlayerMachine = useStrataStateMachine();
  const videoElement = useSelector(selectPlayerVideoElement);
  const player = useSelector(selectPlayer);
  const frames = useSelector(selectPlayerFrames);
  const isPlayingAds = useSelector(selectPlayerCurrentFrameIsAd);

  const checkIfFinished = useCallback(() => {
    if (!videoElement) return false;

    return checkIfAssetFinished({
      currentTime: videoElement.currentTime,
      duration: videoElement.duration,
      frames,
    });
  }, [frames, videoElement]);

  const onVideoPlaying = useCallback(() => {
    const bandwidth = player?.getStats()?.streamBandwidth;
    const bitrate = bandwidth ? Number((bandwidth / 1000000).toFixed(2)) : 0;

    strataPlayerMachine?.send({
      type: 'PLAYING_VIDEO',
      params: { event: TrackEvent.VIDEO_PLAYING, data: { bitrate } },
    });
  }, [player, strataPlayerMachine]);

  const onVideoReadyToPlay = useCallback(() => {
    strataPlayerMachine?.send({
      type: 'SET_PLAYER_READY',
      params: { event: TrackEvent.PLAYER_READY },
    });
  }, [strataPlayerMachine]);

  const onVideoPause = useCallback(() => {
    strataPlayerMachine?.send({
      type: 'STOP_VIDEO',
      params: {
        event: TrackEvent.VIDEO_STOP,
        data: { is_next_episode: false },
      },
    });
  }, [strataPlayerMachine]);

  const onVideoPlay = useCallback(() => {
    if (isPlayingAds) return;

    strataPlayerMachine?.send({
      type: 'START_VIDEO',
      params: { event: TrackEvent.VIDEO_START },
    });
    onVideoPlaying();
  }, [isPlayingAds, onVideoPlaying, strataPlayerMachine]);

  const onVideoFail = useCallback(
    (error_code: string, error_message: string) => {
      strataPlayerMachine?.send({
        type: 'FAIL_VIDEO',
        params: {
          event: TrackEvent.VIDEO_FAIL,
          data: {
            error_code,
            error_message,
          },
        },
      });
    },
    [strataPlayerMachine]
  );

  const onVideoBufferStart = useCallback(() => {
    strataPlayerMachine?.send({
      type: 'VIDEO_START_BUFFER',
      params: {
        event: TrackEvent.VIDEO_BUFFER_START,
      },
    });
  }, [strataPlayerMachine]);

  const onVideoBufferEnd = useCallback(() => {
    strataPlayerMachine?.send({
      type: 'VIDEO_END_BUFFER',
      params: {
        event: TrackEvent.VIDEO_BUFFER_END,
      },
    });

    //Send play event if video finished buffering
    if (!videoElement?.paused) {
      onVideoPlay();
    }
  }, [onVideoPlay, strataPlayerMachine, videoElement?.paused]);

  const onAdStart = useCallback(
    ({ adId, adPodId }: AdTrackEventPayload) => {
      strataPlayerMachine?.send({
        type: 'START_AD',
        params: {
          event: TrackEvent.AD_START,
          version: SchemaVersion.V100,
          data: {
            original_playback_position: Math.round(
              videoElement?.currentTime || 0
            ),
            ad_id: adId,
            adpod_id: adPodId,
          },
        },
      });
    },
    [strataPlayerMachine, videoElement?.currentTime]
  );

  const onAdEnd = useCallback(
    ({ adId, adPodId }: AdTrackEventPayload) => {
      strataPlayerMachine?.send({
        type: 'END_AD',
        params: {
          event: TrackEvent.AD_END,
          version: SchemaVersion.V100,
          data: {
            ad_id: adId,
            adpod_id: adPodId,
          },
        },
      });
    },
    [strataPlayerMachine]
  );

  const onAdPause = useCallback(
    ({ adId, adPodId }: AdTrackEventPayload) => {
      strataPlayerMachine?.send({
        type: 'STOP_AD',
        params: {
          event: TrackEvent.AD_STOP,
          version: SchemaVersion.V100,
          data: {
            ad_id: adId,
            adpod_id: adPodId,
          },
        },
      });
    },
    [strataPlayerMachine]
  );

  usePolling(onVideoPlaying, {
    interval: VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL,
    shouldRunPolling:
      strataPlayerMachine?.getSnapshot().value !== StrataPlayerState.IDLE,
  });

  useEffect(() => {
    if (
      strataPlayerMachine?.getSnapshot().value === StrataPlayerState.AD_END &&
      !isPlayingAds
    ) {
      onVideoPlay();
    }
  }, [isPlayingAds, onVideoPlay, strataPlayerMachine]);

  useEffect(() => {
    return () => {
      strataPlayerMachine?.send({
        type: 'STOP_VIDEO',
        params: {
          event: TrackEvent.VIDEO_STOP,
          data: { is_next_episode: checkIfFinished() },
        },
      });
    };
  }, [checkIfFinished, strataPlayerMachine]);

  return {
    onVideoReadyToPlay,
    onVideoPause,
    onVideoPlay,
    onVideoFail,
    onVideoBufferStart,
    onVideoBufferEnd,
    onAdEnd,
    onAdStart,
    onAdPause,
  };
};

export default useVideoPlayerStrataEvents;

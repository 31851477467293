import React from 'react';
import PlanCarousel from './components/PlanCarousel';
import styles from './index.module.scss';
import { PlanByIntervalWithPrice } from './types';

interface PlanSelectorProps {
  plans?: PlanByIntervalWithPrice[];
  activeIntervalKey: string;
}

const PlanSelector = ({ plans, activeIntervalKey }: PlanSelectorProps) => (
  <div className={styles.root}>
    <PlanCarousel plans={plans} activeIntervalKey={activeIntervalKey} />
  </div>
);

export default PlanSelector;

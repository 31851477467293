import { FadeOutTransition } from '@laminar-product/client-commons-core/web';
import { useEffect, useRef, useState } from 'react';
import useHoverExited from 'utils/useHoverExited';
import { selectPlayerVideoElement } from 'store/player/selectors';
import { useSelector } from 'react-redux';
import { PLAYER_OVERLAY_TIMEOUT } from 'utils/constants';
import styles from './index.module.scss';
import TopControls from './components/TopControls';

const VideoPlayerOverlay = () => {
  const videoOverlayRef = useRef<HTMLDivElement>(null);
  const hoverExited = useHoverExited(videoOverlayRef, PLAYER_OVERLAY_TIMEOUT);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const videoElement = useSelector(selectPlayerVideoElement);

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    if (hoverExited && videoElement.paused) {
      return;
    }

    setIsOverlayVisible(!hoverExited);
  }, [hoverExited, videoElement]);

  return (
    <FadeOutTransition
      isOpen={isOverlayVisible}
      className={styles.videoOverlayTransition}
      timeout={{ enter: 100, exit: 700 }}
    >
      <div className={styles.overlayWrapper} ref={videoOverlayRef}>
        <TopControls />
      </div>
    </FadeOutTransition>
  );
};

export default VideoPlayerOverlay;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import { useGetCurrentProfile } from './profiles';

const useCanPlayTrailer = () => {
  const [canPlayTrailer, setCanPlayTrailer] = useState<boolean>();
  const isAnonymous = useSelector(selectIsAnonymous);
  const profile = useGetCurrentProfile();

  useEffect(() => {
    if (isMobileOrTablet()) {
      setCanPlayTrailer(false);
      return;
    }

    if (isAnonymous) {
      setCanPlayTrailer(true);
      return;
    }

    if (profile) {
      const playTrailer = profile?.playbackSettings?.playTrailer;
      const profileSetting = playTrailer === undefined ? true : playTrailer;

      setCanPlayTrailer(profileSetting);
      return;
    }
  }, [isAnonymous, profile]);

  return { canPlayTrailer };
};

export default useCanPlayTrailer;

import { Profile } from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import { changeLanguage } from 'i18next';
import {
  DEFAULT_USER_LANGUAGE,
  LOCAL_STORAGE_I18NEXT_LANGUAGE_KEY,
} from './constants';

//This hook is responsible for changing current profile language. It used for both profile change and sign out
const useChangeProfileLanguage = () => {
  const changeProfileLanguage = useCallback(async (profile: Profile | null) => {
    const languageValue = profile?.language || DEFAULT_USER_LANGUAGE;
    const currentLang = localStorage.getItem(
      LOCAL_STORAGE_I18NEXT_LANGUAGE_KEY
    );

    if (currentLang === languageValue) {
      return;
    }

    localStorage.setItem(LOCAL_STORAGE_I18NEXT_LANGUAGE_KEY, languageValue);
    await changeLanguage(); //i18next will automatically read new value from local storage and update dynamically translations
  }, []);

  return { changeProfileLanguage };
};

export default useChangeProfileLanguage;

import { RootState } from 'store/reducer';

export const selectRegistrationSelectedPlan = (state: RootState) =>
  state.registration.selectedPlan;

export const selectRegistrationSelectedPrice = (state: RootState) =>
  state.registration.selectedPrice;

export const selectRegistrationMethod = (state: RootState) =>
  state.registration.method;

export const selectRegistrationUserLogin = (state: RootState) =>
  state.registration.userLogin;

export const selectRegistrationUserPaymentEmail = (state: RootState) =>
  state.registration.paymentEmail;

export const selectRegistrationPhoneNumber = (state: RootState) =>
  state.registration.userLogin;

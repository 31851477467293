import {
  Asset,
  ContentType,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';

export default class BaseAssetModel {
  protected asset: Asset;

  constructor(asset: Asset) {
    this.asset = asset;
  }

  get mainContent() {
    if (!this.asset) {
      return null;
    }
    return getAssetContentWithType(this.asset, ContentType.MAIN);
  }

  getContentById(id: string) {
    if (!this.asset) {
      return null;
    }

    return this.asset.contents.find((content) => content.slug === id);
  }
}

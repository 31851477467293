import { RootState } from 'store/reducer';

export const selectPage = (pageId?: string) => (state: RootState) =>
  pageId ? state.pages?.pagesDetails?.[pageId] : undefined;

export const selectPageLastFetchedPagination =
  (pageId?: string) => (state: RootState) =>
    pageId ? state.pages?.pagesLastFetchedPagination?.[pageId] : undefined;

export const selectPageFetchingStatus =
  (pageId?: string) => (state: RootState) =>
    pageId ? state.pages.pagesFetchingStatus?.[pageId] : undefined;

import { useSelector } from 'react-redux';
import { Subscription } from 'types/subscription';
import {
  selectPlatformPartnerMetadata,
  selectUserDetails,
} from 'store/user/selectors';
import AccountUserData from './components/AccountUserData';
import AccountSubscription from './components/AccountSubscription';
import AccountPaymentMethodFactory from './components/AccountPaymentMethodFactory';

interface AccountDetailsProps {
  subscription?: Subscription;
  isSubscriptionError?: boolean;
}

const AccountDetails = ({
  subscription,
  isSubscriptionError,
}: AccountDetailsProps) => {
  const userData = useSelector(selectUserDetails);
  const platformPartner = useSelector(selectPlatformPartnerMetadata);

  return (
    <>
      <AccountUserData userData={userData} platformPartner={platformPartner} />

      <AccountSubscription
        subscription={subscription}
        userData={userData}
        isSubscriptionError={isSubscriptionError}
      />

      <AccountPaymentMethodFactory
        platformPartner={platformPartner}
        subscription={subscription}
        userData={userData}
      />
    </>
  );
};

export default AccountDetails;

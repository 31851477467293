import { SOCIAL_AUTH_PROVIDERS_IDS } from '@laminar-product/client-commons-core/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUserDetails,
  selectUserProvidedData,
} from 'store/user/selectors';

const useSocialUser = () => {
  const userProvidedData = useSelector(selectUserProvidedData);
  const userEmail = useSelector(selectUserDetails)?.email;

  const hasEmailProvider = useMemo(
    () =>
      userProvidedData?.some((p) => p.providerId === 'password') && !!userEmail,
    [userEmail, userProvidedData]
  );

  const currentSocialIdProvider = useMemo(
    () =>
      userProvidedData?.find((p) =>
        SOCIAL_AUTH_PROVIDERS_IDS?.includes(p.providerId)
      )?.providerId,
    [userProvidedData]
  );

  return { currentSocialIdProvider, hasEmailProvider };
};

export default useSocialUser;

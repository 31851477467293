import { Language, languages } from '@laminar-product/client-commons-core/core';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { Severity } from 'types/errors';
import { fetchPlatformLanguages } from 'actions/basic';
import { AxiosError } from 'axios';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { captureError } from './captureError';
import { splitLanguage } from './splitLanguage';
import { DEFAULT_USER_LANGUAGE } from './constants';

const mapLanguageToSelect = (language?: Language) => ({
  value: language?.alpha2 || '',
  label: language?.label || '',
});

const useLanguages = () => {
  const [availableLanguages, setPlatformLanguages] = useState<Language[]>();
  const [defaultLanguageIsoCode, setDefaultLanguageIsoCode] =
    useState<string>();
  const [userCurrentLanguage, setUserCurrentLanguage] = useState<string>(
    DEFAULT_USER_LANGUAGE
  );
  const browserCurrentLanguage = splitLanguage(i18n.language); //i18n automatically read language change in local storage. It's set in useChangeProfileLanguage and useFirebaseAuth

  const [, isLoadingLanguages] = useFetchAction(
    fetchPlatformLanguages,
    useMemo(
      () => ({
        onDone: ({ availableLanguagesIsoCodes, defaultLanguageIsoCode }) => {
          const languagesWithLabel = languages.filter(({ alpha2 }) =>
            [
              ...(availableLanguagesIsoCodes || []),
              defaultLanguageIsoCode,
            ].find((lang) => lang === alpha2)
          );
          setDefaultLanguageIsoCode(defaultLanguageIsoCode);
          setPlatformLanguages(languagesWithLabel);
        },
        onError: (error) => {
          captureError(
            error as AxiosError,
            'fetchPlatformLanguages',
            Severity.Error
          );
        },
      }),
      []
    )
  );

  const getLanguageByIsoCode = useCallback(
    (isoCode?: string) =>
      availableLanguages?.find((lang) => lang.alpha2 === isoCode),
    [availableLanguages]
  );

  const availableLanguageSelectOptions = useMemo(
    () => availableLanguages?.map(mapLanguageToSelect) || [],
    [availableLanguages]
  );

  const formatLanguageSelectValue = useCallback(
    (value?: string) => {
      if (!value) return;

      const lang = getLanguageByIsoCode(splitLanguage(value));

      return mapLanguageToSelect(lang);
    },
    [getLanguageByIsoCode]
  );

  // Detect language change
  useEffect(() => {
    if (userCurrentLanguage === browserCurrentLanguage) {
      return;
    }

    // Check if new language is supported
    const hasUserLanguage = !!getLanguageByIsoCode(browserCurrentLanguage);

    if (!hasUserLanguage) {
      return;
    }

    setUserCurrentLanguage(browserCurrentLanguage);
  }, [
    browserCurrentLanguage,
    getLanguageByIsoCode,
    isLoadingLanguages,
    userCurrentLanguage,
  ]);

  return {
    getLanguageByIsoCode,
    isLoadingLanguages,
    availableLanguages,
    defaultLanguageIsoCode,
    selectedLanguage: userCurrentLanguage,
    availableLanguageSelectOptions,
    formatLanguageSelectValue,
  };
};

export default useLanguages;

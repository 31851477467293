import { Asset } from '@laminar-product/client-commons-core/core';
import { AnyAction } from 'redux';
import { RESET_ASSETS, SET_ASSET, SET_ASSETS } from './actions';

export interface AssetsState {
  [key: string]: Asset;
}

const assetsReducer = (state: AssetsState = {}, action: AnyAction) => {
  switch (action.type) {
    case SET_ASSET:
      return {
        ...state,
        [action.payload.id]: action.payload.asset,
      };
    case SET_ASSETS:
      return {
        ...state,
        ...(action.payload.assets as Asset[]).reduce((acc, asset) => {
          acc[asset.id] = asset;
          return acc;
        }, {} as AssetsState),
      };
    case RESET_ASSETS:
      return {};
    default:
      return state;
  }
};

export default assetsReducer;

import { memo, useLayoutEffect, useMemo, useRef } from 'react';
import { SubHeaderProps } from 'components/SubHeader';
import cn from 'classnames';
import useScroll from 'utils/useScroll';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import MobileStoreBadge from 'components/MobileStoreBadge';
import { useDispatch, useSelector } from 'react-redux';
import { closeMobileAlert, openMobileAlert } from 'store/mobileAlert/actions';
import {
  selectMobileAlertHadNoInteraction,
  selectMobileAlertOpen,
} from 'store/mobileAlert/selectors';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import { isIos } from 'utils/getUserOs';
import { selectAppStoreLinks } from 'store/app/selectors';
import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DesktopMenu';
import styles from './index.module.scss';

export interface MenuProps {
  hasSearch?: boolean;
  isFixed?: boolean;
  position?: 'absolute' | 'static';
  subheader?: Omit<SubHeaderProps, 'fixed'>;
  hasNotifications?: boolean;
}

const Menu = ({
  hasSearch = true,
  isFixed = true,
  position = 'static',
  subheader,
  hasNotifications = true,
}: MenuProps) => {
  const { isTabletWindowSize } = useWindowSize();
  const dispatch = useDispatch();
  const { y: scrollY } = useScroll();
  const navRef = useRef<HTMLDivElement>(null!);
  const alertRef = useRef<HTMLDivElement>(null!);
  const mobileAlertOpen = useSelector(selectMobileAlertOpen);
  const alertHasNotBeenClosed = useSelector(selectMobileAlertHadNoInteraction);
  const appStoreLinks = useSelector(selectAppStoreLinks);

  const linkProvidedForPlatform = useMemo(
    () => (isIos() ? !!appStoreLinks?.appstore : !!appStoreLinks?.playstore),
    [appStoreLinks]
  );

  const onMobileAlertClose = () => dispatch(closeMobileAlert());

  useLayoutEffect(() => {
    if (
      isMobileOrTablet() &&
      alertHasNotBeenClosed &&
      linkProvidedForPlatform
    ) {
      dispatch(openMobileAlert());
    }
  }, [alertHasNotBeenClosed, dispatch, linkProvidedForPlatform]);

  const isMainMenuScrolled =
    scrollY >
    navRef.current?.offsetTop +
      navRef.current?.clientHeight +
      (alertRef.current?.clientHeight || 0);

  const shouldMainMenuBeFixed =
    isFixed && scrollY > navRef.current?.getBoundingClientRect().height;

  //Handles only content pages with jumbotron
  const shouldAddGradientToMenu =
    isFixed && position === 'absolute' && !isMainMenuScrolled;

  return (
    <>
      {isMobileOrTablet() && mobileAlertOpen && (
        <MobileStoreBadge onClose={onMobileAlertClose} ref={alertRef} />
      )}
      <div
        data-testid="Menu"
        className={cn(styles.root, {
          [styles.absoluteRoot]: position === 'absolute',
          [styles.fixedFiller]:
            isMainMenuScrolled &&
            subheader &&
            !isTabletWindowSize &&
            position !== 'absolute',
          [styles.fixedFillerWithoutSubheader]:
            shouldMainMenuBeFixed && !subheader,
          [styles.mobileAlertRootMenu]:
            isMobileOrTablet() && mobileAlertOpen && position === 'absolute',
          [styles.menuFixed]: shouldMainMenuBeFixed,
          [styles.gradient]: shouldAddGradientToMenu,
        })}
      >
        <nav
          ref={navRef}
          className={cn(styles.menu, {
            [styles.background]: isMainMenuScrolled && shouldMainMenuBeFixed,
            [styles.fixed]: shouldMainMenuBeFixed,
            [styles.withMobileAlert]: isTabletWindowSize,
          })}
        >
          {isTabletWindowSize ? (
            <div
              className={cn(styles.mobileMenuContainer, {
                [styles.background]: isMainMenuScrolled,
                [styles.withMobileSubheader]: !!subheader,
              })}
            >
              <MobileMenu
                mobileAlertOpen={!!mobileAlertOpen}
                subheader={subheader}
              />
            </div>
          ) : (
            <DesktopMenu
              hasSearch={hasSearch}
              hasNotifications={hasNotifications}
              subheader={subheader}
            />
          )}
        </nav>
      </div>
    </>
  );
};

export default memo(Menu);

import { Fragment, useMemo } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TranslationPrefix } from 'types/translation';
import styles from './index.module.scss';

export interface BreadcrumbItem {
  title: string;
  link?: string;
  translationPrefix?: TranslationPrefix;
}

interface BreadcrumbsProps {
  pageConfig: {
    title?: string;
    bcpath?: string;
  };
  genre?: string;
}

const Breadcrumbs = ({ genre, pageConfig }: BreadcrumbsProps) => {
  const { t } = useTranslation();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => {
    if (!pageConfig) {
      return [];
    }

    const defaultBreadcrumb = {
      title: pageConfig.title || '',
      link: pageConfig.bcpath,
    };

    return genre
      ? [
          defaultBreadcrumb,
          { title: genre as string, translationPrefix: 'genre' },
        ]
      : [defaultBreadcrumb];
  }, [genre, pageConfig]);

  if (!pageConfig.title) {
    return null; //When there is no title available, the genre selector is displayed instead of breadcrumbs
  }

  return (
    <div className={styles.root}>
      {breadcrumbItems.map((item, i) => {
        const isNotLast = i !== breadcrumbItems.length - 1;
        return (
          <Fragment key={i}>
            {item.link && isNotLast ? (
              <NavLink
                className={cn(styles.title, {
                  [styles.greyedOutTitle]: isNotLast,
                })}
                to={item.link}
              >
                {item.title}
              </NavLink>
            ) : (
              <div className={styles.title}>
                {t(`${item.translationPrefix}.${item.title}`, {
                  defaultValue: item.title,
                })}
              </div>
            )}
            {isNotLast && <ArrowRight className={styles.arrow} />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;

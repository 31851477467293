import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectIsVerified,
  selectProfiles,
  selectUserManagingProfilesVariant,
} from 'store/user/selectors';
import { useGetCurrentProfile, useSetCurrentProfile } from './profiles';
import { APP_PAGE_PATH } from './navigation';

const useShowProfileSelector = () => {
  const location = useLocation();
  const currentProfile = useGetCurrentProfile();
  const profiles = useSelector(selectProfiles);
  const verified = useSelector(selectIsVerified);
  const setCurrentProfile = useSetCurrentProfile();
  const userManagingProfilesVariant = useSelector(
    selectUserManagingProfilesVariant
  );

  const shouldUserSelectProfile = useMemo(() => {
    if (userManagingProfilesVariant) {
      return true;
    }

    if (!profiles || profiles.length === 0 || !!currentProfile) {
      return false;
    }

    const isDefaultProfileLocked =
      !!profiles && profiles.length === 1 && !!profiles[0]?.pin;

    return (
      !!verified &&
      !currentProfile &&
      (profiles.length > 1 || isDefaultProfileLocked)
    );
  }, [userManagingProfilesVariant, profiles, currentProfile, verified]);

  useEffect(() => {
    if (!currentProfile && profiles?.length === 1 && !profiles?.[0]?.pin) {
      setCurrentProfile(profiles[0]);
    }
  }, [currentProfile, profiles, setCurrentProfile]);

  const showProfileSelector = !location.pathname.startsWith(
    APP_PAGE_PATH.REGISTER
  )
    ? shouldUserSelectProfile
    : false;

  return showProfileSelector;
};

export default useShowProfileSelector;

import { useEffect } from 'react';

interface UseOutsideClickProps {
  el: HTMLDivElement | null;
  onOutsideClick: () => void;
}

const useOutsideClick = ({ el, onOutsideClick }: UseOutsideClickProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (el && event.target && !el.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [el, onOutsideClick]);
};

export default useOutsideClick;

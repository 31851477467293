import { Profile } from '@laminar-product/client-commons-core/core';
import SettingsSwitchItem from 'components/SettingsSwitchItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MATURITY_VALUE, MATURITY_KIDS_VALUE } from 'utils/constants';
import ParentalPINControl from './ParentalPINControl';

interface ParentalControlProps {
  profile?: Profile;
  saveProfile: (profile: Profile) => void;
}

const ParentalControlTab = ({ profile, saveProfile }: ParentalControlProps) => {
  const { t } = useTranslation();
  const [kidsProfilechecked, setKidsProfileChecked] = useState<boolean>(false);

  const handleKidsProfileToogle = (checked: boolean) => {
    setKidsProfileChecked(checked);

    if (profile?.uuid) {
      saveProfile({
        uuid: profile.uuid,
        maturityRating: checked ? MATURITY_KIDS_VALUE : DEFAULT_MATURITY_VALUE,
      });
    }
  };

  useEffect(() => {
    if (profile?.maturityRating === MATURITY_KIDS_VALUE) {
      setKidsProfileChecked(true);
    }
  }, [profile]);

  return (
    <>
      <ParentalPINControl profile={profile} saveProfile={saveProfile} />

      <SettingsSwitchItem
        checked={kidsProfilechecked}
        handleToggle={handleKidsProfileToogle}
        title={t(`profile.kidsProfile`)}
        subTitle={t(`profile.kidsProfileDescription`)}
        theme="settings"
      />
    </>
  );
};

export default ParentalControlTab;

import { Button } from '@laminar-product/client-commons-core/web';
import React from 'react';
import styles from './index.module.scss';

const SettingsButtonGroup = ({
  okText,
  cancelText,
  onCancel,
  onOk,
  okType,
  isOkLoading,
}: {
  okText: string;
  cancelText: string;
  onOk?: (event: React.MouseEvent) => void;
  onCancel?: (event: React.MouseEvent) => void;
  okType?: 'button' | 'submit' | 'reset';
  isOkLoading?: boolean;
}) => (
  <div className={styles.root}>
    <Button
      type={okType}
      variant="primary-settings"
      onClick={onOk}
      loading={isOkLoading}
    >
      {okText}
    </Button>
    <Button variant="secondary-settings" onClick={onCancel}>
      {cancelText}
    </Button>
  </div>
);

export default SettingsButtonGroup;

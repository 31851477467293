import cn from 'classnames';
import React from 'react';
import { PosterProps } from 'utils/carouselHelpers';
import styles from './index.module.scss';

export const ScaleUpPoster = ({
  data,
  onItemClick,
  className,
  posterUrl,
}: PosterProps) => {
  return (
    <div
      data-testid={`scale_up_poster_${data.id}`}
      onClick={() => onItemClick(data)}
      className={cn(className, styles.poster)}
    >
      <div
        style={{
          backgroundImage: `url(${posterUrl}_630x945)`,
        }}
        title={data.name}
        className={cn(styles.posterImageWrapper, {
          [styles.posterPlaceholder]: !posterUrl,
        })}
      >
        {!posterUrl && (
          <div className={styles.posterPlaceholderName}>{data.name}</div>
        )}
      </div>
    </div>
  );
};

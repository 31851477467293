import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CodeInput from 'react-code-input';
import {
  PIN_ALREADY_REDEEMED_ERROR_CODE,
  PIN_INVALID_DATE,
} from 'utils/constants';
import { Button } from '@laminar-product/client-commons-core/web';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { redeemPrepaidPin } from 'actions/payments';
import { useSelector } from 'react-redux';
import { selectUserHasPlan } from 'store/user/selectors';
import styles from '../index.module.scss';

interface RedeemCodeEntryProps {
  onClose: () => void;
  onPinRedeemSuccess: () => void;
  isRefreshingPlan?: boolean;
}

const RedeemCodeEntry = ({
  onClose,
  onPinRedeemSuccess,
  isRefreshingPlan,
}: RedeemCodeEntryProps) => {
  const { t } = useTranslation();
  const [codeValue, setCodeValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const userHasPlan = useSelector(selectUserHasPlan);
  const onValueChange = (value: string) => {
    setError('');
    setCodeValue(value);
  };

  const handleError = (error?: any) => {
    const errorCode: string | undefined = error?.response?.data?.code;

    if (errorCode === PIN_ALREADY_REDEEMED_ERROR_CODE) {
      setError(t('planSelector.pinAlreadyRedeemed'));
      return;
    }

    if (errorCode === PIN_INVALID_DATE) {
      setError(t('planSelector.pinInvalidDate'));
      return;
    }

    setError(t('planSelector.pinDefaultError'));
  };

  const { sendAction: redeemCode, isLoading: isRedeemingCode } =
    useSendActionObj(
      useCallback((pin: string) => redeemPrepaidPin(pin), []),
      {
        onDone: ({ data }) => {
          if (data.success === true) {
            onPinRedeemSuccess();
            return;
          }
        },
        onError: handleError,
      }
    );

  const onConfirm = (codeValue?: string) => {
    if (!codeValue || codeValue?.length < 8) {
      setError(t('errors.invalidPrepaidCode'));
      return;
    }

    redeemCode(codeValue);
  };

  return (
    <>
      <p className={styles.header}>{t('planSelector.enterRedeemCode')}</p>

      <div className={styles.codeInputWrapper}>
        <CodeInput
          autoFocus={false}
          value={codeValue}
          onChange={onValueChange}
          inputMode="latin"
          name="redeemCode"
          fields={8}
          className={styles.codeInput}
        />
      </div>

      {userHasPlan && (
        <p className={styles.message}>
          {t('planSelector.subscriptionWillBeCancelled')}
        </p>
      )}

      <div className={styles.buttons}>
        <Button variant="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>

        <Button
          variant="primary"
          onClick={() => onConfirm(codeValue)}
          disabled={isRefreshingPlan}
          loading={isRedeemingCode || isRefreshingPlan}
        >
          {t('planSelector.redeem')}
        </Button>
      </div>

      {!!error && <p className={styles.error}>{error}</p>}
    </>
  );
};

export default RedeemCodeEntry;

import {
  NextAssetResponse,
  getAssetImageWithType,
  isAssetWatchable,
} from '@laminar-product/client-commons-core/core';
import { ReactComponent as Next } from 'assets/icons/next.svg';
import PopOver from 'components/PopOver';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import { useLocation } from 'react-router-dom';
import { AssetLocationState } from 'types/location';
import { Button } from '@laminar-product/client-commons-core/web';
import {
  EPISODE_SHOW_URL_TYPE,
  JUMBOTRON_DESCRIPTION_MAX_LENGTH,
} from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectAsset } from 'store/assets/selectors';
import _truncate from 'lodash/truncate';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import { isMobile } from 'utils/isMobileOrTablet';
import styles from './index.module.scss';

interface NextEpisodeButtonProps {
  seriesName?: string;
  rootNextAsset: NextAssetResponse;
}

const NextEpisodeButton = ({
  seriesName,
  rootNextAsset,
}: NextEpisodeButtonProps) => {
  const { t } = useTranslation();
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const location = useLocation<AssetLocationState>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showEpisodeDetails, toggleShowEpisodeDetails] = useState(false);
  const nextEpisodeDetails = useSelector(selectAsset(rootNextAsset.uuid));
  const canWatchNextEpisode = isAssetWatchable(nextEpisodeDetails?.watchable);
  const { isTabletWindowSize } = useWindowSize();

  const handleRedirectToPlayer = () => {
    if (!nextEpisodeDetails || !canWatchNextEpisode || !location.state?.showId)
      return;

    moveToAssetPlayer(
      nextEpisodeDetails.id,
      EPISODE_SHOW_URL_TYPE,
      seriesName || '',
      {
        state: {
          showId: location.state.showId,
        },
      }
    );
  };

  if (!nextEpisodeDetails || !canWatchNextEpisode) return null;
  //TODO: Handle case when plan upgrade required for next episode

  const buttonProps = {
    ref: buttonRef,
    onClick: handleRedirectToPlayer,
    ...(!isMobile() && {
      onMouseEnter: () => toggleShowEpisodeDetails(true),
      onMouseLeave: () => toggleShowEpisodeDetails(false),
    }),
  };

  return (
    <div className={styles.wrapper}>
      {isTabletWindowSize ? (
        <Button
          variant="secondary"
          round
          className={styles.mobileNextBtn}
          {...buttonProps}
        >
          <Next />
        </Button>
      ) : (
        <Button className={styles.nextBtn} variant="secondary" {...buttonProps}>
          <Next />
          {t('player.nextEpisode')}
        </Button>
      )}

      <PopOver
        placement="bottom-start"
        anchorEl={buttonRef.current}
        isOpen={showEpisodeDetails}
        onClose={() => toggleShowEpisodeDetails(false)}
      >
        <div className={styles.nextEpisode}>
          <div>
            <img
              className={styles.poster}
              src={getAssetImageWithType(nextEpisodeDetails, 'child')?.url}
              alt="episode poster"
            />
          </div>
          <div className={styles.episodeContent}>
            <h3 className={styles.episodeIndex}>
              {t('common.episode')} {nextEpisodeDetails.order}
            </h3>
            <span className={styles.episodeName}>
              {nextEpisodeDetails.name}
            </span>
            <div className={styles.episodeDescription}>
              {_truncate(nextEpisodeDetails.description, {
                length: JUMBOTRON_DESCRIPTION_MAX_LENGTH,
              })}
            </div>
          </div>
        </div>
      </PopOver>
    </div>
  );
};

export default NextEpisodeButton;

import {
  Profile,
  SerializedPageType,
  livePath,
  moviePath,
  showPath,
  transformAssetName,
} from '@laminar-product/client-commons-core/core';
import { getAuth } from 'firebase/auth';
import { collectionPagePath } from './constants';
import { APP_PAGE_PATH } from './navigation';

interface PageLink {
  type: SerializedPageType;
  uuid?: string;
  administrativeName?: string;
}

export const getPageLink = (pageLink?: PageLink) => {
  if (!pageLink) return '/';

  const pageName = transformAssetName(pageLink.administrativeName);

  switch (pageLink.type) {
    case 'COLLECTION':
      return collectionPagePath
        .replace(':name', pageName || '')
        .replace(':id', pageLink.uuid || '');
    case 'CUSTOM_PAGE':
      return `/${pageName}/${pageLink.uuid}`;
    default:
      return '/';
  }
};

export const getProfileDetailsRoute = (profile: Profile) =>
  `/settings/profile/${profile.uuid}`;

export const catalogMoviePath = `/catalog${moviePath}`;
export const catalogShowPath = `/catalog${showPath}`;
export const catalogLivePath = `/catalog${livePath}`;

export const pagesURLPartsWithoutMenu = [
  '/forgot-password',
  APP_PAGE_PATH.LOGIN,
  APP_PAGE_PATH.REGISTER,
  '/watch',
];

export const getDefaultRouteAfterAttributedCheckout = () =>
  getAuth()?.currentUser?.isAnonymous ? APP_PAGE_PATH.LOGIN : '/';

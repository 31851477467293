import { useCallback } from 'react';
import { createStripePayment } from 'actions/payments';
import { loadStripe } from '@stripe/stripe-js';
import { HandlePaymentParams } from 'types/payment';

const useHandleStripePayment = () => {
  const processPayment = useCallback(async (sessionId: string) => {
    const stripe = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? ''
    );
    return stripe?.redirectToCheckout({ sessionId });
  }, []);

  const handleStripePayment = useCallback(
    async ({
      priceId,
      email,
      couponId,
      offerId,
      billingAddress,
      billingName,
    }: HandlePaymentParams) => {
      const stripeForm = await createStripePayment({
        priceId,
        coupon: couponId ?? '',
        paymentEmail: email,
        offerId,
        billingName,
        billingAddress,
      });

      processPayment(stripeForm.data.id);
    },
    [processPayment]
  );

  const handleStripeCardChange = useCallback(async (id: string) => {
    const stripe = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
        'pk_test_Vy2AsifeBGcB5CcZ2lpsI7Ux00S3fwZl3R'
    );
    return stripe?.redirectToCheckout({ sessionId: id });
  }, []);

  return {
    handleStripePayment,
    handleStripeCardChange,
    handleExternalStripePayment: processPayment,
  };
};

export default useHandleStripePayment;

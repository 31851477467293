import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const ProtectedRoute = ({
  isAllowed,
  redirectTo = '/',
  ...props
}: {
  isAllowed: boolean;
  redirectTo?: string | { pathname: string; state: any };
} & RouteProps) =>
  isAllowed ? <Route {...props} /> : <Redirect to={redirectTo} />;

export default ProtectedRoute;

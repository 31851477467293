import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Asset,
  AssetLocationState,
} from '@laminar-product/client-commons-core/core';
import { useMoveToAssetDetails } from './useMoveToAssetDetails';

const usePlayerGoBack = (asset?: Asset | null) => {
  const { push } = useHistory<AssetLocationState>();
  const moveToAsset = useMoveToAssetDetails();

  const goBackFromPlayer = useCallback(() => {
    if (document.fullscreenElement && document.fullscreenElement !== null) {
      document.exitFullscreen();
    }

    if (asset) {
      moveToAsset(asset, 'replace');
    } else {
      push('/');
    }
  }, [asset, moveToAsset, push]);

  return goBackFromPlayer;
};

export default usePlayerGoBack;

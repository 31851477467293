import { checkExcludedSentryErrors } from '@laminar-product/client-commons-core/core';
import { SeverityLevel, captureException, withScope } from '@sentry/react';
import Axios, { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';

export type CaptureErrorType = AxiosError | FirebaseError | Error;

export const captureError = (
  error: CaptureErrorType,
  errorName: string,
  severity: SeverityLevel,
  scope?: {
    tags?: { [key: string]: any };
    customMessage?: string;
    extra?: { [key: string]: any };
  }
) => {
  if (!error || Axios.isCancel(error)) return;

  if ((error as AxiosError).isAxiosError === true) {
    const errorToCheck = error as AxiosError;
    const errorStatus = errorToCheck?.response?.status;
    const errorCode = errorToCheck?.response?.data?.code;

    if (
      checkExcludedSentryErrors(errorStatus) &&
      !CUSTOM_EXCLUDED_ERROR_CODES.includes(errorCode)
    ) {
      return;
    }

    withScope((sentryScope) => {
      forEach(scope?.tags, (value, key) => {
        sentryScope.setTag(key, value);
      });

      sentryScope.setLevel(severity);
      const getErrorName = (name?: string) =>
        [errorName, scope?.customMessage, severity, errorCode, name]
          .filter((value) => !!value)
          .join('/');

      const getError = (error: Error | any) => {
        let errorName: string | undefined;

        if (error instanceof Error) {
          errorName = error?.name;
        }
        if (typeof error === 'string') {
          errorName = error;
        }

        return merge(error, {
          name: getErrorName(errorName),
        });
      };
      const modifiedError = getError(error);

      captureException(modifiedError, {
        extra: merge({ errorCode }, scope?.extra),
      });
    });
    return;
  }

  if (
    typeof error === 'object' &&
    'code' in error &&
    !!error.code &&
    CUSTOM_EXCLUDED_ERROR_CODES.includes(error.code)
  ) {
    return;
  }

  captureException(error);
};

export const CUSTOM_EXCLUDED_ERROR_CODES = [
  'auth/unavailable_in_country',
  'error/validation_error',
  'messaging/permission-blocked',
];

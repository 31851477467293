import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as OvalIcon } from 'assets/icons/oval.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import cn from 'classnames';
import Input, { InputProps } from 'components/Input';
import React, { forwardRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isPasswordValidLength } from 'utils/validators';
import styles from './index.module.scss';

const passwordChecks = [
  {
    message: 'common.validations.passwordLength',
    validate: isPasswordValidLength,
  },
  // {
  //   message: 'common.validations.passwordValue',
  //   validate: isPasswordValidValue,
  // },
];

export interface PasswordInputProps extends InputProps {
  passwordCheckPosition?: 'above' | 'under';
  setPasswordValid?: (valid: boolean) => void;
  checkWrapperStyle?: string;
  wrapperStyle?: string;
  passwordErrorComponent?: React.ReactNode;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      placeholder,
      light,
      passwordCheckPosition,
      className,
      onChange,
      setPasswordValid,
      checkWrapperStyle,
      wrapperStyle,
      passwordErrorComponent,
      ...props
    },
    ref
  ) => {
    const passwordLength = 8;

    // Note that is value is not used for controlling input
    const [value, setValue] = useState<string>('');
    const { t } = useTranslation();
    const [isPasswordVisible, togglePasswordVisibility] =
      useState<boolean>(false);

    const isValid = (value: string) =>
      setPasswordValid
        ? setPasswordValid(
            passwordChecks.every((check) => check.validate(value))
          )
        : null;

    const PasswordChecks = () => (
      <ul
        className={cn(styles.checks, {
          [styles.darkPasswordChecks]: !light,
          checkWrapperStyle,
        })}
      >
        {passwordChecks.map((c) => (
          <li key={c.message} className={styles.passwordCheck}>
            {c.validate(value) ? (
              <CheckIcon
                className={cn(styles.checkIcon, styles.passwordCheckIcon)}
              />
            ) : (
              <OvalIcon className={styles.passwordCheckIcon} />
            )}
            <span>
              <Trans i18nKey={c.message} values={{ passwordLength }} />
            </span>
          </li>
        ))}
      </ul>
    );

    return (
      <div
        className={cn(wrapperStyle, styles.inputWrapper, {
          [styles.reversed]: passwordCheckPosition === 'above',
        })}
      >
        <Input
          {...props}
          className={cn(className, styles.input)}
          light={light}
          startIcon={<LockIcon />}
          endIcon={
            <VisibilityIcon
              onClick={() => togglePasswordVisibility(!isPasswordVisible)}
              className={styles.visibilityIcon}
            />
          }
          ref={ref}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={placeholder || t('common.placeholders.password')}
          onChange={(e) => {
            setValue(e.target.value);
            isValid(e.target.value);
            if (onChange) onChange(e);
          }}
        />
        {passwordErrorComponent}
        {passwordCheckPosition && <PasswordChecks />}
      </div>
    );
  }
);

export default PasswordInput;

import {
  SchemaVersion,
  StrataEventData,
  StrataEvents,
  TrackEvent,
} from '@laminar-product/client-commons-core/core';

export enum StrataPlayerState {
  IDLE = 'IDLE',
  PLAYER_READY = 'PLAYER_READY',
  VIDEO_START = 'VIDEO_START',
  VIDEO_STOP = 'VIDEO_STOP',
  VIDEO_PLAYING = 'VIDEO_PLAYING',
  VIDEO_BUFFER_START = 'VIDEO_BUFFER_START',
  VIDEO_BUFFER_END = 'VIDEO_BUFFER_END',
  VIDEO_FAIL = 'VIDEO_FAIL',
  AD_START = 'AD_START',
  AD_END = 'AD_END',
  AD_STOP = 'AD_STOP',
}

type StrataEventDataWithoutPlaybackId<T extends keyof StrataEvents> = Omit<
  StrataEventData<T>,
  'playback_id'
>;

type StrataVideoEventBase<T extends string, Data = undefined> = {
  type: T;
  params: {
    event: TrackEvent;
    version?: SchemaVersion;
    data?: Data;
  };
};

type StrataVideoEvent =
  | StrataVideoEventBase<'SET_PLAYER_READY'>
  | StrataVideoEventBase<'START_VIDEO'>
  | StrataVideoEventBase<
      'STOP_VIDEO',
      StrataEventDataWithoutPlaybackId<TrackEvent.VIDEO_STOP>
    >
  | StrataVideoEventBase<
      'PLAYING_VIDEO',
      StrataEventDataWithoutPlaybackId<TrackEvent.VIDEO_PLAYING>
    >
  | StrataVideoEventBase<'VIDEO_START_BUFFER'>
  | StrataVideoEventBase<'VIDEO_END_BUFFER'>
  | StrataVideoEventBase<
      'FAIL_VIDEO',
      StrataEventDataWithoutPlaybackId<TrackEvent.VIDEO_FAIL>
    >;

type StrataAdEvent =
  | StrataVideoEventBase<
      'START_AD',
      StrataEventDataWithoutPlaybackId<TrackEvent.AD_START>
    >
  | StrataVideoEventBase<
      'END_AD',
      StrataEventDataWithoutPlaybackId<TrackEvent.AD_END>
    >
  | StrataVideoEventBase<
      'STOP_AD',
      StrataEventDataWithoutPlaybackId<TrackEvent.AD_STOP>
    >;

export type StrataPlayerEvent = StrataVideoEvent | StrataAdEvent;

import { Asset, AssetType } from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import { ASSET_TYPES_ROOT_REDIRECT } from './constants';
import useGetAssetRoot from './useGetAssetRoot';
import { useMoveToAssetDetails } from './useMoveToAssetDetails';

export const shouldRedirectToRoot = (type: AssetType) =>
  ASSET_TYPES_ROOT_REDIRECT?.some((assetType) => assetType === type);

interface EpisodePosterRedirectProps {
  type: 'Player' | 'Details';
  asset: Asset;
}

const useRedirectToRootAsset = () => {
  const moveToAsset = useMoveToAssetDetails();
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const getAssetRoot = useGetAssetRoot();

  const redirect = useCallback(
    async ({ type, asset }: EpisodePosterRedirectProps) => {
      const root = asset.root || (await getAssetRoot(asset));

      if (!root) return;

      const locationState = {
        state: {
          showId: asset.type === AssetType.MOVIE ? '' : asset.rootId,
        },
      };

      type === 'Player'
        ? moveToAssetPlayer(asset.id, asset.type, root.name, locationState)
        : moveToAsset(asset);
    },
    [getAssetRoot, moveToAsset, moveToAssetPlayer]
  );

  return redirect;
};

export default useRedirectToRootAsset;

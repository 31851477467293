import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

const Devices = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className={styles.root}>
      {/* TODO: FETCH LIST OF REGISTERED DEVICES 
      <h2>{t('settings.registeredDevices')}</h2>

      <div className={styles.listContainer}>
        {mockedDevices.map((device) => (
          <DeviceElement onUnregister={console.log} {...device} />
        ))}
      </div> */}
      <div>
        <Button
          variant="secondary-settings"
          onClick={() => push('/settings/devices/register')}
        >
          {t('settings.devices.registerNewDevice')}
        </Button>
      </div>
    </div>
  );
};

export default Devices;

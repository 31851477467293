import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { MobileSelect } from '@laminar-product/client-commons-core/web';
import { getGenres } from 'actions/search';
import { useTranslation } from 'react-i18next';
import { useMoveToListing } from 'utils/useMoveToListing';
import FiltersSelector from 'components/FiltersSelector';
import MobileSortingSelector from 'components/MobileSortingSelector';
import Breadcrumbs from 'components/Breadcrumbs';
import useSubheader from 'utils/useSubheader';
import { useMemo } from 'react';
import { ALL_GENRES_SELECTOR_OPTION } from 'utils/constants';
import styles from './index.module.scss';

export interface SubHeaderMobileProps {
  assetType?: string;
  title?: string;
}

const SubHeaderMobile = ({ title, assetType }: SubHeaderMobileProps) => {
  const [genres] = useFetchAction(getGenres);
  const moveToListing = useMoveToListing();
  const { t } = useTranslation();

  const {
    searchParams,
    filters,
    onGenreSelect,
    onGenreReset,
    shouldShowPageOptions,
    pageType,
    nextBcPath,
    subheaderTitle,
  } = useSubheader({ assetType, title });

  const { sort, genre, bcpath } = searchParams;

  const selectedGenre = genre
    ? {
        value: genre as string,
        label: t(`genre.${genre}`, { defaultValue: genre }),
      }
    : undefined;

  const options = useMemo(() => {
    const allGenresOptions = {
      value: ALL_GENRES_SELECTOR_OPTION,
      label: t('asset.allGenres'),
    };

    const genresOptions =
      genres?.map((genre) => ({
        value: genre,
        label: t(`genre.${genre}`, { defaultValue: genre }),
      })) || [];

    return genre ? [allGenresOptions, ...genresOptions] : genresOptions;
  }, [genre, genres, t]);

  return (
    <div className={styles.subheaderWrapper}>
      <Breadcrumbs
        pageConfig={{ title: subheaderTitle, bcpath }}
        genre={genre as string}
      />

      {shouldShowPageOptions && (
        <div className={styles.pageOptionsWrapper}>
          {(!genre || !subheaderTitle) && (
            <MobileSelect
              options={options}
              value={selectedGenre}
              defaultText={t('asset.allGenres', { defaultValue: 'All' })}
              onChange={(option) =>
                option.value === ALL_GENRES_SELECTOR_OPTION
                  ? onGenreReset()
                  : onGenreSelect(option?.value)
              }
            />
          )}

          <div className={styles.rightOptionsWrapper}>
            <FiltersSelector
              onApply={(filters) => {
                moveToListing(pageType as string, {
                  ...searchParams,
                  ...filters,
                  bc: subheaderTitle,
                  bcpath: nextBcPath,
                  type: pageType,
                });
              }}
              filters={filters}
            />

            <MobileSortingSelector
              value={sort}
              onChange={(sort: string) => {
                moveToListing(pageType as string, {
                  ...searchParams,
                  sort: sort !== 'none' ? sort : '',
                  bcpath: nextBcPath,
                  type: pageType,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubHeaderMobile;

import {
  PaymentGateway,
  User,
} from '@laminar-product/client-commons-core/core';
import { Subscription } from 'types/subscription';
import { PlatformPartnerMetadata } from 'types/user';
import { CUSTOM_CHANGE_PAYMENT_METHOD_GATEWAYS } from 'utils/constants';
import { isSubscriptionFromGateway } from 'utils/subscription';
import SettingsHeading from 'components/SettingsHeading';
import { useTranslation } from 'react-i18next';
import AccountPaymentMethod from '../AccountPaymentMethod';
import RazorpayPaymentMethod from '../RazorpayPaymentMethod';
import CCBillPaymentMethod from '../CCBillPaymentMethod';

interface AccountPaymentMethodFactoryProps {
  subscription?: Subscription;
  platformPartner?: PlatformPartnerMetadata;
  userData?: User;
}

const AccountPaymentMethodFactory = ({
  platformPartner,
  subscription,
  userData,
}: AccountPaymentMethodFactoryProps) => {
  const { t } = useTranslation();

  const hasCustomPaymentMethodChange = subscription
    ? CUSTOM_CHANGE_PAYMENT_METHOD_GATEWAYS.includes(subscription.gateway)
    : false;

  if (platformPartner || !subscription) {
    return null;
  }

  return (
    <>
      {!hasCustomPaymentMethodChange ? (
        <AccountPaymentMethod subscription={subscription} userData={userData} />
      ) : null}

      {isSubscriptionFromGateway(subscription, PaymentGateway.RAZORPAY) ? (
        <>
          <SettingsHeading title={t('settings.paymentMethod')} />
          <RazorpayPaymentMethod />
        </>
      ) : null}

      {isSubscriptionFromGateway(subscription, PaymentGateway.CCBILL) ? (
        <>
          <SettingsHeading title={t('settings.paymentMethod')} />
          <CCBillPaymentMethod />
        </>
      ) : null}
    </>
  );
};

export default AccountPaymentMethodFactory;

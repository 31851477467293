import React, { FC } from 'react';
import { TransitionEffect } from '@laminar-product/client-commons-core/web';
import styles from './index.module.scss';

interface SlideTransitionProps extends React.PropsWithChildren {
  isOpen: boolean;
  deviation: {
    vertical: boolean;
    enter: number;
    exit: number;
    unit: 'px' | '%';
  };
  timeout?: number | { enter?: number; exit?: number };
  className?: string;
  unmountOnExit?: boolean;
}

const SlideTransition: FC<SlideTransitionProps> = ({
  children,
  isOpen,
  deviation,
  timeout = 1000,
  className,
  unmountOnExit = false,
}) => {
  return (
    <TransitionEffect
      isOpen={isOpen}
      deviation={deviation}
      children={children}
      className={className}
      styles={styles}
      unmountOnExit={unmountOnExit}
      timeout={timeout}
    />
  );
};

export default SlideTransition;

import PlayerMuteButton, {
  PlayerMuteButtonProps,
} from 'components/PlayerMuteButton';

const PlayerMuteButtonRenderer = ({
  playerId,
  source,
  className,
  variant = 'medium',
}: PlayerMuteButtonProps) => (
  <PlayerMuteButton
    playerId={playerId}
    source={source}
    variant={variant}
    className={className}
  />
);

export default PlayerMuteButtonRenderer;

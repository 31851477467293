import { Profile, User } from '@laminar-product/client-commons-core/core';
import { AnyAction } from 'redux';
import { Subscription } from 'types/subscription';
import { NotificationType } from 'utils/notifications';
import { User as FirebaseUser } from 'firebase/auth';
import {
  PlatformPartnerMetadata,
  UserManagingProfilesVariant,
} from 'types/user';
import {
  SET_CANNOT_CHANGE_PLAN_MODAL_VISIBLE,
  SET_CURRENT_PROFILE_UUID,
  SET_CURRENT_USER_SUBSCRIPTION,
  SET_FIREBASE_MESSAGING_TOKEN,
  SET_FIREBASE_USER,
  SET_NOTIFICATIONS,
  SET_USER_AUTH_CLAIMS,
  SET_USER_DETAILS,
  SET_USER_LOGIN_METHOD,
  SET_USER_MANAGING_PROFILES_VARIANT,
  SET_USER_PROFILES,
  SET_USER_VERIFIED,
} from './actions';

export type LoginMethod =
  | 'guest'
  | 'partner'
  | 'phone'
  | 'email'
  | 'twitter'
  | 'facebook'
  | 'google'
  | 'apple'
  | 'sso';

export interface UserState {
  details?: User;
  verified?: boolean;
  profiles?: Profile[];
  currentProfileUuid?: string;
  loginMethod?: LoginMethod;
  notificationToken?: string;
  notifications?: NotificationType[];
  subscription?: Subscription;
  firebaseUser?: FirebaseUser;
  countryCode?: string;
  platformPartner?: PlatformPartnerMetadata;
  isPartnerUser?: boolean;
  authClaims?: Record<string, unknown>;
  userManagingProfilesVariant?: UserManagingProfilesVariant;
  cannotChangePlanModalVisible?: boolean;
}

const userReducer = (state: UserState = {}, action: AnyAction): UserState => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        details: action.payload?.data,
        platformPartner: action.payload?.platformPartner,
        isPartnerUser: action.payload?.isPartnerUser,
        countryCode: action.payload?.country,
      };
    case SET_USER_VERIFIED:
      return {
        ...state,
        verified: action.payload.verified,
      };
    case SET_USER_PROFILES:
      return {
        ...state,
        profiles: action.payload.profiles,
      };
    case SET_CURRENT_PROFILE_UUID:
      return {
        ...state,
        currentProfileUuid: action.payload.uuid,
      };
    case SET_USER_LOGIN_METHOD:
      return {
        ...state,
        loginMethod: action.payload.method,
      };
    case SET_FIREBASE_MESSAGING_TOKEN:
      return {
        ...state,
        notificationToken: action.payload.token,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    case SET_CURRENT_USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload.subscription,
      };
    case SET_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.payload.user,
      };
    case SET_USER_AUTH_CLAIMS:
      return {
        ...state,
        authClaims: action.payload,
      };
    case SET_USER_MANAGING_PROFILES_VARIANT:
      return {
        ...state,
        userManagingProfilesVariant: action.payload,
      };
    case SET_CANNOT_CHANGE_PLAN_MODAL_VISIBLE:
      return {
        ...state,
        cannotChangePlanModalVisible: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;

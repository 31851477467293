import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordInput from 'components/PasswordInput';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import { confirmPasswordReset, getAuth } from 'firebase/auth';
import { APP_PAGE_PATH } from 'utils/navigation';
import styles from './index.module.scss';

interface NewPasswordFormFields {
  password: string;
  repeatedPassword: string;
}

const NewPasswordForm = ({
  verificationCode,
}: {
  verificationCode?: string;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<NewPasswordFormFields>({
    mode: 'onSubmit',
  });
  const [globalError, setGlobalError] = useState<string>();
  const [passwordValid, setIsPasswordValid] = useState<boolean>();
  const { push } = useHistory();
  const { t } = useTranslation();

  const onSubmit = async ({
    password,
    repeatedPassword,
  }: NewPasswordFormFields) => {
    setGlobalError('');
    if (password !== repeatedPassword) {
      setGlobalError(t('errors.passwordDoesntMatch'));
      return;
    }
    if (!verificationCode) {
      setGlobalError(t('errors.errorPageTitle'));
      return;
    }
    if (!passwordValid) {
      return;
    }
    try {
      await confirmPasswordReset(getAuth(), verificationCode, password);
      push(APP_PAGE_PATH.RESET_PASSWORD_SUCCESS);
    } catch (e) {
      captureError(e as AxiosError, 'NewPasswordForm/onSubmit', Severity.Error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className={styles.heading}>Reset password</h1>
      <div className={styles.inputWrapper}>
        <PasswordInput
          {...register('password', { required: 'Required' })}
          placeholder="New password"
          passwordCheckPosition="above"
          setPasswordValid={setIsPasswordValid}
        />
        {errors.password && (
          <span className={styles.errorMessage}>{errors.password.message}</span>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <PasswordInput
          {...register('repeatedPassword', { required: 'Required' })}
          placeholder="Repeat password"
        />
        {errors.repeatedPassword && (
          <span className={styles.errorMessage}>
            {errors.repeatedPassword.message}
          </span>
        )}
      </div>
      {globalError && (
        <span className={styles.errorMessage}>{globalError}</span>
      )}
      <Button className={styles.button}>
        {t('settings.changePasswordTitle')}
      </Button>
    </form>
  );
};

export default NewPasswordForm;

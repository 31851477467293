import { ReactComponent as PaymentFailedIcon } from 'assets/images/payment-failed.svg';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.scss';

const StaticPaymentFailed = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <PaymentFailedIcon className={styles.img} />
      </div>
      <h1 className={styles.heading}>{t('payment.failed.heading')}</h1>
      <p className={styles.description}>
        {t('payment.static.failed.description')}
      </p>
    </div>
  );
};

export default StaticPaymentFailed;

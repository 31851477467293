import { AnyAction } from 'redux';
import { Value } from 'firebase/remote-config';
import { RESET_REMOTE_CONFIG, SET_REMOTE_CONFIG } from './actions';

export interface RemoteConfigState {
  remoteConfig?: Record<string, Value>;
}

const remoteConfigReducer = (
  state: RemoteConfigState = {},
  action: AnyAction
): RemoteConfigState => {
  switch (action.type) {
    case RESET_REMOTE_CONFIG:
      return {};
    case SET_REMOTE_CONFIG:
      return {
        ...state,
        remoteConfig: action.payload,
      };
    default:
      return state;
  }
};

export default remoteConfigReducer;

import useVideoPlayerStrataEvents from 'utils/useVideoPlayerStrataEvents';
import useVideoPlayerPlaylistController from 'utils/useVideoPlayerPlaylistController';
import useSaveMovieBookmark from 'utils/useSaveMovieBookmark';
import VideoPlayerComponent from '../VideoPlayerComponent';
import { VideoPlayerBasicProps } from '../types';
import VideoPlayerSkipFrames from '../VideoPlayerSkipFrames/index.module';

interface VideoPlayerMovieComponentProps extends VideoPlayerBasicProps {}

const VideoPlayerMovieComponent = (props: VideoPlayerMovieComponentProps) => {
  useSaveMovieBookmark();
  useVideoPlayerPlaylistController();
  const { onAdEnd, onAdStart, onAdPause, ...strataEventsHandlers } =
    useVideoPlayerStrataEvents();

  return (
    <VideoPlayerComponent
      Frames={
        <VideoPlayerSkipFrames
          onAdEnd={onAdEnd}
          onAdStart={onAdStart}
          onAdPause={onAdPause}
        />
      }
      {...strataEventsHandlers}
      {...props}
    />
  );
};

export default VideoPlayerMovieComponent;

import { useTranslation } from 'react-i18next';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { changeSubscriptionPaymentMethod, getCard } from 'actions/payments';
import CardPaymentBox from 'components/CardPaymentBox';
import { useCallback } from 'react';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import styles from '../../index.module.scss';

const CCBillPaymentMethod = () => {
  const { t } = useTranslation();
  const { data: card, error, isLoading } = useFetchActionObj<string>(getCard);

  const onPaymentMethodChange = useCallback(async () => {
    try {
      const { data } = await changeSubscriptionPaymentMethod();

      //If url exists in data, endpoint returns correct data for ccbill redirection
      if (!('url' in data)) {
        return;
      }

      window.open(data.url, '_blank', 'noopener, noreferrer');
    } catch (error) {
      captureError(
        error as any,
        'CCBillPaymentMethod/onPaymentMethodChange',
        Severity.Error
      );
    }
  }, []);

  return (
    <div className={styles.row}>
      <CardPaymentBox card={card} isError={!!error} isLoading={isLoading} />

      {!error ? (
        <div className={styles.paymentChange}>
          <button className={styles.link} onClick={onPaymentMethodChange}>
            {t('settings.changePaymentInfo')}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CCBillPaymentMethod;

import { getComponentsPage } from 'actions/basic';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setAppMeta } from 'store/app/actions';
import { selectMenuHomeLink } from 'store/app/selectors';
import {
  setComponentsPage,
  setPageFetchingStatus,
  setPageLastFetchedPagination,
} from 'store/pages/actions';
import {
  selectPage,
  selectPageFetchingStatus,
  selectPageLastFetchedPagination,
} from 'store/pages/selectors';
import {
  DEFAULT_FIRST_PAGINATION,
  PAGE_FETCHING_STATUS,
  isFinalFetchingStatus,
  isLoadingPageStatus,
} from 'store/pages/types';
import { selectFirebaseUser } from 'store/user/selectors';
import { Severity } from 'types/errors';
import { captureError } from 'utils/captureError';
import { PAGE_COMPONENTS_PAGINATION_LIMIT } from 'utils/constants';
import { prepareMetaForPage } from 'utils/meta';
import { RouteIdParams } from 'utils/props';

const useFetchPage = () => {
  const dispatch = useDispatch();
  const { id: routePageUuid } = useParams<RouteIdParams>();
  const firebaseUser = useSelector(selectFirebaseUser);
  const homeLink = useSelector(selectMenuHomeLink);
  const pageUuidToFetch = routePageUuid || homeLink?.uuid;
  const page = useSelector(selectPage(pageUuidToFetch));
  const pageFetchingStatus = useSelector(
    selectPageFetchingStatus(pageUuidToFetch)
  );
  const lastFetchedPagination = useSelector(
    selectPageLastFetchedPagination(pageUuidToFetch)
  );

  const isLoadingPage = isLoadingPageStatus(pageFetchingStatus);
  const isLoadingFirstPagination =
    isLoadingPage && lastFetchedPagination === undefined;

  //Check if current pagination is already fetched
  const checkShouldOmitPaginationFetch = useCallback(
    (paginationNumber: number) => {
      if (isFinalFetchingStatus(pageFetchingStatus) || isLoadingPage) {
        return true;
      }

      return lastFetchedPagination !== undefined
        ? lastFetchedPagination >= paginationNumber
        : false;
    },
    [isLoadingPage, lastFetchedPagination, pageFetchingStatus]
  );

  //Check if all components are fetched
  const checkAllComponentsFetched = useCallback(() => {
    if (lastFetchedPagination === undefined || !page || !pageUuidToFetch) {
      return false;
    }

    const allComponentsFetched =
      lastFetchedPagination >=
      Math.ceil(page.components.count / PAGE_COMPONENTS_PAGINATION_LIMIT) - 1;

    if (allComponentsFetched) {
      dispatch(
        setPageFetchingStatus(pageUuidToFetch, PAGE_FETCHING_STATUS.ALL_LOADED)
      );

      return true;
    }
  }, [dispatch, lastFetchedPagination, page, pageUuidToFetch]);

  const fetchPagePagination = useCallback(
    (currentPagination: number) => {
      if (
        !pageUuidToFetch ||
        checkShouldOmitPaginationFetch(currentPagination) ||
        checkAllComponentsFetched()
      ) {
        return;
      }

      dispatch(
        setPageFetchingStatus(pageUuidToFetch, PAGE_FETCHING_STATUS.LOADING)
      );

      const skip = currentPagination * PAGE_COMPONENTS_PAGINATION_LIMIT;
      const isLoadingFirstPagination = currentPagination === 0; //If first pagination failed - error message is displayed

      getComponentsPage({ pageUuid: pageUuidToFetch, skip })
        .then((data) => {
          dispatch(setComponentsPage(data, skip));

          //Handles case when page is empty; In that case there is no "count" returned from BE
          if (
            isLoadingFirstPagination &&
            (!data.components?.count || data.components?.count === 0)
          ) {
            return dispatch(
              setPageFetchingStatus(
                pageUuidToFetch,
                PAGE_FETCHING_STATUS.FAILED
              )
            );
          }

          dispatch(
            setPageFetchingStatus(
              pageUuidToFetch,
              PAGE_FETCHING_STATUS.CURRENT_PART_LOADED
            )
          );
        })
        .catch((error) => {
          captureError(error, 'page/fetchPage', Severity.Error);

          //If first pagination failed - display error page. if any other pagination failed - stop fetching
          dispatch(
            setPageFetchingStatus(
              pageUuidToFetch,
              isLoadingFirstPagination
                ? PAGE_FETCHING_STATUS.FAILED
                : PAGE_FETCHING_STATUS.ALL_LOADED
            )
          );
        })
        .finally(() => {
          dispatch(
            setPageLastFetchedPagination(pageUuidToFetch, currentPagination)
          );
        });
    },
    [
      checkAllComponentsFetched,
      checkShouldOmitPaginationFetch,
      dispatch,
      pageUuidToFetch,
    ]
  );

  //Fetch page after scrolling
  const handleFetchNextComponentsPage = useCallback(() => {
    if (lastFetchedPagination === undefined) {
      return;
    }

    fetchPagePagination(lastFetchedPagination + 1);
  }, [fetchPagePagination, lastFetchedPagination]);

  //First fetch when entering page
  useEffect(() => {
    if (!pageFetchingStatus && firebaseUser) {
      fetchPagePagination(DEFAULT_FIRST_PAGINATION);
    }
  }, [fetchPagePagination, firebaseUser, pageFetchingStatus]);

  useEffect(() => {
    if (!page) return;

    dispatch(
      setAppMeta({
        og: prepareMetaForPage(page.title, page.description),
        description: page.description,
      })
    );
  }, [dispatch, page]);

  return {
    isLoadingPage: isLoadingFirstPagination,
    handleFetchNextComponentsPage,
    pageFetchingStatus,
    page,
  };
};

export default useFetchPage;

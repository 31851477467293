import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchInput from 'components/SearchInput';
import {
  usePrevious,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  className?: string;
}

const Search = ({ className }: SearchProps) => {
  const location = useLocation();
  const { push } = useHistory();

  const locationSearch = location.search;
  const derivedQuery = new URLSearchParams(locationSearch).get('query');
  const { t } = useTranslation();

  const history = useHistory();

  const { isTabletWindowSize } = useWindowSize();

  const [query, setQuery] = useState(derivedQuery ?? '');
  const previousQuery = usePrevious(query);

  useEffect(() => {
    if (query.length > 0 && query !== derivedQuery) {
      push({
        pathname: '/search',
        search: qs.stringify({ query }),
      });
    }
  }, [derivedQuery, push, query]);

  // Moving out of listing view
  useEffect(() => {
    if (
      previousQuery !== undefined &&
      previousQuery?.length > 0 &&
      query?.length === 0
    ) {
      history.goBack();
    }
  }, [history, previousQuery, query]);

  return (
    <SearchInput
      onSearchChange={setQuery}
      value={query}
      placeholder={
        isTabletWindowSize
          ? t('common.placeholders.searchFor', { query: 'title, cast, genre' })
          : t('common.placeholders.search')
      }
      hasBorder={false}
      className={className}
    />
  );
};

export default Search;

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './index.module.scss';

interface PlayerLiveIndicatorProps {
  className?: string;
}

const PlayerLiveIndicator = ({ className }: PlayerLiveIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.liveIndicatorContainer, className)}>
      <span className={styles.liveIndicatorDot} />
      <span>{t('common.live')}</span>
    </div>
  );
};

export default PlayerLiveIndicator;

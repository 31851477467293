export const setShakaControlsBarVisibility = (isVisible: boolean) => {
  const bottomControls = document.getElementsByClassName(
    'shaka-bottom-controls'
  )[0];

  const topControls = document.getElementById('shaka-player-top-controls');

  const visibilityValue = `visibility: ${isVisible ? 'visible' : 'hidden'}`;

  bottomControls?.setAttribute('style', visibilityValue);
  topControls?.setAttribute('style', visibilityValue);
};

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button } from '@laminar-product/client-commons-core/web';
import commonStyles from '../commonsStyles.module.scss';
import styles from './index.module.scss';

interface UpgradeNextEpisodeProps {
  onWatchCredits: () => void;
  onUpgradeToWatch: () => void;
}

const UpgradeNextEpisode = ({
  onWatchCredits,
  onUpgradeToWatch,
}: UpgradeNextEpisodeProps) => {
  const { t } = useTranslation();

  return (
    <div className={commonStyles.creditsFrameContainer}>
      <div className={commonStyles.nextEpisodeOverlay} />

      <div className={commonStyles.meta}>
        <div className={commonStyles.title}>
          {t('player.upgradeNextEpisodeHeader')}
        </div>

        <div className={styles.subTitleNextEpisode}>
          {t('player.upgradeNextEpisodeDescription')}
        </div>
      </div>

      <div
        className={cn(
          commonStyles.buttonsContainer,
          styles.upgradeNextEpisodeButtons
        )}
      >
        <Button
          variant="secondary"
          className={cn(
            styles.upgradeNextEpisodeBtn,
            styles.watchCreditsNextEpisode
          )}
          onClick={onWatchCredits}
        >
          {t('player.watchCredits')}
        </Button>
        <Button
          variant="primary"
          className={cn(
            styles.upgradeNextEpisodeBtn,
            styles.upgradeToWatchNextEpisode
          )}
          onClick={onUpgradeToWatch}
        >
          {t('common.upgradeToWatch')}
        </Button>
      </div>
    </div>
  );
};

export default UpgradeNextEpisode;

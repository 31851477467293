import {
  ContentType,
  ContinueWatchingEvent,
  ContinueWatchingItem,
} from '@laminar-product/client-commons-core/core';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChromecastAsset,
  selectChromecastCastElement,
  selectChromecastContent,
  selectChromecastNextEpisode,
  selectChromecastPlaying,
} from 'store/chromecast/selectors';
import { ChromecastControllerEvents } from 'store/chromecast/types';
import { selectDeviceId } from 'store/app/selectors';
import { putElementToContinueWatching } from 'store/app/actions';
import { updateWatchingProgress } from 'actions/watchingProgress';
import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { checkIfAssetFinished } from './player';
import { VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL } from './constants';

const useChromecastContinueWatching = () => {
  const castElement = useSelector(selectChromecastCastElement);
  const currentTimeRef = useRef<number>();
  const sessionInitialized = useRef(false);
  const chromecastPlaying = useSelector(selectChromecastPlaying);
  const asset = useSelector(selectChromecastAsset);
  const content = useSelector(selectChromecastContent);
  const deviceId = useSelector(selectDeviceId);
  const dispatch = useDispatch();
  const chromecastNextEpisode = useSelector(selectChromecastNextEpisode);

  const onSaveContinueWatching = useCallback(
    async (event: ContinueWatchingEvent) => {
      if (!asset || !content || !castElement || !currentTimeRef.current) {
        return;
      }

      if (content.type !== ContentType.MAIN) {
        return;
      }

      const { id, rootId, parentId } = asset;
      const currentSecond = currentTimeRef.current;

      const finished = checkIfAssetFinished({
        currentTime: currentSecond,
        duration: castElement.duration,
        frames: content.frames,
      });

      const currentContinueWatchingItem: ContinueWatchingItem = {
        assetUuid: id,
        parentId,
        rootId,
        currentSecond,
        deviceId,
        event,
        finished,
      };

      dispatch(putElementToContinueWatching(currentContinueWatchingItem));
      await updateWatchingProgress(
        currentContinueWatchingItem,
        content.frames || []
      );

      if (!chromecastNextEpisode || !finished) {
        return;
      }

      const {
        id: nextEpisodeId,
        parentId: nextEpisodeParentId,
        rootId: nextEpisodeRootId,
      } = chromecastNextEpisode;

      const nextEpisodeContinueWatchingItem: ContinueWatchingItem = {
        assetUuid: nextEpisodeId,
        parentId: nextEpisodeParentId,
        rootId: nextEpisodeRootId,
        currentSecond: 1, //To appear in continue watching
        deviceId,
        event: ContinueWatchingEvent.NEXT_INITIALIZED,
      };

      dispatch(putElementToContinueWatching(nextEpisodeContinueWatchingItem));
      await updateWatchingProgress(
        nextEpisodeContinueWatchingItem,
        content.frames || []
      );
    },
    [asset, castElement, chromecastNextEpisode, content, deviceId, dispatch]
  );

  useEffect(() => {
    if (!castElement) {
      return;
    }

    //CastJS has incorrect handling of listeners when multiple callbacks are passed; Need to attach listener directly to cast controller
    const onTimeUpdate = () => {
      currentTimeRef.current = castElement.time;
    };

    castElement._controller?.addEventListener(
      ChromecastControllerEvents.CURRENT_TIME_CHANGED,
      onTimeUpdate
    );

    return () =>
      castElement._controller?.removeEventListener(
        ChromecastControllerEvents.CURRENT_TIME_CHANGED,
        onTimeUpdate
      );
  }, [castElement]);

  usePolling(() => onSaveContinueWatching(ContinueWatchingEvent.PROGRESSED), {
    interval: VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL,
    shouldRunPolling: true,
  });

  useEffect(() => {
    if (!sessionInitialized.current && chromecastPlaying) {
      onSaveContinueWatching(ContinueWatchingEvent.STARTED);
      sessionInitialized.current = true;
    }
  }, [chromecastPlaying, onSaveContinueWatching]);

  useEffect(() => {
    return () => {
      if (sessionInitialized.current) {
        //TODO: ADD HANDLER FOR NEXT EPISODE
        onSaveContinueWatching(ContinueWatchingEvent.CLOSED);
        sessionInitialized.current = false;
      }
    };
  }, [onSaveContinueWatching]);
};

export default useChromecastContinueWatching;

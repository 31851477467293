import cn from 'classnames';
import React, { ReactElement } from 'react';
import styles from './index.module.scss';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  light?: boolean;
  label?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { required, error, startIcon, endIcon, label, className, light, ...props },
    ref
  ) => (
    <div
      className={cn(className, {
        [styles.light]: light,
        [styles.dark]: !light,
      })}
    >
      {!!label && <label className={styles.inputLabel}>{label}</label>}
      <div className={styles.inputWrapper}>
        {!!startIcon && startIcon}
        <input
          ref={ref}
          className={cn(className, styles.defaultInput, {
            [styles.endPadding]: !!endIcon,
            [styles.startPadding]: !!startIcon,
            [styles.inputError]: !!error,
            [styles.required]: !!required,
          })}
          {...props}
        />
        {!!endIcon && endIcon}
      </div>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  )
);

export default Input;

import { useCallback, useEffect } from 'react';
import { fetchTranslations } from 'actions/translation';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import i18n from 'i18next';
import Login from 'pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { setAppSettings } from 'store/app/actions';
import useScrollToTop from 'utils/useScrollToTop';
import { useTracker } from 'utils/useTracker';
import { useLocation } from 'react-router';
import useFirebaseAuth from 'utils/useFirebaseAuth';
import StaticPaymentCompleted from 'pages/PaymentStatus/static/PaymentSuccess';
import StaticPaymentFailed from 'pages/PaymentStatus/static/PaymentFailed';
import Register from 'pages/Register';
import 'react-phone-number-input/style.css';
import useRedirectFromTv from 'utils/useRedirectFromTv';
import { selectFirebaseUser } from 'store/user/selectors';
import useDeviceId from 'utils/useDeviceId';
import useAppTheme from 'utils/useAppTheme';
import { useAuthHashTokenLogin } from 'utils/useAuthHashTokenLogin';
import { TrackEvent } from '@laminar-product/client-commons-core/core';
import useLanguages from 'utils/useLanguages';
import { PaymentActionType } from 'utils/useExternalPayment';
import AttributedCheckout from 'pages/AttributedCheckout';
import {
  getDefaultRouteAfterAttributedCheckout,
  pagesURLPartsWithoutMenu,
} from 'utils/routing';
import cn from 'classnames';
import useFirebaseRemoteConfig from 'utils/useFirebaseRemoteConfig';
import { useSSOAuth } from 'components/SSO/useSSOAuth';
import AppLoadingErrorPage from 'components/AppLoadingErrorPage';
import useAuthUserClaims from 'utils/useAuthClaims';
import useChromecast from 'utils/useChromecast';
import { APP_PAGE_PATH } from 'utils/navigation';
import useFetchUserSubscription from 'utils/useFetchUserSubscription';
import usePrepareWatchlist from 'utils/usePrepareWatchlist';
import usePrepareAppSettings from 'utils/usePrepareAppMenu';
import { useQuery } from '@tanstack/react-query';
import FreemiumApp from './FreemiumApp';
import styles from './App.module.scss';

const App = () => {
  const dispatch = useDispatch();
  const { selectedLanguage } = useLanguages();
  const { config, isLoadingConfiguration, loadingConfigurationError } =
    usePrepareAppSettings();

  const { data: translation } = useQuery({
    queryKey: ['translations'],
    queryFn: () => fetchTranslations(selectedLanguage),
    enabled: !!selectedLanguage,
  });
  const location = useLocation<{ action: string }>();
  const { trackPageViewEvent, trackNonUserEvent } = useTracker();
  const { isLoadingUser } = useFirebaseAuth();
  const user = useSelector(selectFirebaseUser);
  const { push } = useHistory();
  useAuthHashTokenLogin();
  const { isLoadingSSOUser } = useSSOAuth();
  useAppTheme(config?.theme);
  useScrollToTop();
  useDeviceId();
  useFirebaseRemoteConfig();
  const {
    isPromptingEmail,
    setIsPromptingEmail,
    handlePaymentAction,
    attributedCheckoutContextID,
    paymentErrorCode,
    setPaymentErrorCode,
  } = useRedirectFromTv(isLoadingUser);
  useAuthUserClaims();
  useChromecast();
  useFetchUserSubscription();
  usePrepareWatchlist();

  const onPromptEmailSubmit = useCallback(
    (email: string) => {
      setIsPromptingEmail(false);

      handlePaymentAction({
        email,
        actionType: PaymentActionType.PAY,
        attributedCheckoutContextID,
      });
    },
    [attributedCheckoutContextID, handlePaymentAction, setIsPromptingEmail]
  );

  const onAttributionPageClose = useCallback(() => {
    push(getDefaultRouteAfterAttributedCheckout());

    setPaymentErrorCode(undefined);
    setIsPromptingEmail(false);
  }, [push, setIsPromptingEmail, setPaymentErrorCode]);

  useEffect(() => {
    trackNonUserEvent<TrackEvent.APPLICATION_READY>({
      event: TrackEvent.APPLICATION_READY,
    });
  }, [trackNonUserEvent]);

  useEffect(() => {
    if (config) {
      dispatch(setAppSettings(config));
    }
  }, [config, dispatch]);

  useEffect(() => {
    if (translation || Object.keys(translation || {}).length > 0) {
      i18n.addResources(i18n.language, 'translation', translation);
    }
  }, [translation]);

  // Track page Event
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageViewEvent(), [location.pathname]);

  if (isLoadingConfiguration || isLoadingUser || isLoadingSSOUser)
    return <AppLoadingIndicator />;

  if (loadingConfigurationError)
    return <AppLoadingErrorPage error={loadingConfigurationError} />;

  if (location.search.includes('resetPassword')) {
    return (
      <Redirect
        to={{
          pathname: '/reset-password',
          state: { action: location.search },
        }}
      />
    );
  }

  if (attributedCheckoutContextID) {
    return (
      <AttributedCheckout
        isPromptingEmail={isPromptingEmail}
        paymentErrorCode={paymentErrorCode}
        onPromptEmailSubmit={onPromptEmailSubmit}
        onAttributionPageClose={onAttributionPageClose}
      />
    );
  }

  const getAppComponent = () => {
    if (!config) return null;

    return <FreemiumApp />;
  };

  // check if pathname is included in the pages without menu
  const isPageWithoutMenu = pagesURLPartsWithoutMenu.some((part) =>
    location.pathname.includes(part)
  );

  return (
    <div
      className={cn(styles.appWrapper, [
        isPageWithoutMenu && styles.withoutMenu,
      ])}
    >
      <Switch>
        <Route path="/tv2">
          {user && !user.isAnonymous ? (
            <Redirect
              to={{
                pathname: '/settings/devices/register',
              }}
            />
          ) : (
            <Login />
          )}
        </Route>
        <Route path={APP_PAGE_PATH.LOGIN}>
          <Login />
        </Route>
        <Route path="/mobile/payment/success" exact>
          <Register>
            <StaticPaymentCompleted />
          </Register>
        </Route>
        <Route path="/mobile/payment/failed" exact>
          <Register>
            <StaticPaymentFailed />
          </Register>
        </Route>
        <Route
          path="/mobile/about"
          component={() => {
            if (import.meta.env.VITE_MOBILE_REDIRECT_URL) {
              window.location.href = import.meta.env.VITE_MOBILE_REDIRECT_URL;
            }
            return null;
          }}
        />
        <Route path="/">{getAppComponent()}</Route>
      </Switch>
    </div>
  );
};
export default App;

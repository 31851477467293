import Axios from 'axios';
import { getAppCheckHeaders } from 'utils/appCheck';

export type OtpProviderType = 'custom' | 'firebase';

export const getOtpProvider = async (phoneNumber?: string) => {
  const headers = await getAppCheckHeaders();

  const { data } = await Axios.get<{ name: OtpProviderType; next?: string }>(
    `${
      import.meta.env.VITE_BACKEND_URL
    }/provider/otp/provider?phoneNumber=${phoneNumber}`,
    {
      headers,
    }
  );

  return data;
};

interface CustomProviderOtpProps {
  url: string;
}

export const customProviderGenerateOtp = async ({
  url,
}: CustomProviderOtpProps) => {
  const headers = await getAppCheckHeaders();

  const { data } = await Axios.post<{ next: string }>(url, {}, { headers });

  return data?.next;
};

interface CustomProviderVerifyOtpProps {
  url: string;
  otpValue: string;
}

export const customProviderVerifyOtp = async ({
  url,
  otpValue,
}: CustomProviderVerifyOtpProps) => {
  const headers = await getAppCheckHeaders();
  const { data } = await Axios.post<{ customToken: string }>(
    `${url}&otp=${otpValue}`,
    {},
    { headers }
  );

  return data?.customToken;
};

import {
  ContentFrame,
  ContinueWatchingEvent,
  FrameType,
  PlaybackSettings,
  STREAM_CONCURRENCY_ERROR_CODE,
  TIME_BREAK_TILL_VIDEO_END,
} from '@laminar-product/client-commons-core/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { ShakaPlayerConfigurationGeneric } from 'library/VideoPlayer/types';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { isValueInRange } from './helpers';

export const AD_SERVICE_ERROR = 'ad/service_response_unavailable';
export const VALIDATION_ERROR_CODE = 'error/validation_error';
export const AGE_RESTRICTION_PLAYER_ERROR_CODE =
  'asset_access_forbidden/user_age_restriction_exceeded';
export const NO_DRM_SHAKA_ERROR_CODE = '6001';

export const ACCESS_FORBIDDEN_REASON_ERROR_CODES = [
  'error/validation_error',
  'asset_access_forbidden/asset_not_available_in_user_plan',
  'asset_access_forbidden/asset_not_available_in_user_region',
  'asset_access_forbidden/plan_not_available_in_user_region',
  'asset_access_forbidden/plan_not_available_for_user_platform',
  'asset_access_forbidden/user_access_to_plan_expired',
  'asset_access_forbidden/user_unregistered',
  'asset_access_forbidden/user_has_no_plan',
];

export const usePlayerValidationError = (errorCode: string) => {
  const { t } = useTranslation();

  const playerErrorMessage = useMemo(() => {
    if (ACCESS_FORBIDDEN_REASON_ERROR_CODES.includes(errorCode)) {
      return {
        header: t('errors.defaultValidationErrorHeader'),
        message: t('errors.defaultValidationErrorMessage'),
      };
    }

    switch (errorCode) {
      case AGE_RESTRICTION_PLAYER_ERROR_CODE:
        return {
          header: t('errors.ageRestrictionHeader'),
          message: t('errors.ageRestrictionMessage'),
        };

      case STREAM_CONCURRENCY_ERROR_CODE:
        return {
          header: t('errors.streamConcurrencyHeader'),
          message: t('errors.streamConcurrencyMessage'),
        };
      case NO_DRM_SHAKA_ERROR_CODE:
        return {
          header: t('errors.noDrmHeader'),
          message: t('errors.noDrmMessage'),
        };
      default:
        return {
          header: t('errors.defaultPlayerErrorHeader'),
          message: t('errors.defaultPlayerErrorMessage'),
        };
    }
  }, [errorCode, t]);

  return { playerErrorMessage };
};

export const mapFrameTypeToProfileSettingsKey: (
  type: FrameType
) => keyof PlaybackSettings | undefined = (type: FrameType) => {
  switch (type) {
    case FrameType.CAPTIONS:
      return 'skipCredits';
    case FrameType.INTRO:
      return 'skipIntro';
    case FrameType.RECAP:
      return 'skipRecap';
  }
};

export const checkIsFrameTypeAutoSkipEnabled = (type: FrameType) => {
  const state = store.getState();
  const currentProfile = state.user.profiles?.find(
    (profile) => state.user.currentProfileUuid === profile.uuid
  );

  if (!currentProfile) {
    return false;
  }

  const settingsKey = mapFrameTypeToProfileSettingsKey(type);

  if (!settingsKey) {
    return false;
  }

  return !!currentProfile.playbackSettings?.[settingsKey];
};

export const VIDEO_PLAYER_FRAME_ANIMATION_TIMEOUT = 500; //ms

export const checkIsAutoplayNextEpisodeEnabled = () => {
  const state = store.getState();
  const currentProfile = state.user.profiles?.find(
    (profile) => state.user.currentProfileUuid === profile.uuid
  );

  return !!currentProfile?.playbackSettings?.playNextEpisode;
};

//https://shaka-player-demo.appspot.com/docs/api/tutorial-network-and-buffering-config.html
export const shakaDefaultRetryParameters: ShakaPlayerConfigurationGeneric<shaka.extern.RetryParameters> =
  { maxAttempts: 5, timeout: 10000, baseDelay: 500, fuzzFactor: 0.5 };

export const requestFullScreen = async (element?: HTMLElement | null) => {
  if (!element) {
    return;
  }
  if (element.webkitRequestFullscreen) {
    await element.webkitRequestFullscreen(); /* Safari */
  } else if (element.mozRequestFullScreen) {
    await element.mozRequestFullScreen(); /* Firefox */
  } else if (element.msRequestFullscreen) {
    await element.msRequestFullscreen(); /* IE/EDGE */
  } else if (element.requestFullscreen) {
    await element.requestFullscreen();
  }
};

interface CheckIfAssetFinishedProps {
  currentTime: number;
  frames?: ContentFrame[];
  duration: number;
}

export const checkIfAssetFinished = ({
  currentTime,
  duration,
  frames,
}: CheckIfAssetFinishedProps) => {
  const creditsFrame = frames?.find(
    (frame) => frame.type === FrameType.CAPTIONS
  );

  const inCredits = creditsFrame
    ? isValueInRange(currentTime, creditsFrame.start, creditsFrame.end)
    : false;

  const isInTheEnd = isValueInRange(
    currentTime,
    duration - TIME_BREAK_TILL_VIDEO_END,
    duration
  );

  return inCredits || isInTheEnd;
};

export const secondsToHoursLabel = (seconds: number) =>
  new Date(seconds * 1000).toISOString().slice(11, 19);

export const isStartWatchingSessionEvent = (event: ContinueWatchingEvent) =>
  event === ContinueWatchingEvent.STARTED;

export const isCloseWatchingSessionEvent = (event: ContinueWatchingEvent) =>
  [ContinueWatchingEvent.CLOSED, ContinueWatchingEvent.FINISHED].includes(
    event
  );

export enum PAGE_FETCHING_STATUS {
  LOADING = 'LOADING',
  CURRENT_PART_LOADED = 'CURRENT_PART_LOADED',
  ALL_LOADED = 'ALL_LOADED',
  FAILED = 'FAILED',
}

export const DEFAULT_FIRST_PAGINATION = 0;

export const isFinalFetchingStatus = (status?: PAGE_FETCHING_STATUS) =>
  status
    ? [PAGE_FETCHING_STATUS.FAILED, PAGE_FETCHING_STATUS.ALL_LOADED].includes(
        status
      )
    : false;

export const isLoadingPageStatus = (status?: PAGE_FETCHING_STATUS) =>
  status && status === PAGE_FETCHING_STATUS.LOADING;

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'store/user/selectors';
import { ProfileConfirmationMethods } from 'types/profiles';
import useSocialUser from 'utils/useSocialUser';

export const useIdentityConfirmation = () => {
  const user = useSelector(selectUserDetails);
  const { currentSocialIdProvider, hasEmailProvider } = useSocialUser();

  const identityConfirmationMethods = Object.values(ProfileConfirmationMethods);

  const availableConfirmationMethods = useMemo(
    () =>
      identityConfirmationMethods
        ?.map((method) => {
          switch (method) {
            case ProfileConfirmationMethods.PASSWORD:
              return hasEmailProvider
                ? ProfileConfirmationMethods.PASSWORD
                : null;
            case ProfileConfirmationMethods.PHONE:
              return user?.phoneNumber
                ? ProfileConfirmationMethods.PHONE
                : null;
            case ProfileConfirmationMethods.SOCIAL:
              return currentSocialIdProvider
                ? ProfileConfirmationMethods.SOCIAL
                : null;
            default:
              return null;
          }
        })
        ?.filter((m) => !!m) as ProfileConfirmationMethods[],
    [
      hasEmailProvider,
      identityConfirmationMethods,
      currentSocialIdProvider,
      user,
    ]
  );

  const userHasMultipleConfirmationMethods = useMemo(
    () => availableConfirmationMethods?.length > 1,
    [availableConfirmationMethods]
  );

  const hasGivenMethod = useCallback(
    (method: ProfileConfirmationMethods) =>
      availableConfirmationMethods?.some((m) => m === method),
    [availableConfirmationMethods]
  );

  const getDefaultConfirmationMethod = useCallback(() => {
    const hasPassword = hasGivenMethod(ProfileConfirmationMethods.PASSWORD);
    const hasPhone = hasGivenMethod(ProfileConfirmationMethods.PHONE);
    const hasSocial = hasGivenMethod(ProfileConfirmationMethods.SOCIAL);

    if (hasPassword && !hasPhone && !hasSocial) {
      return ProfileConfirmationMethods.PASSWORD;
    }

    if (hasPhone && !hasPassword && !hasSocial) {
      return ProfileConfirmationMethods.PHONE;
    }
    if (hasSocial && !hasPassword && !hasPhone) {
      return ProfileConfirmationMethods.SOCIAL;
    }
  }, [hasGivenMethod]);

  return {
    availableConfirmationMethods,
    userHasMultipleConfirmationMethods,
    getDefaultConfirmationMethod,
    currentSocialIdProvider,
  };
};

import { useCallback, useEffect, useState } from 'react';
import {
  ConfirmationResult,
  getAdditionalUserInfo,
  getAuth,
  reauthenticateWithPhoneNumber,
  signInWithCustomToken,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { registerUser } from 'actions/basic';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoginMethod } from 'store/user/actions';
import {
  customProviderGenerateOtp,
  customProviderVerifyOtp,
} from 'actions/otpProvider';
import { Severity } from 'types/errors';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { useTranslation } from 'react-i18next';
import { useGetPhoneVerifyError } from './phoneVerify';
import useRecaptchaElement from './useRecaptchaElement';
import { captureError } from './captureError';

interface UseFirebaseOtpProps {
  phoneNumber?: string;
  isRegistering?: boolean;
}

export const useFirebaseOtp = ({
  phoneNumber,
  isRegistering,
}: UseFirebaseOtpProps) => {
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [errorOtpMessage, setErrorOtpMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const getPhoneVerifyError = useGetPhoneVerifyError();
  const { push } = useHistory();
  const auth = getAuth();
  const appVerifier = useRecaptchaElement();

  const getOtp = useCallback(async () => {
    if (!appVerifier || !phoneNumber) return;
    try {
      setErrorOtpMessage('');
      await appVerifier.render();
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      !isRegistering && dispatch(setLoginMethod('phone'));
      setConfirmationResult(result);
    } catch (e) {
      const error = e as any;
      setErrorOtpMessage(getPhoneVerifyError(error.code));
      captureError(error, 'useFirebaseOtp/getOtp', Severity.Error);
    }
  }, [
    appVerifier,
    auth,
    dispatch,
    getPhoneVerifyError,
    isRegistering,
    phoneNumber,
  ]);

  // Get OTP
  useEffect(() => {
    getOtp();

    return () => {
      setConfirmationResult(undefined);
    };
  }, [getOtp]);

  const clearError = () => setErrorOtpMessage('');

  const signIn = async (otpValue: string) => {
    if (!confirmationResult) return;

    setIsLoading(true);

    try {
      const confirm = await confirmationResult.confirm(otpValue);
      const user = getAdditionalUserInfo(confirm);
      if (user?.isNewUser) {
        const token = await registerUser();
        await signInWithCustomToken(auth, token.data);
        push('/register/plan');
        return;
      }
      push('/');
      return;
    } catch (e) {
      const error = e as any;
      setErrorOtpMessage(getPhoneVerifyError(error.code));
      captureError(error, 'useFirebaseOtp/signIn', Severity.Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    clearError,
    signIn,
    sendOtp: getOtp,
    isLoading,
    errorOtpMessage,
  };
};

interface UseFirebaseReauthenticateOtpProps {
  phoneNumber: string;
  onConfirmationSuccess: () => void;
}

export const useFirebaseReauthenticateOtp = ({
  phoneNumber,
  onConfirmationSuccess,
}: UseFirebaseReauthenticateOtpProps) => {
  const [isConfirmationResultSent, setIsConfirmationResultSent] =
    useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const getPhoneVerifyError = useGetPhoneVerifyError();
  const [errorOtpMessage, setErrorOtpMessage] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const auth = getAuth();
  const appVerifier = useRecaptchaElement();

  const sendOtp = async () => {
    if (!phoneNumber || !auth.currentUser || !appVerifier) return;

    await appVerifier.render();
    setIsConfirmationResultSent(true);

    try {
      const result = await reauthenticateWithPhoneNumber(
        auth.currentUser,
        phoneNumber,
        appVerifier
      );
      setConfirmationResult(result);
    } catch (err) {
      setErrorOtpMessage(getPhoneVerifyError((err as any).code));
      setIsAuthenticationError(true);
    }
  };

  const confirmOtp = async (otpValue: string) => {
    if (!confirmationResult || !isConfirmationResultSent) return;

    setIsConfirming(true);

    try {
      await confirmationResult.confirm(otpValue);
      onConfirmationSuccess();
    } catch (err) {
      setErrorOtpMessage(getPhoneVerifyError((err as any).code));
    } finally {
      setIsConfirming(false);
    }
  };

  return {
    sendOtp,
    confirmOtp,
    errorOtpMessage,
    isConfirming,
    isAuthenticationError,
    isConfirmationResultSent,
  };
};

interface UseCustomOtpProviderProps {
  otpGenerationUrl: string;
  isRegistering?: boolean;
  onConfirmationSuccess?: () => void;
}

export const useCustomOtpProvider = ({
  isRegistering,
  otpGenerationUrl,
  onConfirmationSuccess,
}: UseCustomOtpProviderProps) => {
  const [isConfirmationResultSent, setIsConfirmationResultSent] =
    useState(false);
  const [errorOtpMessage, setErrorOtpMessage] = useState('');
  const dispatch = useDispatch();
  const getPhoneVerifyError = useGetPhoneVerifyError();
  const { push } = useHistory();
  const appVerifier = useRecaptchaElement();
  const auth = getAuth();
  const { t } = useTranslation();

  const fetchOtpConfirmationResult = useCallback(async () => {
    try {
      if (!appVerifier) return;
      if (!otpGenerationUrl) {
        setErrorOtpMessage(t('errors.generateOtpEmpty'));
        return;
      }

      const customProviderOtpVerificationUrl = await customProviderGenerateOtp({
        url: otpGenerationUrl,
      });

      if (!customProviderOtpVerificationUrl) {
        setErrorOtpMessage(t('errors.verifyOTpEmpty'));
        return;
      }

      !isRegistering && dispatch(setLoginMethod('phone'));
      setIsConfirmationResultSent(true);

      return customProviderOtpVerificationUrl;
    } catch (e) {
      const error = e as any;
      captureError(error, 'hooks/useCustomOtpProvider', Severity.Error);
      setErrorOtpMessage(
        getPhoneVerifyError(error.response?.data?.code || error.code)
      );
    }
  }, [
    appVerifier,
    dispatch,
    getPhoneVerifyError,
    isRegistering,
    otpGenerationUrl,
    t,
  ]);

  const {
    sendAction: getOtpConfirmationResult,
    isLoading: isLoadingConfirmationResult,
    data: confirmationResult,
  } = useSendActionObj(fetchOtpConfirmationResult);

  const handleConfirmOtp = useCallback(
    async (otpValue: string) => {
      if (!confirmationResult) {
        setErrorOtpMessage('');
        return;
      }

      try {
        const customToken = await customProviderVerifyOtp({
          otpValue,
          url: confirmationResult,
        });

        await signInWithCustomToken(auth, customToken);
        const tokenResult = await getAuth().currentUser?.getIdTokenResult();
        const isNewUser = tokenResult?.claims.isNewUser;

        if (isNewUser) {
          const token = await registerUser();
          await signInWithCustomToken(auth, token?.data);
          push('/register/plan');
          return true;
        }

        if (onConfirmationSuccess) return onConfirmationSuccess();

        push('/');
        return true;
      } catch (e) {
        const error = e as any;
        setErrorOtpMessage(
          getPhoneVerifyError(error.response?.data?.code || error.code)
        );
        captureError(
          error,
          'hooks/useCustomOtpProvider/confirmOtp',
          Severity.Error
        );
      }
    },
    [auth, confirmationResult, getPhoneVerifyError, onConfirmationSuccess, push]
  );

  const {
    sendAction: signIn,
    isLoading: isLoadingConfirmOtp,
    data: isConfirmed,
  } = useSendActionObj(handleConfirmOtp);

  const clearError = () => setErrorOtpMessage('');

  const sendOtp = useCallback(
    () => getOtpConfirmationResult(),
    [getOtpConfirmationResult]
  );

  return {
    sendOtp,
    clearError,
    signIn,
    isLoading: isLoadingConfirmationResult || isLoadingConfirmOtp,
    errorOtpMessage,
    isConfirmed,
    isConfirmationResultSent,
  };
};

import { RootState } from 'store/reducer';
import { CHROMECAST_DEFAULT_IS_ALLOWED_TO_USE } from 'utils/constants';

export const selectFirebaseUser = (state: RootState) => state.user.firebaseUser;

export const selectUserLoggedIn = (state: RootState) =>
  !!state.user.firebaseUser && !state.user.firebaseUser.isAnonymous;

export const selectIsAnonymous = (state: RootState) =>
  state.user.firebaseUser?.isAnonymous;

export const selectUserCountryCode = (state: RootState) =>
  state.user.countryCode;

export const selectProfiles = (state: RootState) => state.user.profiles;

export const selectCanChangeProfiles = (state: RootState) =>
  state.user.profiles ? state.user.profiles.length > 1 : false;

export const selectIsVerified = (state: RootState) => state.user.verified;

export const selectUserDetails = (state: RootState) => state.user.details;

export const selectUserPhoneNumber = (state: RootState) =>
  state.user.details?.phoneNumber;

export const selectUserSubscription = (state: RootState) =>
  state.user.subscription;

export const selectUserIsAllowedToCast = (state: RootState) =>
  state.user.details?.plan?.casting !== undefined
    ? state.user.details?.plan?.casting
    : CHROMECAST_DEFAULT_IS_ALLOWED_TO_USE;

export const selectUserSubscriptionPrice = (state: RootState) =>
  state.user.subscription?.price;

export const selectUserHasPlan = (state: RootState) =>
  !!state.user.subscription?.plan?.uuid || !!state.user?.details?.plan?.uuid;

export const selectCurrentProfile = (state: RootState) =>
  state.user?.profiles?.find(
    ({ uuid }) => uuid === state.user?.currentProfileUuid
  );

export const selectCurrentProfileUuid = (state: RootState) =>
  state.user?.currentProfileUuid;
export const selectCurrentProfileHasInterests = (state: RootState) => {
  const currentProfile = selectCurrentProfile(state);

  return currentProfile?.interests && currentProfile.interests.length > 0;
};
export const selectCurrentProfileInterests = (state: RootState) =>
  selectCurrentProfile(state)?.interests;

export const selectUserPlanQuality = (state: RootState) =>
  state.user?.details?.plan?.quality;

export const selectNotifications = (state: RootState) =>
  state?.user?.notifications;

export const selectNotificationToken = (state: RootState) =>
  state.user?.notificationToken;

export const selectUserUid = (state: RootState) => state.user?.details?.uid;

export const selectFirebaseUserId = (state: RootState) =>
  state.user.firebaseUser?.uid;

export const selectUserProfileLanguage = (state: RootState) => {
  const currentProfile = selectCurrentProfile(state);
  return currentProfile?.language;
};

export const selectUserPlanMetadata = (state: RootState) =>
  state.user.subscription?.planMetadata ?? state.user.details?.planMetadata;

export const selectUserProvidedData = (state: RootState) =>
  state.user?.firebaseUser?.providerData;

// Temporary solution to check if user has Times SSO provider for the purpose of disabling the reauthentication flow for Times SSO users
export const selectIsTimesSSOProvider = (state: RootState) =>
  state.user.authClaims?.isTimesSSOProvider;

export const selectIsPartnerUser = (state: RootState) =>
  !!state.user?.platformPartner;

export const selectPlatformPartnerMetadata = (state: RootState) =>
  state.user.platformPartner;

export const selectUserEmail = (state: RootState) => state.user.details?.email;

export const selectLoginMethod = (state: RootState) => {
  return state.user.loginMethod;
};

export const selectUserManagingProfilesVariant = (state: RootState) =>
  state.user.userManagingProfilesVariant;

export const selectCannotChangePlanModalVisible = (state: RootState) =>
  state.user.cannotChangePlanModalVisible;

import React from 'react';
import SettingsPageContainer from 'components/SettingsPageContainer';
import { Plan, Price } from '@laminar-product/client-commons-core/core';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import SetPayment from 'pages/Register/components/SetPayment';
import usePathWithParams from 'utils/usePathWithParams';

const PaymentPlanPage = () => {
  const selectedPlan = useSelector<RootState, Plan | undefined>(
    (state) => state.registration.selectedPlan
  );
  const selectedPrice = useSelector<RootState, Price | undefined>(
    (state) => state.registration.selectedPrice
  );
  const getPath = usePathWithParams();

  if (!selectedPlan || !selectedPrice) {
    return (
      <Switch>
        <Redirect to={getPath('/settings/change-plan')} />
      </Switch>
    );
  }

  return (
    <SettingsPageContainer>
      <SetPayment isUpgradingPlan={true} />
    </SettingsPageContainer>
  );
};

export default PaymentPlanPage;

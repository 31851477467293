import React, { FC, useEffect, useRef } from 'react';

interface ClickAwayProps extends React.PropsWithChildren {
  onClick: (event: Event) => void;
  className?: string;
}

export const ClickAway: FC<ClickAwayProps> = ({
  onClick,
  className,
  children,
  // props for passing data-testid
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onClick(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick]);

  return (
    <div className={className} {...props} ref={ref}>
      {children}
    </div>
  );
};

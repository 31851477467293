import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from 'firebase/remote-config';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirebaseUser } from 'store/user/selectors';
import { setRemoteConfig } from 'store/remoteConfig/actions';

const useFirebaseRemoteConfig = () => {
  const firebaseUser = useSelector(selectFirebaseUser);
  const dispatch = useDispatch();

  const getRemoteConfigValues = useCallback(async () => {
    const remoteConfig = getRemoteConfig();

    try {
      await fetchAndActivate(remoteConfig);

      const remoteConfigValues = getAll(remoteConfig);
      dispatch(setRemoteConfig(remoteConfigValues));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!firebaseUser) {
      return;
    }

    getRemoteConfigValues();
  }, [firebaseUser, getRemoteConfigValues]);
};

export default useFirebaseRemoteConfig;

import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SubscriptionPrice } from 'types/subscription';
import { formatPriceWithInterval } from 'utils/price';
import { PlanDetailsVariant } from 'utils/constants';
import parentStyles from '../../../index.module.scss';
import SubscriptionDevices from './SubscriptionDevices';
import styles from './index.module.scss';

interface PlanDetailsProps {
  paymentDate?: Date | number;
  planName?: string;
  isCancelationScheduled?: boolean;
  screenLimit?: number;
  price?: SubscriptionPrice;
  variant?: PlanDetailsVariant;
}

const PlanDetails = ({
  screenLimit,
  planName,
  isCancelationScheduled,
  paymentDate,
  price,
  variant,
}: PlanDetailsProps) => {
  const { t } = useTranslation();
  const shouldDisplayEndsOnDate =
    isCancelationScheduled || variant === PlanDetailsVariant.PREPAID;
  const formattedPrice = formatPriceWithInterval(
    price,
    t('planInterval.interval', {
      interval: price?.interval,
      count: price?.intervalMultiplier,
    })
  );

  return (
    <div className={cn(parentStyles.row, styles.planRow)}>
      <div
        className={styles.planDetails}
        data-testid="AccountSettings__PlanNameWithPrice"
      >
        {planName}
        {formattedPrice ? ` - ${formattedPrice}` : ''}
      </div>

      {screenLimit ? <SubscriptionDevices screenLimit={screenLimit} /> : null}
      {paymentDate && (
        <div className={styles.paymentDate}>
          {shouldDisplayEndsOnDate
            ? t('common.endsOn')
            : t('common.nextPayment')}

          <div className={parentStyles.value}>
            {format(paymentDate, 'dd/MM/yyyy')}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanDetails;

import {
  Asset,
  ItemsPresented,
  PageComponentType,
  mapAssetsForItemsPresentedEvent,
} from '@laminar-product/client-commons-core/core';
import { useCallback, useEffect, useState } from 'react';
import _truncate from 'lodash/truncate';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import { DEFAULT_PLAN_CAROUSEL_ITEM_WIDTH } from './constants';

export enum CarouselType {
  PORTRAIT_CAROUSEL = 'PORTRAIT_CAROUSEL',
  LARGE_PORTRAIT_CAROUSEL = 'LARGE_PORTRAIT_CAROUSEL',
  PORTRAIT_EXPANDABLE_CAROUSEL = 'PORTRAIT_EXPANDABLE_CAROUSEL',
  CAROUSEL = 'CAROUSEL',
  CONTINUE_WATCHING = 'CONTINUE_WATCHING',
  POSTER_SCALE_UP = 'POSTER_SCALE_UP',
}

export type UseCarouselPaginationType = CarouselType | 'PlanCarousel';

export interface useCarouselParams {
  wrapper: Element | null;
  itemsCount?: number;
  carouselType?: UseCarouselPaginationType;
  isMobilePlan?: boolean;
  wrapperPagination?: number;
}

export interface PosterProps {
  onItemClick: (item: Asset) => void;
  data: Asset;
  className: string;
  posterUrl?: string;
  type?: CarouselType;
}

export function useCarouselPagination(
  parentEl: Element | null,
  calculateItemWidth: (
    parentWidth: number,
    type?: UseCarouselPaginationType
  ) => number,
  type?: UseCarouselPaginationType,
  wrapperPagination?: number
) {
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const { windowSize } = useWindowSize();
  const parentElRect = parentEl?.getBoundingClientRect();

  useEffect(() => {
    const width = parentElRect?.width;
    if (width) {
      const totalWidth = wrapperPagination ? width + wrapperPagination : width; //NOTE: Need to add component padding for proper calculation
      const itemWidth = Math.floor(calculateItemWidth(totalWidth, type));

      const getItemsPerPage = () => {
        if (type === 'PlanCarousel') {
          const calculatedItems = Math.floor(totalWidth / itemWidth);

          return calculatedItems > 0 ? calculatedItems : 1; //NOTE: Plans have static width, user can see only the whole plan item; minimum 1 per page
        }

        return parseFloat((totalWidth / itemWidth).toFixed(2)); //NOTE: Can't floor as user can can see e.g. half of item in carousel
      };

      setItemsPerPage(getItemsPerPage());
    }
  }, [
    calculateItemWidth,
    type,
    parentElRect,
    wrapperPagination,
    windowSize.width,
  ]);

  return itemsPerPage;
}

export function useCarousel({
  wrapper,
  itemsCount,
  carouselType = CarouselType.CAROUSEL,
  isMobilePlan,
  wrapperPagination,
}: useCarouselParams) {
  const [currentPage, setPage] = useState(0);
  const [currentTransform, setTransform] = useState(0);

  const itemsPerPage = useCarouselPagination(
    wrapper,
    calculateCarouselItemWidth,
    carouselType,
    wrapperPagination
  );

  const pages =
    itemsPerPage && itemsCount ? Math.ceil(itemsCount / itemsPerPage) : 0;

  const goToPage = useCallback(
    (page: number) => setPage(page <= pages - 1 && page > 0 ? page : 0),
    [pages]
  );

  const onSwipedLeft = useCallback(
    () => goToPage(currentPage + 1),
    [currentPage, goToPage]
  );

  const onSwipedRight = useCallback(
    () => goToPage(currentPage - 1),
    [currentPage, goToPage]
  );

  useEffect(() => {
    if (itemsCount && currentPage === pages - 1 && pages > 1 && !isMobilePlan) {
      const lastPageItems = itemsCount % itemsPerPage;
      const itemPart = 100 / itemsPerPage; //part on whole width which takes one item
      const partialTransform =
        lastPageItems * itemPart === 0 ? 0 : 100 - lastPageItems * itemPart;
      setTransform(currentPage * -100 + partialTransform);
    } else {
      setTransform(currentPage * -100); // move the carousel for the whole width
    }
  }, [currentPage, isMobilePlan, itemsCount, itemsPerPage, pages]);

  useEffect(() => {
    if (currentPage > pages - 1 && pages > 0) {
      setPage(pages - 1);
    }
  }, [currentPage, pages]);

  return {
    goToPage,
    pages,
    currentPage,
    currentTransform,
    itemsPerPage,
    lastPage: pages > 0 && currentPage >= pages - 1,
    onSwipedLeft,
    onSwipedRight,
  };
}

const getDefaultItemRatio = (type?: CarouselType) => {
  switch (type) {
    case CarouselType.CAROUSEL:
      return 0.65;
    case CarouselType.PORTRAIT_CAROUSEL:
      return 0.44;
    case CarouselType.LARGE_PORTRAIT_CAROUSEL:
    case CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL:
      return 1;
    default:
      return 0.5;
  }
};

export function calculateCarouselItemWidth(
  parentWidth: number,
  type?: UseCarouselPaginationType
): number {
  //NOTE (1): The width of plan item is always the same, set in CSS
  if (type === 'PlanCarousel') {
    return DEFAULT_PLAN_CAROUSEL_ITEM_WIDTH;
  }

  //NOTE (2): The following calculation is based on the scss rules from ./components/CarouselItem/index.module.scss
  //NOTE: The default values will be taken only for screen width < 500
  let itemRatio = getDefaultItemRatio(type);

  if (parentWidth >= 1400) {
    switch (type) {
      case CarouselType.PORTRAIT_CAROUSEL:
        itemRatio = 1 / 9;
        break;
      case CarouselType.POSTER_SCALE_UP:
        itemRatio = 1 / 10;
        break;
      default:
        itemRatio = 1 / 6;
        break;
    }
  }

  if (parentWidth < 1400 && parentWidth >= 1100) {
    switch (type) {
      case CarouselType.PORTRAIT_CAROUSEL:
        itemRatio = 1 / 7;
        break;
      case CarouselType.LARGE_PORTRAIT_CAROUSEL:
      case CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL:
        itemRatio = 1 / 4;
        break;
      case CarouselType.POSTER_SCALE_UP:
        itemRatio = 1 / 7;
        break;
      default:
        itemRatio = 1 / 5;
        break;
    }
  }

  if (parentWidth < 1100 && parentWidth >= 800) {
    switch (type) {
      case CarouselType.PORTRAIT_CAROUSEL:
        itemRatio = 1 / 5;
        break;
      case CarouselType.LARGE_PORTRAIT_CAROUSEL:
      case CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL:
        itemRatio = 1 / 3;
        break;
      case CarouselType.POSTER_SCALE_UP:
        itemRatio = 1 / 5;
        break;
      default:
        itemRatio = 1 / 4;
        break;
    }
  }

  if (parentWidth < 800 && parentWidth >= 500) {
    switch (type) {
      case CarouselType.LARGE_PORTRAIT_CAROUSEL:
      case CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL:
        itemRatio = 1 / 2;
        break;
      case CarouselType.POSTER_SCALE_UP:
        itemRatio = 1 / 4;
        break;
      default:
        itemRatio = 1 / 3;
        break;
    }
  }

  if (parentWidth <= 500) {
    switch (type) {
      case CarouselType.PORTRAIT_CAROUSEL:
        itemRatio = 1 / 3;
    }
  }

  return parentWidth * itemRatio;
}

export const getItemsForItemsPresentedEvents = (
  assets: Asset[],
  lastPage: boolean,
  currentPage: number,
  itemsPerPage: number
): ItemsPresented['items'] => {
  if (lastPage) {
    return mapAssetsForItemsPresentedEvent(
      assets.slice(-itemsPerPage)
    ) as ItemsPresented['items'];
  } else {
    const sliceStartIndex = currentPage * itemsPerPage;
    const sliceEndIndex = (currentPage + 1) * itemsPerPage;

    return mapAssetsForItemsPresentedEvent(
      assets.slice(sliceStartIndex, sliceEndIndex)
    ) as ItemsPresented['items'];
  }
};

export const getFallbackCarouselTitle = (title: string) =>
  _truncate(title, { length: 100 });

export const getDynamicCarouselType = (
  type: PageComponentType
): CarouselType | undefined => {
  switch (type) {
    case PageComponentType.CAROUSEL:
      return CarouselType.CAROUSEL;
    case PageComponentType.PORTRAIT_CAROUSEL:
      return CarouselType.PORTRAIT_CAROUSEL;
    case PageComponentType.PORTRAIT_EXPANDABLE_CAROUSEL:
      return CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL;
    case PageComponentType.LARGE_PORTRAIT_CAROUSEL:
      return CarouselType.LARGE_PORTRAIT_CAROUSEL;
    case PageComponentType.CONTINUE_WATCHING:
      return CarouselType.CONTINUE_WATCHING;
    default:
      return undefined;
  }
};

export const getCarouselTypeMinHeight = (type?: CarouselType) => {
  switch (type) {
    case CarouselType.PORTRAIT_CAROUSEL:
      return 200;
    case CarouselType.LARGE_PORTRAIT_CAROUSEL:
      return 300;
    case CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL:
      return 400;
    default:
      return 150;
  }
};

export const isContinueWatchingCarousel = (type?: CarouselType) =>
  type === CarouselType.CONTINUE_WATCHING;

import Input from 'components/Input';
import PasswordInput from 'components/PasswordInput';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthInputType, validateAuthInput } from 'utils/auth';
import { registerUser } from 'actions/basic';
import { useHistory } from 'react-router-dom';
import { FirebaseAuthCodes, upgradePlanParamId } from 'utils/constants';
import usePathWithParams from 'utils/usePathWithParams';
import { useDispatch } from 'react-redux';
import {
  setRegistrationMethod,
  setUserLogin,
} from 'store/registration/actions';
import { ReactComponent as LoginUserIcon } from 'assets/icons/login-user.svg';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Button } from '@laminar-product/client-commons-core/web';
import styles from './index.module.scss';

/**
 * Registers user in Client API and then signs by Firebase.
 *
 * This is not a standard Firebase auth flow. This is a strict requirement from the API.
 * @param {{userEmail: string, password: string}} form
 */

export interface SignUpEmailFormData {
  userEmail: string;
  password: string;
}

const registerUserAndSignInFirebase = async ({
  userEmail,
  password,
}: SignUpEmailFormData) => {
  const auth = getAuth();
  await createUserWithEmailAndPassword(auth, userEmail, password);
  await signInWithEmailAndPassword(auth, userEmail, password);
};

const SignUpEmailPassword = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignUpEmailFormData>();
  const { t } = useTranslation();
  const [registerUserInProgress, toggleRegisterUserProgress] = useState(false);
  const { push, location } = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const getPath = usePathWithParams();
  const [registerError, setRegisterError] = useState<string>();
  const dispatch = useDispatch();

  const getRegisterErrorMessage = useCallback(
    (e: any) => {
      if (e.code === FirebaseAuthCodes.ACCOUNT_EXISTS) {
        return t('errors.accountExistsWithEmail');
      }

      return t('errors.defaultErrorFallback');
    },
    [t]
  );

  const onSubmit = async ({ password, userEmail }: SignUpEmailFormData) => {
    toggleRegisterUserProgress(true);

    dispatch(setUserLogin(userEmail));
    dispatch(setRegistrationMethod('email'));

    registerUserAndSignInFirebase({ userEmail, password })
      .then(async () => {
        const auth = getAuth();
        const token = await registerUser();
        await signInWithCustomToken(auth, token.data);
        push(
          queryParams.get(upgradePlanParamId)
            ? getPath('/register/plan')
            : '/register/plan'
        );
      })
      .catch((e) => {
        toggleRegisterUserProgress(false);
        setRegisterError(getRegisterErrorMessage(e));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.input}>
        <Controller
          control={control}
          name="userEmail"
          defaultValue=""
          rules={{
            validate: (value) => validateAuthInput(value, AuthInputType.EMAIL),
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              autoFocus
              value={value}
              className={styles.inputWrapper}
              onChange={(e) => onChange(e.target.value.trim())}
              placeholder={t('common.placeholders.email')}
              startIcon={<LoginUserIcon />}
            />
          )}
        />
      </div>

      {!!errors.userEmail && (
        <p className={styles.errorMessage}>{errors.userEmail?.message}</p>
      )}

      <PasswordInput
        {...register('password', {
          validate: (value) => validateAuthInput(value, AuthInputType.PASSWORD),
        })}
        passwordCheckPosition="under"
        autoComplete="new-password"
        checkWrapperStyle={styles.passwordCheckWrapper}
        wrapperStyle={styles.input}
        className={styles.inputWrapper}
        passwordErrorComponent={
          !!errors.password && (
            <p className={styles.errorMessage}>{errors.password?.message}</p>
          )
        }
      />

      {!!registerError && (
        <p className={styles.errorMessage}>{registerError}</p>
      )}

      <Button
        loading={registerUserInProgress}
        className={styles.registerButton}
        variant="primary"
      >
        {t('login.register')}
      </Button>
    </form>
  );
};

export default SignUpEmailPassword;

import { Asset } from '@laminar-product/client-commons-core/core';
import api from 'api';

interface Recommendation {
  assets?: Asset[];
}

export const getAssetRecommendation = async (
  assetId: string
): Promise<Recommendation> => {
  const { data } = await api().get(`asset/${assetId}/recommendation`);

  return data;
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useBackHandler = (onBackCallback: () => void) => {
  const history = useHistory();

  useEffect(() => {
    const backListener = history.listen(() => {
      if (history.action === 'POP') {
        onBackCallback();
      }
    });

    return () => backListener();
  }, [history, onBackCallback]);
};

export default useBackHandler;

import '@laminar-product/client-commons-core/style.css';
import React from 'react';
import AppMetaHeader from 'components/AppMetaHeader';
import AppScriptHeader from 'components/AppScriptHeader';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { MenuNavigationProvider } from 'utils/useMenuContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import './i18n.ts';
import './index.scss';
import setupApp from './SetupApp';
import store from './store';

const history = createBrowserHistory();

const run = async () => {
  await setupApp(history);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: 3,
        retryDelay: 1000,
      },
    },
  });
  const rootElement = document.getElementById('root');
  if (rootElement === null) throw new Error('root element not found');

  const setViewportHeightUnit = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setViewportHeightUnit);
  setViewportHeightUnit();

  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AppMetaHeader />
          <AppScriptHeader />
          <Router history={history}>
            <MenuNavigationProvider>
              <App />
            </MenuNavigationProvider>
          </Router>
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

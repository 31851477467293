import { Modal } from '@laminar-product/client-commons-core/web';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAppStoreLinks, selectCompanyName } from 'store/app/selectors';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import { isIos } from 'utils/getUserOs';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ShareSafari } from 'assets/icons/shareSafari.svg';
import styles from './index.module.scss';

type AddToHomeScreenVisibility = 'visible' | 'hidden' | null;
const ADD_TO_HOME_SCREEN_VISIBILITY_KEY = 'addToHomeScreenVisibility';

const AddToHomeScreen = () => {
  const { t } = useTranslation();
  const appStoreLinks = useSelector(selectAppStoreLinks);
  const companyName = useSelector(selectCompanyName);
  const iconSrc = `${import.meta.env.VITE_STATIC_URL}/favicon512.png`;

  const [addToHomeScreenVisibilityLocal, setAddToHomeScreenVisibilityLocal] =
    useState<AddToHomeScreenVisibility>('hidden');

  const shouldDisplayAddToHomeScreenModal = useMemo(() => {
    const linkProvidedForPlatform = isIos()
      ? !!appStoreLinks?.appstore
      : !!appStoreLinks?.playstore;

    //Display only for mobile/tablet if no mobile app
    if (!isMobileOrTablet() || linkProvidedForPlatform) {
      return false;
    }

    //Display only if not dismissed before
    return addToHomeScreenVisibilityLocal === 'visible';
  }, [appStoreLinks, addToHomeScreenVisibilityLocal]);

  const handleModalClose = () => {
    localStorage.setItem(ADD_TO_HOME_SCREEN_VISIBILITY_KEY, 'hidden');
    setAddToHomeScreenVisibilityLocal('hidden');
  };

  //Handle initial value
  useEffect(() => {
    const localValue = localStorage.getItem(
      ADD_TO_HOME_SCREEN_VISIBILITY_KEY
    ) as AddToHomeScreenVisibility;

    //If null then display
    setAddToHomeScreenVisibilityLocal(localValue || 'visible');
  }, []);

  if (!shouldDisplayAddToHomeScreenModal) {
    return null;
  }

  return (
    <Modal wrapperId="addToHomeScreenModal" className={styles.wrapper} isOpen>
      <div className={styles.content}>
        <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />

        <img src={iconSrc} alt="icon" className={styles.companyIcon} />
        <div className={styles.header}>{t('addToHomeScreen.header')}</div>
        <div className={styles.description}>
          <Trans i18nKey="addToHomeScreen.message">
            Enjoy quick access to <strong>{{ companyName } as any}</strong> by
            adding it to your home screen.
          </Trans>
        </div>

        <div className={styles.divider} />

        <div className={styles.instruction}>
          {isIos() ? (
            <Trans i18nKey="addToHomeScreen.safariInstruction">
              In you Safari browser menu, tap
              <ShareSafari className={styles.shareIcon} /> then
              <strong>'Add to Home Screen'</strong>.
            </Trans>
          ) : (
            <Trans i18nKey="addToHomeScreen.defaultInstruction">
              In your browser menu, find an option
              <strong>Add to Home Screen</strong>
            </Trans>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddToHomeScreen;

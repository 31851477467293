import api from 'api';

export interface LikeResponse {
  like: string | null | undefined;
  user: string;
}

export const getLikeForAsset = async ({
  id,
}: {
  id: string;
}): Promise<LikeResponse> => {
  const { data } = await api().get(`/favorites/like/${id}`);

  return data;
};

export const likeAsset = async ({ id }: { id: string }) => {
  await api().put('/favorites/like', { assetUuid: id, like: 'LIKE' });
};

export const dislikeAsset = async ({ id }: { id: string }) => {
  await api().put('/favorites/like', { assetUuid: id, like: 'DISLIKE' });
};

export const removeLikeForAsset = async ({ id }: { id: string }) => {
  await api().delete('/favorites/like', { data: { assetUuid: id } });
};

export interface WatchlistResponse {
  assetUuids: string[];
  user: string;
}

export const getWatchlist = async (): Promise<WatchlistResponse> => {
  const { data } = await api().get('/favorites/watchlist');

  return data;
};

export const getIsAssetOnWatchlist = async ({ id }: { id: string }) => {
  const { data } = await api().get(`/favorites/watchlist/${id}`);

  return data;
};

export const addToWatchlist = async ({ id }: { id: string }) => {
  await api().put('/favorites/watchlist', { assetUuid: id });
};

export const removeFromWatchlist = async ({ id }: { id: string }) => {
  await api().delete('/favorites/watchlist', { data: { assetUuid: id } });
};

import { Button } from '@laminar-product/client-commons-core/web';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import CompanyLogo from 'components/CompanyLogo';
import useFeatureEnabled from 'components/FeatureEnabled/useFeatureEnabled';
import Notifications from 'components/Notifications';
import ProfileMenu from 'components/ProfileMenu';
import Search from 'components/Search';
import SubHeader from 'components/SubHeader';
import { ReactComponent as Stars } from 'assets/icons/stars.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { selectIsInterestsEnabled, selectMenuLinks } from 'store/app/selectors';
import {
  selectCurrentProfileHasInterests,
  selectIsAnonymous,
} from 'store/user/selectors';
import { FeatureFlag } from 'utils/constants';
import MenuLink from 'components/MenuLink';
import { APP_PAGE_PATH } from 'utils/navigation';
import { useState } from 'react';
import InterestsModal from 'components/InterestsModal';
import styles from '../../index.module.scss';

const DesktopMenu = ({
  hasSearch = true,
  hasNotifications = true,
  subheader,
}: {
  hasSearch?: boolean;
  hasNotifications?: boolean;
  subheader?: any;
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const showRegistration = useFeatureEnabled(FeatureFlag.showRegistration);
  const profileHasInterests = useSelector(selectCurrentProfileHasInterests);
  const interestsEnabled = useSelector(selectIsInterestsEnabled);
  const isAnonymous = useSelector(selectIsAnonymous);
  const [showInterestModal, setShowInterestModal] = useState(
    !isAnonymous && interestsEnabled && !profileHasInterests
  );
  const menuItems = useSelector(selectMenuLinks);
  const { isTabletWindowSize } = useWindowSize();

  return (
    <>
      <div className={styles.menuNavigation}>
        <NavLink
          className={styles.link}
          activeClassName={styles.activeLink}
          to="/"
          exact
        >
          <CompanyLogo className={styles.logo} />
        </NavLink>
        <div className={styles.linksGroup}>
          {menuItems?.map((menuItem, index) => (
            <MenuLink
              key={`${menuItem.uuid}_${index}`}
              menuItem={menuItem}
              activeClassName={styles.activeLink}
              className={styles.link}
            />
          ))}
          {!isAnonymous && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/watchlist"
              exact
              id="Menu__WatchlistNavLink"
            >
              {t('watchlist.title')}
            </NavLink>
          )}
        </div>
        <div className={styles.menuGroup}>
          {hasSearch && <Search className={styles.search} />}
          {!isAnonymous && interestsEnabled && (
            <Stars
              className={styles.interestIcon}
              onClick={() => setShowInterestModal(true)}
            />
          )}
          {hasNotifications && !isAnonymous && <Notifications />}
          {!isAnonymous && <ProfileMenu />}
          {isAnonymous && (
            <div className={styles.buttonAuthGroup}>
              {showRegistration && (
                <Button
                  className={styles.customButton}
                  onClick={() => push(APP_PAGE_PATH.REGISTER)}
                  variant="cta"
                >
                  {t('common.signUp')}
                </Button>
              )}
              <Button
                className={styles.customButton}
                variant="secondary"
                onClick={() => push(APP_PAGE_PATH.LOGIN)}
              >
                {t('common.signIn')}
              </Button>
            </div>
          )}
        </div>
      </div>
      {!!subheader && !isTabletWindowSize && <SubHeader {...subheader} />}
      {showInterestModal && <InterestsModal setOpen={setShowInterestModal} />}
    </>
  );
};

export default DesktopMenu;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlayerAssetId,
  selectPlayerNextEpisode,
} from 'store/player/selectors';
import { setPlayerNextEpisodeId } from 'store/player/actions';
import {
  AssetLocationState,
  isAssetWatchable,
} from '@laminar-product/client-commons-core/core';
import { useLocation } from 'react-router-dom';
import { useMoveToAssetPlayer } from './useMoveToAssetPlayer';
import { EPISODE_SHOW_URL_TYPE } from './constants';
import useFetchNextEpisode from './useFetchNextEpisode';

const useVideoPlayerNextEpisode = () => {
  const nextEpisode = useSelector(selectPlayerNextEpisode);
  const assetId = useSelector(selectPlayerAssetId);
  const dispatch = useDispatch();
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const location = useLocation<AssetLocationState>();

  const onNextEpisodeFetch = useCallback(
    (nextEpisodeId: string) => dispatch(setPlayerNextEpisodeId(nextEpisodeId)),
    [dispatch]
  );

  useFetchNextEpisode({ assetId, onNextEpisodeFetch });

  const onVideoEnded = useCallback(() => {
    if (!nextEpisode || !isAssetWatchable(nextEpisode.watchable)) {
      return;
    }

    moveToAssetPlayer(
      nextEpisode.id,
      EPISODE_SHOW_URL_TYPE,
      nextEpisode.root?.name || '',
      { state: { showId: location.state?.showId } }
    );
  }, [moveToAssetPlayer, nextEpisode, location]);

  return { onVideoEnded };
};

export default useVideoPlayerNextEpisode;

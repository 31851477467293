import { ReactComponent as Play } from 'assets/icons/play-button.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';
import { ReactComponent as SkipBackward } from 'assets/icons/skipBackward.svg';
import { ReactComponent as SkipForward } from 'assets/icons/skipForward.svg';
import { useSelector } from 'react-redux';
import {
  selectChromecastAsset,
  selectChromecastBuffering,
  selectChromecastCastElement,
  selectChromecastPaused,
} from 'store/chromecast/selectors';
import { VIDEO_PLAYER_SEEK_TIME } from 'utils/constants';
import cn from 'classnames';
import { useCallback } from 'react';
import { shouldDisableChromecastSeek } from 'components/ChromecastRemoteController/helpers';
import styles from './index.module.scss';
interface PlaybackActionsButtonsProps {
  wrapperClassName?: string;
  isPlayingAds?: boolean;
}

const PlaybackActionsButtons = ({
  wrapperClassName,
  isPlayingAds,
}: PlaybackActionsButtonsProps) => {
  const castElement = useSelector(selectChromecastCastElement);
  const buffering = useSelector(selectChromecastBuffering);
  const paused = useSelector(selectChromecastPaused);
  const asset = useSelector(selectChromecastAsset);
  const shouldDisableSeek = shouldDisableChromecastSeek({
    asset,
    isPlayingAds,
  });

  const onPlayButtonClick = useCallback(() => {
    if (buffering || !castElement) {
      return;
    }

    castElement.play();
  }, [buffering, castElement]);

  const onPauseButtonClick = useCallback(() => {
    if (buffering || !castElement) {
      return;
    }

    castElement.pause();
  }, [buffering, castElement]);

  const onSkip = useCallback(
    (direction: 'backward' | 'forward') => {
      const currentTime = castElement?.time;

      if (!currentTime || !castElement || buffering) {
        return;
      }

      // To avoid exiting player duration
      const timeAfterBackward = Math.max(
        currentTime - VIDEO_PLAYER_SEEK_TIME,
        0
      );

      const timeAfterForward = Math.min(
        currentTime + VIDEO_PLAYER_SEEK_TIME,
        castElement.duration
      );

      castElement.seek(
        direction === 'backward' ? timeAfterBackward : timeAfterForward
      );
    },
    [buffering, castElement]
  );

  if (!castElement) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.buttonsWrapper,
        wrapperClassName,
        buffering && styles.buffering
      )}
    >
      {!shouldDisableSeek ? (
        <SkipBackward
          className={styles.skipButton}
          onClick={() => onSkip('backward')}
        />
      ) : null}

      {paused ? (
        <Play className={styles.playPauseButton} onClick={onPlayButtonClick} />
      ) : (
        <Pause
          className={styles.playPauseButton}
          onClick={onPauseButtonClick}
        />
      )}

      {!shouldDisableSeek ? (
        <SkipForward
          className={styles.skipButton}
          onClick={() => onSkip('forward')}
        />
      ) : null}
    </div>
  );
};

export default PlaybackActionsButtons;

import { Asset, AssetType } from '@laminar-product/client-commons-core/core';
import BaseAssetModel from './base';
import LiveAssetModel from './live';

const assetModelFactory = (asset: Asset) => {
  switch (asset.type) {
    case AssetType.LIVE_CHANNEL:
    case AssetType.LIVE_EVENT:
      return new LiveAssetModel(asset);
    default:
      return new BaseAssetModel(asset);
  }
};

export default assetModelFactory;

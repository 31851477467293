import PasswordInput from 'components/PasswordInput';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isPasswordValidLength } from 'utils/validators';
import SettingsHeader from 'components/SettingsHeader';
import SettingsButtonGroup from 'components/SettingsButtonGroup';
import SettingsPageContainer from 'components/SettingsPageContainer';
import { useTranslation } from 'react-i18next';
import { selectFirebaseUser } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import { updatePassword } from 'firebase/auth';
import styles from './index.module.scss';

interface IChangePassword {
  newPassword: string;
  repeatPassword: string;
}

const ChangePassword = () => {
  const user = useSelector(selectFirebaseUser);
  const [globalError, setGlobalError] = useState<string>('');
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IChangePassword>();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const changePassword = useCallback(
    async (newPassword: string) => {
      if (!user) {
        throw new Error('There was a problem. Please relogin.');
      }
      setLoading(true);
      return updatePassword(user, newPassword).finally(() => setLoading(false));
    },
    [user]
  );

  const onSubmit = (data: IChangePassword) => {
    const { newPassword, repeatPassword } = data;
    if (newPassword !== repeatPassword) {
      setGlobalError(`Passwords doesn't match`);
      return;
    }

    setGlobalError('');

    //@todo when user session is old, firebase will throw error. To handle in future.
    return changePassword(data.newPassword)
      .then(() => push('/settings'))
      .catch((err) => setGlobalError(err.message));
  };

  //@todo to discuss commented checkbox

  return (
    <SettingsPageContainer>
      <div className={styles.wrapper}>
        <SettingsHeader title={t('settings.changePasswordTitle')} />
        <form
          className={styles.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formGroup}>
            <PasswordInput
              light
              {...register('newPassword', {
                required: t('common.validations.required').toString(),
                validate: (value) => isPasswordValidLength(value),
              })}
              label={t('settings.newPassword')}
              error={errors.newPassword?.message as string}
              placeholder={t('settings.newPassword')}
              passwordCheckPosition="under"
            />
          </div>
          <div className={styles.formGroup}>
            <PasswordInput
              light
              {...register('repeatPassword', {
                required: t('common.validations.required').toString(),
              })}
              label={t('settings.repeatPassword')}
              error={errors.repeatPassword?.message as string}
              placeholder={t('settings.repeatPassword')}
            />
          </div>

          {globalError && <p className={styles.errorMessage}>{globalError}</p>}
          <SettingsButtonGroup
            okText={t('common.save')}
            cancelText={t('common.cancel')}
            onCancel={() => push('/settings')}
            okType="submit"
            isOkLoading={loading}
          />
        </form>
      </div>
    </SettingsPageContainer>
  );
};

export default ChangePassword;

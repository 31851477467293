import { DiscountType } from '@laminar-product/client-commons-core/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPromotionDiscountLabel } from 'utils/promotions';
import { PaymentSummaryProps } from '..';
import styles from '../../index.module.scss';

const DiscountSummary = ({
  price,
  calculatedPriceCoupon,
}: PaymentSummaryProps) => {
  const { t } = useTranslation();
  const { getPromotionDiscountLabel } = useGetPromotionDiscountLabel();

  const getDiscoutTypeLabel = (discountType: DiscountType) => {
    return discountType === DiscountType.PERIOD
      ? t('orderSummary.freePeriod')
      : t('payment.discount');
  };

  if (!price.promotion && !calculatedPriceCoupon) return null;

  if (price.promotion && !calculatedPriceCoupon) {
    return (
      <div className={styles.couponDiscountDetails}>
        <span>{getDiscoutTypeLabel(price.promotion.discountType)}</span>
        <span>
          {getPromotionDiscountLabel({
            amount: price.promotion.amount,
            currency: price.currency,
            discountType: price.promotion.discountType,
            summaryLabel: true,
          })}
        </span>
      </div>
    );
  }

  return (
    <>
      {calculatedPriceCoupon?.discounts?.map((d, i) => (
        <div key={i} className={styles.couponDiscountDetails}>
          <span>
            {d.discountType === DiscountType.PERIOD &&
              t('orderSummary.freePeriod')}
            {d.discountType !== DiscountType.PERIOD &&
              i === 0 &&
              t('payment.discount')}
          </span>
          <span>
            {getPromotionDiscountLabel({
              amount: d.amount,
              currency: calculatedPriceCoupon.currency,
              discountType: d.discountType,
              summaryLabel: true,
            })}
          </span>
        </div>
      ))}
    </>
  );
};

export default DiscountSummary;

import { t } from 'i18next';

export enum RAZORPAY_ERROR_CODES {
  DEFAULT_ERROR = 'gateway/razorpay/subscription_create_default_error',
  CREATE_NEW_SUBSCRIPTION_FROZEN = 'gateway/razorpay/subscription_creation_frozen',
}

export const mapPaymentErrorToMessage = (errorCode: string) => {
  switch (errorCode) {
    case RAZORPAY_ERROR_CODES.CREATE_NEW_SUBSCRIPTION_FROZEN:
      return t('errors.subscriptionFrozenErrorMessage');
    case RAZORPAY_ERROR_CODES.DEFAULT_ERROR:
      return t('errors.defaultSubscriptionCreateErrorError');
    default:
      return t('errors.defaultSubscriptionCreateErrorError');
  }
};

import React from 'react';
import styles from './index.module.scss';

interface AssetProgressCircleProps {
  percent: number;
  width: number;
  height: number;
}
const AssetProgressCircle = ({
  percent,
  height,
  width,
}: AssetProgressCircleProps) => {
  const strokeWidth = 1.2;
  const r = width / 2 - strokeWidth / 2;
  const strokeDasharray = 2 * Math.PI * r;
  const strokeDashOffset = strokeDasharray * (1 - percent / 100);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={styles.progressCircle}
    >
      <circle
        cx={width / 2}
        cy={height / 2}
        r={r}
        fill="none"
        stroke="none"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={width / 2}
        cy={height / 2}
        r={r}
        fill="none"
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashOffset}
        className={styles.colouredCircle}
      />
    </svg>
  );
};

export default AssetProgressCircle;

import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useDeferredRedirect = ({
  url,
  timeout = 5000,
  shouldRunTimer = true,
}: {
  url: string;
  timeout?: number;
  shouldRunTimer?: boolean;
}) => {
  const { push } = useHistory();
  const [timeLeft, setTimeLeft] = useState(timeout);
  const intervalIdRef = useRef<NodeJS.Timeout>();

  const decreaseTimeLeft = useCallback(() => {
    if (timeLeft === 0 && intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    setTimeLeft(timeLeft - 1000);
  }, [timeLeft]);

  useEffect(() => {
    if (!shouldRunTimer) return;

    intervalIdRef.current = setInterval(decreaseTimeLeft, 1000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [decreaseTimeLeft, timeout, shouldRunTimer]);

  useEffect(() => {
    if (!shouldRunTimer) return;

    if (timeLeft === 0 && url) {
      push(url);
    }
  });

  return timeLeft;
};

export default useDeferredRedirect;

import { useEffect } from 'react';
import {
  SchemaVersion,
  TrackEvent,
} from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import { selectFirebaseUserId, selectLoginMethod } from 'store/user/selectors';
import { LoginMethod } from 'store/user/reducer';
import { useTracker } from './useTracker';

const useApplicationLoginTracking = () => {
  const { trackNonUserEvent } = useTracker();
  const userUid = useSelector(selectFirebaseUserId);
  const loginMethod = useSelector(selectLoginMethod);

  useEffect(() => {
    if (!loginMethod || !userUid) return;
    trackNonUserEvent<TrackEvent.APPLICATION_LOGIN>({
      event: TrackEvent.APPLICATION_LOGIN,
      data: {
        login_method: loginMethod.toLowerCase() as LoginMethod,
        account_id: userUid,
      },
      version: SchemaVersion.V310,
    });
  }, [loginMethod, trackNonUserEvent, userUid]);
};

export default useApplicationLoginTracking;

import React, { useState } from 'react';
import CodeInput from 'react-code-input';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { useResendOtpTimeout } from 'utils/useResentOtpTimeout';
import PolicyNote from 'components/PolicyNote';
import Checkbox from '../Checkbox';
import styles from './index.module.scss';

interface OneTimePasswordFormProps {
  isRegistering?: boolean;
  isDarkMode?: boolean;
  isLoginForm?: boolean;
  hasRememberMe?: boolean;
  errorMessage?: string;
  phoneNumber: string;
  onVerify: (otpValue: string) => void;
  isLoading?: boolean;
  clearError: () => void;
  sendOtp: () => void;
}

const OneTimePasswordForm = ({
  isRegistering,
  isDarkMode,
  isLoginForm,
  hasRememberMe,
  onVerify,
  isLoading,
  sendOtp,
  errorMessage,
  clearError,
}: OneTimePasswordFormProps) => {
  const [code, setCode] = useState('');
  const { t } = useTranslation();
  const [rememberMe, toggleRememberMe] = useState(true);
  const { resendOtpTimeout, setResendOtpTimeout } = useResendOtpTimeout();

  const onResendOtpClick = () => {
    setResendOtpTimeout();
    sendOtp();
  };

  const onVerifyOtp = () => {
    clearError();
    onVerify(code);
  };

  return (
    <div className={styles.root}>
      <div className={styles.inputsGroup}>
        <CodeInput
          inputMode="latin"
          name="oneTimeCode"
          value={code}
          onChange={setCode}
          type="number"
          fields={6}
          className={cn(styles.codeInput, {
            [styles.codeInputDark]: isDarkMode,
            [styles.codeInputLoginForm]: isLoginForm,
          })}
        />
        <Button
          variant={isDarkMode ? 'primary' : 'cta'}
          className={styles.verifyButton}
          onClick={onVerifyOtp}
          loading={isLoading}
        >
          Verify
        </Button>

        {isRegistering && <PolicyNote />}

        {hasRememberMe && (
          <Checkbox
            variant="primary"
            label={t('login.rememberMe')}
            checked={rememberMe}
            className={styles.rememberMe}
            onChange={(e) => toggleRememberMe(e.target.checked)}
          />
        )}
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        {resendOtpTimeout > 0 ? (
          <p
            className={cn(styles.blockedOtp, {
              [styles.blockedOtpDark]: isDarkMode,
            })}
          >
            You can request another One-Time password in {resendOtpTimeout}s
          </p>
        ) : (
          <Button
            variant="text"
            className={cn(styles.boldButton, {
              [styles.boldButtonDark]: isDarkMode,
            })}
            onClick={onResendOtpClick}
          >
            Resend One-Time password
          </Button>
        )}
      </div>
    </div>
  );
};

export default OneTimePasswordForm;

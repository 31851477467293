import { Profile } from '@laminar-product/client-commons-core/core';
import LinearProfileWrapper from 'components/LinearProfileWrapper';
import React, { useCallback, useState } from 'react';
import { ProfileConfirmationMethods } from 'types/profiles';
import { useIdentityConfirmation } from 'pages/ProfilesSelector/utils';
import CloseProfileModal from 'components/CloseProfileModal';
import ConfirmationMethodSelector from '../ConfirmationMethodSelector';
import PasswordConfirmation from '../ConfirmationForm/PasswordConfirmation';
import SocialConfirmation from '../ConfirmationForm/SocialConfirmation';
import PhoneConfirmationProvider from '../ConfirmationForm/PhoneConfirmation';

interface ProfileIdentityConfirmationProps {
  profile: Profile;
  onClose: () => void;
  onConfirmationSuccess: () => void;
}

export interface ProfileConfirmationProps {
  onConfirmationSuccess: () => void;
  onClose: () => void;
}

const ProfileIdentityConfirmation = ({
  profile,
  onClose,
  onConfirmationSuccess,
}: ProfileIdentityConfirmationProps) => {
  const [selectedConfirmationMethod, setSelectedConfirmationMethod] =
    useState<ProfileConfirmationMethods>();

  const {
    availableConfirmationMethods,
    userHasMultipleConfirmationMethods,
    getDefaultConfirmationMethod,
    currentSocialIdProvider,
  } = useIdentityConfirmation();

  const onConfirmationModalClose = useCallback(() => {
    if (userHasMultipleConfirmationMethods) {
      setSelectedConfirmationMethod(undefined);
      return;
    }

    onClose();
  }, [userHasMultipleConfirmationMethods, onClose]);

  const getDefaultConfirmationContent = useCallback(
    (selectedMethod?: ProfileConfirmationMethods) => {
      const commonProps: ProfileConfirmationProps = {
        onClose: onConfirmationModalClose,
        onConfirmationSuccess,
      };

      const confirmationMethod =
        selectedMethod || getDefaultConfirmationMethod();

      switch (confirmationMethod) {
        case ProfileConfirmationMethods.PASSWORD:
          return <PasswordConfirmation {...commonProps} />;
        case ProfileConfirmationMethods.PHONE:
          return <PhoneConfirmationProvider {...commonProps} />;
        case ProfileConfirmationMethods.SOCIAL:
          return (
            <SocialConfirmation
              {...commonProps}
              socialProviderId={currentSocialIdProvider}
            />
          );
        default:
          return null;
      }
    },
    [
      currentSocialIdProvider,
      getDefaultConfirmationMethod,
      onConfirmationModalClose,
      onConfirmationSuccess,
    ]
  );

  if (!availableConfirmationMethods?.length) {
    return null;
  }

  if (userHasMultipleConfirmationMethods && !selectedConfirmationMethod) {
    return (
      <ConfirmationMethodSelector
        onSelect={setSelectedConfirmationMethod}
        onClose={onClose}
        profile={profile}
        allowedConfirmationMethods={availableConfirmationMethods}
      />
    );
  }

  return (
    <LinearProfileWrapper>
      <CloseProfileModal onClose={onClose} />

      {getDefaultConfirmationContent(selectedConfirmationMethod)}
    </LinearProfileWrapper>
  );
};

export default ProfileIdentityConfirmation;

import { ComponentsPage } from '@laminar-product/client-commons-core/core';
import { AnyAction } from 'redux';
import {
  RESET_PAGES,
  SET_COMPONENTS_PAGE,
  SET_PAGE_FETCHING_STATUS,
  SET_PAGE_LAST_FETCHED_PAGINATION,
} from './actions';
import { PAGE_FETCHING_STATUS } from './types';

export interface PagesState {
  pagesDetails?: {
    [key: string]: ComponentsPage | undefined;
  };
  pagesLastFetchedPagination?: {
    [key: string]: number;
  };
  pagesFetchingStatus?: {
    [key: string]: PAGE_FETCHING_STATUS;
  };
}

const handleSetComponentsPage = (
  state: PagesState,
  page: ComponentsPage,
  skip: number
) => {
  const currentComponents =
    state?.pagesDetails?.[page.uuid]?.components?.data || [];

  return {
    ...page,
    components: {
      ...page.components,
      data: [...(skip > 0 ? currentComponents : []), ...page.components.data], //TODO: skip > 0 is a hack for doubled rerenders - doubled added the same components in strict mode
    },
  };
};

const pagesReducer = (
  state: PagesState = {},
  action: AnyAction
): PagesState => {
  switch (action.type) {
    case RESET_PAGES:
      return {};
    case SET_COMPONENTS_PAGE: {
      return {
        ...state,
        pagesDetails: {
          ...state.pagesDetails,
          [action.payload.page.uuid]: handleSetComponentsPage(
            state,
            action.payload.page,
            action.payload.skip
          ),
        },
      };
    }
    case SET_PAGE_LAST_FETCHED_PAGINATION: {
      return {
        ...state,
        pagesLastFetchedPagination: {
          ...state.pagesLastFetchedPagination,
          [action.payload.pageId]: action.payload.lastFetchedPagination,
        },
      };
    }

    case SET_PAGE_FETCHING_STATUS: {
      return {
        ...state,
        pagesFetchingStatus: {
          ...state.pagesFetchingStatus,
          [action.payload.pageId]: action.payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default pagesReducer;

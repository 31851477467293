import { RootState } from 'store/reducer';
import {
  Asset,
  AssetType,
  ContinueWatchingItem,
  SSO_AUTH_PROVIDERS,
} from '@laminar-product/client-commons-core/core';
import {
  CustomAuthenticationProviders,
  SocialAuthenticationProviders,
} from 'utils/constants';
import _sortBy from 'lodash/sortBy';

export const selectRootAsset = (id?: string) => (state: RootState) =>
  id ? state.app?.assetRoot?.[id] : undefined;

export const selectAssetWatchingProgress =
  (assetId?: string, assetType?: AssetType) => (state: RootState) => {
    if (!assetId) return null;

    if (assetType === AssetType.SHOW) {
      return Object.values(state.app.continueWatching || []).find(
        (item: ContinueWatchingItem) => item.rootId === assetId
      );
    }

    return state.app.continueWatching[assetId] || 0;
  };

export const selectAppLinks = (state: RootState) =>
  state.app.appSettings?.links;

export const selectSupportLink = (state: RootState) =>
  state.app.appSettings?.links?.support;

export const selectAppSocialLinks = (state: RootState) =>
  state.app.appSettings?.social;

export const selectCompanyName = (state: RootState) =>
  state.app.appSettings?.companyName;

export const selectAppName = (state: RootState) =>
  state.app.appSettings?.appName;

export const selectAppStoreLinks = (state: RootState) =>
  state.app.appSettings?.stores;

export const selectIsInterestsEnabled = (state: RootState) =>
  state.app?.appSettings?.interestEnabled ||
  (state.app?.appSettings?.interests || []).length > 0;

export const selectIsPaymentsMaintenance = (state: RootState) =>
  state.app?.appSettings?.paymentsMaintenance;

export const selectDeviceId = (state: RootState) => state.app?.device?.deviceId;

export const selectAuthenticationProviders = (state: RootState) =>
  state.app?.appSettings?.authenticationProviders;

export const selectSSOAuthenticationProviders = (state: RootState) => {
  const allowedAuthenticationProviders =
    state.app?.appSettings?.authenticationProviders || ([] as string[]);

  return SSO_AUTH_PROVIDERS.filter((p) =>
    allowedAuthenticationProviders.includes(p)
  );
};

export const selectSocialAuthenticationProviders = (state: RootState) => {
  const allowedAuthenticationProviders = (state.app?.appSettings
    ?.authenticationProviders || []) as string[];

  return Object.values(SocialAuthenticationProviders).filter((social) =>
    allowedAuthenticationProviders?.includes(social)
  );
};

export const selectCustomAuthenticationProviders = (state: RootState) => {
  const allowedAuthenticationProviders = (state.app?.appSettings
    ?.authenticationProviders || []) as string[];

  return Object.values(CustomAuthenticationProviders).filter((social) =>
    allowedAuthenticationProviders?.includes(social)
  );
};

export const selectAppTheme = (state: RootState) =>
  state.app?.appSettings?.theme;

export const selectMenuLinks = (state: RootState) =>
  state.app?.appSettings?.menuLinks;

export const selectAvailableInterests = (state: RootState) =>
  state.app?.appSettings?.interests;

export const selectMenuHomeLink = (state: RootState) =>
  state.app?.appSettings?.menuLinks?.[0];

export const selectWelcomeBackgroundImage = (state: RootState) =>
  state.app.appSettings?.theme?.images?.welcomeBackground;

export const selectFavicon = (state: RootState) =>
  state.app.appSettings?.theme?.images?.favicon;

export const selectLogo = (state: RootState) =>
  state.app.appSettings?.theme?.images?.logo;

export const selectSeasonSortedChildren = (id?: string) => (state: RootState) =>
  id
    ? _sortBy(state.app.assetChildren?.[id], (episode: Asset) => episode.order)
    : [];

export const selectAllowedOtpCountryCodes = (state: RootState) =>
  state.app.appSettings?.allowedOtpCountryCodes;

export const selectSupportPhoneNumber = (state: RootState) =>
  state.app.appSettings?.contactPhones?.support;

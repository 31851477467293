import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { useCallback, useEffect, useState } from 'react';
import {
  NEXT_EPISODE_INITIAL_PROGRESS_VALUE,
  NEXT_EPISODE_INTERVAL_TIME,
} from 'utils/constants';
import _throttle from 'lodash/throttle';
import { checkIsAutoplayNextEpisodeEnabled } from './player';

interface UseAutoplayNextEpisodeProps {
  onRedirectToNextEpisode: () => void;
}

const useAutoplayNextEpisode = ({
  onRedirectToNextEpisode,
}: UseAutoplayNextEpisodeProps) => {
  const [counterTime, setCounterTime] = useState(NEXT_EPISODE_INTERVAL_TIME);
  const [circlePercent, setCirclePercent] = useState(
    NEXT_EPISODE_INITIAL_PROGRESS_VALUE
  );

  const [isCounterVisible, setIsCounterVisible] = useState(false);
  const isAutoplayNextEpisodeEnabled = checkIsAutoplayNextEpisodeEnabled();

  const cancelCounter = usePolling(() => setCounterTime((prev) => prev - 1), {
    interval: 1000,
    shouldRunPolling: isAutoplayNextEpisodeEnabled,
  });

  const cancelProgressCircle = usePolling(
    () => setCirclePercent((prevCircle) => prevCircle - 100),
    { interval: 100, shouldRunPolling: isAutoplayNextEpisodeEnabled }
  );

  const onStopCounting = useCallback(() => {
    cancelCounter();
    cancelProgressCircle();
    setIsCounterVisible(false);
  }, [cancelCounter, cancelProgressCircle]);

  useEffect(() => {
    //Init counter if autoplay next episode is on
    if (isAutoplayNextEpisodeEnabled) {
      setIsCounterVisible(true);
    }
  }, [isAutoplayNextEpisodeEnabled]);

  useEffect(() => {
    //Autoplay next episode after finish counting
    if (counterTime <= 0 && isCounterVisible) {
      onStopCounting();
      onRedirectToNextEpisode();
    }
  }, [counterTime, isCounterVisible, onStopCounting, onRedirectToNextEpisode]);

  useEffect(() => {
    // Cancel counting on user action
    if (!isCounterVisible) {
      return;
    }

    const onMouseMove = _throttle(() => {
      onStopCounting();
    }, 500);

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isCounterVisible, onStopCounting]);

  return {
    isCounterVisible,
    counterTime,
    circlePercent,
  };
};

export default useAutoplayNextEpisode;

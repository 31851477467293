import {
  Asset,
  AssetType,
  CatalogAsset,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import { MetaOg } from 'types/meta';

const getAssetOgType = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.MOVIE:
      return 'video.movie';
    case AssetType.SHOW:
      return 'video.tv_show';
    case AssetType.EPISODE:
      return 'video.episode';
    default:
      return 'video.other';
  }
};

export const prepareMetaForAsset = (asset: Asset | CatalogAsset): MetaOg => {
  return {
    title: asset.name,
    description: asset.description,
    type: getAssetOgType(asset.type),
    image: getAssetImageWithType(asset, 'thumbnail')?.url,
    ...('id' in asset && { url: `${window.location.origin}/a/${asset.id}` }),
  };
};

export const prepareMetaForPage = (title: string, description = ''): MetaOg => {
  return {
    title,
    description: description,
    type: 'website',
    url: window.location.href,
    image: '',
  };
};

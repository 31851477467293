import CompanyLogo from 'components/CompanyLogo';
import Search from 'components/Search';
import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useMenuContext } from 'utils/useMenuContext';
import SubHeaderMobile from 'components/SubHeaderMobile';
import { SubHeaderProps } from 'components/SubHeader';
import styles from '../../index.module.scss';
import HamburgerButton from '../HamburgerButton';
import SideMenu from '../SideMenu';

interface MobileMenuProps {
  mobileAlertOpen: boolean;
  subheader?: SubHeaderProps;
}

const MobileMenu = ({ mobileAlertOpen, subheader }: MobileMenuProps) => {
  const { isOpen, setIsOpen } = useMenuContext();

  const menuClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div
        className={cn(styles.hamburgerWrapper, {
          [styles.repositioned]: isOpen,
        })}
      >
        <HamburgerButton isOpen={isOpen} onClick={menuClickHandler} />
        <NavLink
          className={styles.link}
          activeClassName={styles.activeLink}
          to="/"
          exact
        >
          <CompanyLogo className={styles.logo} />
        </NavLink>
      </div>
      <Search
        className={cn(styles.search, {
          [styles.disabled]: isOpen,
        })}
      />
      <SideMenu
        className={cn(styles.sideMenu, {
          [styles.visible]: isOpen,
          [styles.alertTopOffset]: mobileAlertOpen,
        })}
      />
      {subheader ? <SubHeaderMobile {...subheader} /> : null}
    </>
  );
};

export default MobileMenu;

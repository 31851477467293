import { Plan, Price } from '@laminar-product/client-commons-core/core';

export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const setUserLogin = (userLogin: string) => ({
  type: SET_USER_LOGIN,
  payload: { userLogin },
});

export const SET_REGISTRATION_METHOD = 'SET_REGISTRATION_METHOD';
export const setRegistrationMethod = (method: string) => ({
  type: SET_REGISTRATION_METHOD,
  payload: { method },
});

export const SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN';
export const setSubscriptionPlan = (plan?: Plan) => ({
  type: SET_SUBSCRIPTION_PLAN,
  payload: { plan },
});

export const SET_PAYMENT_EMAIL = 'SET_PAYMENT_EMAIL';
export const setPaymentEmail = (email: string) => ({
  type: SET_PAYMENT_EMAIL,
  payload: { email },
});

export const SET_SELECTED_PRICE = 'SET_SELECTED_PRICE';
export const setSelectedPrice = (price?: Price) => ({
  type: SET_SELECTED_PRICE,
  payload: { price },
});

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AuthInputType, validateAuthInput } from 'utils/auth';
import AuthInputError from 'components/AuthInputError';
import { useDispatch } from 'react-redux';
import { setUserCredentials } from 'store/login/actions';
import usePathWithParams from 'utils/usePathWithParams';
import { useHistory } from 'react-router-dom';
import { upgradePlanParamId } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import CountryCodePhoneInput from 'components/CountryCodePhoneInput';
import styles from '../index.module.scss';

export interface PhoneInputForm {
  userPhoneNumber: string;
}

const PhoneInput = () => {
  const form = useForm<PhoneInputForm>();
  const { handleSubmit, control } = form;
  const dispatch = useDispatch();
  const getPath = usePathWithParams();
  const { push, location } = useHistory<{ redirectTo: string }>();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const onSubmit = (values: PhoneInputForm) => {
    dispatch(setUserCredentials({ phone: values.userPhoneNumber }));
    push(getPath('/login/phone-verify', !!queryParams.get(upgradePlanParamId)));
  };

  return (
    <div className={styles.inputGroup}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="userPhoneNumber"
            defaultValue=""
            rules={{
              required: true,
              validate: (value) =>
                validateAuthInput(value, AuthInputType.PHONE),
            }}
            render={({ field: { onChange, value } }) => (
              <CountryCodePhoneInput
                value={value}
                onChange={onChange}
                className={styles.phoneField}
              />
            )}
          />
          <AuthInputError />
          <Button
            className={styles.loginButton}
            variant="primary"
            type="submit"
          >
            {t('common.continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default PhoneInput;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsSwitchItem from 'components/SettingsSwitchItem';
import { Profile } from '@laminar-product/client-commons-core/core';

interface PlaybackItemProps {
  settingKey: string;
  settingValue: boolean;
  profile: Profile;
  saveProfile: (profile: Profile) => void;
  isLoading?: boolean;
}

const PlaybackItem = ({
  settingKey,
  settingValue,
  profile,
  saveProfile,
  isLoading,
}: PlaybackItemProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  const handleToggle = useCallback(
    async (value: boolean) => {
      setChecked(value);

      if (profile.playbackSettings) {
        const profileToUpdate: Profile = {
          uuid: profile.uuid,
          playbackSettings: {
            ...profile.playbackSettings,
            [settingKey]: value,
          },
        };

        saveProfile(profileToUpdate);
      }
    },
    [profile, saveProfile, settingKey]
  );

  useEffect(() => {
    if (settingValue) {
      setChecked(true);
    }
  }, [settingValue]);

  return (
    <SettingsSwitchItem
      isLoading={isLoading}
      checked={checked}
      handleToggle={handleToggle}
      title={t(`playbackSettingsTitles.${settingKey}`)}
      subTitle={t(`playbackSettingsDescription.${settingKey}`)}
      theme="settings"
    />
  );
};
export default PlaybackItem;

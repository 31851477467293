import { AnyAction } from 'redux';
import Castjs from '../../../chromecast/cast';
import {
  RESET_CHROMECAST_CONNECTION,
  SET_CHROMECAST_ACTIVE_AUDIO_TRACK_ID,
  SET_CHROMECAST_ACTIVE_TEXT_TRACK_ID,
  SET_CHROMECAST_ASSET_ID,
  SET_CHROMECAST_AVAILABLE,
  SET_CHROMECAST_AVAILABLE_TRACKS,
  SET_CHROMECAST_CAST_ELEMENT,
  SET_CHROMECAST_CONTENT_ID,
  SET_CHROMECAST_NEXT_EPISODE_ID,
  SET_CHROMECAST_STATUS,
} from './actions';
import { AvailableTracks, ChromecastStatus } from './types';

export interface ChromecastState {
  castElement?: Castjs;
  assetId?: string;
  available?: boolean;
  status?: ChromecastStatus;
  contentId?: string;
  availableTracks?: AvailableTracks;
  activeTextTrackId?: number;
  activeAudioTrackId?: number;
  nextEpisodeId?: string;
}

const chromecastReducer = (
  { castElement, ...state }: ChromecastState = {},
  action: AnyAction
): ChromecastState => {
  switch (action.type) {
    case SET_CHROMECAST_CAST_ELEMENT:
      return {
        ...state,
        castElement: action.payload,
      };
    case SET_CHROMECAST_ASSET_ID:
      return {
        ...state,
        castElement,
        assetId: action.payload,
      };
    case SET_CHROMECAST_AVAILABLE:
      return {
        ...state,
        castElement,
        available: action.payload,
      };
    case SET_CHROMECAST_STATUS:
      return {
        ...state,
        castElement,
        status: action.payload,
      };
    case SET_CHROMECAST_CONTENT_ID:
      return {
        ...state,
        castElement,
        contentId: action.payload,
      };
    case SET_CHROMECAST_AVAILABLE_TRACKS:
      return {
        ...state,
        castElement,
        availableTracks: action.payload,
      };
    case SET_CHROMECAST_ACTIVE_TEXT_TRACK_ID:
      return {
        ...state,
        castElement,
        activeTextTrackId: action.payload,
      };
    case SET_CHROMECAST_ACTIVE_AUDIO_TRACK_ID:
      return {
        ...state,
        castElement,
        activeAudioTrackId: action.payload,
      };
    case RESET_CHROMECAST_CONNECTION:
      return {};
    case SET_CHROMECAST_NEXT_EPISODE_ID:
      return {
        ...state,
        castElement,
        nextEpisodeId: action.payload,
      };
    default:
      return { ...state, castElement };
  }
};

export default chromecastReducer;

import LinearProfileWrapper from 'components/LinearProfileWrapper';
import ProfileModalHeader from 'components/ProfileModalHeader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContentRating, SettingSwitchItemType } from 'types/profiles';
import CloseProfileModal from 'components/CloseProfileModal';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { updateProfile } from 'actions/profiles';
import {
  ProfileAvatarSize,
  getContentRatingOptions,
  useUpdateProfilesState,
} from 'utils/profiles';
import {
  DEFAULT_MATURITY_VALUE,
  MATURITY_KIDS_VALUE,
  PROFILE_EDIT_ITEMS_KEYS,
} from 'utils/constants';
import ProfileAvatar from 'components/ProfileAvatar';
import SettingsSwitchItem from 'components/SettingsSwitchItem';
import {
  PlaybackSettings,
  Profile,
} from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import { splitLanguage } from 'utils/splitLanguage';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { DropdownOption } from 'types/dropdown';
import SettingsDropdownItem from 'components/SettingsDropdownItem';
import CodeInput from 'react-code-input';
import { selectIsTimesSSOProvider } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import ProfileIdentityConfirmation from 'pages/ProfilesSelector/ForgotPin/ProfileIdentityConfirmation';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import ProfileGeneralDetails from '../ProfileGeneralDetails';
import ProfileRemove from '../ProfileDetails/ProfileRemove';
import styles from './index.module.scss';

interface ProfileEditProps {
  profile?: Profile;
  onClose: () => void;
}

const ProfileEdit = ({ profile, onClose }: ProfileEditProps) => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [profileContentRating, setProfileContentRating] =
    useState<DropdownOption>();
  const formMethods = useForm<Profile>({ mode: 'onSubmit' });
  const {
    handleSubmit,
    getValues,
    reset,
    control,
    watch,
    formState: { errors },
  } = formMethods;
  const updateProfileInState = useUpdateProfilesState();
  const contentRatingOptions = getContentRatingOptions();
  const [pinControlChecked, setPinControlChecked] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const isTimesSSOProvider = useSelector(selectIsTimesSSOProvider);
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const handleUpdateProfile = () => {
    if (!profile) {
      return;
    }

    setIsSaving(true);

    const values = getValues();

    const profileToUpdate: Profile = {
      ...values,
      uuid: profile.uuid,
      maturityRating: DEFAULT_MATURITY_VALUE,
      pin: pinControlChecked ? values.pin : '',
    };

    if (profile.playbackSettings) {
      profileToUpdate.playbackSettings = {
        ...profile.playbackSettings,
        ...profileToUpdate.playbackSettings,
      };
    }

    if (profileContentRating?.value === ProfileContentRating.KIDS) {
      profileToUpdate.maturityRating = MATURITY_KIDS_VALUE;
    }

    updateProfile(profileToUpdate)
      .then(() => {
        const newProfile: Profile = {
          ...profile,
          ...profileToUpdate,
        };
        updateProfileInState(newProfile);
        onClose();
      })
      .catch((e) => {
        captureError(
          e as AxiosError,
          'ProfileEdit/updateProfile',
          Severity.Warning,
          { extra: { profileToUpdate } }
        );
      })
      .finally(() => setIsSaving(false));
  };

  const handleOnSubmit = () => {
    const values = getValues();

    //If no pin before - need to be verified. If timesSSO provider - no need to verify for now (https://laminarglobal.atlassian.net/browse/DDD-7691)
    if (values.pin && !profile?.pin && !isTimesSSOProvider) {
      return setIsConfirmationModalOpen(true);
    }

    handleUpdateProfile();
  };

  useEffect(() => {
    if (profile && !getValues('name')) {
      reset({
        ...profile,
        language: splitLanguage(profile.language || ''),
      });

      if (profile.pin) {
        setPinControlChecked(true);
      }
    }
  }, [reset, profile, getValues]);

  useEffect(() => {
    if (!contentRatingOptions || profileContentRating || !profile) {
      return;
    }

    if (profile.maturityRating === MATURITY_KIDS_VALUE) {
      const kidRating = contentRatingOptions.find(
        (o) => o.value === ProfileContentRating.KIDS
      );

      return setProfileContentRating(kidRating);
    }

    const defaultMaturity = contentRatingOptions.find(
      (o) => o.value === ProfileContentRating.ALL
    );
    setProfileContentRating(defaultMaturity);
  }, [contentRatingOptions, profile, profileContentRating]);

  return (
    <LinearProfileWrapper contentClassName={styles.wrapper}>
      <FormProvider {...formMethods}>
        <CloseProfileModal onClose={onClose} />
        <ProfileModalHeader text={t('profile.editProfile')} />

        <ProfileAvatar
          name={watch('name')}
          isKidsProfile={
            profileContentRating?.value === ProfileContentRating.KIDS
          }
          size={ProfileAvatarSize.MEDIUM}
          className={styles.profileAvatar}
        />

        <ProfileGeneralDetails type="modal" />

        <span className={styles.divider} />

        <SettingsDropdownItem
          title={t('profile.contentRating')}
          options={contentRatingOptions}
          selectedOption={profileContentRating}
          onChange={setProfileContentRating}
          className={styles.contentRatingRow}
        />

        <span className={styles.divider} />

        {Object.entries(profile?.playbackSettings || {}).map(([key], index) =>
          PROFILE_EDIT_ITEMS_KEYS.includes(key as keyof PlaybackSettings) ? (
            <Controller
              key={index}
              render={({ field }) => (
                <SettingsSwitchItem
                  type={SettingSwitchItemType.MODAL}
                  checked={!!field.value}
                  handleToggle={field.onChange}
                  title={t(`playbackSettingsTitles.${key}`)}
                  subTitle={t(`playbackSettingsDescription.${key}`)}
                  className={styles.playbackSwitch}
                />
              )}
              control={control}
              name={`playbackSettings.${key as keyof PlaybackSettings}`}
            />
          ) : null
        )}

        <SettingsSwitchItem
          title={t(`parentalControlSettings.parentalControlPIN`)}
          subTitle={t(`parentalControlSettings.parentalControlPINDesctipion`)}
          type={SettingSwitchItemType.MODAL}
          checked={pinControlChecked}
          handleToggle={() => setPinControlChecked((prev) => !prev)}
        />

        {pinControlChecked && (
          <>
            <Controller
              name="pin"
              control={control}
              rules={{ validate: (value) => value?.length === 4 }}
              render={({ field: { value, onChange } }) => (
                <CodeInput
                  autoFocus={false}
                  value={value || ''} //react-code-input crashes when passing undefined
                  onChange={onChange}
                  inputMode="latin"
                  name="oneTimeCode"
                  type="number"
                  fields={4}
                  className={styles.codeInput}
                />
              )}
            />

            {!!errors.pin && (
              <span className={styles.error}>
                {t('profile.invalidPinMessage')}
              </span>
            )}
          </>
        )}

        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button
            loading={isSaving}
            onClick={handleSubmit(handleOnSubmit)}
            variant="primary"
          >
            {t('common.save')}
          </Button>
        </div>

        {!profile?.defaultProfile && (
          <Button
            variant="text"
            className={styles.deleteProfileBtn}
            onClick={() => setRemoveModalVisible(true)}
          >
            <div className={styles.deleteProfileIcon}>
              <TrashIcon fill="white" />
            </div>
            {t('profile.deleteProfile')}
          </Button>
        )}
      </FormProvider>

      <Modal
        wrapperId="profileConfirmationModal"
        isOpen={!!profile && isConfirmationModalOpen}
      >
        <ProfileIdentityConfirmation
          profile={profile!}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirmationSuccess={handleUpdateProfile}
        />
      </Modal>

      <Modal wrapperId="profileRemoveModal" isOpen={isRemoveModalVisible}>
        <ProfileRemove
          profile={profile}
          onClose={() => setRemoveModalVisible(false)}
          onRemoveSuccess={onClose}
        />
      </Modal>
    </LinearProfileWrapper>
  );
};

export default ProfileEdit;

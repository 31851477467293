import { AnyAction } from 'redux';
import { CLOSE_MOBILE_ALERT, OPEN_MOBILE_ALERT } from './actions';

export interface MobileAlertState {
  state: 'initial' | 'open' | 'closed';
}

const mobileAlertReducer = (
  state: MobileAlertState = { state: 'initial' },
  action: AnyAction
): MobileAlertState => {
  switch (action.type) {
    case OPEN_MOBILE_ALERT:
      return {
        state: 'open',
      };

    case CLOSE_MOBILE_ALERT:
      return {
        state: 'closed',
      };

    default:
      return state;
  }
};

export default mobileAlertReducer;

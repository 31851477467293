import { Asset } from '@laminar-product/client-commons-core/core';
import {
  AssetTags,
  AssetTitleLogo,
  LiveTags,
  isLive,
} from '@laminar-product/client-commons-core/web';
import styles from './index.module.scss';

interface AssetMetaDataProps {
  asset: Asset;
  showTags?: boolean;
}

const AssetMetaData = ({ asset, showTags = true }: AssetMetaDataProps) => {
  return (
    <>
      {asset && <AssetTitleLogo asset={asset} />}
      {showTags && asset && !isLive(asset) && <AssetTags asset={asset} />}

      {asset && isLive(asset) && (
        <div className={styles.liveTagsContainer}>
          <LiveTags asset={asset} />
        </div>
      )}
    </>
  );
};

export default AssetMetaData;

import { Button } from '@laminar-product/client-commons-core/web';
import AuthenticationContainer from 'components/AuthenticationContainer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  const onLoginClick = () => replace(`/login`);

  return (
    <AuthenticationContainer>
      <div className={styles.root}>
        <h1 className={styles.heading}>{t('login.passwordChanged')}</h1>

        <p className={styles.description}>
          {t('login.passwordChangedMessage')}
        </p>

        <Button onClick={onLoginClick} className={styles.button}>
          {t('login.login')}
        </Button>
      </div>
    </AuthenticationContainer>
  );
};

export default ResetPasswordSuccess;

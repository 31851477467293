import { Value } from 'firebase/remote-config';

export const RESET_REMOTE_CONFIG = 'RESET_REMOTE_CONFIG';
export const resetRemoteConfig = () => ({
  type: RESET_REMOTE_CONFIG,
});

export const SET_REMOTE_CONFIG = 'SET_REMOTE_CONFIG';
export const setRemoteConfig = (remoteConfig: Record<string, Value>) => ({
  type: SET_REMOTE_CONFIG,
  payload: remoteConfig,
});

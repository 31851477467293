import {
  AssetPlaylist,
  Content,
  ContentFrame,
} from '@laminar-product/client-commons-core/core';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { PLAYER_INITIALIZING_STATE, PLAYER_STRATA_STATUS } from './types';

export const RESET_PLAYER = 'RESET_PLAYER';
export const resetPlayer = () => ({
  type: RESET_PLAYER,
});

export const SET_PLAYER_ASSET_ID = 'SET_PLAYER_ASSET_ID';
export const setPlayerAssetId = (id?: string) => ({
  type: SET_PLAYER_ASSET_ID,
  payload: id,
});

export const SET_PLAYER_VIDEO_ELEMENT = 'SET_PLAYER_VIDEO_ELEMENT';
export const setPlayerVideoElement = (
  videoElement: HTMLMediaElement | null
) => ({
  type: SET_PLAYER_VIDEO_ELEMENT,
  payload: videoElement,
});

export const SET_PLAYER_FRAMES = 'SET_PLAYER_FRAMES';
export const setPlayerFrames = (frames: ContentFrame[]) => ({
  type: SET_PLAYER_FRAMES,
  payload: frames,
});

export const SET_PLAYER_INITIALIZING_STATE = 'SET_PLAYER_INITIALIZING_STATE';
export const setPlayerInitializingState = (
  state: PLAYER_INITIALIZING_STATE
) => ({
  type: SET_PLAYER_INITIALIZING_STATE,
  payload: state,
});

export const SET_PLAYER = 'SET_PLAYER';
export const setPlayer = (player: shaka.Player) => ({
  type: SET_PLAYER,
  payload: player,
});

export const SET_PLAYER_NEXT_EPISODE_ID = 'SET_PLAYER_NEXT_EPISODE_ID';
export const setPlayerNextEpisodeId = (id: string) => ({
  type: SET_PLAYER_NEXT_EPISODE_ID,
  payload: id,
});

export const SET_PLAYER_CONTENT = 'SET_PLAYER_CONTENT';
export const setPlayerContent = (content: Content) => ({
  type: SET_PLAYER_CONTENT,
  payload: content,
});

export const SET_PLAYER_PLAYBACK_ID = 'SET_PLAYER_PLAYBACK_ID';
export const setPlayerPlaybackId = (id: string) => ({
  type: SET_PLAYER_PLAYBACK_ID,
  payload: id,
});

export const SET_PLAYER_BUFFER_ID = 'SET_PLAYER_BUFFER_ID';
export const setPlayerBufferId = (id: string) => ({
  type: SET_PLAYER_BUFFER_ID,
  payload: id,
});

export const SET_PLAYER_ERROR = 'SET_PLAYER_ERROR';
export const setPlayerError = (errorCode: string) => ({
  type: SET_PLAYER_ERROR,
  payload: { errorCode },
});

export const SET_PLAYER_PLAYLIST = 'SET_PLAYER_PLAYLIST';
export const setPlayerPlaylist = (playlist?: AssetPlaylist[]) => ({
  type: SET_PLAYER_PLAYLIST,
  payload: playlist,
});

export const SET_PLAYER_PLAYLIST_ACTIVE_ELEMENT =
  'SET_PLAYER_PLAYLIST_ACTIVE_ELEMENT';
export const setPlayerPlaylistActiveElement = (
  activePlaylist?: AssetPlaylist
) => ({
  type: SET_PLAYER_PLAYLIST_ACTIVE_ELEMENT,
  payload: activePlaylist,
});

export const SET_PLAYER_STRATA_STATUS = 'SET_PLAYER_STRATA_STATUS';
export const setPlayerStrataStatus = (status?: PLAYER_STRATA_STATUS) => ({
  type: SET_PLAYER_STRATA_STATUS,
  payload: status,
});

export const SET_PLAYER_CURRENT_FRAME = 'SET_PLAYER_CURRENT_FRAME';
export const setPlayerCurrentFrame = (frame?: ContentFrame) => ({
  type: SET_PLAYER_CURRENT_FRAME,
  payload: frame,
});

import { BasicRemoteControllerProps } from 'components/ChromecastRemoteController/types';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ChromecastConnected } from 'assets/icons/chromecast-connected.svg';
import {
  AssetType,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import { Button, isLive } from '@laminar-product/client-commons-core/web';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectChromecastAsset,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import PlaybackActionsButtons from 'components/ChromecastControllerComponents/PlaybackActionsButtons';
import { useCallback } from 'react';
import cn from 'classnames';
import PlayerLiveIndicator from 'components/PlayerLiveIndicator';
import styles from './index.module.scss';

interface MinimizedRemoteControllerProps extends BasicRemoteControllerProps {}

const MinimizedRemoteController = ({
  onChromecastDisconnect,
  setIsMinimized,
  isPlayingAds,
}: MinimizedRemoteControllerProps) => {
  const asset = useSelector(selectChromecastAsset);
  const castElement = useSelector(selectChromecastCastElement);
  const img = asset ? getAssetImageWithType(asset, 'thumbnail')?.url : '';
  const isEpisode = asset?.type === AssetType.EPISODE;
  const { t } = useTranslation();

  const onChromecastIconClick = useCallback(async () => {
    if (!castElement) {
      return;
    }

    await castElement.openCastModal();
  }, [castElement]);

  if (!castElement || !asset) {
    return null;
  }

  return (
    <div className={styles.minimizedRemoteWrapper}>
      <div className={styles.minimizedAssetDetailsWrapper}>
        <ArrowDownIcon
          className={styles.arrowUpIcon}
          onClick={() => setIsMinimized(false)}
        />

        <div className={styles.assetDetails}>
          <img src={img} alt="" className={styles.minimizedImg} />

          <div className={styles.metadata}>
            <span className={styles.title}>
              {isEpisode ? asset.parent?.name : asset.name}
            </span>

            {isEpisode ? (
              <span className={styles.subtitle}>
                {t('asset.episodeShort')}
                {asset.parent?.order}
                {t('asset.seasonShort')}
                {asset.order}: {asset.name}
              </span>
            ) : null}

            {isLive(asset) && (
              <PlayerLiveIndicator className={styles.minimizedLiveIndicator} />
            )}
          </div>
        </div>
      </div>

      <PlaybackActionsButtons
        wrapperClassName={styles.playbackActionButtons}
        isPlayingAds={isPlayingAds}
      />

      <div className={styles.disconnectWrapper}>
        <div
          className={cn(styles.metadata, styles.disconnect)}
          onClick={onChromecastIconClick}
        >
          <span className={styles.subtitle}>
            {t('chromecast.castingToShort')}
          </span>

          <span className={styles.deviceName}>
            <ChromecastConnected className={styles.chromecastIcon} />{' '}
            {castElement.device}
          </span>
        </div>

        <Button
          onClick={onChromecastDisconnect}
          className={styles.disconnectBtn}
        >
          {t('chromecast.disconnect')}
        </Button>
      </div>
    </div>
  );
};

export default MinimizedRemoteController;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChromecastAssetId } from 'store/chromecast/selectors';
import { setChromecastNextEpisodeId } from 'store/chromecast/actions';
import useFetchNextEpisode from './useFetchNextEpisode';

const useChromecastNextEpisode = () => {
  const assetId = useSelector(selectChromecastAssetId);
  const dispatch = useDispatch();

  const onNextEpisodeFetch = useCallback(
    (nextEpisodeId: string) =>
      dispatch(setChromecastNextEpisodeId(nextEpisodeId)),
    [dispatch]
  );

  useFetchNextEpisode({ assetId, onNextEpisodeFetch });
};

export default useChromecastNextEpisode;

export const isEmail = (value: string) => {
  const email =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  // 256 being the max size of an email address
  return value.length < 256 && email.test(value);
};

export const isPhoneNumber = (value: string) => {
  const phoneNumber = /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return phoneNumber.test(value);
};

export const isPasswordValidLength = (value: string) => value.length > 7;

export const isPasswordValidValue = (value: string) => {
  const password =
    // eslint-disable-next-line
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[${!"#$%&\'()*+,-.\/:;<=>?@\[\\\]\^_`{\|}~}]).+$/;

  return password.test(value);
};

export const isPasswordValid = (value: string) => {
  return isPasswordValidLength(value) && isPasswordValidValue(value);
};

export const isEmailOrPhoneNumberValid = (value: string): boolean =>
  isEmail(value) || isPhoneNumber(value);

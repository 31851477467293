import { useEffect, useMemo } from 'react';
import qs from 'qs';
import { signInFromTv, signUpFromTv } from 'store/login/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { REGISTER_FROM_TV_URLS } from 'utils/constants';
import { selectFirebaseUser } from 'store/user/selectors';
import useExternalPayment, { PaymentActionType } from './useExternalPayment';
import { APP_PAGE_PATH } from './navigation';

export enum RedirectTvRoute {
  REGISTER = 'register',
  LOGIN = 'login',
  BUY = 'buy',
}

const useRedirectFromTv = (isSessionLoading: boolean) => {
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
    push,
  } = useHistory();
  const {
    handlePaymentAction,
    paymentProcessing,
    isPromptingEmail,
    setIsPromptingEmail,
    paymentErrorCode,
    setPaymentErrorCode,
  } = useExternalPayment();
  const firebaseUser = useSelector(selectFirebaseUser);
  const split = pathname.split('/');

  const attributedCheckoutContextID = useMemo(() => {
    const tvRedirect = pathname.split('/')[2];

    // path tv2/buy leaved for backward compatibility
    if (pathname.includes('/tv2/buy') || tvRedirect === RedirectTvRoute.BUY) {
      return split[split.length - 1];
    }
  }, [pathname, split]);

  useEffect(() => {
    // note: Leaved for backward compatibility
    if (pathname.includes('/tv2/buy')) {
      handlePaymentAction({
        attributedCheckoutContextID,
        actionType: PaymentActionType.PAY,
      });

      return;
    }

    if (REGISTER_FROM_TV_URLS.includes(pathname)) {
      dispatch(signUpFromTv());
      return;
    }

    if (pathname === '/tv2') {
      dispatch(signInFromTv());
      return;
    }

    // note: New way of handling url
    const redirect = pathname.split('/')[2];
    const billingEmail = qs.parse(search, { ignoreQueryPrefix: true })
      .billingEmail as string;

    switch (redirect) {
      case RedirectTvRoute.LOGIN:
        dispatch(signInFromTv());
        if (isSessionLoading) return;
        if (firebaseUser && !firebaseUser.isAnonymous) {
          push('/settings/devices/register');
          return;
        }
        push(APP_PAGE_PATH.LOGIN);
        break;
      case RedirectTvRoute.REGISTER:
        dispatch(signUpFromTv());
        push(APP_PAGE_PATH.REGISTER);
        break;
      case RedirectTvRoute.BUY:
        if (paymentProcessing || isSessionLoading) {
          break;
        }

        handlePaymentAction({
          attributedCheckoutContextID,
          actionType: PaymentActionType.PAY,
          email: billingEmail,
        });
        break;
    }
  }, [
    dispatch,
    push,
    attributedCheckoutContextID,
    handlePaymentAction,
    paymentProcessing,
    firebaseUser,
    isSessionLoading,
    pathname,
    search,
    paymentErrorCode,
  ]);

  return {
    paymentProcessing,
    isPromptingEmail,
    setIsPromptingEmail,
    handlePaymentAction,
    attributedCheckoutContextID,
    paymentErrorCode,
    setPaymentErrorCode,
  };
};

export default useRedirectFromTv;

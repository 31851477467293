import { useCallback } from 'react';
import { createPayuPayment } from 'actions/payments';
import _each from 'lodash/each';
import { PayuPaymentResponse } from '@laminar-product/client-commons-core/core';
import { HandlePaymentParams } from 'types/payment';

const appendToForm = (form: HTMLFormElement, formValues: any) => {
  _each(formValues, (val, key) => {
    if (['url'].includes(key)) return;
    const fieldValue = typeof val === 'object' ? JSON.stringify(val) : val;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = fieldValue;

    form.appendChild(hiddenField);
  });
};

const submitPayuForm = (formValues: PayuPaymentResponse['data']) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = formValues.url;

  appendToForm(form, formValues);

  document.body.appendChild(form);
  form.submit();
};

const useHandlePayuPayment = () => {
  const handlePayuPayment = useCallback(
    async ({ priceId, email, couponId, offerId }: HandlePaymentParams) => {
      const payuForm = await createPayuPayment(
        priceId,
        couponId,
        email,
        offerId
      );
      submitPayuForm(payuForm.data);
    },
    []
  );

  const handlePayuCardChange = useCallback(
    (formData: PayuPaymentResponse['data']) => submitPayuForm(formData),
    []
  );

  return {
    handlePayuPayment,
    handlePayuCardChange,
    handleExternalPayuPayment: submitPayuForm,
  };
};

export default useHandlePayuPayment;

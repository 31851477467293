import { Button } from '@laminar-product/client-commons-core/web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentFrame } from '@laminar-product/client-commons-core/core';
import { checkIsFrameTypeAutoSkipEnabled } from 'utils/player';
import styles from './index.module.scss';

interface SkipButtonProps {
  frame: ContentFrame;
  onFrameSkip: (frame: ContentFrame) => void;
}

const SkipFrameButton = ({ frame, onFrameSkip }: SkipButtonProps) => {
  const { t } = useTranslation();
  const isAutoSkipEnabled = checkIsFrameTypeAutoSkipEnabled(frame.type);

  useEffect(() => {
    if (!isAutoSkipEnabled) {
      return;
    }

    onFrameSkip(frame);
  }, [frame, isAutoSkipEnabled, onFrameSkip]);

  if (isAutoSkipEnabled) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      className={styles.skipBtn}
      onClick={() => onFrameSkip(frame)}
      data-testid={`SkipButton__${frame.type}`}
    >
      {t(`asset.frames.skip_${frame.type}`)}
    </Button>
  );
};

export default SkipFrameButton;

import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RedeemCodeModal from 'components/RedeemCodeModal';
import styles from './index.module.scss';

interface PlanActionsProps {
  canChangePlan: boolean;
  canCancelPlan: boolean;
  canRedeemPin: boolean;
  hasNoPlan: boolean;
}
const PlanActions = ({
  canChangePlan,
  canCancelPlan,
  canRedeemPin,
  hasNoPlan,
}: PlanActionsProps) => {
  const { t } = useTranslation();

  const [isRedeemCodeModalVisible, setIsRedeemCodeModalVisible] =
    useState(false);

  return (
    <div className={styles.planActionsRoot}>
      {hasNoPlan && (
        <Link to="/settings/change-plan" className={cn(styles.link)}>
          {t('settings.buyPlan')}
        </Link>
      )}
      {canChangePlan && (
        <Link to="/settings/change-plan" className={cn(styles.link)}>
          {t('settings.changePlan')}
        </Link>
      )}
      {canCancelPlan && (
        <Link to="/settings/cancel-plan" className={styles.link}>
          {t('settings.cancelYourMembership')}
        </Link>
      )}
      {canRedeemPin && (
        <div
          className={styles.link}
          onClick={() => setIsRedeemCodeModalVisible(true)}
        >
          {t('planSelector.redeemCode')}
        </div>
      )}
      {isRedeemCodeModalVisible && (
        <RedeemCodeModal onClose={() => setIsRedeemCodeModalVisible(false)} />
      )}
    </div>
  );
};

export default PlanActions;

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'store/user/selectors';
import { format } from 'date-fns';
import { Button } from '@laminar-product/client-commons-core/web';
import styles from '../index.module.scss';

interface RedeemCodeResultProps {
  onOkClick: (closingAfterSuccess: boolean) => void;
  isError?: boolean;
}
const RedeemCodeResult = ({ onOkClick, isError }: RedeemCodeResultProps) => {
  const { t } = useTranslation();
  const userDetails = useSelector(selectUserDetails);

  return (
    <>
      <p className={styles.header}>
        {isError ? t('errors.errorPageTitle') : t('planSelector.successRedeem')}
      </p>

      <p className={styles.message}>
        {isError && t('planSelector.failureRedeem')}

        {!isError &&
          !!userDetails?.plan?.expireAt &&
          t('planSelector.planAddedToAccount', {
            planName: userDetails?.planMetadata?.name,
            validDate: format(userDetails?.plan?.expireAt, 'dd/MM/yyyy'),
          })}
      </p>

      <div className={styles.buttons}>
        <Button variant="primary" onClick={() => onOkClick(!isError)}>
          {t('common.OK')}
        </Button>
      </div>
    </>
  );
};

export default RedeemCodeResult;

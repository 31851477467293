import {
  ContentType,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';
import BaseAssetModel from './base';

export default class LiveAssetModel extends BaseAssetModel {
  override get mainContent() {
    if (!this.asset) {
      return null;
    }
    return getAssetContentWithType(this.asset, ContentType.LIVE);
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import PosterList from 'components/PosterList';
import { searchContent } from 'actions/search';
import useSearchParams from 'utils/useSearchParams';
import { getSearchPagination } from 'utils/pagination';
import useListingAssets from 'utils/useListingAssets';
import { defaultAssetSearchLimit } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { useTranslation } from 'react-i18next';
import { ProgressIndicator } from '@laminar-product/client-commons-core/web';
import { prepareMetaForPage } from 'utils/meta';
import { useTracker } from 'utils/useTracker';
import { TrackEvent } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import { AxiosError } from 'axios';
import { Severity } from 'types/errors';
import PageLayoutWrapper from 'layouts/PageLayoutWrapper';
import styles from './index.module.scss';

const SearchResults = () => {
  const { t } = useTranslation();
  const params = useSearchParams();
  const dispatch = useDispatch();
  const [fetchStatus, setFetchStatus] = useState<
    'idle' | 'pending' | 'resolved' | 'rejected'
  >('idle');
  const [page, setPage] = useState(1);
  const { assets, totalAssetsCount, addAssets, setTotalCount, clearAssets } =
    useListingAssets();
  const { trackEvent } = useTracker();

  const fetchSearchAssets = useCallback(
    async (page?: number) => {
      setFetchStatus('pending');
      try {
        const searchParams = {
          ...params,
          ...getSearchPagination(page, defaultAssetSearchLimit),
        };
        const result = await searchContent(searchParams);
        setTotalCount(result.total);
        addAssets(result.hits);
        setFetchStatus('resolved');
        trackEvent<TrackEvent.SEARCH>({
          event: TrackEvent.SEARCH,
          data: {
            search_term: params.query || '',
            assets: (result.hits?.map((asset) => asset.id) || ['']) as
              | []
              | [string],
            // There is diffrent typing for array type on strata side that's why we need to change it here
          },
        });
      } catch (e) {
        captureError(
          e as AxiosError,
          'SearchResults/fetchSearchAssets',
          Severity.Warning
        );
        setFetchStatus('rejected');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, setTotalCount, addAssets, setFetchStatus]
  );

  // Reset page on query change
  useEffect(() => {
    setPage(1);
  }, [params.query]);

  useEffect(() => {
    dispatch(
      setAppMeta({
        og: prepareMetaForPage(
          `${params.query}`,
          t('common.placeholders.searchFor', { query: params.query })
        ),
      })
    );
  }, [dispatch, t, params]);

  useEffect(() => {
    clearAssets();
  }, [clearAssets, params]);

  useEffect(() => {
    fetchSearchAssets(page);
  }, [fetchSearchAssets, page]);

  return (
    <>
      <PageLayoutWrapper>
        {fetchStatus === 'pending' && (
          <div className={styles.root}>
            <ProgressIndicator variant="circular" className={styles.loader} />
          </div>
        )}
        {fetchStatus === 'rejected' && (
          <div className={styles.message}>
            {t('errors.defaultErrorFallback')}
          </div>
        )}
        {fetchStatus === 'resolved' && !assets.length && (
          <div className={styles.message}>{t('common.noResults')}</div>
        )}
        {fetchStatus === 'resolved' && !!assets.length && (
          <PosterList
            assets={assets}
            total={totalAssetsCount}
            onLoadMore={() => setPage(page + 1)}
            testId="SearchResults__PosterList"
          />
        )}
      </PageLayoutWrapper>
    </>
  );
};

export default SearchResults;

import React, { useState } from 'react';
import { ReactComponent as GlobalIcon } from 'assets/icons/global.svg';
import { useSelector } from 'react-redux';
import { selectUserCountryCode } from 'store/user/selectors';
import { useTranslation } from 'react-i18next';
import { Modal } from '@laminar-product/client-commons-core/web';
import TravelModal from '../TravelModal';
import styles from './index.module.scss';

interface TravelButtonProps {
  countries?: string[];
}
const TravelButton = ({ countries }: TravelButtonProps) => {
  const currentCountryCode = useSelector(selectUserCountryCode);
  const { t } = useTranslation();
  const [travelPlansModalVisible, setTravelModalPlansVisible] = useState(false);

  const onTravelButtonClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (countries?.length) {
      e.stopPropagation();
      setTravelModalPlansVisible(true);
    }
  };

  if (!currentCountryCode) return null;

  return (
    <>
      <div className={styles.travelPlanWrapper} onClick={onTravelButtonClick}>
        <GlobalIcon className={styles.globalIcon} />
        <span className={styles.watchInfo}>
          {t('planSelector.watchIn', {
            country: t(`languagesCodes.${currentCountryCode}`),
          })}{' '}
          {countries && countries.length > 0 && (
            <span className={styles.link}>
              {t('planSelector.moreCountries', {
                count: countries.length,
              })}
            </span>
          )}
        </span>
      </div>

      <Modal
        className={styles.travelPlanModal}
        wrapperId="travelPlansModal"
        isOpen={travelPlansModalVisible}
      >
        <TravelModal
          onClose={() => setTravelModalPlansVisible(false)}
          countries={countries}
          currentCountryCode={currentCountryCode}
        />
      </Modal>
    </>
  );
};

export default TravelButton;

import {
  Asset,
  AssetType,
  ContentType,
  Watchable,
  getAssetContentWithType,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import {
  ButtonToDisplay,
  HorizontalButtonGroup,
  JumbotronBackground,
  WatchlistButton,
  displayWatchButton,
  getAssetTrailer,
  isLiveActive,
  shouldDisplayTrailerButtonOnJumbotron,
} from '@laminar-product/client-commons-core/web';
import {
  useAssetWithContent,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import { useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import PlayerMuteButtonRenderer from 'components/PlayerMuteButtonRenderer';
import { useHistory } from 'react-router-dom';
import useFetchAssetWatchingProgress from 'utils/useFetchAssetWatchingProgress';
import { captureError } from 'utils/captureError';
import useCanPlayTrailer from 'utils/useCanPlayTrailer';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import { getAsset } from 'actions/assets';
import { Severity } from 'types/errors';
import cn from 'classnames';
import { JUMBOTRON_DESCRIPTION_MAX_LENGTH } from 'utils/constants';
import _truncate from 'lodash/truncate';
import VideoPlayerRendererComponent from 'library/VideoPlayer/VideoPlayerRerenderComponent';
import useUpgradeToWatch from 'utils/useUpgradeToWatch';
import useWatchlist from 'utils/useWatchlist';
import styles from './index.module.scss';
import AssetMetaData from './AssetMetaData';
import AssetButtons from './AssetButtons';

interface JumbotronProps {
  showTags?: boolean;
  asset?: Asset;
}

const Jumbotron = ({ showTags, asset }: JumbotronProps) => {
  const [playerId, setPlayerId] = useState<string>('');
  const { push } = useHistory();
  const isAnonymous = useSelector(selectIsAnonymous);
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const moveToAsset = useMoveToAssetDetails();
  const progress = useFetchAssetWatchingProgress(asset);
  const [lastWatchedEpisodeDetails, setLastWatchedEpisodeDetails] =
    useState<Asset>();
  const isWatchingProgress =
    !!progress && !!progress.currentSecond && progress.currentSecond > 0;
  const isFinished = !!progress && progress.finished;
  const isLiveStreamActive = !!asset && isLiveActive(asset);
  const { assetWithContent } = useAssetWithContent({
    asset,
    lastWatchedEpisodeDetails,
    isFinished,
  });
  const buttonToDisplay = displayWatchButton(assetWithContent, isAnonymous);
  const trailer = getAssetTrailer(asset);
  const shouldDisplayTrailerButton = shouldDisplayTrailerButtonOnJumbotron(
    assetWithContent,
    trailer?.content
  );
  const { canPlayTrailer } = useCanPlayTrailer();
  const { isTabletWindowSize, isPhoneWindowSize } = useWindowSize();
  const { handleUpgradeToWatch } = useUpgradeToWatch();
  const { watchlistActions } = useWatchlist();

  useEffect(() => {
    if (!progress || !progress?.currentSecond || asset?.type !== AssetType.SHOW)
      return;

    getAsset(progress.assetUuid)
      .then((lastWatchedEpisode) =>
        setLastWatchedEpisodeDetails(lastWatchedEpisode)
      )
      .catch((e) =>
        captureError(e, 'jumbotron/getLastWatchedEpisode', Severity.Warning)
      );
  }, [progress, asset]);

  const moveToAssetPlayerHandler = () => {
    // Play trailer when there is no Content
    if (shouldDisplayTrailerButton && trailer) {
      const { id, type, administrativeName } = trailer.asset;
      const { slug } = trailer.content;

      return moveToAssetPlayer(id, type, administrativeName, {
        contentId: slug,
      });
    }

    if (!asset || !assetWithContent) return;

    const locationState = {
      state: {
        showId: asset?.type !== AssetType.MOVIE ? asset.id : undefined,
      },
    };

    if (
      isAnonymous &&
      assetWithContent.watchable !== Watchable.YES_ANONYMOUS_ACCESS
    ) {
      push(`/login`);
      return;
    }

    if (buttonToDisplay === ButtonToDisplay.UPGRADE_TO_WATCH) {
      return handleUpgradeToWatch();
    }

    return moveToAssetPlayer(
      assetWithContent.id,
      assetWithContent.type,
      assetWithContent.administrativeName,
      locationState
    );
  };

  const moveToAssetHandler = () => !!asset && moveToAsset(asset);

  const prepareImage = useCallback(() => {
    if (!asset) return;
    if (isPhoneWindowSize)
      return `${
        getAssetImageWithType(asset, 'backgroundCentred')?.url
      }_1280x720`;

    return `${getAssetImageWithType(asset, 'jumbotron')?.url}_1280x720`;
  }, [asset, isPhoneWindowSize]);

  const trailerContent = useMemo(() => {
    if (isPhoneWindowSize || !asset) {
      return;
    }

    return getAssetContentWithType(asset, ContentType.TRAILER);
  }, [asset, isPhoneWindowSize]);

  if (!asset) {
    return null;
  }

  return (
    <div className={styles.root} data-testid="Jumbotron__Wrapper">
      <JumbotronBackground
        onPlayerId={setPlayerId}
        image={prepareImage()}
        video={trailerContent}
        VideoPlayerComponent={VideoPlayerRendererComponent}
        canPlayTrailer={canPlayTrailer}
        assetId={asset?.id}
      />
      <div className={cn(styles.jumbotron)}>
        <div className={styles.content}>
          <AssetMetaData asset={asset} showTags={showTags} />

          {asset?.description && !isTabletWindowSize && (
            <div className={styles.description}>
              {_truncate(asset.description, {
                length: JUMBOTRON_DESCRIPTION_MAX_LENGTH,
              })}
            </div>
          )}
        </div>

        <div className={styles.buttonRow}>
          <HorizontalButtonGroup
            muteButtonRenderer={
              trailerContent && playerId
                ? PlayerMuteButtonRenderer({
                    playerId,
                    source: 'jumbotron',
                  })
                : undefined
            }
          >
            <AssetButtons
              shouldDisplayTrailerButton={shouldDisplayTrailerButton}
              moveToAssetHandler={moveToAssetHandler}
              moveToAssetPlayerHandler={moveToAssetPlayerHandler}
              isFinished={isFinished}
              isWatchingProgress={isWatchingProgress}
              isLiveStreamActive={isLiveStreamActive}
              buttonToDisplay={buttonToDisplay}
            />

            {!isAnonymous && asset && (
              <WatchlistButton
                watchlistActions={watchlistActions}
                asset={asset}
                className={styles.addToWatchlistBtn}
              />
            )}
          </HorizontalButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;

import React from 'react';
import { useSelector } from 'react-redux';
import { selectWelcomeBackgroundImage } from 'store/app/selectors';
import useRecaptchaElement from 'utils/useRecaptchaElement';
import styles from './index.module.scss';

interface AuthenticationContainerProps {
  children?: React.ReactNode;
  menu?: React.ReactNode;
}

const AuthPageContainer = ({
  children,
  menu,
}: AuthenticationContainerProps) => {
  const backgroundImage = useSelector(selectWelcomeBackgroundImage);
  useRecaptchaElement();

  return (
    <div className={styles.root}>
      <div className={styles.shadow} />
      <article className={styles.fullLengthBg}>
        <img src={backgroundImage} alt="" className={styles.bgImage} />
      </article>

      {menu}

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AuthPageContainer;

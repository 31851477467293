import {
  EventContext,
  SchemaVersion,
  StrataEventData,
  TrackEvent,
  TrackEventData,
  trackerSchema,
} from '@laminar-product/client-commons-core/core';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { getReleaseVersion } from 'utils/helpers';

const getClientId = (clientName?: string) => {
  return import.meta.env.VITE_ENVIRONMENT || '';
};

class BaseMetricsTracker {
  protected userUid: string | undefined;
  protected currentProfileUid: string | undefined;
  protected companyName: string | undefined;

  init(companyName?: string, userUid?: string, currentProfileUid?: string) {
    this.userUid = userUid;
    this.currentProfileUid = currentProfileUid;
    this.companyName = companyName;
  }

  private getClientContext() {
    return {
      schema: trackerSchema(EventContext.CLIENT_CONTEXT),
      data: {
        client_id: getClientId(this.companyName),
        client_version: getReleaseVersion(),
      },
    };
  }

  private getUserContext() {
    return {
      schema: trackerSchema(EventContext.USER_CONTEXT),
      data: {
        account_id: this.userUid,
        profile_id: this.currentProfileUid,
      },
    };
  }

  buildUserContext(customContext?: TrackEventData<any, any>['eventContext']) {
    const contexts: SelfDescribingJson<Record<string, unknown>>[] = [
      this.getClientContext(),
    ];
    if (this.userUid && this.currentProfileUid) {
      contexts.push(this.getUserContext());
    }
    if (customContext) {
      contexts.push({
        schema: trackerSchema(
          customContext.context,
          customContext.version || SchemaVersion.V100
        ),
        data: customContext.data,
      });
    }

    return contexts;
  }

  buildNonUserContext() {
    return [this.getClientContext()];
  }

  private buildEvent(
    event: TrackEvent,
    data: Record<string, unknown> = {},
    version: SchemaVersion = SchemaVersion.V100,
    context: SelfDescribingJson<Record<string, unknown>>[]
  ) {
    return {
      event: {
        schema: trackerSchema(event, version),
        data,
      },
      context,
    };
  }

  buildNonUserEvent(
    event: TrackEvent,
    data: StrataEventData<any>,
    version?: SchemaVersion
  ) {
    return this.buildEvent(event, data, version, this.buildNonUserContext());
  }

  buildUserEvent(
    event: TrackEvent,
    data: StrataEventData<any>,
    version?: SchemaVersion, // update type in the commons
    eventContext?: TrackEventData<any, any>['eventContext']
  ) {
    return this.buildEvent(
      event,
      data,
      version,
      this.buildUserContext(eventContext)
    );
  }
}

export default BaseMetricsTracker;

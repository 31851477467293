import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface ErrorPageProps {
  reason?: string;
  linkTo?: string;
  linkName?: string;
}

const ErrorPage = ({ reason, linkTo, linkName }: ErrorPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h1 data-testid="ErrorPage__heading">
        {t('errors.errorPageTitle')}{' '}
        <span role="img" aria-label="sad face">
          😕
        </span>
      </h1>
      <div>{reason}</div>
      {linkTo && linkName && (
        <NavLink className={styles.link} to={linkTo}>
          {t('errors.redirect')} {linkName}
        </NavLink>
      )}
    </div>
  );
};

export default ErrorPage;

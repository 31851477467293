import {
  useModalStyles,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface MenuNavContext {
  isOpen: boolean;
  setIsOpen: (isOpened: boolean) => void;
}

const MenuContext = createContext<MenuNavContext>({
  isOpen: false,
  setIsOpen: () => {},
});

export const MenuNavigationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { isTabletWindowSize } = useWindowSize();
  useModalStyles(isOpen, 'menu');

  useEffect(() => {
    const handleOrientationChange = () => setIsOpen(false);
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (!isTabletWindowSize) setIsOpen(false);
  }, [isTabletWindowSize, setIsOpen, isOpen]);

  useEffect(() => setIsOpen(false), [pathname]);

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-forward.svg';
import styles from '../index.module.scss';

interface ResetPlanFilterButtonProps {
  onClick: () => void;
}

const ResetPlanFilterButton = ({ onClick }: ResetPlanFilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.seeAllWrapper} onClick={onClick}>
      <span className={styles.seeAll}>{t('common.seeAll')}</span>
      <ArrowRight className={styles.seeAllIcon} width={10} height={10} />
    </div>
  );
};

export default ResetPlanFilterButton;

import cn from 'classnames';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

export interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: ReactNode;
  variant?: 'primary' | 'primary-settings' | 'orange';
  light?: boolean;
}

const Checkbox = ({
  label,
  className,
  variant = 'primary',
  light,
  ...props
}: CheckboxProps) => {
  return (
    <label
      className={cn(
        styles.root,
        {
          [styles.primary]: variant === 'primary',
          [styles.primarySettings]: variant === 'primary-settings',
          [styles.light]: light,
        },
        className
      )}
    >
      <input {...props} type="checkbox" />
      <span className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </label>
  );
};

export default Checkbox;

import {
  SearchSortField,
  SearchSortOrder,
} from '@laminar-product/client-commons-core/core';
import { t } from 'i18next';

export type SortOptionValue =
  | 'alpha'
  | 'invertedAlpha'
  | 'recommended'
  | 'releaseDate'
  | 'none';

export interface SortOption {
  label: string;
  value: SortOptionValue;
}

export interface SearchSortParams {
  sortField: SearchSortField;
  sortOrder?: SearchSortOrder;
}

const sortingOptionToSearchParams: {
  [key in SortOptionValue]: SearchSortParams;
} = {
  alpha: {
    sortField: 'NAME',
    sortOrder: 'ASC',
  },
  invertedAlpha: {
    sortField: 'NAME',
    sortOrder: 'DESC',
  },
  recommended: {
    sortField: 'RELEVANCE',
  },
  releaseDate: {
    sortField: 'YEAR',
    sortOrder: 'DESC',
  },
  none: {
    sortField: 'NONE',
  },
};

export const mapURLParamToSearchParams = (
  value: string
): SearchSortParams | undefined => {
  return sortingOptionToSearchParams[value as SortOptionValue];
};

export const SORTING_OPTIONS: SortOption[] = [
  {
    label: 'A-Z',
    value: 'alpha',
  },
  {
    label: 'Z-A',
    value: 'invertedAlpha',
  },
  {
    label: t('filter.recommendedSorting'),
    value: 'recommended',
  },
  {
    label: t('filter.releaseDateSorting'),
    value: 'releaseDate',
  },
  {
    label: t('filter.noneSorting'),
    value: 'none',
  },
];

import { VideoPlayerRendererProps } from '@laminar-product/client-commons-core/core';
import VideoPlayerComponent from 'library/VideoPlayer/VideoPlayerComponent';
import useVideoPlayerConfiguration from 'utils/useVideoPlayerConfiguration';
import styles from './index.module.scss';

const VideoPlayerRendererComponent = ({
  assetId,
  playerId,
  videoFile,
  jumbotron,
}: VideoPlayerRendererProps) => {
  const playerLoadConfiguration = useVideoPlayerConfiguration({
    assetId,
    content: videoFile,
    variant: 'embedded',
  });

  if (!playerLoadConfiguration) {
    return null;
  }

  return (
    <VideoPlayerComponent
      loop
      loadConfig={playerLoadConfiguration}
      playerId={playerId}
      className={jumbotron ? styles.jumbotronVideoContainer : ''}
      muted={true}
    />
  );
};

export default VideoPlayerRendererComponent;

import { Asset } from '@laminar-product/client-commons-core/core';
import { Reducer, useCallback, useReducer } from 'react';

interface AssetReducerState {
  assets: Asset[];
  total: number;
  deduplicatedAssetsCount: number;
}

interface AssetReducerAction {
  type:
    | 'CLEAR_ASSETS'
    | 'ADD_ASSETS'
    | 'SET_TOTAL_COUNT'
    | 'ADD_DEDUPLICATED_ASSETS_COUNT';
  payload?: number | Asset[];
}

const useListingAssets = () => {
  const assetReducer = (
    state: AssetReducerState,
    action: AssetReducerAction
  ) => {
    switch (action.type) {
      case 'CLEAR_ASSETS':
        return {
          assets: [],
          total: 0,
          deduplicatedAssetsCount: 0,
        };
      case 'ADD_ASSETS':
        return {
          ...state,
          assets: [...state.assets, ...(action.payload as Asset[])],
        };
      case 'SET_TOTAL_COUNT':
        return {
          ...state,
          total: action.payload as number,
        };
      case 'ADD_DEDUPLICATED_ASSETS_COUNT':
        return {
          ...state,
          deduplicatedAssetsCount:
            state.deduplicatedAssetsCount + (action.payload as number),
        };
      default:
        return state;
    }
  };
  const [state, assetsDispatch] = useReducer<
    Reducer<AssetReducerState, AssetReducerAction>
  >(assetReducer, {
    assets: [],
    total: 0,
    deduplicatedAssetsCount: 0,
  });
  const addAssets = useCallback((assets: Asset[]) => {
    assetsDispatch({ type: 'ADD_ASSETS', payload: assets });
  }, []);
  const clearAssets = useCallback(() => {
    assetsDispatch({ type: 'CLEAR_ASSETS' });
  }, []);

  const setTotalCount = useCallback((total: number) => {
    assetsDispatch({ type: 'SET_TOTAL_COUNT', payload: total });
  }, []);
  const addDeduplicatedAssetsCount = useCallback(
    (deduplicatedCount: number) => {
      assetsDispatch({
        type: 'ADD_DEDUPLICATED_ASSETS_COUNT',
        payload: deduplicatedCount,
      });
    },
    []
  );

  return {
    assets: state.assets,
    totalAssetsCount: state.total,
    deduplicatedAssetsCount: state.deduplicatedAssetsCount,
    addAssets,
    setTotalCount,
    clearAssets,
    addDeduplicatedAssetsCount,
  };
};

export default useListingAssets;

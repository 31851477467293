import {
  Asset,
  ImageType,
  MoveToAssetPlayer,
} from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import {
  EPISODE_SHOW_URL_TYPE,
  NEXT_EPISODE_INITIAL_PROGRESS_VALUE,
} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { ReactComponent as PlayIcon } from 'assets/icons/play-button.svg';
import { Circle } from 'rc-progress';
import useAutoplayNextEpisode from 'utils/useAutoplayNextEpisode';
import commonStyles from '../commonsStyles.module.scss';
import styles from './index.module.scss';

interface AutoplayNextEpisodeProps {
  nextEpisodeDetails: Asset;
  moveToAssetPlayer?: MoveToAssetPlayer;
  showName?: string;
  showId?: string;
  onWatchCredits: () => void;
}

const AutoplayNextEpisode = ({
  nextEpisodeDetails,
  moveToAssetPlayer,
  showName,
  showId,
  onWatchCredits,
}: AutoplayNextEpisodeProps) => {
  const { t } = useTranslation();
  const imageSrc = nextEpisodeDetails.images?.find(
    (i) => i.type === ImageType.thumbnail
  )?.url;

  const onRedirectToNextEpisode = useCallback(() => {
    moveToAssetPlayer?.(
      nextEpisodeDetails.id,
      EPISODE_SHOW_URL_TYPE,
      showName || '',
      { state: { showId } }
    );
  }, [moveToAssetPlayer, nextEpisodeDetails.id, showName, showId]);

  const { isCounterVisible, counterTime, circlePercent } =
    useAutoplayNextEpisode({ onRedirectToNextEpisode });

  return (
    <div className={commonStyles.creditsFrameContainer}>
      <div className={commonStyles.nextEpisodeOverlay} />

      <div className={commonStyles.meta}>
        {isCounterVisible && (
          <div className={styles.timeInfo}>
            {t('player.nextEpisodeIn')}
            <span className={styles.counter}>{counterTime}</span>
            {t('player.seconds')}
          </div>
        )}

        <p className={commonStyles.title}>{nextEpisodeDetails.name}</p>
        <p className={styles.subTitle}>
          {nextEpisodeDetails.parent
            ? `${t('common.season')} ${nextEpisodeDetails.parent?.order}  |  `
            : null}
          {t('common.episode')} {nextEpisodeDetails.order}
        </p>
      </div>

      <div className={styles.autoplayNextEpisodeButtons}>
        <Button
          variant="secondary"
          round
          className={styles.nextEpisodeBtn}
          onClick={onWatchCredits}
          data-testid="AutoplayNextEpisode__WatchCredits"
        >
          {t('player.watchCredits')}
        </Button>

        <Button
          variant="secondary"
          round
          className={styles.nextEpisodeBtn}
          onClick={onRedirectToNextEpisode}
          data-testid="AutoplayNextEpisode__PlayNextEpisode"
        >
          <img
            className={styles.nextEpisodeImage}
            src={imageSrc}
            alt={nextEpisodeDetails.name}
          />

          {isCounterVisible && (
            <Circle
              percent={
                100 -
                (circlePercent / NEXT_EPISODE_INITIAL_PROGRESS_VALUE) * 100
              }
              strokeWidth={5}
              trailWidth={3}
              strokeColor="rgb(255,255,255)"
              trailColor="rgba(255,255,255,0.1)"
              className={styles.playBtn}
            />
          )}
          <PlayIcon className={styles.playIcon} />
        </Button>
      </div>
    </div>
  );
};

export default AutoplayNextEpisode;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChromecastCastElement } from 'store/chromecast/selectors';
import {
  resetChromecastConnection,
  setChromecastAvailable,
  setChromecastCastElement,
  setChromecastStatus,
} from 'store/chromecast/actions';
import { ChromecastEvents, ChromecastStatus } from 'store/chromecast/types';
import { Severity } from 'types/errors';
import Castjs from '../../chromecast/cast';
import { captureError } from './captureError';

const useChromecast = () => {
  const castElement = useSelector(selectChromecastCastElement);
  const dispatch = useDispatch();

  //Handle init chromecast on app load; need to verify if chromecast session is active
  useEffect(() => {
    if (castElement) {
      return;
    }

    const cjsInstance = new Castjs({
      receiver: import.meta.env.VITE_CHROMECAST_RECEIVER_ID,
      joinpolicy: 'tab_and_origin_scoped', // default
    });

    dispatch(setChromecastAvailable(cjsInstance.available));
    dispatch(setChromecastCastElement(cjsInstance));
  }, [castElement, dispatch]);

  //Listeners for cast state changing
  useEffect(() => {
    if (!castElement) {
      return;
    }

    const onChromecastConnected = () =>
      dispatch(setChromecastStatus(ChromecastStatus.CONNECTED));

    const onChromecastAvailable = () => dispatch(setChromecastAvailable(true));

    const onChromecastDisconnect = () => {
      dispatch(setChromecastStatus(ChromecastStatus.DISCONNECTED));
      dispatch(resetChromecastConnection());
    };

    //TODO: Handle play next episode if possible - feature
    const onChromecastEnd = () => castElement.disconnect();

    const onChromecastError = (error: any) => {
      castElement.disconnect();
      captureError(error, 'chromecast/connectionError', Severity.Error);
    };

    castElement.on(ChromecastEvents.CONNECT, onChromecastConnected);
    castElement.on(ChromecastEvents.AVAILABLE, onChromecastAvailable);
    castElement.on(ChromecastEvents.DISCONNECT, onChromecastDisconnect);
    castElement.on(ChromecastEvents.END, onChromecastEnd);
    castElement.on(ChromecastEvents.ERROR, onChromecastError);

    return () => {
      castElement.off(ChromecastEvents.CONNECT, onChromecastConnected);
      castElement.off(ChromecastEvents.AVAILABLE, onChromecastAvailable);
      castElement.off(ChromecastEvents.DISCONNECT, onChromecastDisconnect);
      castElement.off(ChromecastEvents.END, onChromecastEnd);
      castElement.off(ChromecastEvents.ERROR, onChromecastError);
    };
  }, [castElement, dispatch]);

  //LIsteners for playback actions
  useEffect(() => {
    if (!castElement) {
      return;
    }

    const onChromecastPause = () =>
      dispatch(setChromecastStatus(ChromecastStatus.PAUSED));

    const onChromecastPlay = () =>
      dispatch(setChromecastStatus(ChromecastStatus.PLAYING));

    const onChromecastBuffering = () =>
      dispatch(setChromecastStatus(ChromecastStatus.BUFFERING));

    castElement.on(ChromecastEvents.PLAYING, onChromecastPlay);
    castElement.on(ChromecastEvents.PAUSE, onChromecastPause);
    castElement.on(ChromecastEvents.BUFFERING, onChromecastBuffering);

    return () => {
      castElement.off(ChromecastEvents.PLAYING, onChromecastPlay);
      castElement.off(ChromecastEvents.PAUSE, onChromecastPause);
      castElement.off(ChromecastEvents.BUFFERING, onChromecastBuffering);
    };
  }, [castElement, dispatch]);
};

export default useChromecast;

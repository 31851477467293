import { ProgressIndicator } from '@laminar-product/client-commons-core/web';
import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface AppLoadingIndicatorProps {
  fullScreen?: boolean;
  className?: string;
}

const AppLoadingIndicator = (props?: AppLoadingIndicatorProps) => (
  <div
    className={cn(
      styles.root,
      props?.className,
      props?.fullScreen && styles.fullScreen
    )}
    data-testid="AppLoadingIndicator"
  >
    <div className={styles.loader}>
      <ProgressIndicator variant="circular" />
    </div>
  </div>
);

export default AppLoadingIndicator;

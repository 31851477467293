import {
  CalculatePriceCouponResponse,
  DiscountType,
  Price,
} from '@laminar-product/client-commons-core/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { checkForCouponApplied, formatPrice } from 'utils/price';
import styles from '../../index.module.scss';

interface OrderBillingInformationProps {
  price: Price;
  calculatedPriceCoupon?: CalculatePriceCouponResponse;
}

const OrderBillingInformation = ({
  price,
  calculatedPriceCoupon,
}: OrderBillingInformationProps) => {
  const { t } = useTranslation();

  const checkForPeriodCoupon =
    calculatedPriceCoupon &&
    checkForCouponApplied(calculatedPriceCoupon) &&
    calculatedPriceCoupon.discountType === DiscountType.PERIOD;

  const checkForPromotionPeriod =
    price.promotion && price.promotion.discountType === DiscountType.PERIOD;

  return (
    <div className={styles.cancelPosibility}>
      <InfoIcon className={styles.infoIcon} />
      {checkForPromotionPeriod || checkForPeriodCoupon ? (
        <div>
          {t('orderSummary.willBeBilled')}{' '}
          <span className={styles.bold}>
            {formatPrice(price.amount, price.currency)}{' '}
          </span>
          {t('orderSummary.after')}{' '}
          {price.promotion?.amount && (
            <span className={styles.bold}>
              {price.promotion?.amount}{' '}
              {t('payment.days', { count: price.promotion.amount })}{' '}
            </span>
          )}
          {calculatedPriceCoupon?.amount && (
            <span className={styles.bold}>
              {calculatedPriceCoupon.amount}{' '}
              {t('payment.days', { count: calculatedPriceCoupon?.amount })}{' '}
            </span>
          )}
          {t('orderSummary.ofFreeTrial')} {t('orderSummary.cancelPossibility')}
        </div>
      ) : (
        <div>
          {t('orderSummary.continueUntilCancel')}{' '}
          {t('orderSummary.willBeBilled')}{' '}
          <span className={styles.bold}>
            {formatPrice(price.amount, price.currency)}{' '}
          </span>
          {price.promotion || calculatedPriceCoupon
            ? t('orderSummary.inNextBillingCycles')
            : t('orderSummary.inEachBillingCycle')}
        </div>
      )}
    </div>
  );
};

export default OrderBillingInformation;

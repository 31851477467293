import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DividerIcon } from 'assets/icons/arrow-down.svg';
import { Plan, Price } from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import usePathWithParams from 'utils/usePathWithParams';
import {
  selectUserPlanMetadata,
  selectUserSubscriptionPrice,
} from 'store/user/selectors';
import styles from './index.module.scss';
import ModalPlanCard from './ModalPlanCard';
import useCardAccordion from './useCardAccordion';

interface ChangePlanModalProps {
  newPlan: Plan;
  selectedPrice: Price;
  onModalClose: () => void;
}

const ChangePlanModal = ({
  onModalClose,
  newPlan,
  selectedPrice,
}: ChangePlanModalProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const userPlanMetadata = useSelector(selectUserPlanMetadata);
  const price = useSelector(selectUserSubscriptionPrice);
  const { expanded, toggle } = useCardAccordion('newPlan');

  const getPath = usePathWithParams();
  // todo use currentPlan data from /subscription endpoint & map new Plan + new Price to the same data interface

  return (
    <Modal wrapperId="changePlanModal" isOpen>
      <div className={styles.modalWrapper}>
        <div className={styles.modalCard}>
          <div className={styles.header}>
            <Trans i18nKey="planSelector.changePlanHeader" />
          </div>
          <div className={styles.description}>
            <Trans
              i18nKey="planSelector.changePlanMessage"
              values={{
                currentPlan: userPlanMetadata?.name,
                newPlan: newPlan.name,
              }}
            />
          </div>
          <div className={styles.modalBody}>
            <ModalPlanCard
              plan={userPlanMetadata}
              price={price}
              title={t('planSelector.currentPlan')}
              variant="current"
              open={expanded === 'currentPlan'}
              onCollapseClick={() => toggle('currentPlan')}
            />
            <DividerIcon className={styles.divider} />
            <ModalPlanCard
              plan={newPlan}
              price={selectedPrice}
              title={t('planSelector.newPlan')}
              variant="new"
              open={expanded === 'newPlan'}
              onCollapseClick={() => toggle('newPlan')}
            />
          </div>
          {/*todo get proper date when BE ready*/}
          {/* <p>You wont be charged until 12.03.2021</p> */}
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              variant="secondary-settings"
              onClick={() => onModalClose()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="cta"
              onClick={() => push(getPath('/settings/change-plan/payment'))}
            >
              {t('settings.confirmChange')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePlanModal;

import {
  AssetType,
  Collection,
  SearchParams,
} from '@laminar-product/client-commons-core/core';
import { getCollection } from 'actions/collections';
import { searchContent } from 'actions/search';
import { cancelable } from 'cancelable-promise';
import PosterList from 'components/PosterList';
import Menu from 'library/Menu';
import _differenceBy from 'lodash/differenceBy';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { defaultAssetSearchLimit } from 'utils/constants';
import { getSearchPagination } from 'utils/pagination';
import { RouteIdParams } from 'utils/props';
import useListingAssets from 'utils/useListingAssets';
import { toArray } from 'utils/toArray';
import { SearchParamsFromUrl } from 'types/search';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { useTranslation } from 'react-i18next';
import { mapURLParamToSearchParams } from 'utils/sorting';
import { prepareMetaForPage } from 'utils/meta';
import Footer from 'components/Footer';

const ListingView = () => {
  const { id } = useParams<RouteIdParams>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [collection, setCollection] = useState<Collection>();
  const {
    assets,
    totalAssetsCount,
    deduplicatedAssetsCount,
    addAssets,
    setTotalCount,
    addDeduplicatedAssetsCount,
    clearAssets,
  } = useListingAssets();
  const [page, setPage] = useState(1);
  const [isGettingResults, setIsGettingResults] = useState<boolean>(false);

  const fetchSearchAssetsWithCollection = useCallback(
    async (collection?: Collection, page?: number) => {
      const pagination = getSearchPagination(page, defaultAssetSearchLimit);
      if (collection && collection.filters) {
        const searchParams: SearchParams = {
          ...collection?.filters,
          ...pagination,
        };
        const result = await searchContent(searchParams);
        const deduplicatedSearchHits = _differenceBy(
          result.hits,
          collection.assets,
          'id'
        );
        setTotalCount(result.total + collection.assets.length);
        addDeduplicatedAssetsCount(
          result.hits.length - deduplicatedSearchHits.length
        );
        addAssets(deduplicatedSearchHits);
      }
    },
    [addAssets, addDeduplicatedAssetsCount, setTotalCount]
  );
  const fetchSearchAssets = useCallback(
    async (search: SearchParamsFromUrl, page?: number) => {
      setIsGettingResults(true);
      let sortOptions;
      const pagination = getSearchPagination(page, defaultAssetSearchLimit);

      if (!search.type) return;
      if (search.sort) {
        sortOptions = mapURLParamToSearchParams(search.sort);
      }

      const searchParams: SearchParams = {
        ...pagination,
        sortField: sortOptions?.sortField,
        sortOrder: sortOptions?.sortOrder,
        genres: toArray(search.genre),
        audios: toArray(search.audios),
        subtitles: toArray(search.subtitles),
        moods: toArray(search.mood),
        types: [search.type.toUpperCase() as AssetType],
      };

      const result = await searchContent(searchParams);
      setTotalCount(result.total);
      addAssets(result.hits);
      setIsGettingResults(false);
    },
    [addAssets, setTotalCount]
  );

  useEffect(() => {
    clearAssets();
  }, [clearAssets, location.search]);

  useEffect(() => {
    const locationSearch = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (!locationSearch.type) {
      const fetch = cancelable(getCollection(id));
      fetch.then((collection) => {
        setCollection(collection);
        addAssets(collection.assets);
      });

      return () => fetch.cancel();
    }
  }, [addAssets, dispatch, id, location.search]);

  useEffect(() => {
    const locationSearch = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (locationSearch.type) {
      dispatch(
        setAppMeta({ og: prepareMetaForPage(t('common.placeholders.search')) })
      );
      fetchSearchAssets(locationSearch, page);
    }
  }, [collection, dispatch, fetchSearchAssets, location.search, page, t]);

  useEffect(() => {
    if (!collection) return;
    dispatch(
      setAppMeta({
        og: prepareMetaForPage(collection?.title, collection?.description),
        description: collection?.description,
      })
    );

    fetchSearchAssetsWithCollection(collection, page);
  }, [page, collection, fetchSearchAssetsWithCollection, dispatch]);

  const subheader = {
    title: collection?.title,
  };

  return (
    <>
      <Menu subheader={subheader} />
      <PosterList
        assets={assets}
        total={totalAssetsCount - deduplicatedAssetsCount}
        onLoadMore={() => setPage(page + 1)}
        isLoading={isGettingResults}
      />
      <Footer />
    </>
  );
};

export default ListingView;

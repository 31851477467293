import {
  CalculatePriceCouponResponse,
  Price,
} from '@laminar-product/client-commons-core/core';
import React from 'react';
import { checkForCouponApplied, formatPrice } from 'utils/price';

export interface TotalInformationProps {
  price: Price;
  calculatedPriceCoupon?: CalculatePriceCouponResponse;
}

const TotalInformation = ({
  price,
  calculatedPriceCoupon,
}: TotalInformationProps) => {
  const checkForNetPrice =
    price.netPrice !== undefined && price.netPrice !== null;

  if (calculatedPriceCoupon && checkForCouponApplied(calculatedPriceCoupon)) {
    return (
      <span>{formatPrice(calculatedPriceCoupon.netPrice, price.currency)}</span>
    );
  }

  return (
    <span data-testid="PaymentSummary__TotalPrice">
      {formatPrice(
        checkForNetPrice ? price.netPrice : price.amount,
        price.currency
      )}
    </span>
  );
};

export default TotalInformation;

import {
  Asset,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import Poster from 'components/Poster';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import { CarouselType } from 'utils/carouselHelpers';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import styles from './index.module.scss';

interface PosterListProps {
  assets: Asset[];
  total?: number;
  className?: string;
  posterClassName?: string;
  onLoadMore?: () => void;
  isLoading?: boolean;
  testId?: string;
}

const PosterList = ({
  assets,
  className,
  total,
  onLoadMore,
  isLoading,
  posterClassName,
  testId,
}: PosterListProps) => {
  const totalCount = total ?? assets.length;
  const { t } = useTranslation();
  const moveToAsset = useMoveToAssetDetails();
  const { isTabletWindowSize } = useWindowSize();

  if (isLoading) {
    return <AppLoadingIndicator />;
  }

  if (assets.length === 0) {
    return <div className={styles.message}>{t('common.noResults')}</div>;
  }

  return (
    <div className={cn(styles.root, className)} data-testid={testId}>
      <InfiniteScroll
        dataLength={assets.length}
        next={() => {
          if (onLoadMore) onLoadMore();
        }}
        hasMore={assets.length < totalCount}
        loader={<AppLoadingIndicator />}
      >
        {assets?.map((asset, i) => {
          const imageType = isTabletWindowSize ? 'portrait' : 'thumbnail';
          const url = getAssetImageWithType(asset, imageType)?.url;
          return (
            <Poster
              type={
                isTabletWindowSize ? CarouselType.PORTRAIT_CAROUSEL : undefined
              }
              onItemClick={() => moveToAsset(asset)}
              key={asset.id}
              role="link"
              className={cn(styles.item, posterClassName, {
                [styles.itemPortrait]: isTabletWindowSize,
                [styles.item16_9]: !isTabletWindowSize,
              })}
              data={asset}
              posterUrl={url}
              hoverUrl={url}
              index={i}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PosterList;

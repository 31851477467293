import { useEffect } from 'react';
import _debounce from 'lodash/debounce';

interface UsePageScrollProps {
  handleFetchNextComponentsPage: () => void;
  bottomOffset?: number;
}

const usePageScroll = ({
  handleFetchNextComponentsPage,
  bottomOffset = 0,
}: UsePageScrollProps) => {
  useEffect(() => {
    const handlePageScroll = _debounce(() => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      const isScrolledToPageBottom =
        scrollTop + clientHeight + bottomOffset >= scrollHeight;

      if (isScrolledToPageBottom) {
        handleFetchNextComponentsPage();
      }
    }, 500);

    window.addEventListener('scroll', handlePageScroll);

    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, [bottomOffset, handleFetchNextComponentsPage]);
};

export default usePageScroll;

import { ComponentsPage } from '@laminar-product/client-commons-core/core';
import { PAGE_FETCHING_STATUS } from './types';

export const RESET_PAGES = 'RESET_PAGES';
export const resetPages = () => ({
  type: RESET_PAGES,
});

export const SET_COMPONENTS_PAGE = 'SET_COMPONENTS_PAGE';
export const setComponentsPage = (page: ComponentsPage, skip: number = 0) => ({
  type: SET_COMPONENTS_PAGE,
  payload: { page, skip },
});

export const SET_PAGE_LAST_FETCHED_PAGINATION =
  'SET_PAGE_LAST_FETCHED_PAGINATION';
export const setPageLastFetchedPagination = (
  pageId: string,
  lastFetchedPagination: number
) => ({
  type: SET_PAGE_LAST_FETCHED_PAGINATION,
  payload: { pageId, lastFetchedPagination },
});

export const SET_PAGE_FETCHING_STATUS = 'SET_PAGE_FETCHING_STATUS';
export const setPageFetchingStatus = (
  pageId: string,
  status: PAGE_FETCHING_STATUS
) => ({
  type: SET_PAGE_FETCHING_STATUS,
  payload: { pageId, status },
});

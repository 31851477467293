import { assign, setup } from 'xstate';
import { v4 as uuidv4 } from 'uuid';
import { StrataPlayerEvent, StrataPlayerState } from './types';

const videoFailTransition = {
  FAIL_VIDEO: {
    target: StrataPlayerState.VIDEO_FAIL,
  },
};

const trackAction: { type: 'trackVideoPlayerEvent' } = {
  type: 'trackVideoPlayerEvent',
};

export const strataPlayerMachine = setup({
  types: {
    events: {} as StrataPlayerEvent,
  },
  actions: {
    trackVideoPlayerEvent: () => {},
    assignBufferId: assign({
      bufferId: (ctx) => uuidv4(),
    }),
  },
  guards: {
    hasBufferId: ({ context }) => !!context.bufferId,
  },
}).createMachine({
  id: 'strataPlayer',
  initial: StrataPlayerState.IDLE,
  context: {
    bufferId: '',
  },
  states: {
    [StrataPlayerState.IDLE]: {
      on: {
        SET_PLAYER_READY: StrataPlayerState.PLAYER_READY,
        ...videoFailTransition,
      },
    },
    [StrataPlayerState.PLAYER_READY]: {
      on: {
        VIDEO_START_BUFFER: StrataPlayerState.VIDEO_BUFFER_START,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.VIDEO_BUFFER_START]: {
      on: {
        VIDEO_END_BUFFER: {
          target: StrataPlayerState.VIDEO_BUFFER_END,
          guard: 'hasBufferId',
        },
        ...videoFailTransition,
      },
      entry: [
        {
          type: 'assignBufferId',
        },
        trackAction,
      ],
    },
    [StrataPlayerState.VIDEO_BUFFER_END]: {
      on: {
        START_VIDEO: StrataPlayerState.VIDEO_START,
        START_AD: StrataPlayerState.AD_START,
        ...videoFailTransition,
      },
      entry: [trackAction, assign({ bufferId: '' })],
    },
    [StrataPlayerState.VIDEO_START]: {
      on: {
        PLAYING_VIDEO: StrataPlayerState.VIDEO_PLAYING,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.VIDEO_PLAYING]: {
      on: {
        STOP_VIDEO: StrataPlayerState.VIDEO_STOP,
        VIDEO_START_BUFFER: StrataPlayerState.VIDEO_BUFFER_START,
        PLAYING_VIDEO: StrataPlayerState.VIDEO_PLAYING,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.VIDEO_STOP]: {
      on: {
        START_VIDEO: StrataPlayerState.VIDEO_START,
        VIDEO_START_BUFFER: StrataPlayerState.VIDEO_BUFFER_START,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.AD_START]: {
      on: {
        END_AD: StrataPlayerState.AD_END,
        STOP_AD: StrataPlayerState.AD_STOP,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.AD_STOP]: {
      on: {
        START_AD: StrataPlayerState.AD_START,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.AD_END]: {
      on: {
        START_VIDEO: StrataPlayerState.VIDEO_START,
        START_AD: StrataPlayerState.AD_START,
        ...videoFailTransition,
      },
      entry: [trackAction],
    },
    [StrataPlayerState.VIDEO_FAIL]: {
      type: 'final',
      entry: [trackAction],
    },
  },
});

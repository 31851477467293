import { ReactComponent as PaymentCompletedIcon } from 'assets/images/payment-completed.svg';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.scss';

const StaticPaymentCompleted = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <PaymentCompletedIcon className={styles.img} />
      </div>
      <h1 className={styles.heading}>{t('payment.success.heading')}</h1>
      <p className={styles.description}>
        {t('payment.static.success.description')}
      </p>
    </div>
  );
};

export default StaticPaymentCompleted;

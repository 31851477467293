import { getAssetImageWithType } from '@laminar-product/client-commons-core/core';
import { BasicRemoteControllerProps } from 'components/ChromecastRemoteController/types';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { Button } from '@laminar-product/client-commons-core/web';
import { ReactComponent as ChromecastConnected } from 'assets/icons/chromecast-connected.svg';
import {
  selectChromecastAsset,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import { useSelector } from 'react-redux';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import FullRemoteControls from '../FullRemoteControls';
import styles from './index.module.scss';

interface FullRemoteControllerProps extends BasicRemoteControllerProps {}

const FullRemoteController = ({
  setIsMinimized,
  onChromecastDisconnect,
  isPlayingAds,
}: FullRemoteControllerProps) => {
  const asset = useSelector(selectChromecastAsset);
  const castElement = useSelector(selectChromecastCastElement);
  const img = asset
    ? getAssetImageWithType(asset, 'backgroundCentred')?.url
    : '';

  const { t } = useTranslation();

  const onChromecastIconClick = useCallback(async () => {
    if (!castElement) {
      return;
    }

    await castElement.openCastModal();
  }, [castElement]);

  //Disable page scroll when full remote is visible
  useEffect(() => {
    if (isMobileOrTablet()) {
      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  if (!castElement || !asset) {
    return null;
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, rgba(0, 0, 0, 0.8) 0,rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.8) 100%), url(${img})`,
      }}
      className={styles.fullRemoteWrapper}
    >
      <div className={styles.fullRemoteContent}>
        <div className={styles.fullRemoteTopControls}>
          <div className={styles.actionWrapper}>
            <ArrowDownIcon
              className={styles.arrowDown}
              onClick={() => setIsMinimized(true)}
            />
            <span>{t('chromecast.hideControls')}</span>
          </div>

          <div className={styles.actionWrapper} onClick={onChromecastIconClick}>
            <span>{castElement.device}</span>
            <ChromecastConnected />
          </div>
        </div>

        <div className={styles.castingTo}>
          <span>
            {t('chromecast.castingTo')} <b>{castElement.device}</b>
          </span>

          <Button onClick={onChromecastDisconnect}>
            {t('chromecast.disconnect')}
          </Button>
        </div>

        <FullRemoteControls asset={asset} isPlayingAds={isPlayingAds} />
      </div>
    </div>
  );
};

export default FullRemoteController;

import {
  Asset,
  AssetType,
  ContinueWatchingItem,
} from '@laminar-product/client-commons-core/core';
import { getAssetWatchingProgress } from 'actions/watchingProgress';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirebaseUser } from 'store/user/selectors';
import { selectAssetWatchingProgress } from 'store/app/selectors';
import { putElementToContinueWatching } from 'store/app/actions';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import { useGetCurrentProfile } from './profiles';
import { captureError } from './captureError';

const useFetchAssetWatchingProgress = (asset?: Asset) => {
  const user = useSelector(selectFirebaseUser);
  const [progress, setProgress] = useState<ContinueWatchingItem>();
  const currentProfileUuid = useGetCurrentProfile()?.uuid;
  const dispatch = useDispatch();
  const assetProgress = useSelector(
    selectAssetWatchingProgress(asset?.id, asset?.type)
  );

  useEffect(() => {
    if (!asset || !user || user.isAnonymous || !currentProfileUuid) {
      return;
    }

    if (assetProgress) {
      setProgress({
        assetUuid: assetProgress.assetUuid,
        currentSecond: assetProgress.currentSecond,
        finished: assetProgress.finished,
      });

      return;
    }

    getAssetWatchingProgress(asset.id)
      .then(async (item) => {
        dispatch(
          putElementToContinueWatching({
            assetUuid: item.assetUuid,
            currentSecond: item.currentSecond,
            finished: item.finished,
            parentId: item.parentId,
            rootId:
              item.rootId ||
              (asset.type === AssetType.SHOW ? asset.id : undefined),
          })
        );
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          setProgress({
            assetUuid: asset.id,
            currentSecond: 0,
          });
          return;
        }

        captureError(
          e as AxiosError,
          'hooks/useCarouselContinueWatchingData/getAssetWatchingProgress',
          Severity.Warning,
          { extra: { assetId: asset.id } }
        );
      });
  }, [asset, user, currentProfileUuid, dispatch, assetProgress]);

  return progress;
};

export default useFetchAssetWatchingProgress;

import React, { SyntheticEvent, useState } from 'react';
import { getFallbackCarouselTitle } from 'utils/carouselHelpers';
import styles from './index.module.scss';

interface ImageProps extends React.ComponentProps<'img'> {
  title: string;
}
const Image = ({ title, alt = '', onError, ...props }: ImageProps) => {
  const [imageHasError, setImageHasError] = useState(false);

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    setImageHasError(true);
    onError?.(e);
  };

  if (imageHasError) {
    return (
      <div className={styles.imagePlaceholderContainer}>
        <span className={styles.imagePlaceholderText}>
          {getFallbackCarouselTitle(title)}
        </span>
      </div>
    );
  }
  return <img {...props} alt={alt} onError={handleError} />;
};

export default Image;

import axios from 'axios';
import { Translation } from 'types/translation';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';

export const fetchTranslations = async (lang: string): Promise<Translation> => {
  try {
    const { data } = await axios.get<Translation>(
      `${import.meta.env.VITE_STATIC_URL}/translations/${lang}.json`
    );

    return data;
  } catch (error) {
    captureError(
      error as any,
      'translation/fetchTranslations',
      Severity.Warning
    );
    return Promise.reject();
  }
};

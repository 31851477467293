import { Button } from '@laminar-product/client-commons-core/web';
import LinearProfileWrapper from 'components/LinearProfileWrapper';
import ProfileModalHeader from 'components/ProfileModalHeader';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUserManagingProfilesVariant } from 'store/user/actions';
import {
  selectProfiles,
  selectUserDetails,
  selectUserManagingProfilesVariant,
} from 'store/user/selectors';
import { checkForProfileLimit, useUpdateProfilesState } from 'utils/profiles';
import { UserManagingProfilesVariant } from 'types/user';
import styles from './index.module.scss';
import ProfileSelectorItem from './ProfileSelectorItem';

const ProfilesSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const profiles = useSelector(selectProfiles);
  const userData = useSelector(selectUserDetails);
  const userManagingProfilesVariant = useSelector(
    selectUserManagingProfilesVariant
  );
  const onProfileCreate = useUpdateProfilesState();

  const isProfilesManageVariant =
    userManagingProfilesVariant === UserManagingProfilesVariant.MANAGE;

  const getHeaderText = useCallback(() => {
    return isEditMode
      ? t('profile.manageProfiles')
      : t('profile.whoIsWatching');
  }, [isEditMode, t]);

  const onDoneClick = useCallback(() => {
    if (isProfilesManageVariant) {
      return dispatch(setUserManagingProfilesVariant(undefined));
    }

    setIsEditMode(false);
  }, [dispatch, isProfilesManageVariant]);

  useEffect(() => {
    if (isProfilesManageVariant) {
      setIsEditMode(true);
    }
  }, [isProfilesManageVariant]);

  return (
    <LinearProfileWrapper contentClassName={styles.profileSelectorContainer}>
      {!isEditMode && (
        <Button
          variant="secondary"
          className={styles.editBtn}
          onClick={() => setIsEditMode(true)}
        >
          {t('profile.manageProfiles')}
        </Button>
      )}

      <div className={styles.profileSelectorWrapper}>
        <ProfileModalHeader text={getHeaderText()} bold />
        <div className={styles.profilesContainer}>
          {profiles?.map((profile, index) => (
            <ProfileSelectorItem
              isEditMode={isEditMode}
              key={index}
              profile={profile}
            />
          ))}

          {checkForProfileLimit(profiles, userData) && (
            <ProfileSelectorItem newProfile onProfileCreate={onProfileCreate} />
          )}
        </div>

        {isEditMode && (
          <div className={styles.doneBtn}>
            <Button variant="secondary" onClick={onDoneClick}>
              {t('common.done')}
            </Button>
          </div>
        )}
      </div>
    </LinearProfileWrapper>
  );
};
export default ProfilesSelector;

import {
  StrataContext,
  StrataEvents,
  TrackEventData,
} from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import {
  trackPageView,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';
import { selectCurrentProfileUuid, selectUserUid } from 'store/user/selectors';
import { selectCompanyName } from 'store/app/selectors';
import BaseMetricsTracker from 'models/metrics/base';

export const useTracker = () => {
  const userUid = useSelector(selectUserUid);
  const companyName = useSelector(selectCompanyName);
  const currentProfileUuid = useSelector(selectCurrentProfileUuid);
  const trackerModel = useRef(new BaseMetricsTracker());

  useEffect(() => {
    trackerModel.current.init(companyName, userUid, currentProfileUuid);
  }, [companyName, currentProfileUuid, userUid]);

  const trackPageViewEvent = useCallback(
    () =>
      trackPageView({
        context: trackerModel.current.buildUserContext(),
      }),
    []
  );

  const trackNonUserEvent = useCallback(
    <
      T extends keyof StrataEvents,
      C extends keyof StrataContext | void = void
    >({
      event,
      data,
      version,
    }: TrackEventData<T, C>) => {
      const preparedEvent = trackerModel.current.buildNonUserEvent(
        event,
        data,
        version
      );

      trackSelfDescribingEvent(preparedEvent);
    },
    []
  );

  const trackEvent = useCallback(
    <
      T extends keyof StrataEvents,
      C extends keyof StrataContext | void = void
    >({
      event,
      data,
      version,
      eventContext,
    }: TrackEventData<T, C>) => {
      const preparedEvent = trackerModel.current.buildUserEvent(
        event,
        data,
        version,
        eventContext
      );

      trackSelfDescribingEvent(preparedEvent);
    },
    []
  );

  return { trackEvent, trackPageViewEvent, trackNonUserEvent };
};

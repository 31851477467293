import {
  AssetType,
  ContinueWatchingEvent,
  ContinueWatchingItem,
} from '@laminar-product/client-commons-core/core';
import { updateWatchingProgress } from 'actions/watchingProgress';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putElementToContinueWatching } from 'store/app/actions';
import { selectDeviceId } from 'store/app/selectors';
import {
  selectPlayerAsset,
  selectPlayerCurrentFrameIsAd,
  selectPlayerFrames,
  selectPlayerNextEpisode,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL } from './constants';
import {
  checkIfAssetFinished,
  isCloseWatchingSessionEvent,
  isStartWatchingSessionEvent,
} from './player';

/*
    Please note that this hook is responsible for saving continue watching of asset on start, end and during playback

    start - event `loadeddata` on player if player was initialized
    progressed - polling interval
    closed - on hook unmount
*/

const useVideoPlayerContinueWatching = () => {
  const dispatch = useDispatch();
  const videoElement = useSelector(selectPlayerVideoElement);
  const asset = useSelector(selectPlayerAsset);
  const frames = useSelector(selectPlayerFrames);
  const deviceId = useSelector(selectDeviceId);
  const nextEpisode = useSelector(selectPlayerNextEpisode);
  const startedEventFired = useRef(false);
  const isPlayingAds = useSelector(selectPlayerCurrentFrameIsAd);

  const checkIfFinished = useCallback(() => {
    if (!videoElement) return false;

    return checkIfAssetFinished({
      currentTime: videoElement.currentTime,
      duration: videoElement.duration,
      frames,
    });
  }, [frames, videoElement]);

  const onSaveCurrentAssetContinueWatching = useCallback(
    async (event: ContinueWatchingEvent) => {
      if (!asset || !videoElement) {
        return;
      }

      const { id, rootId, parentId } = asset;
      const currentSecond = videoElement.currentTime;
      const finished = checkIfFinished();

      const continueWatchingItem: ContinueWatchingItem = {
        assetUuid: id,
        parentId,
        rootId,
        currentSecond,
        deviceId,
        event,
        finished,
      };

      dispatch(putElementToContinueWatching(continueWatchingItem));
      await updateWatchingProgress(continueWatchingItem, frames || []);
    },
    [asset, checkIfFinished, deviceId, dispatch, frames, videoElement]
  );

  const onSaveNextEpisodeContinueWatching = useCallback(async () => {
    if (!nextEpisode) {
      return;
    }

    const { id, parentId, rootId } = nextEpisode;

    const continueWatchingItem: ContinueWatchingItem = {
      assetUuid: id,
      parentId,
      rootId,
      currentSecond: 1, //To appear in continue watching
      deviceId,
      event: ContinueWatchingEvent.NEXT_INITIALIZED,
    };

    dispatch(putElementToContinueWatching(continueWatchingItem));
    await updateWatchingProgress(continueWatchingItem, frames || []);
  }, [deviceId, dispatch, frames, nextEpisode]);

  const onSaveCurrentAndNextEpisodeContinueWatching = useCallback(
    async (event: ContinueWatchingEvent) => {
      const finished = checkIfFinished();

      if (!finished || !nextEpisode) {
        return await onSaveCurrentAssetContinueWatching(event);
      }

      await onSaveCurrentAssetContinueWatching(event);
      await onSaveNextEpisodeContinueWatching();
    },
    [
      checkIfFinished,
      nextEpisode,
      onSaveCurrentAssetContinueWatching,
      onSaveNextEpisodeContinueWatching,
    ]
  );

  const onSaveContinueWatching = useCallback(
    async (event: ContinueWatchingEvent) => {
      const isStartEvent = isStartWatchingSessionEvent(event);
      const isCloseEvent = isCloseWatchingSessionEvent(event);

      //Don't send events until started event fire; This prevents from closed callbacks from cleanup effects when exiting player
      if (!startedEventFired.current && !isStartEvent) {
        return;
      }

      //Update ref value after start event and after exiting player with close event
      if (isCloseEvent || isStartEvent) {
        startedEventFired.current = isStartEvent;
      }

      switch (asset?.type) {
        case AssetType.MOVIE:
          return onSaveCurrentAssetContinueWatching(event);
        case AssetType.EPISODE:
          return onSaveCurrentAndNextEpisodeContinueWatching(event);
      }
    },
    [
      asset?.type,
      onSaveCurrentAndNextEpisodeContinueWatching,
      onSaveCurrentAssetContinueWatching,
    ]
  );

  //SC expects heartbeat even if player is paused
  const onVideoPlaying = useCallback(() => {
    onSaveContinueWatching(ContinueWatchingEvent.PROGRESSED);
  }, [onSaveContinueWatching]);

  usePolling(onVideoPlaying, {
    interval: VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL,
    shouldRunPolling: !isPlayingAds && !!videoElement,
  });

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    onSaveContinueWatching(ContinueWatchingEvent.STARTED);
  }, [onSaveContinueWatching, videoElement]);

  return {
    onSaveContinueWatching,
    checkIfFinished,
  };
};

export default useVideoPlayerContinueWatching;

import React from 'react';
import { ReactComponent as PaymentFailedIcon } from 'assets/images/payment-failed.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { useHistory, useLocation } from 'react-router-dom';
import useDeferredRedirect from 'utils/useDeferredRedirect';
import styles from './index.module.scss';

const PaymentFailed = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const timeLeft = useDeferredRedirect({ url: '/settings' });
  const tryAgain = () => {
    push('/settings');
  };
  const location = useLocation<{ planError: boolean }>();

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <PaymentFailedIcon className={styles.img} />
      </div>
      <h1 className={styles.heading}>{t('payment.failed.heading')}</h1>
      <p className={styles.description}>
        {t(
          `payment.failed.${
            location?.state?.planError ? 'planError' : 'description'
          }`,
          { interval: timeLeft / 1000 }
        )}
      </p>
      <Button onClick={tryAgain} variant="cta">
        {t('payment.failed.tryAgain')}
      </Button>
    </div>
  );
};

export default PaymentFailed;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import {
  BillingAddress,
  TaxConfiguration,
  TaxEstimation,
  TaxProductTypes,
  mapTaxProduct,
} from '@laminar-product/client-commons-core/core';
import {
  useFetchAction,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import {
  estimateTax,
  getTaxConfiguration,
  saveBillingAddress,
} from 'actions/taxEstimation';
import { Severity } from 'types/errors';
import { billingAddressInitialValues } from 'pages/Register/components/SetPayment/constants';
import { useSelector } from 'react-redux';
import {
  selectRegistrationSelectedPlan,
  selectRegistrationSelectedPrice,
} from 'store/registration/selectors';
import { captureError } from './captureError';

const useTaxBilling = (onBillingAddressSaved: () => void, offerId?: string) => {
  const [billingAddress, setBillingAddress] = useState<BillingAddress>(
    billingAddressInitialValues
  );
  const [isBillingAddressValid, setIsBillingAddressValid] = useState(false);
  const selectedPlan = useSelector(selectRegistrationSelectedPlan);
  const selectedPrice = useSelector(selectRegistrationSelectedPrice);
  const pricePaymentGateway = selectedPrice?.gateway;

  const [taxConfiguration, isLoadingTaxConfiguration] =
    useFetchAction<TaxConfiguration>(
      useCallback(() => {
        if (!pricePaymentGateway) {
          return Promise.reject('No selected payment method');
        }
        return getTaxConfiguration(pricePaymentGateway);
      }, [pricePaymentGateway]),
      useMemo(
        () => ({
          onDone: (data) =>
            data &&
            setBillingAddress((currentState) => ({
              ...currentState,
              country: data.validation.countryCode,
            })),
          onError: (err) =>
            captureError(
              err as AxiosError,
              'PaymentForm/getTaxValidation',
              Severity.Error
            ),
        }),
        [setBillingAddress]
      )
    );

  const {
    sendAction: handleTaxEstimation,
    isLoading: isLoadingTaxEstimation,
    data: taxEstimation,
  } = useSendActionObj<TaxEstimation | undefined, BillingAddress | undefined>(
    useCallback(
      (billingAddress?: BillingAddress) => {
        const product = mapTaxProduct({
          price: selectedPrice,
          productUuid: selectedPlan?.uuid,
          type: TaxProductTypes.PLAN, // For now we only have PLAN product types
        });

        if (!product || !offerId || !pricePaymentGateway) {
          return Promise.resolve(undefined);
        }

        return estimateTax({
          address: billingAddress,
          offerId,
          gateway: pricePaymentGateway,
          products: [product], // It has to be an array because in future we could have multiple products
        });
      },
      [offerId, pricePaymentGateway, selectedPlan, selectedPrice]
    ),
    useMemo(
      () => ({
        onError: (err) =>
          captureError(
            err as AxiosError,
            'PaymentForm/estimateTax',
            Severity.Error
          ),
      }),
      []
    )
  );

  const {
    sendAction: saveBillingAddressAction,
    isLoading: isSavingBillingAddress,
    error: saveBillingAddressError,
  } = useSendActionObj<string | undefined, BillingAddress>(
    useCallback(
      async (billingAddress: BillingAddress) => {
        if (!offerId || !pricePaymentGateway) {
          return Promise.resolve(undefined);
        }
        setBillingAddress(billingAddress);
        const data = await saveBillingAddress({
          address: billingAddress,
          offerId,
          gateway: pricePaymentGateway,
        });
        taxConfiguration?.validation.estimationRequired &&
          handleTaxEstimation(billingAddress);
        return data;
      },
      [handleTaxEstimation, offerId, pricePaymentGateway, taxConfiguration]
    ),
    {
      onDone: useCallback(() => {
        setIsBillingAddressValid(true);
        onBillingAddressSaved();
      }, [onBillingAddressSaved]),
      onError: useCallback(
        (err: Error) =>
          captureError(
            err as AxiosError,
            'PaymentForm/estimateTax',
            Severity.Error
          ),
        []
      ),
    }
  );

  const shouldEstimateTaxWithoutAddress =
    !taxConfiguration?.validation.billingAddressRequired &&
    taxConfiguration?.validation.estimationRequired;

  useEffect(() => {
    if (shouldEstimateTaxWithoutAddress && offerId) {
      handleTaxEstimation(undefined);
    }
  }, [handleTaxEstimation, offerId, shouldEstimateTaxWithoutAddress]);

  return {
    billingAddress,
    taxEstimation,
    taxValidation: taxConfiguration?.validation,
    isBillingAddressValid,
    saveBillingAddress: saveBillingAddressAction,
    saveBillingAddressError,
    isSavingBillingAddress,
    isTaxLoading: isLoadingTaxConfiguration || isLoadingTaxEstimation,
  };
};

export default useTaxBilling;

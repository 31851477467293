import { ReactElement, ReactNode } from 'react';
import { FeatureFlag } from 'utils/constants';
import useFeatureEnabled from './useFeatureEnabled';

interface FeatureEnabledProps {
  featureFlag: FeatureFlag;
  children: ReactNode;
}

const FeatureEnabled = ({ featureFlag, children }: FeatureEnabledProps) => {
  const isFeatureEnabled = useFeatureEnabled(featureFlag);

  return isFeatureEnabled ? (children as ReactElement) : null;
};

export default FeatureEnabled;

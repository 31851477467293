import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useGetSearchParams = () => {
  const { search } = useLocation();

  const searchParams = useMemo(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
      }),
    [search]
  );

  return searchParams;
};

export default useGetSearchParams;

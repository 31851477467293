import Input from 'components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectAppTheme } from 'store/app/selectors';
import useLanguages from 'utils/useLanguages';
import { Profile } from '@laminar-product/client-commons-core/core';
import SettingsDropdownItem from 'components/SettingsDropdownItem';
import cn from 'classnames';
import styles from './index.module.scss';
interface ProfileGeneralDetailsProps {
  type?: 'modal' | 'settings';
}

const ProfileGeneralDetails = ({
  type = 'settings',
}: ProfileGeneralDetailsProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<Profile>();
  const appTheme = useSelector(selectAppTheme);
  const {
    formatLanguageSelectValue,
    selectedLanguage,
    availableLanguageSelectOptions,
  } = useLanguages();
  const isModalVariant = type === 'modal';

  return (
    <>
      {!isModalVariant && (
        <p className={styles.label}>{t('profile.profileName')}</p>
      )}

      <Controller
        render={({ field: { onChange, value } }) => (
          <Input
            id="profileName"
            light={!isModalVariant}
            onChange={onChange}
            placeholder={t('profile.profileName')}
            maxLength={30}
            value={value}
            className={cn(isModalVariant && styles.modalInput)}
          />
        )}
        control={control}
        name="name"
        rules={{ required: t('common.validations.required').toString() }}
      />
      <p className={styles.error}>{errors.name?.message} </p>

      {!isModalVariant && (
        <p className={styles.label}>{t('profile.language')}</p>
      )}

      <Controller
        render={({ field }) => {
          return isModalVariant ? (
            <SettingsDropdownItem
              title={t('settings.appLanguage')}
              options={availableLanguageSelectOptions}
              onChange={(value) => field.onChange(value?.value)}
              selectedOption={formatLanguageSelectValue(field.value)}
            />
          ) : (
            <Select
              options={availableLanguageSelectOptions}
              appTheme={appTheme}
              id="profileLanguage"
              placeholder="Language"
              value={formatLanguageSelectValue(field.value)}
              onChange={(value) => field.onChange(value?.value)}
              useCustomStyles={false}
              type="settings"
            />
          );
        }}
        control={control}
        name="language"
        defaultValue={selectedLanguage}
      />

      {!isModalVariant && (
        <p className={styles.error}>{errors.language?.message} </p>
      )}
    </>
  );
};

export default ProfileGeneralDetails;

import { Asset } from '@laminar-product/client-commons-core/core';

export const SET_ASSET = 'SET_ASSET';
export const setAsset = (id: string, asset: Asset) => ({
  type: SET_ASSET,
  payload: { id, asset },
});

export const SET_ASSETS = 'SET_ASSETS';
export const setAssets = (assets: Asset[]) => ({
  type: SET_ASSETS,
  payload: { assets },
});

export const RESET_ASSETS = 'RESET_ASSETS';
export const resetAssets = () => ({
  type: RESET_ASSETS,
});

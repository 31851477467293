import { ReactComponent as Subtitles } from 'assets/icons/subtitles.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChromecastAvailableTracks,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import PopOver from 'components/PopOver';
import { CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE } from 'utils/constants';
import { ChromecastControllerEvents, Track } from 'store/chromecast/types';
import {
  setChromecastActiveAudioTrackId,
  setChromecastActiveTextTrackId,
  setChromecastAvailableTracks,
} from 'store/chromecast/actions';
import styles from './index.module.scss';
import TrackSelector from './components/TrackSelector';
interface PlaybackSettingsButtonsProps {
  wrapperClassName?: string;
}

const PlaybackSettingsButtons = ({
  wrapperClassName,
}: PlaybackSettingsButtonsProps) => {
  const dispatch = useDispatch();
  const subtitlesElementRef = useRef(null);
  const { t } = useTranslation();
  const castElement = useSelector(selectChromecastCastElement);
  const { isPhoneWindowSize } = useWindowSize();
  const [isSelectorOpen, toggleSelectorOpen] = useState(false);
  const availableTracks = useSelector(selectChromecastAvailableTracks);

  useEffect(() => {
    if (!castElement) {
      return;
    }

    const onMediaInfoChanged = () => {
      if (availableTracks) {
        return;
      }

      const tracks: Track[] | undefined = castElement.getAvailableTracks();

      if (!tracks?.length) {
        return;
      }

      const audioTracks = tracks.filter((t) => t.type === 'AUDIO');
      const textTracks = tracks.filter((t) => t.type === 'TEXT');

      const textOffTrack = {
        name: t('chromecast.off'),
        trackId: CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE, //This value is also read in castjs to detect if subtitles should be turned off
        type: 'TEXT',
      } as Track;

      const textTracksWithOffOption: Track[] = [textOffTrack, ...textTracks];

      dispatch(
        setChromecastAvailableTracks({
          audioTracks,
          textTracks: textTracksWithOffOption,
        })
      );

      dispatch(setChromecastActiveAudioTrackId(audioTracks[0].trackId));
      dispatch(
        setChromecastActiveTextTrackId(CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE)
      );
    };

    castElement._controller.addEventListener(
      ChromecastControllerEvents.MEDIA_INFO_CHANGED,
      onMediaInfoChanged
    );

    return () => {
      castElement._controller.removeEventListener(
        ChromecastControllerEvents.MEDIA_INFO_CHANGED,
        onMediaInfoChanged
      );
    };
  }, [availableTracks, castElement, dispatch, t]);

  return (
    <div className={wrapperClassName}>
      <div
        ref={subtitlesElementRef}
        onMouseLeave={() => toggleSelectorOpen(false)}
      >
        <div className={styles.controlColumn}>
          {availableTracks && (
            <Subtitles
              className={styles.subtitlesIcon}
              onMouseEnter={() => toggleSelectorOpen(true)}
            />
          )}

          {isPhoneWindowSize && !!availableTracks && (
            <span>{t('chromecast.audioAndSubtitles')}</span>
          )}
        </div>

        <PopOver
          contentClassName={styles.tracksPopover}
          anchorEl={subtitlesElementRef.current}
          isOpen={isSelectorOpen}
          onClose={() => toggleSelectorOpen(false)}
          placement={isPhoneWindowSize ? 'top' : 'top-end'}
        >
          {availableTracks && (
            <TrackSelector availableTracks={availableTracks} />
          )}
        </PopOver>
      </div>
    </div>
  );
};

export default PlaybackSettingsButtons;

import React from 'react';
import { Filters } from 'types/filters';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import FilterList from './FilterList';

const FilterModal = ({
  onSelectFilters,
  selectedFilters,
  initialFilters,
  onModalClose,
  onApply,
  moods,
}: {
  onSelectFilters: (data: Filters) => void;
  onApply: () => void;
  onModalClose: () => void;
  selectedFilters: Filters;
  initialFilters: Filters;
  moods?: string[];
}) => {
  const { t } = useTranslation();
  const checkIfExist = (query: string, label: keyof Filters): boolean =>
    selectedFilters[label].includes(query);

  const updateFilters = (query: string, label: keyof Filters) =>
    onSelectFilters({
      ...selectedFilters,
      [label]: checkIfExist(query, label)
        ? selectedFilters[label].filter((s) => s !== query)
        : [...selectedFilters[label], query],
    });

  const clearFilters = () => {
    onModalClose();
    onSelectFilters(initialFilters);
  };
  return (
    <Modal wrapperId="filters" isOpen>
      <div className={styles.modalWrapper}>
        <div className={styles.modalCard}>
          <div className={styles.modalHeader}>
            <h2 className={styles.modalTitle}>{t('filter.filters')}</h2>
            <CloseIcon
              className={styles.close}
              onClick={() => clearFilters()}
            />
          </div>
          <div className={styles.modalBody}>
            <FilterList
              title={t('filter.mood')}
              data={moods}
              translationPrefix={'mood'}
              selectedFilters={selectedFilters.mood}
              onSelect={(mood) => updateFilters(mood, 'mood')}
            />
          </div>
          <div className={styles.modalFooter}>
            <Button variant="secondary" onClick={() => clearFilters()}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" onClick={() => onApply()}>
              {t('common.apply')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FilterModal;

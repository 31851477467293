import {
  Asset,
  ContentType,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';
import { t } from 'i18next';

export const calculateWatchingPercent = ({
  progress,
  duration,
}: {
  progress: number;
  duration: number;
}) => Math.floor((progress / duration) * 100);

export const calculateWatchingProgress = ({
  progress,
}: {
  progress: number;
}) => {
  const h = Math.floor(progress / 3600);
  const m = Math.round((progress % 3600) / 60);

  const hDisplay = h > 0 ? t('time.hourWithCount', { count: h }) : '';
  const mDisplay =
    m > 0
      ? t('time.minuteWithCount', { count: m })
      : t('time.minuteWithCount', { count: 1 });

  return hDisplay + ' ' + mDisplay;
};

export const shouldDisplayAssetInContinueWatching = (asset?: Asset) => {
  return (
    !!asset &&
    !!asset.progress &&
    asset.progress > 0 &&
    !asset.planUpgradeRequired &&
    !!getAssetContentWithType(asset, ContentType.MAIN)
  );
};

import {
  AssetType,
  PageComponentType,
  PaymentGateway,
  PlaybackSettings,
  defaultDetailsPath,
  livePath,
  moviePath,
  showPath,
} from '@laminar-product/client-commons-core/core';
import { CarouselType, getCarouselTypeMinHeight } from './carouselHelpers';

export const colors = {
  error: '#e74c3c',
};

export const collectionPagePath = '/list/:name/:id';
export const listingPagePath = '/list/:id';
export const watchMovieContentPath = `${moviePath}/watch/:contentId`;
export const watchShowContentPath = `${showPath}/watch/:contentId`;
export const watchMoviePath = `${moviePath}/watch`;
export const watchShowPath = `${showPath}/watch`;
export const watchLivePath = `${livePath}/watch`;
export const watchLiveContentPath = `${livePath}/watch/:contentId`;
export const watchDefaultPath = `${defaultDetailsPath}/watch`;
export const pagePath = '/:name/:id';
export const settingsPath = '/settings';

export const upgradePlanParamId = 'id';
export const upgradePlanParamType = 'type';
export const upgradePlanParamName = 'name';

export const EPISODE_SHOW_URL_TYPE = AssetType.SHOW;

export const defaultAssetSearchLimit = 30;

export enum FeatureFlag {
  showRegistration = 'showRegistration',
}

export enum FirebaseAuthCodes {
  EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  INVALID_OOB_CODE = 'auth/invalid-action-code',
  INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
  INVALID_VERIFICATION_CODE_CUSTOM_PROVIDER = 'error/invalid_mtalkz_otp_verification',
  EXPIRED_CODE = 'auth/code-expired',
  USER_EXIST = 'user/duplicated',
  INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  ACCOUNT_EXISTS = 'auth/email-already-in-use',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
}

//set for now - consulted with the backend
//when backend will return the maturityRating list it will be changed
export const MATURITY_KIDS_VALUE = 7;
export const DEFAULT_MATURITY_VALUE = 21;
export const PROFILE_EDIT_ITEMS_KEYS: (keyof PlaybackSettings)[] = [
  'playNextEpisode',
];
export const MATURITY_VALUES_ALL_RATINGS = [DEFAULT_MATURITY_VALUE];

export enum Resolution {
  HD = 720,
  FHD = 1080,
  UHD = 2160,
}

export const SESSION_STORAGE_NOTIFICATION_KEY = 'notifications';
export const LOCAL_STORAGE_CURRENT_PROFILE_UUID = 'currentProfileUuid';
export const LOCAL_STORAGE_I18NEXT_LANGUAGE_KEY = 'i18nextLng';
export const NOTIFICATION_LIMIT_COUNT = 3;

export const ASSET_TYPES_ROOT_REDIRECT = [AssetType.EPISODE, AssetType.SEASON];
export const ASSET_TYPES_POSTER_ACTION_BUTTONS = [
  AssetType.SHOW,
  AssetType.MOVIE,
  AssetType.LIVE_CHANNEL,
  AssetType.LIVE_EVENT,
];
export const ASSET_TYPES_POSTER_WATCH_BUTTON = [
  AssetType.MOVIE,
  AssetType.EPISODE,
  AssetType.LIVE_CHANNEL,
  AssetType.LIVE_EVENT,
];

export const PIN_ALREADY_REDEEMED_ERROR_CODE = 'gift_card_already_redeemed';
export const PIN_INVALID_DATE = 'gift_card_invalid_redemption_date';
export const PROFILE_VERIFY_OTP_LENGTH = 6;
export const TOO_MANY_REQUESTS_MESSAGE = 'TOO_MANY_ATTEMPTS_TRY_LATER';
export const REGISTER_FROM_TV_URLS = [
  '/web-tv/register',
  '/android-tv/register',
];

export enum SocialAuthenticationProviders {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

export enum CustomAuthenticationProviders {
  EMAIL_PASSWORD = 'EMAIL_PASSWORD',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export const WEB_CAROUSELS_TYPES = [
  PageComponentType.CAROUSEL,
  PageComponentType.CONTINUE_WATCHING,
  PageComponentType.PORTRAIT_CAROUSEL,
  PageComponentType.PORTRAIT_EXPANDABLE_CAROUSEL,
  PageComponentType.LARGE_PORTRAIT_CAROUSEL,
];

export enum ATTRIBUTED_CHECKOUT_ERRORS {
  ATTRIBUTED_CHECKOUT_PROCESSING_ERROR_CODE = 'attributed-checkout/already_processing',
  ATTRIBUTED_CHECKOUT_AUTONOMIC_GATEWAY_ERROR_CODE = 'subscription/can_not_modify_autonomic_gateway',
  ATTRIBUTED_CHECKOUT_INVALID_CODE_ERROR = 'attributed-checkout/code_does_not_exist',
}

export const DEFAULT_PLAN_CAROUSEL_ITEM_WIDTH = 285;

export const JUMBOTRON_COMPONENTS_TYPES = [
  PageComponentType.FEATURED_ASSET,
  PageComponentType.JUMBOTRON,
];

export const NEXT_EPISODE_INTERVAL_TIME = 10;
export const NEXT_EPISODE_INITIAL_PROGRESS_VALUE =
  NEXT_EPISODE_INTERVAL_TIME * 1000;

export const JUMBOTRON_DESCRIPTION_MAX_LENGTH = 160;

export const VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY = 'com.widevine.alpha';
export const VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY = 'com.apple.fps';
export const VIDEO_PLAYER_SEEK_TIME = 10;
export const VIDEO_PLAYER_CONTINUE_WATCHING_INTERVAL = 60000;

export const CUSTOM_CHANGE_PAYMENT_METHOD_GATEWAYS = [
  PaymentGateway.CCBILL,
  PaymentGateway.RAZORPAY,
];

export const CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE = -1;
export const CHROMECAST_TRACK_LABEL_MAX_LENGTH = 10;
export const CHROMECAST_DEFAULT_IS_ALLOWED_TO_USE = true;
export const DEFAULT_USER_LANGUAGE = 'en';

export enum PlanDetailsVariant {
  PREPAID = 'PREPAID',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export const PLAYER_OVERLAY_TIMEOUT = 1500;
export const PAGE_COMPONENTS_PAGINATION_LIMIT = 5;
export const PAGE_COMPONENTS_FOOTER_GAP =
  250 + getCarouselTypeMinHeight(CarouselType.CAROUSEL); //Footer + Skeleton

export const ALL_GENRES_SELECTOR_OPTION = 'ALL_GENRES';

export const DEFAULT_SEO_DESCRIPTION =
  'Dive into the latest OTT platform for exclusive web series, movies, & more! Unlimited entertainment awaits you! Stream your favorites now!';

import {
  AssetPlaylist,
  ContentType,
  getInitialPlaylistStartTime,
} from '@laminar-product/client-commons-core/core';
import { getAssetWatchingProgress } from 'actions/watchingProgress';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putElementToContinueWatching } from 'store/app/actions';
import { Severity } from 'types/errors';
import { selectAssetWatchingProgress } from 'store/app/selectors';
import { captureError } from './captureError';

interface GetVideoPlayerStartTimeProps {
  contentType: ContentType;
  initialPlaylist?: AssetPlaylist;
}

const useVideoPlayerStartTime = (assetId: string) => {
  const dispatch = useDispatch();
  const assetProgress = useSelector(selectAssetWatchingProgress(assetId));

  const getVideoPlayerStartTime = useCallback(
    async ({ contentType, initialPlaylist }: GetVideoPlayerStartTimeProps) => {
      if (contentType !== ContentType.MAIN) {
        return 0;
      }

      const startTime = getInitialPlaylistStartTime(initialPlaylist);

      if (assetProgress) {
        return assetProgress.finished ? 0 : startTime;
      }

      try {
        const progress = await getAssetWatchingProgress(assetId);
        dispatch(putElementToContinueWatching(progress));

        return progress.finished ? 0 : startTime;
      } catch (error) {
        captureError(
          error as any,
          'hooks/useVideoPlayerStartTime/getVideoPlayerStartTime',
          Severity.Warning,
          { extra: { assetId } }
        );
      }

      return 0;
    },
    [assetId, assetProgress, dispatch]
  );

  return getVideoPlayerStartTime;
};

export default useVideoPlayerStartTime;

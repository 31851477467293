import React from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface GoBackLinkProps {
  text: string;
  onClick: () => void;
  className?: string;
}
const GoBackLink = ({ text, onClick, className }: GoBackLinkProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.backContainer, className)} onClick={onClick}>
      <ArrowBackIcon className={styles.backIcon} />
      <p className={styles.backText}>{t(text)}</p>
    </div>
  );
};

export default GoBackLink;

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssets } from 'store/assets/selectors';
import {
  addAssetToWatchlist,
  removeAssetFromWatchlist,
} from 'store/watchlist/actions';
import {
  selectWatchlistLoadingState,
  selectWatchlistUuids,
} from 'store/watchlist/selectors';
import {
  addToWatchlist as addToWatchlistApi,
  removeFromWatchlist as removeFromWatchlistApi,
} from 'actions/likes';
import { Severity } from 'types/errors';
import { getAsset } from 'actions/assets';
import { setAsset } from 'store/assets/actions';
import { captureError } from './captureError';

// This hook is a handler for watchlist actions and source of watchlist assets. Fetching and preparing watchlist is handled by usePrepareWatchlist hook initialized in the App component

const useWatchlist = () => {
  const watchlistLoadingState = useSelector(selectWatchlistLoadingState);
  const watchlistUuids = useSelector(selectWatchlistUuids);
  const assets = useSelector(selectAssets);
  const dispatch = useDispatch();

  const watchlistAssets = useMemo(
    () =>
      watchlistUuids?.map((uuid) => assets?.[uuid])?.filter((asset) => !!asset),
    [assets, watchlistUuids]
  );

  const isOnWatchlist = useCallback(
    (uuid: string) => watchlistUuids?.includes(uuid) || false,
    [watchlistUuids]
  );

  const addToWatchlist = useCallback(
    async (uuid: string) => {
      dispatch(addAssetToWatchlist(uuid));

      try {
        await addToWatchlistApi({ id: uuid });

        //Load asset details if not fetched before (eg in posters) so it can be displayed in watchlist.
        const assetInState = assets?.[uuid];

        if (!assetInState) {
          const asset = await getAsset(uuid);
          dispatch(setAsset(uuid, asset));
        }
      } catch (error) {
        captureError(
          error as any,
          'useWatchlist/addToWatchlist',
          Severity.Error
        );
      }
    },
    [assets, dispatch]
  );

  const removeFromWatchlist = useCallback(
    async (uuid: string) => {
      dispatch(removeAssetFromWatchlist(uuid));

      try {
        await removeFromWatchlistApi({ id: uuid });
      } catch (error) {
        captureError(
          error as any,
          'useWatchlist/removeFromWatchlist',
          Severity.Error
        );
      }
    },
    [dispatch]
  );

  return {
    watchlistLoadingState,
    watchlistAssets,
    watchlistActions: {
      isOnWatchlist,
      addToWatchlist,
      removeFromWatchlist,
    },
  };
};

export default useWatchlist;

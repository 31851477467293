import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ProgressIndicator } from '@laminar-product/client-commons-core/web';
import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { getUserData } from 'actions/basic';
import { ReactComponent as PaymentInProgressIcon } from 'assets/images/payment-in-progress.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getPaymentStatus } from 'actions/payments';
import { PaymentStatus, PaymentStatusData } from 'types/payment';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import { maxCallsCount, maxTimerTime, pollingInterval } from 'utils/polling';
import { useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import useCountdown, { secondsToMinutesLabel } from 'utils/useCountdown';
import styles from './index.module.scss';
import PaymentAttributedCheckout from './PaymentAttributedCheckout';

const PaymentInProgress = () => {
  const [statusData, setStatusData] = useState<PaymentStatusData | undefined>();
  const [paymentStatusCallsCount, setPaymentStatusCallsCount] = useState(0);
  const [userDataCallsCount, setUserDataCallsCount] = useState(0);
  const paymentId = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('session_id');
  }, []);
  const userDataInterval = useRef<NodeJS.Timeout | null>(null);
  const { push } = useHistory();
  const { t } = useTranslation();
  const isAnonymous = useSelector(selectIsAnonymous);
  const shouldAvoidFetchingPaymentStatus = isAnonymous;
  const { countdown, shouldDisplayCounter } = useCountdown(maxTimerTime);

  const fetchPaymentStatus = async () => {
    if (!paymentId || shouldAvoidFetchingPaymentStatus) return;

    try {
      const statusData = await getPaymentStatus(paymentId);
      setStatusData(statusData);
      setPaymentStatusCallsCount((prevCount) => prevCount + 1);
    } catch (err) {
      captureError(
        err as AxiosError,
        'PaymentInProgress/getPaymentStatus',
        Severity.Error
      );
    }
  };

  const cancelPaymentStatusPolling = usePolling(fetchPaymentStatus, {
    interval: pollingInterval,
    shouldRunPolling: !shouldAvoidFetchingPaymentStatus,
  });

  // Clear intervals on unmount
  useEffect(() => {
    return () => {
      cancelPaymentStatusPolling();
      userDataInterval.current && clearInterval(userDataInterval.current);
    };
  }, [cancelPaymentStatusPolling, userDataInterval]);

  // Track max calls for payment status
  useEffect(() => {
    if (paymentStatusCallsCount >= maxCallsCount) {
      push('/register/payment/failed');
    }
  }, [paymentStatusCallsCount, push]);

  // Track max calls for user data
  useEffect(() => {
    if (userDataCallsCount >= maxCallsCount) {
      push('/register/payment/failed', { planError: true });
    }
  }, [push, userDataCallsCount, userDataInterval]);

  const checkForSubscription = useCallback(async () => {
    try {
      const { data: userData } = await getUserData();

      if (userData?.plan?.uuid) {
        push('/register/payment/success');
      }
      setUserDataCallsCount((prevCount) => prevCount + 1);
    } catch (err) {
      captureError(
        err as AxiosError,
        'PaymentInProgress/checkForSubscription',
        Severity.Error
      );
    }
  }, [push]);

  useEffect(() => {
    if (
      !statusData ||
      statusData?.status === PaymentStatus.CREATED ||
      shouldAvoidFetchingPaymentStatus
    )
      return;

    cancelPaymentStatusPolling();

    if (statusData.status === PaymentStatus.PAYED) {
      userDataInterval.current = setInterval(
        async () => await checkForSubscription(),
        pollingInterval
      );
    }

    if (statusData.status === PaymentStatus.FAILED)
      push('/register/payment/failed');
  }, [
    cancelPaymentStatusPolling,
    push,
    statusData,
    checkForSubscription,
    shouldAvoidFetchingPaymentStatus,
  ]);

  if (shouldAvoidFetchingPaymentStatus) {
    return <PaymentAttributedCheckout />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <PaymentInProgressIcon className={styles.img} />
        <div className={styles.loaderWrapper}>
          <ProgressIndicator variant="circular" className={styles.loader} />
          {shouldDisplayCounter && (
            <span className={styles.counter}>
              {secondsToMinutesLabel(countdown)}
            </span>
          )}
        </div>
      </div>
      <h1 className={styles.heading}>
        {shouldDisplayCounter
          ? t('payment.inProgress.heading')
          : t('payment.inProgress.timeoutHeader')}
      </h1>

      {!shouldDisplayCounter && (
        <p className={styles.timeoutMessage}>
          {t('payment.inProgress.timeoutMessage')}
        </p>
      )}

      <p className={styles.restrictionMessages}>
        {t('payment.inProgress.dontRefreshMessage')} {<br />}
        {t('payment.inProgress.dontInitNewPaymentMessage')}
      </p>
    </div>
  );
};

export default PaymentInProgress;

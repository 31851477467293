import { selectAsset } from 'store/assets/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAsset } from 'store/assets/actions';
import { getAsset } from 'actions/assets';
import { Severity } from 'types/errors';
import assetModelFactory from 'models/asset';
import { captureError } from './captureError';

export const useAsset = (id?: string) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAsset(id));
  const [assetFetchingError, setAssetFetchingError] = useState(false);

  useEffect(() => {
    if (asset || !id) {
      return;
    }

    getAsset(id)
      .then((asset) => {
        dispatch(setAsset(id, asset));
      })
      .catch((e) => {
        captureError(e, 'useAsset/getAsset', Severity.Error);
        setAssetFetchingError(true);
      });
  }, [asset, dispatch, id]);

  return {
    asset,
    assetFetchingError,
    assetModel: asset ? assetModelFactory(asset) : undefined,
  };
};

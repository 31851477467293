import React from 'react';
import cn from 'classnames';
import { SettingSwitchItemType } from 'types/profiles';
import 'rc-switch/assets/index.css';
import Switch, { SwitchTheme } from 'components/Switch';
import styles from './index.module.scss';

interface SettingsSwitchItemProps {
  title: string;
  subTitle?: string;
  checked: boolean;
  handleToggle: (value: boolean) => void;
  noBorder?: boolean;
  type?: SettingSwitchItemType;
  isLoading?: boolean;
  theme?: SwitchTheme;
  className?: string;
}

const SettingsSwitchItem = ({
  checked,
  handleToggle,
  title,
  subTitle,
  noBorder,
  type = SettingSwitchItemType.LIST,
  isLoading,
  theme = 'base',
  className,
}: SettingsSwitchItemProps) => {
  return (
    <div
      className={cn(styles.row, className, {
        [styles.noBorder]: noBorder,
        [styles.disabled]: isLoading,
      })}
      onClick={() => handleToggle(!checked)}
    >
      <div className={styles.metadataContainer}>
        <span
          className={cn(styles.title, {
            [styles.titleModal]: type === SettingSwitchItemType.MODAL,
          })}
        >
          {title}
        </span>
        {subTitle && (
          <span
            className={cn(styles.subTitle, {
              [styles.subTitleModal]: type === SettingSwitchItemType.MODAL,
            })}
          >
            {subTitle}
          </span>
        )}
      </div>
      <div className={styles.switchContainer}>
        <Switch checked={checked} theme={theme} />
      </div>
    </div>
  );
};

export default SettingsSwitchItem;

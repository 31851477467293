import { useCallback, useEffect, useState } from 'react';
import { Modal } from '@laminar-product/client-commons-core/web';
import {
  selectChromecastAsset,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import useChromecastContinueWatching from 'utils/useChromecastContinueWatching';
import useChromecastNextEpisode from 'utils/useChromecastNextEpisode';
import { ChromecastControllerEvents } from 'store/chromecast/types';
import MinimizedRemoteController from './components/MinimizedRemoteController';
import FullRemoteController from './components/FullRemoteController';
import { BasicRemoteControllerProps } from './types';
import styles from './index.module.scss';

const ChromecastRemoteController = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPlayingAds, setIsPlayingAds] = useState(true);
  const castElement = useSelector(selectChromecastCastElement);
  const asset = useSelector(selectChromecastAsset);
  useChromecastContinueWatching();
  useChromecastNextEpisode();

  const onChromecastDisconnect = useCallback(() => {
    castElement?.disconnect();
  }, [castElement]);

  useEffect(() => {
    if (!castElement) {
      return;
    }

    const onMediaInfoChanged = () => {
      //TODO: BreakId is undefined when player is initializing - rethink how to handle that
      const breakId = castElement.getCurrentBreakId();

      //CastElement has breakId when playing ad
      if (!!breakId !== isPlayingAds) {
        setIsPlayingAds(!!breakId);
      }
    };

    castElement._controller.addEventListener(
      ChromecastControllerEvents.MEDIA_INFO_CHANGED,
      onMediaInfoChanged
    );

    return () => {
      castElement._controller.removeEventListener(
        ChromecastControllerEvents.MEDIA_INFO_CHANGED,
        onMediaInfoChanged
      );
    };
  }, [castElement, isPlayingAds]);

  const commonProps: BasicRemoteControllerProps = {
    setIsMinimized,
    onChromecastDisconnect,
    isPlayingAds,
  };

  if (!asset || !castElement) {
    return null;
  }

  return (
    <Modal
      wrapperId="ChromecastRemoteController"
      className={cn(isMinimized && styles.minimizedChromecastModal)}
      isOpen
    >
      {isMinimized ? (
        <MinimizedRemoteController {...commonProps} />
      ) : (
        <FullRemoteController {...commonProps} />
      )}
    </Modal>
  );
};

export default ChromecastRemoteController;

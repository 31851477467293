import { useCallback, useEffect, useRef, useState } from 'react';
import { Content } from '@laminar-product/client-commons-core/core';
import { Severity } from 'types/errors';
import { usePrevious } from '@laminar-product/client-commons-core/hooks';
import isEqual from 'lodash/isEqual';
import { AxiosError } from 'axios';
import { setPlayerError } from 'store/player/actions';
import { useDispatch } from 'react-redux';
import {
  ShakaPlayerConfigurationOptions,
  VideoPlayerLoadConfig,
  VideoPlayerLoadConfigVariant,
  VideoPlayerLoadingState,
} from '../library/VideoPlayer/types';
import useVideoPlayerQuality from './useVideoPlayerQuality';
import useVideoPlayerPlayback from './useVideoPlayerPlayback';
import { captureError } from './captureError';
import useVideoPlayerStartTime from './useVideoPlayerStartTime';
import { getPlayerOptions } from './drm';

interface UseVideoPlayerConfigurationProps {
  content: Content | null;
  assetId: string;
  variant?: VideoPlayerLoadConfigVariant;
}

const useVideoPlayerConfiguration: (
  props: UseVideoPlayerConfigurationProps
) => VideoPlayerLoadConfig | undefined = ({ assetId, content, variant }) => {
  const configurationLoadingRef = useRef<VideoPlayerLoadingState>('idle'); //Hacky solution for strange rerenders to load configuration only
  const quality = useVideoPlayerQuality();
  const [playerLoadConfiguration, setPlayerLoadConfiguration] =
    useState<VideoPlayerLoadConfig>();
  const prepareVideoPlayerConfig = useVideoPlayerPlayback();
  const getVideoPlayerStartTime = useVideoPlayerStartTime(assetId);
  const prevContentSlug = usePrevious(content?.slug);
  const dispatch = useDispatch();

  const loadPlayerConfiguration = useCallback(async () => {
    if (!quality || !content) {
      return;
    }

    try {
      configurationLoadingRef.current = 'loaded';

      const assetPlayback = await prepareVideoPlayerConfig({
        assetId,
        content,
      });

      if (!assetPlayback) {
        return;
      }

      const { maxHeight, maxWidth } = quality;
      const {
        source,
        drmCertificateUrl,
        drmLicenseUrl,
        frames,
        playbackId,
        playlist,
      } = assetPlayback;

      if (!source) {
        return;
      }

      const options: ShakaPlayerConfigurationOptions = getPlayerOptions(
        {
          maxHeight,
          maxWidth,
        },
        drmLicenseUrl,
        drmCertificateUrl
      );

      const startTime = await getVideoPlayerStartTime({
        contentType: content.type,
        initialPlaylist: assetPlayback?.playlist?.[0],
      });

      const configuration: VideoPlayerLoadConfig = {
        options,
        startTime,
        sourceUrl: source.src,
        frames,
        playbackId,
        playlist,
        variant,
      };

      setPlayerLoadConfiguration(configuration);
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const data = (error as AxiosError).response?.data;
        const errorCode: string = data?.reasonCode || data?.code;
        dispatch(setPlayerError(errorCode));
      }
      captureError(
        error as any,
        'useVideoPlayerConfiguration/loadConfiguration',
        Severity.Error
      );
    }
  }, [
    assetId,
    content,
    dispatch,
    getVideoPlayerStartTime,
    prepareVideoPlayerConfig,
    quality,
    variant,
  ]);

  useEffect(() => {
    if (configurationLoadingRef.current === 'loaded') {
      return;
    }

    loadPlayerConfiguration();
  }, [loadPlayerConfiguration]);

  //Hacky solution for non-unmounting jumbotron when changing pages
  useEffect(() => {
    if (
      configurationLoadingRef.current === 'loaded' &&
      prevContentSlug &&
      !isEqual(content?.slug, prevContentSlug)
    ) {
      loadPlayerConfiguration();
    }
  }, [content?.slug, loadPlayerConfiguration, prevContentSlug]);

  return playerLoadConfiguration;
};

export default useVideoPlayerConfiguration;

import { useDispatch, useSelector } from 'react-redux';
import { setDeviceId } from 'store/app/actions';
import { selectDeviceId } from 'store/app/selectors';
import { v4 as uuid } from 'uuid';

export const LOCAL_STORAGE_DEVICE_ID_KEY = 'LOCAL_STORAGE_DEVICE_ID_KEY';

const useDeviceId = () => {
  const deviceId = useSelector(selectDeviceId);
  const dispatch = useDispatch();

  if (!deviceId) {
    const localStorageDeviceId = localStorage.getItem(
      LOCAL_STORAGE_DEVICE_ID_KEY
    );

    if (localStorageDeviceId) {
      dispatch(setDeviceId(localStorageDeviceId));
      return;
    }

    const generatedDeviceId = uuid();
    localStorage.setItem(LOCAL_STORAGE_DEVICE_ID_KEY, generatedDeviceId);
    dispatch(setDeviceId(generatedDeviceId));
  }
};

export default useDeviceId;

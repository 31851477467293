import {
  BillingAddress,
  getCountryByCode,
} from '@laminar-product/client-commons-core/core';
import { Button } from '@laminar-product/client-commons-core/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface BillingAddressProps {
  billingAddress: BillingAddress;
  openModal: () => void;
  isValid: boolean;
}

const BillingFormContainer = ({
  openModal,
  billingAddress,
  isValid,
}: BillingAddressProps) => {
  const { t } = useTranslation();

  const renderAddressValue = (value?: string) =>
    value && <span className={styles.addressLabel}>{value}</span>;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>{t('orderSummary.billingForm.title')}</span>
        </div>
        {isValid ? (
          <div className={styles.wrapper}>
            <div className={styles.billingAddressWrapper}>
              {renderAddressValue(billingAddress.line1)}
              {renderAddressValue(billingAddress.line2)}
              <span>
                {renderAddressValue(billingAddress.state)}
                {billingAddress.state && ','}
                {renderAddressValue(billingAddress.city)}
              </span>
              {renderAddressValue(billingAddress.postalCode)}
              {renderAddressValue(
                getCountryByCode(billingAddress.country)?.name ||
                  billingAddress.country
              )}
            </div>
            <span className={styles.changeButton} onClick={openModal}>
              {t('orderSummary.billingForm.change')}
            </span>
          </div>
        ) : (
          <div className={styles.wrapper}>
            <Button
              className={styles.addButton}
              variant="cta"
              onClick={openModal}
              data-testid="BillingForm__AddBillingInfoButton"
            >
              + {t('orderSummary.billingForm.add')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default BillingFormContainer;

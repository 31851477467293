import { AnyAction } from 'redux';
import {
  ADD_ASSET_TO_WATCHLIST,
  REMOVE_ASSET_FROM_WATCHLIST,
  RESET_WATCHLIST,
  SET_WATCHLIST_LOADING_STATE,
  SET_WATCHLIST_UUIDS,
} from './actions';
import { WatchlistLoadingState } from './types';

export interface WatchlistState {
  watchlistUuids?: string[];
  watchlistLoadingState: WatchlistLoadingState;
}

const watchlistReducer = (
  state: WatchlistState = {
    watchlistUuids: [],
    watchlistLoadingState: 'loading',
  },
  action: AnyAction
): WatchlistState => {
  switch (action.type) {
    case SET_WATCHLIST_UUIDS:
      return {
        ...state,
        watchlistUuids: action.payload,
      };
    case SET_WATCHLIST_LOADING_STATE:
      return {
        ...state,
        watchlistLoadingState: action.payload,
      };
    case ADD_ASSET_TO_WATCHLIST:
      return {
        ...state,
        watchlistUuids: [...(state.watchlistUuids || []), action.payload],
      };
    case REMOVE_ASSET_FROM_WATCHLIST:
      return {
        ...state,
        watchlistUuids: state.watchlistUuids?.filter(
          (uuid) => uuid !== action.payload
        ),
      };
    case RESET_WATCHLIST: {
      return { watchlistLoadingState: 'loading', watchlistUuids: [] };
    }

    default:
      return state;
  }
};

export default watchlistReducer;

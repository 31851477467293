import { Asset, AssetType } from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import {
  selectChromecastBuffering,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import SeekBar from 'components/SeekBar';
import PlaybackActionsButtons from 'components/ChromecastControllerComponents/PlaybackActionsButtons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import PlaybackSettingsButtons from 'components/ChromecastControllerComponents/PlaybackSettingsButtons';
import { ChromecastControllerEvents } from 'store/chromecast/types';
import cn from 'classnames';
import { isLive } from '@laminar-product/client-commons-core/web';
import PlayerLiveIndicator from 'components/PlayerLiveIndicator';
import { shouldDisableChromecastSeek } from 'components/ChromecastRemoteController/helpers';
import TimeInfoLabel from '../TimeInfoLabel';
import styles from './index.module.scss';
interface FullRemoteControlsProps {
  asset: Asset;
  isPlayingAds?: boolean;
}

interface PlayerCurrentTimeState {
  time: number;
  timeFormatted: string;
}

const FullRemoteControls = ({
  asset,
  isPlayingAds,
}: FullRemoteControlsProps) => {
  const isEpisode = asset.type === AssetType.EPISODE;
  const castElement = useSelector(selectChromecastCastElement);
  const [playerCurrentTime, setPlayerCurrentTime] =
    useState<PlayerCurrentTimeState>();
  const { t } = useTranslation();
  const { isPhoneWindowSize } = useWindowSize();
  const buffering = useSelector(selectChromecastBuffering);
  const shouldDisableSeek = shouldDisableChromecastSeek({
    asset,
    isPlayingAds,
  });

  /* When component is mounting we need to take current time from ref until timeupdate event will update local state */
  const timeFormattedLabel =
    playerCurrentTime?.timeFormatted || castElement?.timePretty || '-';

  const timeValue = playerCurrentTime?.time || castElement?.time || 0;

  useEffect(() => {
    if (!castElement) {
      return;
    }

    //CastJS has incorrect handling of listeners when multiple callbacks are passed; Need to attach listener directly to cast controller

    const onTimeUpdate = () => {
      const { time, timePretty } = castElement;
      setPlayerCurrentTime({ time, timeFormatted: timePretty });
    };

    castElement._controller?.addEventListener(
      ChromecastControllerEvents.CURRENT_TIME_CHANGED,
      onTimeUpdate
    );

    return () => {
      castElement._controller?.removeEventListener(
        ChromecastControllerEvents.CURRENT_TIME_CHANGED,
        onTimeUpdate
      );
    };
  });

  if (!castElement) {
    return null;
  }

  return (
    <div className={styles.bottomControlsWrapper}>
      {isPhoneWindowSize && (
        <PlaybackSettingsButtons
          wrapperClassName={styles.phoneSettingsButtons}
        />
      )}

      <div
        className={cn(styles.metadata, {
          [styles.metadataAds]: shouldDisableSeek,
        })}
      >
        <span className={styles.title}>
          {isEpisode ? asset.parent?.name : asset.name}
        </span>
        {isEpisode ? (
          <span className={styles.subtitle}>
            {t('asset.episodeShort')}
            {asset.parent?.order}
            {t('asset.seasonShort')}
            {asset.order}: {asset.name}
          </span>
        ) : null}

        {isLive(asset) && (
          <PlayerLiveIndicator className={styles.fullControllerLiveIndicator} />
        )}
      </div>

      {!shouldDisableSeek ? (
        <>
          <div className={styles.timeline}>
            <SeekBar
              playerCurrentTime={timeValue}
              onEndSeeking={(time) => {
                castElement.seek(time);
                castElement.play();
              }}
              onStartSeeking={() => castElement.pause()}
              maxValue={castElement.duration}
              buffering={buffering}
              disabled={buffering || shouldDisableSeek}
            />

            {isPhoneWindowSize && (
              <TimeInfoLabel
                timeLabel={timeFormattedLabel}
                durationLabel={castElement.durationPretty}
                className={styles.phoneTimeLabel}
              />
            )}
          </div>

          <div className={styles.actionButtonsWrapper}>
            {!isPhoneWindowSize && (
              <TimeInfoLabel
                timeLabel={timeFormattedLabel}
                durationLabel={castElement.durationPretty}
              />
            )}

            <PlaybackActionsButtons />

            {!isPhoneWindowSize && <PlaybackSettingsButtons />}
          </div>
        </>
      ) : (
        <div className={styles.actionButtonsWrapper}>
          <PlaybackActionsButtons
            isPlayingAds={isPlayingAds}
            wrapperClassName={styles.playbackButtonsAds}
          />
        </div>
      )}
    </div>
  );
};

export default FullRemoteControls;

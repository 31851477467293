import { SerializedMenu } from '@laminar-product/client-commons-core/core';
import { NavLink } from 'react-router-dom';
import { getPageLink } from 'utils/routing';

interface MenuLinkProps {
  menuItem: SerializedMenu;
  className?: string;
  activeClassName?: string;
}

const MenuLink = ({ menuItem, activeClassName, className }: MenuLinkProps) =>
  menuItem.type === 'EXTERNAL_LINK' && menuItem.url ? (
    <a
      href={menuItem.url}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {menuItem.title}
    </a>
  ) : (
    <NavLink
      key={menuItem.uuid}
      activeClassName={activeClassName}
      className={className}
      to={getPageLink(menuItem)}
      exact
    >
      {menuItem.title}
    </NavLink>
  );

export default MenuLink;

import React, { useCallback, useMemo, useState } from 'react';
import {
  Currency,
  DiscountType,
} from '@laminar-product/client-commons-core/core';
import { Button } from '@laminar-product/client-commons-core/web';
import { OptionType } from 'types/select';
import { useGetPromotionDiscountLabel } from 'utils/promotions';
import { useCheckIfCurrentPlan } from 'utils/price';
import { buildPriceOption } from 'components/PlanSelector/helpers';
import { PlanByIntervalWithPrice } from 'components/PlanSelector/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedPrice,
  setSubscriptionPlan,
} from 'store/registration/actions';
import _omit from 'lodash/omit';
import ChangePlanModal from 'pages/Settings/components/ChangePlanModal';
import { selectIsAnonymous, selectUserHasPlan } from 'store/user/selectors';
import usePathWithParams from 'utils/usePathWithParams';
import { useHistory, useLocation } from 'react-router-dom';
import { MAX_PLAN_DESCRIPTION_LENGTH } from 'components/PlanSelector/constants';
import _truncate from 'lodash/truncate';
import { APP_PAGE_PATH } from 'utils/navigation';
import PlanDetails from './components/PlanDetails';
import PlanTitle from './components/PlanTitle';
import styles from './index.module.scss';
import TravelButton from './components/TravelButton';

interface PlanCardProps {
  plan: PlanByIntervalWithPrice;
}

interface PriceDiscount {
  amount?: number;
  type?: DiscountType;
}

export interface SelectedPrice extends OptionType {
  interval: string;
  price: string;
  netPrice?: string;
  discount?: PriceDiscount;
  currency?: Currency;
  intervalMultiplier?: number;
}

const PlanCard = ({ plan }: PlanCardProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { push } = useHistory();
  const getPath = usePathWithParams();
  const isAnonymous = useSelector(selectIsAnonymous);
  const selectedPrice = useMemo(
    () => (plan.price ? buildPriceOption(plan.price) : null),
    [plan.price]
  );
  const { getPromotionDiscountLabel } = useGetPromotionDiscountLabel();
  const { t } = useTranslation();
  const userHasCurrentPlan = useSelector(selectUserHasPlan);
  const [isChangePlanModalVisible, setIsChangePlanModalVisible] =
    useState(false);

  const planWithPrices = useMemo(
    () => ({
      ..._omit(plan, 'price'),
      prices: plan.price ? [plan.price] : [],
    }),
    [plan]
  ); //Plan from props contain single price property. For submit we build plan with prices as expected;

  const isCurrentPlan = useCheckIfCurrentPlan({
    planUuid: plan?.uuid,
    priceUuid: selectedPrice?.value,
  });

  const onPlanSelectSubmit = useCallback(() => {
    if (isAnonymous) {
      push(getPath(APP_PAGE_PATH.LOGIN));
      return;
    }

    if (userHasCurrentPlan) {
      setIsChangePlanModalVisible(true);
      return;
    }

    if (location.pathname.includes('change-plan')) {
      push(getPath('/settings/change-plan/payment'));
      return;
    }

    push(getPath('/register/payment'));
  }, [getPath, isAnonymous, location.pathname, push, userHasCurrentPlan]);

  const onSelectPlan = useCallback(() => {
    if (!planWithPrices.prices?.length) {
      return;
    }

    dispatch(setSelectedPrice(planWithPrices.prices[0]));
    dispatch(setSubscriptionPlan(planWithPrices));

    onPlanSelectSubmit();
  }, [dispatch, onPlanSelectSubmit, planWithPrices]);

  const getActionButtonLabel = () => {
    if (isCurrentPlan) {
      return t('planSelector.yourPlan');
    }

    return t(userHasCurrentPlan ? 'settings.changePlan' : 'settings.buyPlan');
  };

  return (
    <div className={styles.planCard}>
      {selectedPrice?.discount && !isCurrentPlan && (
        <span className={styles.discountBadge}>
          {getPromotionDiscountLabel({
            currency: selectedPrice?.currency,
            discountType: selectedPrice?.discount?.type,
            amount: selectedPrice?.discount?.amount,
          })}
        </span>
      )}

      {selectedPrice && (
        <PlanTitle
          planName={plan.name}
          isCurrentPlan={isCurrentPlan}
          selectedPrice={selectedPrice}
        />
      )}

      <p className={styles.description}>
        {_truncate(plan.description, { length: MAX_PLAN_DESCRIPTION_LENGTH })}
      </p>

      <PlanDetails plan={plan} />

      <div className={styles.cardBottom}>
        <TravelButton countries={plan.availableCountries} />

        <Button
          className={styles.actionButton}
          variant="cta"
          disabled={isCurrentPlan}
          onClick={onSelectPlan}
        >
          {getActionButtonLabel()}
        </Button>
      </div>

      {isChangePlanModalVisible && (
        <ChangePlanModal
          newPlan={planWithPrices}
          selectedPrice={planWithPrices.prices[0]}
          onModalClose={() => setIsChangePlanModalVisible(false)}
        />
      )}
    </div>
  );
};

export default PlanCard;

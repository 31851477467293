import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isUserDisabled } from './auth';
import { FirebaseAuthCodes } from './constants';

export const useGetPhoneVerifyError = () => {
  const { t } = useTranslation();

  const getPhoneVerifyError = useCallback(
    (errorCode: string, isRegistering?: boolean) => {
      if (isUserDisabled(errorCode)) {
        return isRegistering
          ? t('errors.userDisabledRegisterError', {
              loginMethod: 'phone number',
            })
          : t('errors.userDisabledLoginError');
      }

      if (errorCode === FirebaseAuthCodes.TOO_MANY_REQUESTS) {
        return t('errors.tooManyRequests');
      }

      if (
        errorCode === FirebaseAuthCodes.INVALID_VERIFICATION_CODE ||
        errorCode ===
          FirebaseAuthCodes.INVALID_VERIFICATION_CODE_CUSTOM_PROVIDER
      ) {
        return t('errors.invalidCode');
      }

      if (errorCode === FirebaseAuthCodes.INVALID_PHONE_NUMBER) {
        return t('errors.invalidPhone');
      }

      if (errorCode === FirebaseAuthCodes.EXPIRED_CODE) {
        return t('errors.expiredCode');
      }

      if (errorCode === FirebaseAuthCodes.USER_EXIST) {
        return t('errors.userExist');
      }

      return t('errors.somethingWentWrong');
    },
    [t]
  );

  return getPhoneVerifyError;
};

import { AnyAction } from 'redux';
import {
  SET_REDIRECT_TO,
  SET_USER_CREDENTIALS,
  SIGN_IN_FROM_TV,
  SIGN_UP_FROM_TV,
} from './actions';

export interface LoginState {
  userCredentials: { email?: string; phone?: string };
  redirectTo?: string;
  signInFromTv?: boolean;
  signUpFromTv?: boolean;
}

const loginReducer = (
  state: LoginState = { userCredentials: {} },
  action: AnyAction
) => {
  switch (action.type) {
    case SET_USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: action.payload,
      };
    case SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload.redirectTo,
      };
    case SIGN_IN_FROM_TV:
      return {
        ...state,
        signInFromTv: true,
      };
    case SIGN_UP_FROM_TV:
      return {
        ...state,
        signUpFromTv: true,
      };
    default:
      return state;
  }
};

export default loginReducer;

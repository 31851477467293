export const getDeviceType = (): 'web-desktop' | 'web-android' | 'web-ios' => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIos = /iphone|ipad|ipod/.test(userAgent);
  // in the latest iPadOS Safari mimics Mac OS
  const isIpad =
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2;
  const isAndroid = /android/.test(userAgent);

  if (isIos || isIpad) return 'web-ios';
  if (isAndroid) return 'web-android';
  return 'web-desktop';
};

import { NO_PREFERENCE, Plan } from '@laminar-product/client-commons-core/core';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getPlans } from 'actions/payments';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsInterestsEnabled } from 'store/app/selectors';
import { selectSignUpFromTv } from 'store/login/selectors';
import { selectCurrentProfileInterests } from 'store/user/selectors';

export enum PlansFilteringOption {
  TV = 'TV',
}

const usePlans = () => {
  const [plans, isLoading] = useFetchAction<Plan[]>(getPlans);
  const isSignUpFromTv = useSelector(selectSignUpFromTv);
  const [filteringOption, setFilteringOption] =
    useState<PlansFilteringOption>();
  const interestsEnabled = useSelector(selectIsInterestsEnabled);
  const profileInterests = useSelector(selectCurrentProfileInterests);
  const [resetFilters, setResetFilters] = useState(false);

  const reset = () => {
    setResetFilters(true);
    setFilteringOption(undefined);
  };

  const plansByInterests = useMemo(() => {
    const userHasNoPreference = profileInterests?.[0] === NO_PREFERENCE;
    if (interestsEnabled && profileInterests?.length && !userHasNoPreference) {
      return plans?.reduce<Plan[]>((acc, plan) => {
        const planInterests = plan.interests || [];
        const hasCommonInterests = planInterests.some((interest) =>
          profileInterests.includes(interest)
        );
        if (hasCommonInterests || profileInterests[0] === NO_PREFERENCE) {
          acc.push(plan);
        }
        return acc;
      }, []);
    }

    return plans;
  }, [interestsEnabled, plans, profileInterests]);

  const filteredPlans = useMemo(() => {
    if (isSignUpFromTv && !resetFilters) {
      setFilteringOption(PlansFilteringOption.TV);
      return plansByInterests?.filter((plan) =>
        plan.platforms.includes(PlansFilteringOption.TV)
      );
    }

    return plansByInterests;
  }, [isSignUpFromTv, resetFilters, plansByInterests]);
  return { filteredPlans, isLoading, filteringOption, reset };
};

export default usePlans;

import { Profile } from '@laminar-product/client-commons-core/core';
import React from 'react';
import PlaybackItem from './PlaybackItem';

interface PlaybackProps {
  profile?: Profile;
  saveProfile: (profile: Profile) => void;
  isLoading?: boolean;
}

const PlaybackTab = ({ profile, saveProfile, isLoading }: PlaybackProps) => {
  if (!profile?.playbackSettings) {
    return null;
  }

  return (
    <>
      {Object.entries(profile.playbackSettings).map(([key, val], index) => {
        if (key === 'mutePreview') {
          return null; //Hidden for now as browser is always auto-muting trailers by default
        }

        return (
          <PlaybackItem
            saveProfile={saveProfile}
            profile={profile}
            key={index}
            settingKey={key}
            settingValue={val}
            isLoading={isLoading}
          />
        );
      })}
    </>
  );
};

export default PlaybackTab;

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface CarouselNavigationProps {
  goToPage: (page: number) => void;
  currentPage: number;
}

const CarouselNavigation = ({
  goToPage,
  currentPage,
}: CarouselNavigationProps) => {
  const onLeft = () => {
    goToPage(currentPage - 1);
  };

  const onRight = () => {
    goToPage(currentPage + 1);
  };

  return (
    <>
      {currentPage > 0 && (
        <button
          onClick={onLeft}
          className={cn(styles.button, styles.buttonLeft)}
        >
          <ArrowRight className={cn(styles.arrow, styles.arrowLeft)} />
        </button>
      )}
      <button
        onClick={onRight}
        className={cn(styles.button, styles.buttonRight)}
      >
        <ArrowRight className={styles.arrow} />
      </button>
    </>
  );
};

export default CarouselNavigation;

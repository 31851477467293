import React, { useRef } from 'react';
import cn from 'classnames';
import PopOver, { PopOverPlacement } from 'components/PopOver';
import styles from './index.module.scss';

interface TooltipProps {
  data: React.ReactNode;
  children: React.ReactNode;
  onHoverEnabled?: boolean;
  contentClassName?: string;
  placement?: PopOverPlacement;
  popoverClassName?: string;
  indicatorClassName?: string;
}

const Tooltip = ({
  data,
  children,
  onHoverEnabled = true,
  contentClassName,
  placement,
  popoverClassName,
  indicatorClassName,
}: TooltipProps) => {
  const childrenRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={childrenRef}
      className={cn(styles.tooltipChildren, indicatorClassName)}
    >
      {children}

      <div className={styles.tooltip}>
        <PopOver
          placement={placement}
          isOpen={onHoverEnabled}
          anchorEl={childrenRef.current}
          className={popoverClassName}
        >
          <div className={cn(styles.tooltipText, contentClassName)}>{data}</div>
        </PopOver>
      </div>
    </div>
  );
};

export default Tooltip;

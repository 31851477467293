import { Profile } from '@laminar-product/client-commons-core/core';
import { Subscription } from 'types/subscription';
import { NotificationType } from 'utils/notifications';
import { User as FirebaseUser } from 'firebase/auth';
import { UserDetails, UserManagingProfilesVariant } from 'types/user';
import { LoginMethod } from './reducer';

export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const setUserDetails = (userDetails?: UserDetails) => ({
  type: SET_USER_DETAILS,
  payload: userDetails,
});

export const SET_USER_VERIFIED = 'SET_USER_VERIFIED';
export const setUserVerified = (verified: boolean) => ({
  type: SET_USER_VERIFIED,
  payload: { verified },
});

export const SET_USER_PROFILES = 'SET_USER_PROFILES';
export const setUserProfiles = (profiles: Profile[] | null) => ({
  type: SET_USER_PROFILES,
  payload: { profiles },
});

export const SET_CURRENT_PROFILE_UUID = 'SET_CURRENT_PROFILE_UUID';
export const setCurrentProfileUuid = (uuid: string | null) => ({
  type: SET_CURRENT_PROFILE_UUID,
  payload: { uuid },
});

export const SET_USER_LOGIN_METHOD = 'SET_USER_LOGIN_METHOD';
export const setLoginMethod = (method?: LoginMethod) => ({
  type: SET_USER_LOGIN_METHOD,
  payload: { method },
});

export const SET_FIREBASE_MESSAGING_TOKEN = 'SET_FIREBASE_MESSAGING_TOKEN';
export const setFirebaseMessagingToken = (token: string | null) => ({
  type: SET_FIREBASE_MESSAGING_TOKEN,
  payload: { token },
});

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const setNotifications = (notifications: NotificationType[]) => ({
  type: SET_NOTIFICATIONS,
  payload: { notifications },
});

export const SET_CURRENT_USER_SUBSCRIPTION = 'SET_CURRENT_USER_SUBSCRIPTION';
export const setCurrentUserSubscription = (subscription?: Subscription) => ({
  type: SET_CURRENT_USER_SUBSCRIPTION,
  payload: { subscription },
});

export const SET_FIREBASE_USER = 'SET_FIREBASE_USER';
export const setFirebaseUser = (user: FirebaseUser | null) => ({
  type: SET_FIREBASE_USER,
  payload: { user },
});

export const SET_USER_AUTH_CLAIMS = 'SET_USER_AUTH_CLAIMS';
export const setUserAuthClaims = (claims: Record<string, unknown>) => ({
  type: SET_USER_AUTH_CLAIMS,
  payload: { claims },
});

export const SET_USER_MANAGING_PROFILES_VARIANT =
  'SET_USER_MANAGING_PROFILES_VARIANT';
export const setUserManagingProfilesVariant = (
  variant: UserManagingProfilesVariant | undefined
) => ({
  type: SET_USER_MANAGING_PROFILES_VARIANT,
  payload: variant,
});

export const SET_CANNOT_CHANGE_PLAN_MODAL_VISIBLE =
  'SET_CANNOT_CHANGE_PLAN_MODAL_VISIBLE';
export const setCannotChangePlanModalVisible = (payload: boolean) => ({
  type: SET_CANNOT_CHANGE_PLAN_MODAL_VISIBLE,
  payload,
});

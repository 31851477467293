import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AttributedCheckoutProcessingIcon } from 'assets/images/attributed-checkout-process.svg';
import CompanyLogo from 'components/CompanyLogo';
import { useHistory } from 'react-router-dom';
import { getDefaultRouteAfterAttributedCheckout } from 'utils/routing';
import styles from './index.module.scss';

const PaymentAttributedCheckout = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const handleDefaultRedirect = () =>
    push(getDefaultRouteAfterAttributedCheckout());

  return (
    <>
      <div className={styles.topBar}>
        <CompanyLogo onClick={handleDefaultRedirect} />
      </div>
      <div className={styles.root}>
        <div className={styles.imgWrapper}>
          <AttributedCheckoutProcessingIcon className={styles.img} />
        </div>
        <h1 className={styles.heading}>
          {t('payment.inProgress.attributedCheckoutInProgressHeader')}
        </h1>
        <p className={styles.description}>
          {t('payment.inProgress.attributedCheckoutInProgressMessage')}
        </p>
      </div>
    </>
  );
};

export default PaymentAttributedCheckout;

import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { RouteContentIdParams } from './props';
import { useAsset } from './useAsset';
import { prepareMetaForAsset } from './meta';
import { useMoveToAssetDetails } from './useMoveToAssetDetails';

const useVideoPlayerContent = () => {
  const { id, contentId } = useParams<RouteContentIdParams>();
  const { asset, assetModel, assetFetchingError } = useAsset(id);
  const dispatch = useDispatch();
  const moveToAssetDetails = useMoveToAssetDetails();

  useEffect(() => {
    if (!asset) {
      return;
    }

    dispatch(
      setAppMeta({
        description: asset.description,
        og: prepareMetaForAsset(asset),
      })
    );
  }, [asset, dispatch]);

  const content = useMemo(() => {
    if (!assetModel || !asset) return null;

    return contentId
      ? assetModel.getContentById(contentId)
      : assetModel.mainContent;
  }, [asset, assetModel, contentId]);

  useEffect(() => {
    if (!content && asset) {
      //Fallback if user is in player but there is no content available
      return moveToAssetDetails(asset);
    }
  }, [asset, content, moveToAssetDetails]);

  return { asset, content, isError: assetFetchingError };
};

export default useVideoPlayerContent;

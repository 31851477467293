import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { Severity } from 'types/errors';
import { getUserData } from 'actions/basic';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAssets } from 'store/assets/actions';
import { setCurrentUserSubscription, setUserDetails } from 'store/user/actions';
import { selectUserDetails } from 'store/user/selectors';
import { UserDetails } from 'types/user';
import { captureError } from './captureError';

export const pollingInterval = 5000;
export const maxCallsTime = 180000; //5min
export const maxTimerTime = 60; //2min
export const maxCallsCount = maxCallsTime / pollingInterval;

export enum PollingUserPlanResultType {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

interface usePollingUserPlanProps {
  shouldRun?: boolean;
}

export const usePollingUserPlanPrepaid = ({
  shouldRun = true,
}: usePollingUserPlanProps) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserDetails);
  const [userDataCallsCount, setUserDataCallsCount] = useState(0);
  const [fetchedUser, setFetchedUser] = useState<UserDetails>();
  const [fetchingPlanResult, setFetchingPlanResult] =
    useState<PollingUserPlanResultType>();
  const [pollingRunning, setPollingRunning] = useState(false);

  useEffect(() => {
    if (fetchingPlanResult) {
      setPollingRunning(false);
      return;
    }

    if (shouldRun && !pollingRunning) {
      setPollingRunning(true);
      return;
    }
  }, [shouldRun, pollingRunning, fetchingPlanResult]);

  const fetchUserData = async () => {
    if (!userData) return;

    try {
      const data = await getUserData();
      setFetchedUser(data);
      setUserDataCallsCount((prevCount) => prevCount + 1);
    } catch (err) {
      captureError(
        err as AxiosError,
        'currentUser/getUserData',
        Severity.Error
      );
    }
  };

  const cancelUserPlanPolling = usePolling(fetchUserData, {
    interval: pollingInterval,
    shouldRunPolling: shouldRun,
    runOnInit: true,
  });

  useEffect(() => {
    if (!fetchedUser) return;

    //Note: If expire date has changes it means that the plan was updated
    if (
      fetchedUser?.data.plan?.expireAt !== userData?.plan?.expireAt ||
      (!!fetchedUser?.data.plan?.expireAt && !userData?.plan)
    ) {
      cancelUserPlanPolling();

      // Note: Prepaid is not subscription = reset to undefined
      dispatch(setUserDetails(fetchedUser));
      dispatch(setCurrentUserSubscription(undefined));
      dispatch(resetAssets());
      setFetchingPlanResult(PollingUserPlanResultType.SUCCESS);
    }
  }, [cancelUserPlanPolling, dispatch, fetchedUser, userData]);

  useEffect(() => {
    if (userDataCallsCount >= maxCallsCount) {
      setFetchingPlanResult(PollingUserPlanResultType.FAILURE);
    }
  }, [userDataCallsCount]);

  useEffect(() => {
    return () => {
      cancelUserPlanPolling();
    };
  }, [cancelUserPlanPolling]);

  return { fetchingPlanResult, pollingRunning };
};

import { useFormContext } from 'react-hook-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const AuthInputError = () => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const isRequiredError =
    errors.userEmail?.type === 'required' ||
    errors.userPhoneNumber?.type === 'required';

  const isEmailValidationError =
    errors.userEmail && errors.userEmail.type === 'validate';

  const isPhoneValidationError =
    errors.userPhoneNumber && errors.userPhoneNumber.type === 'validate';

  return (
    <p className={styles.errorMessage}>
      {isRequiredError && t('common.validations.required')}
      {isEmailValidationError && t('errors.invalidEmail')}
      {isPhoneValidationError && t('errors.invalidPhone')}
    </p>
  );
};

export default AuthInputError;

import { BillingAddressValue } from '@laminar-product/client-commons-core/core';

export const billingAddressInitialValues = {
  [BillingAddressValue.LINE_1]: '',
  [BillingAddressValue.LINE_2]: '',
  [BillingAddressValue.CITY]: '',
  [BillingAddressValue.STATE]: '',
  [BillingAddressValue.POSTAL_CODE]: '',
  [BillingAddressValue.COUNTRY]: '',
};

export const USCountryCode = 'US';

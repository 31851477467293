import {
  SearchParams,
  SearchResult,
} from '@laminar-product/client-commons-core/core';
import api from 'api';
import { AiParams } from 'types/ai';

export const getGenres = async (): Promise<string[]> => {
  const { data } = await api().get('/genre');

  return data;
};

export const getMoods = async (): Promise<string[]> => {
  const { data } = await api().get('/mood');

  return data;
};

export const searchContent = async (params: SearchParams) => {
  const { data } = await api().post<SearchResult>('/search/search', params);

  return data;
};

export const searchAssetLabels = async (
  sourceId: string,
  query: string
): Promise<AiParams> => {
  const { data } = await api().get<AiParams>(
    `/search/labels/${sourceId}?query=${query}`
  );

  return data;
};

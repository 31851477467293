import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChromecastCastElement } from 'store/chromecast/selectors';
import {
  setChromecastAssetId,
  setChromecastContentId,
} from 'store/chromecast/actions';
import { Severity } from 'types/errors';
import { getAsset } from 'actions/assets';
import { selectAssets } from 'store/assets/selectors';
import {
  ContentType,
  GetAssetTokenType,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';
import { isLive } from '@laminar-product/client-commons-core/web';
import useVideoPlayerPlayback from './useVideoPlayerPlayback';
import { captureError } from './captureError';
import useVideoPlayerQuality from './useVideoPlayerQuality';

interface CastWithChromecastProps {
  assetId: string;
  contentId?: string;
  startTime?: number;
}

const useChromecastCast = () => {
  const prepareVideoPlayerConfig = useVideoPlayerPlayback();
  const castElement = useSelector(selectChromecastCastElement);
  const dispatch = useDispatch();
  const assets = useSelector(selectAssets);
  const quality = useVideoPlayerQuality();

  const castWithChromecast = useCallback(
    async ({ assetId, contentId, startTime }: CastWithChromecastProps) => {
      if (!castElement) {
        return;
      }

      try {
        const asset = assets?.[assetId] || (await getAsset(assetId));

        if (!asset) {
          return;
        }

        const chromecastDefaultContent = getAssetContentWithType(
          asset,
          isLive(asset) ? ContentType.LIVE : ContentType.MAIN
        );

        const content = contentId
          ? asset.contents.find((c) => c.sourceUuid === contentId)
          : chromecastDefaultContent;

        if (!content) {
          return;
        }

        const assetPlayback = await prepareVideoPlayerConfig({
          assetId: asset.id,
          content,
          tokenType: GetAssetTokenType.CHROMECAST,
        });

        castElement.cast(assetPlayback?.source?.src, {
          currentTime: startTime || 0,
          customData: {
            licenseUrl: assetPlayback?.drmLicenseUrl,
            frames: assetPlayback?.frames,
            contentType: content.type,
            maxQuality: quality.maxHeight,
          },
        });

        dispatch(setChromecastAssetId(asset.id));
        dispatch(setChromecastContentId(content.slug)); //Live assets don't have sourceUuid so we pass here slug
      } catch (error) {
        console.error(error);
        captureError(
          error as any,
          'useChromecast/assetPlayback',
          Severity.Error
        );
      }
    },
    [castElement, assets, prepareVideoPlayerConfig, quality.maxHeight, dispatch]
  );

  return castWithChromecast;
};

export default useChromecastCast;

import axios, { AxiosInstance } from 'axios';
import { getAuth } from 'firebase/auth';
import i18n from 'i18next';
import state from 'store';
import { splitLanguage } from 'utils/splitLanguage';
import { LOCAL_STORAGE_CURRENT_PROFILE_UUID } from 'utils/constants';
import { getReleaseVersion } from 'utils/helpers';

interface ApiProps {
  baseURL?: string;
}

let _api: AxiosInstance;

const localStorageProfileUuid = localStorage.getItem(
  LOCAL_STORAGE_CURRENT_PROFILE_UUID
);

export const api: (props?: ApiProps) => AxiosInstance = (props) => {
  const baseURL =
    props?.baseURL ||
    import.meta.env.VITE_BACKEND_URL ||
    'https://content.dev.laminar.video';

  if (_api) {
    _api.defaults.baseURL = baseURL;
    return _api;
  }

  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(
    async (config) => {
      const auth = getAuth();
      if (auth.currentUser) {
        const token = await auth.currentUser?.getIdToken();
        const currentProfileUuidFromStore =
          state.getState().user.currentProfileUuid;

        config.headers = {
          Authorization: `Bearer ${token}`,
          Lang: splitLanguage(i18n.language),
          'x-platform': 'WEB',
          'x-client-version': getReleaseVersion(),
        };

        const profileUuid =
          currentProfileUuidFromStore || localStorageProfileUuid;

        if (profileUuid) {
          config.headers['x-profile-id'] = profileUuid;
        }
      }

      return config;
    },
    (error) => {
      console.error('Interceptor: ', error);
    }
  );

  _api = instance;
  return instance;
};

export default api;

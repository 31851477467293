import {
  PaymentGateway,
  PayuPaymentResponse,
} from '@laminar-product/client-commons-core/core';
import { addCard, getAttributedCheckout } from 'actions/payments';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Severity } from 'types/errors';
import { StripePaymentResponse } from 'types/payment';
import { CreateSubscriptionRazorpayData } from 'types/subscription';
import { getAuth } from 'firebase/auth';
import { captureError } from './captureError';
import { getDefaultRouteAfterAttributedCheckout } from './routing';
import useHandlePayuPayment from './useHandlePayuPayment';
import useHandleRazorpayPayment from './useHandleRazorpayPayment';
import useHandleStripePayment from './useHandleStripePayment';

export enum PaymentActionType {
  PAYMENT_INFO_CHANGE = 'PAYMENT_INFO_CHANGE',
  PAY = 'PAY',
}
interface HandlePaymentActionProps {
  actionType: PaymentActionType;
  email?: string;
  attributedCheckoutContextID?: string;
}

interface CommonPaymentProps {
  email: string;
}

interface HandlePayAction extends CommonPaymentProps {
  attributedCheckoutContextID?: string;
}

const useExternalPayment = () => {
  const [isPromptingEmail, setIsPromptingEmail] = useState(false);
  const { t } = useTranslation();
  const [cardChangingError, setCardChangingError] = useState('');
  const { handlePayuCardChange, handleExternalPayuPayment } =
    useHandlePayuPayment();
  const { handleStripeCardChange, handleExternalStripePayment } =
    useHandleStripePayment();
  const { handleRazorpayAttributedCheckoutPayment } =
    useHandleRazorpayPayment();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentErrorCode, setPaymentErrorCode] = useState<string>();
  const { push } = useHistory();

  const handleDefaultRedirect = useCallback(() => {
    return push(getDefaultRouteAfterAttributedCheckout());
  }, [push]);

  const handlePaymentInfoChange = useCallback(
    async ({ email }: CommonPaymentProps) => {
      try {
        const response = await addCard(email);

        if (!response) {
          setCardChangingError(t('errors.somethingWentWrong'));
          return;
        }

        const { gateway, data } = response;

        switch (gateway) {
          case PaymentGateway.Payu:
            return await handlePayuCardChange(
              data as PayuPaymentResponse['data']
            );
          case PaymentGateway.Stripe:
            return await handleStripeCardChange((data as { id: string }).id);
          default:
            return handleDefaultRedirect();
        }
      } catch (error) {
        setCardChangingError(t('errors.somethingWentWrong'));
      }
    },
    [handleDefaultRedirect, handlePayuCardChange, handleStripeCardChange, t]
  );

  const handlePay = useCallback(
    async ({ email, attributedCheckoutContextID }: HandlePayAction) => {
      if (paymentProcessing || !attributedCheckoutContextID) return;

      try {
        setPaymentProcessing(true);

        const { gateway, data } = await getAttributedCheckout(
          attributedCheckoutContextID,
          email
        );

        switch (gateway) {
          case PaymentGateway.Payu:
            return handleExternalPayuPayment(
              data as PayuPaymentResponse['data']
            );
          case PaymentGateway.Stripe:
            return handleExternalStripePayment(
              (data as StripePaymentResponse).id
            );
          case PaymentGateway.RAZORPAY:
            return handleRazorpayAttributedCheckoutPayment(
              data as CreateSubscriptionRazorpayData
            );
          default:
            return handleDefaultRedirect();
        }
      } catch (e) {
        captureError(e as any, 'useExternalPayment/handlePay', Severity.Error);

        const errorCode = (e as any)?.response?.data?.code;
        setPaymentErrorCode(errorCode);
        setPaymentProcessing(false);
        setIsPromptingEmail(false);
      }
    },
    [
      paymentProcessing,
      handleExternalPayuPayment,
      handleExternalStripePayment,
      handleRazorpayAttributedCheckoutPayment,
      handleDefaultRedirect,
    ]
  );

  const handlePaymentAction = useCallback(
    ({
      actionType,
      email,
      attributedCheckoutContextID,
    }: HandlePaymentActionProps) => {
      //NOTE: Attributed checkout flow is rendered without rendering app. There is no user in state
      const paymentEmail = getAuth()?.currentUser?.email || email;

      if (paymentErrorCode) {
        return;
      }

      if (!paymentEmail) {
        setIsPromptingEmail(true);
        return;
      }

      switch (actionType) {
        case PaymentActionType.PAYMENT_INFO_CHANGE:
          return handlePaymentInfoChange({ email: paymentEmail });
        case PaymentActionType.PAY:
          return handlePay({
            email: paymentEmail,
            attributedCheckoutContextID,
          });
      }
    },
    [paymentErrorCode, handlePaymentInfoChange, handlePay]
  );

  return {
    handlePaymentAction,
    isPromptingEmail,
    setIsPromptingEmail,
    cardChangingError,
    paymentProcessing,
    paymentErrorCode,
    setPaymentErrorCode,
  };
};

export default useExternalPayment;

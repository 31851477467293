import AuthenticationContainer from 'components/AuthenticationContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentError from './components/PaymentError';
import PromptEmail from './components/PromptEmail';
import styles from './index.module.scss';

interface AttributedCheckoutProps {
  paymentErrorCode?: string;
  isPromptingEmail?: boolean;
  onPromptEmailSubmit: (email: string) => void;
  onAttributionPageClose: () => void;
}

const AttributedCheckout = ({
  isPromptingEmail,
  paymentErrorCode,
  onPromptEmailSubmit,
  onAttributionPageClose,
}: AttributedCheckoutProps) => {
  const { t } = useTranslation();
  const shouldDisplayPaymentInProgressInformation =
    !isPromptingEmail && !paymentErrorCode;

  return (
    <AuthenticationContainer onLogoClick={onAttributionPageClose}>
      {isPromptingEmail && !paymentErrorCode && (
        <PromptEmail onSubmit={onPromptEmailSubmit} />
      )}

      {paymentErrorCode && (
        <PaymentError
          errorCode={paymentErrorCode}
          onClose={onAttributionPageClose}
        />
      )}

      {shouldDisplayPaymentInProgressInformation && (
        <>
          <h1 className={styles.heading}>
            {t('settings.attributedCheckoutPaymentInProgressHeader')}
          </h1>
          <div className={styles.description}>
            {t('settings.attributedCheckoutPaymentInProgressDescription')}
          </div>
        </>
      )}
    </AuthenticationContainer>
  );
};

export default AttributedCheckout;

import { AssetType } from '@laminar-product/client-commons-core/core';
import React, { useContext } from 'react';
import { AssetLocationState } from 'types/location';

/**
 * Navigates to PlayerView. Handles additional parameters.
 *
 * @param {string} id id of the asset
 * @param {string} options.contentId id of the content, if not provided main content will be selected or fallback to any content
 * @param {AssetLocationState} options.state additional information about asset state
 */
export type MoveToAssetPlayer = (
  id: string,
  type: AssetType,
  administrativeName: string,
  options?: {
    contentId?: string;
    state?: AssetLocationState;
  }
) => void;

export const MoveToAssetPlayerContext = React.createContext<MoveToAssetPlayer>(
  () => {}
);

export const useMoveToAssetPlayer = () => {
  return useContext(MoveToAssetPlayerContext);
};

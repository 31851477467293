import Input from 'components/Input';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  BillingAddress,
  BillingAddressValue,
  BillingName,
  BillingNameValue,
  TaxRegion,
  getCountryByCode,
} from '@laminar-product/client-commons-core/core';
import { Button, Select } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectAppTheme } from 'store/app/selectors';
import styles from './index.module.scss';
import BillingInput from './components/BillingInput';
import BillingInputError from './components/BillingInputError';
import { DEFAULT_BILLING_FORM_VALUES } from './constants';

type BillingFormType = BillingAddress & BillingName;

const MAX_LONG_VALUE = 100;
const MAX_SHORT_VALUE = 50;

interface BillingFormProps {
  error?: any;
  isLoading?: boolean;
  billingAddress: BillingAddress;
  regionRequired?: boolean;
  fullNameRequired?: boolean;
  availableRegions?: TaxRegion[];
  onCancel: () => void;
  onSave: (address: BillingAddress) => void;
  setBillingInfo: (billingInfo: {
    name: BillingName;
    address: BillingAddress;
  }) => void;
}

const BillingForm = ({
  onCancel,
  onSave,
  setBillingInfo,
  billingAddress,
  isLoading,
  error,
  regionRequired,
  fullNameRequired,
  availableRegions,
}: BillingFormProps) => {
  const { t } = useTranslation();
  const formMethods = useForm<BillingFormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: DEFAULT_BILLING_FORM_VALUES,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;
  const appTheme = useSelector(selectAppTheme);

  useEffect(() => {
    if (billingAddress) {
      reset(billingAddress);
    }
  }, [billingAddress, reset]);

  const regionOptions = useMemo(
    () =>
      availableRegions?.map((r) => ({
        label: r.name,
        value: r.isoCode,
      })) || [],
    [availableRegions]
  );

  const onBillingFormSubmit = useCallback(
    (values: BillingFormType) => {
      const { name, surname, ...address } = values;
      onSave(address);
      setBillingInfo({
        name: { name, surname },
        address: address,
      });
    },
    [onSave, setBillingInfo]
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('orderSummary.billingForm.title')}</div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onBillingFormSubmit)}>
          {fullNameRequired && (
            <div className={styles.rowFields}>
              <div>
                <Controller
                  control={control}
                  name={BillingNameValue.NAME}
                  render={({ field: { onChange } }) => (
                    <BillingInput
                      label={t('billingForm.firstName')}
                      onChange={onChange}
                      error={errors?.[BillingNameValue.NAME]?.message || ''}
                      testId="BillingForm__FirstNameInput"
                    />
                  )}
                  rules={{
                    required: t('orderSummary.billingName.firstNameRequired'),
                    maxLength: {
                      value: MAX_LONG_VALUE,
                      message: t('orderSummary.billingName.firstNameTooLong'),
                    },
                  }}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name={BillingNameValue.SURNAME}
                  render={({ field: { onChange } }) => (
                    <BillingInput
                      label={t('billingForm.lastName')}
                      onChange={onChange}
                      error={errors?.[BillingNameValue.SURNAME]?.message || ''}
                      testId="BillingForm__LastNameInput"
                    />
                  )}
                  rules={{
                    required: t('orderSummary.billingName.lastNameRequired'),
                    maxLength: {
                      value: MAX_LONG_VALUE,
                      message: t('orderSummary.billingName.lastNameTooLong'),
                    },
                  }}
                />
              </div>
            </div>
          )}
          <Controller
            control={control}
            name={BillingAddressValue.LINE_1}
            render={({ field: { onChange } }) => (
              <BillingInput
                label={t('billingForm.addressLine1')}
                onChange={onChange}
                error={errors?.[BillingAddressValue.LINE_1]?.message || ''}
                testId="BillingForm__AddressLine1Input"
              />
            )}
            rules={{
              required: t('orderSummary.billingAddress.addressRequired'),
              maxLength: {
                value: MAX_LONG_VALUE,
                message: t('orderSummary.billingAddress.addressTooLong'),
              },
            }}
          />
          <Controller
            control={control}
            name={BillingAddressValue.LINE_2}
            render={({ field: { onChange } }) => (
              <BillingInput
                label={t('billingForm.addressLine2')}
                onChange={onChange}
                error={errors?.[BillingAddressValue.LINE_2]?.message || ''}
                testId="BillingForm__AddressLine2Input"
              />
            )}
            rules={{
              maxLength: {
                value: MAX_LONG_VALUE,
                message: t('orderSummary.billingAddress.addressTooLong'),
              },
            }}
          />
          <div className={styles.rowFields}>
            <div>
              <Controller
                control={control}
                name={BillingAddressValue.CITY}
                render={({ field: { onChange } }) => (
                  <BillingInput
                    label={t('billingForm.city')}
                    addMargin={regionRequired}
                    onChange={onChange}
                    error={errors?.[BillingAddressValue.CITY]?.message || ''}
                    testId="BillingForm__CityInput"
                  />
                )}
                rules={{
                  required: t('orderSummary.billingAddress.cityRequired'),
                  maxLength: {
                    value: MAX_SHORT_VALUE,
                    message: t('orderSummary.billingAddress.cityTooLong'),
                  },
                }}
              />
            </div>
            {regionRequired && (
              <div>
                <Controller
                  control={control}
                  name={BillingAddressValue.STATE}
                  render={({ field }) => (
                    <div className={styles.selectContainer}>
                      <label htmlFor="taxRegion" className={styles.label}>
                        {t(`tax.regionLabel.${billingAddress.country}`, {
                          defaultValue: t('tax.regionLabel.default'),
                        })}
                      </label>
                      <Select
                        options={regionOptions}
                        value={regionOptions.find(
                          (r) => r.value === field.value
                        )}
                        onChange={(event) => field.onChange(event?.value)} // TODO(pr0gramista): improve type definitions
                        appTheme={appTheme}
                        className={cn(styles.input, styles.select)}
                        useCustomStyles={false}
                        placeholder="Select"
                        id="taxRegion"
                        type="settings"
                      />
                    </div>
                  )}
                  rules={{
                    required: t('orderSummary.billingAddress.regionRequired'),
                  }}
                />
                <BillingInputError
                  label={BillingAddressValue.STATE}
                  errors={errors}
                />
              </div>
            )}
          </div>
          <div className={styles.rowFields}>
            <div>
              <Controller
                control={control}
                name={BillingAddressValue.POSTAL_CODE}
                render={({ field: { onChange } }) => (
                  <BillingInput
                    label={t('billingForm.zip')}
                    addMargin
                    onChange={onChange}
                    error={
                      errors?.[BillingAddressValue.POSTAL_CODE]?.message || ''
                    }
                    testId="BillingForm__ZIPInput"
                  />
                )}
                rules={{
                  required: t('orderSummary.billingAddress.zipCodeRequired'),
                }}
              />
            </div>
            <div>
              <Controller
                control={control}
                name={BillingAddressValue.COUNTRY}
                defaultValue={billingAddress.country}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Input
                      disabled
                      light
                      label={t('billingForm.country')}
                      onChange={onChange}
                      className={styles.input}
                      placeholder={getCountryByCode(value)?.name || value}
                      error={
                        (errors?.[BillingAddressValue.COUNTRY]
                          ?.message as string) || ''
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.actionButtons}>
            <Button
              variant="secondary-settings"
              onClick={onCancel}
              className={styles.button}
            >
              {t('common.cancel')}
            </Button>
            <Button
              loading={isLoading}
              type="submit"
              variant="cta"
              className={cn(styles.saveBtn, styles.button)}
              data-testid="BillingForm__SaveButton"
            >
              {t('common.save')}
            </Button>
          </div>
          {!!error?.response?.data?.details?.[0] && (
            <span className={styles.errorMessage}>
              {error?.response?.data?.details?.[0]?.msg || ''}:{' '}
              {error?.response?.data?.details?.[0]?.param || ''}
            </span>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default BillingForm;

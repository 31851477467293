import AppLoadingIndicator from 'components/AppLoadingIndicator';
import PageNotFoundComponent from 'components/ErrorPage/PageNotFoundComponent';
import useVideoPlayerContent from 'utils/useVideoPlayerContent';
import { useUniquePlayerId } from '@laminar-product/client-commons-core/hooks';
import { StrataStateMachineProvider } from 'state-machines/strata/context';
import VideoPlayerFactory from '../VideoPlayerFactory';
import styles from './index.module.scss';

const VideoPlayerWrapper = () => {
  const { asset, content, isError } = useVideoPlayerContent();
  const playerId = useUniquePlayerId();

  if (!content || !asset) {
    return <AppLoadingIndicator fullScreen />;
  }

  if (isError) {
    return <PageNotFoundComponent />;
  }

  return (
    <div className={styles.root}>
      <StrataStateMachineProvider>
        <VideoPlayerFactory
          asset={asset}
          content={content}
          playerId={playerId}
          key={asset.id} //Allows for rerender of factory when changing episodes
        />
      </StrataStateMachineProvider>
    </div>
  );
};

export default VideoPlayerWrapper;

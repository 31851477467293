import React, { ReactElement } from 'react';
import styles from './index.module.scss';

const SettingsPageContainer = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => <div className={styles.root}>{children}</div>;

export default SettingsPageContainer;

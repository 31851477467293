import React from 'react';
import S from 'rc-switch';
import cn from 'classnames';
import styles from './index.module.scss';

export type SwitchTheme = 'base' | 'settings';

const Switch = ({
  checked,
  disabled,
  theme = 'base',
  ...props
}: {
  checked: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  theme?: SwitchTheme;
}) => (
  <div className={styles.root}>
    <S
      className={cn(styles.switch, {
        [theme === 'settings'
          ? styles.switchSettingsActive
          : styles.switchBaseActive]: checked,
      })}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  </div>
);

export default Switch;

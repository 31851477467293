import { PositioningStrategy } from '@popperjs/core';
import React, { FC, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import cn from 'classnames';
import { useIntersectionObserver } from 'utils/useIntersectionObserver';
import FadeInTransition from '../FadeInTransition';
import styles from './index.module.scss';

export type PopOverPlacement =
  | 'top-start'
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';
export interface PopOverProps extends React.PropsWithChildren {
  anchorEl: Element | null;
  isOpen: boolean;
  onClose?: () => void;
  onMouseLeave?: () => void;
  contentClassName?: string;
  strategy?: PositioningStrategy;
  placement?: PopOverPlacement;
  className?: string;
}

const PopOver: FC<PopOverProps> = ({
  anchorEl,
  isOpen,
  children,
  onClose,
  contentClassName,
  placement,
  onMouseLeave,
  strategy = 'absolute',
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const isAnchorVisible = useIntersectionObserver({ ref: anchorEl });

  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(anchorEl, contentRef.current, {
    placement: placement || 'top',
    strategy: strategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  useEffect(() => {
    if (isAnchorVisible && update) {
      update();
    }
  }, [isAnchorVisible, update]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        contentRef &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Node) &&
        !anchorEl?.contains(event.target as Node)
      ) {
        onClose?.();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, anchorEl]);

  return (
    <div
      className={cn(styles.root, className)}
      ref={contentRef}
      style={popperStyles.popper}
      {...attributes?.popper}
      onMouseLeave={onMouseLeave}
    >
      <FadeInTransition className={contentClassName} isOpen={isOpen}>
        {children}
      </FadeInTransition>
    </div>
  );
};
export default PopOver;

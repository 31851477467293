import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSupportLink } from 'store/app/selectors';
import { Button } from '@laminar-product/client-commons-core/web';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

const EmptyPlans = () => {
  const { t } = useTranslation();
  const supportEmail = useSelector(selectSupportLink);
  const { push } = useHistory();

  return (
    <div className={styles.emptyPlansWrapper}>
      <div>
        <h1>{t('settings.emptyPlansTitle')}</h1>
        <span className={styles.emptyPlansDescription}>
          {t('settings.emptyPlansDescription')}
        </span>
      </div>

      <ul className={styles.emptyPlansSolutionsList}>
        <li>{t('settings.emptyPlansSolutionFirst')}</li>
        <li>{t('settings.emptyPlansSolutionSecond', { supportEmail })}</li>
      </ul>

      <Button onClick={() => push('/')}>{t('common.goHome')}</Button>
    </div>
  );
};

export default EmptyPlans;

import {
  ContentFrame,
  ContinueWatchingItem,
} from '@laminar-product/client-commons-core/core';
import api from 'api';

export const updateWatchingProgress = async (
  item: ContinueWatchingItem,
  frames: ContentFrame[]
) => {
  const {
    assetUuid,
    currentSecond,
    rootId,
    parentId,
    finished,
    event,
    deviceId,
  } = item;
  const { data } = await api().post(`/favorites/watching/${assetUuid}`, {
    currentSecond: Math.floor(currentSecond),
    rootId,
    parentId,
    finished,
    event,
    deviceId,
    frames,
  });

  return data;
};

export const getAssetsWatchingProgressByParent = async (parentId: string) => {
  const { data } = await api().get<ContinueWatchingItem[]>(
    `/favorites/watching/parent`,
    {
      params: {
        parentId,
      },
    }
  );
  return data;
};

export const getAssetWatchingProgress = async (id: string) => {
  const { data } = await api().get<ContinueWatchingItem>(
    `/favorites/watching/${id}`
  );
  return data;
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import styles from './index.module.scss';

// Please note that we don't have access to theme when there is settings error
interface AppLoadingErrorPageProps {
  error: Error;
}

const AppLoadingErrorPage = ({ error }: AppLoadingErrorPageProps) => {
  const { t } = useTranslation();

  const errorPageConfig = useMemo(() => {
    const errorResponseStatus = Number((error as AxiosError).response?.status);

    switch (errorResponseStatus) {
      case 420:
        return {
          header: t('errorPage.VPNErrorHeader'),
          message: t('errorPage.VPNErrorMessage'),
        };
      default:
        return {
          header: t('errorPage.genericErrorHeader'),
          message: t('errorPage.genericErrorMessage'),
        };
    }
  }, [error, t]);

  const onTryAgainPress = () => window.location.reload();

  return (
    <div className={styles.errorPageWrapper}>
      <h2 className={styles.errorHeader}>{errorPageConfig.header}</h2>
      <span className={styles.errorMessage}>{errorPageConfig.message}</span>
      <button className={styles.tryAgainButton} onClick={onTryAgainPress}>
        {t('common.tryAgain')}
      </button>
    </div>
  );
};

export default AppLoadingErrorPage;

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAppLinks,
  selectAppSocialLinks,
  selectCompanyName,
} from 'store/app/selectors';
import cn from 'classnames';
import { links, socials } from 'utils/companyLinks';
import styles from './index.module.scss';

const Footer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const appLinks = useSelector(selectAppLinks);
  const socialLinks = useSelector(selectAppSocialLinks);
  const companyName = useSelector(selectCompanyName);

  const filteredLinks = links.filter((l) => appLinks?.[l.link] !== undefined);

  return (
    <footer className={cn(styles.root, className)}>
      <ul className={styles.list}>
        {socials
          .filter((s) => !!socialLinks?.[s.link])
          .map(({ link, Icon }, i) => (
            <li className={styles.listItem} key={i}>
              <a
                href={socialLinks?.[link]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon />
              </a>
            </li>
          ))}
      </ul>
      <ul className={cn(styles.list, styles.links)}>
        {filteredLinks.map((l) => (
          <li className={styles.listItem} key={l.text}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={appLinks?.[l.link]}
            >
              {t(l.text)}
            </a>
          </li>
        ))}
      </ul>
      <div className={styles.copyright}>&copy; {companyName}</div>
    </footer>
  );
};

export default Footer;

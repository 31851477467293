export enum OS {
  WINDOWS_PHONE = 'Windows Phone',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
  ANDROID = 'Android',
}

declare global {
  interface Window {
    opera?: string;
    MSStream?: string;
  }
}

const getUserMobileOs = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || window.opera || '';

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OS.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return OS.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.IOS;
  }

  return OS.UNKNOWN;
};

export const isIos = () => getUserMobileOs() === OS.IOS;

export default getUserMobileOs;

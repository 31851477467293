import { RootState } from 'store/reducer';
import { FrameType } from '@laminar-product/client-commons-core/core';
import { PLAYER_INITIALIZING_STATE } from './types';

export const selectPlayerVideoElement = (state: RootState) =>
  state.player.videoElement;

export const selectPlayerAssetId = (state: RootState) => state.player.assetId;

export const selectPlayerAsset = (state: RootState) =>
  state.player.assetId ? state.assets[state.player.assetId] : null;

export const selectPlayerFrames = (state: RootState) => state.player.frames;

export const selectPlayerInitializingState = (state: RootState) =>
  state.player.initializingState;

export const selectPlayerInitialized = (state: RootState) =>
  state.player.initializingState === PLAYER_INITIALIZING_STATE.LOADED;

export const selectPlayer = (state: RootState) => state.player.player;

export const selectPlayerNextEpisodeId = (state: RootState) =>
  state.player.nextEpisodeId;

export const selectPlayerNextEpisode = (state: RootState) =>
  state.player.nextEpisodeId ? state.assets[state.player.nextEpisodeId] : null;

export const selectPlayerContent = (state: RootState) => state.player.content;

export const selectPlayerPlaybackId = (state: RootState) =>
  state.player.playbackId;

export const selectPlayerError = (state: RootState) => state.player.error;

export const selectPlayerPlaylist = (state: RootState) => state.player.playlist;

export const selectPlayerPlaylistActiveElement = (state: RootState) =>
  state.player.playlistActiveElement;

export const selectPlayerCurrentFrame = (state: RootState) =>
  state.player.currentFrame;

export const selectPlayerCurrentFrameIsAd = (state: RootState) =>
  state.player.currentFrame?.type === FrameType.ADS;

import React from 'react';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const EmptyWatchlist = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyWatchlist}>
      <div className={styles.emptyWatchlist__icon}>
        <AddIcon />
      </div>
      <h2 className={styles.emptyWatchlist__heading}>
        {t('watchlist.empty.heading')}
      </h2>
      <p className={styles.emptyWatchlist__description}>
        {t('watchlist.empty.description')}
      </p>
    </div>
  );
};

export default EmptyWatchlist;

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserLoggedIn } from 'store/user/selectors';
import { useEffect } from 'react';
import { APP_PAGE_PATH } from './navigation';

const useRedirectToHomePage = () => {
  const {
    location: { pathname },
    replace,
  } = useHistory();
  const userLoggedIn = useSelector(selectUserLoggedIn);

  useEffect(() => {
    if (!userLoggedIn) {
      return;
    }

    const paths = [APP_PAGE_PATH.LOGIN, APP_PAGE_PATH.REGISTER] as string[];

    if (paths.includes(pathname)) {
      replace('/');
    }
  }, [pathname, replace, userLoggedIn]);
};

export default useRedirectToHomePage;

import api from 'api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Asset,
  Collection,
  ContinueWatchingItem,
} from '@laminar-product/client-commons-core/core';
import { getAsset } from 'actions/assets';
import { useTranslation } from 'react-i18next';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import _orderBy from 'lodash/orderBy';
import { selectAssets } from 'store/assets/selectors';
import { CarouselType, isContinueWatchingCarousel } from './carouselHelpers';
import { captureError } from './captureError';
import { useGetCurrentProfile } from './profiles';
import { shouldDisplayAssetInContinueWatching } from './watchingProgress';

const useCarouselContinueWatchingData = (carouselType?: CarouselType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cachedAssets = useSelector(selectAssets);
  const [carouselData, setCarouselData] = useState<Collection>({
    assets: [],
    title: '',
    administrativeName: '',
  });
  const [continueWatchingItems, setContinueWatchingItems] = useState<
    ContinueWatchingItem[] | undefined
  >();
  const currentProfileUuid = useGetCurrentProfile()?.uuid;

  useEffect(() => {
    if (!isContinueWatchingCarousel(carouselType) || !currentProfileUuid)
      return;
    api()
      .get(`/favorites/watching`)
      .then(async ({ data }) => {
        setContinueWatchingItems(data);
      })
      .catch((e) => {
        captureError(
          e,
          'hooks/useCarouselContinueWatchingData/getContinueWatching',
          Severity.Warning
        );
      });
  }, [dispatch, carouselType, currentProfileUuid]);

  useEffect(() => {
    if (!continueWatchingItems) return;
    const continueWatchingArray = Object.values(continueWatchingItems) || [];

    const sortedContinueWatching = _orderBy(
      continueWatchingArray,
      'createdAt',
      'desc'
    );

    Promise.all(
      sortedContinueWatching
        .filter((item) => !item.finished)
        .map(async (item: ContinueWatchingItem) => {
          try {
            let asset;

            const cachedAsset =
              (cachedAssets && cachedAssets[item.assetUuid]) || undefined;

            if (cachedAsset) {
              asset = cachedAsset;
              asset.progress = item.currentSecond;
            }

            if (!cachedAsset) {
              asset = await getAsset(item.assetUuid);
              asset.progress = item.currentSecond;
            }

            return asset;
          } catch (error) {
            captureError(
              error as AxiosError,
              'hooks/useCarouselContinueWatchingData/getAsset',
              Severity.Warning,
              { extra: { assetId: item.assetUuid } }
            );
          }
        })
    ).then((assets) => {
      const assetsCarousel = assets.filter((asset) =>
        shouldDisplayAssetInContinueWatching(asset)
      );

      setCarouselData({
        assets: assetsCarousel as Asset[],
        title: t('common.continueWatching'),
        administrativeName: 'continue-watching',
      });
    });
  }, [cachedAssets, continueWatchingItems, t]);

  return {
    continueWatchingData: carouselData,
  };
};

export default useCarouselContinueWatchingData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectAppLinks, selectCompanyName } from 'store/app/selectors';
import { links } from 'utils/companyLinks';
import styles from '../index.module.scss';
import footerStyles from './index.module.scss';

const SettingsFooter = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const appLinks = useSelector(selectAppLinks);
  const companyName = useSelector(selectCompanyName);

  return (
    <footer
      className={cn(styles.root, footerStyles.settingsFooterRoot, className)}
    >
      <ul className={cn(styles.list, styles.links)}>
        {links.map((l) => (
          <li className={styles.listItem} key={l.text}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={appLinks?.[l.link]}
            >
              {t(l.text)}
            </a>
          </li>
        ))}
      </ul>
      <div className={styles.copyright}>&copy; {companyName}</div>
    </footer>
  );
};

export default SettingsFooter;

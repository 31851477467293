import LinearProfileWrapper from 'components/LinearProfileWrapper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteProfile } from 'actions/profiles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfiles } from 'store/user/actions';
import ProfileModalHeader from 'components/ProfileModalHeader';
import { settingsPath } from 'utils/constants';
import ProfileAvatar from 'components/ProfileAvatar';
import {
  checkIsKidsProfile,
  useGetCurrentProfile,
  useSetCurrentProfile,
} from 'utils/profiles';
import { Profile } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import { selectProfiles } from 'store/user/selectors';
import { Button } from '@laminar-product/client-commons-core/web';
import styles from './index.module.scss';

interface ProfileRemoveProps {
  onClose: () => void;
  onRemoveSuccess?: () => void;
  profile?: Profile;
}

const ProfileRemove = ({
  onClose,
  profile,
  onRemoveSuccess,
}: ProfileRemoveProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const currentProfile = useGetCurrentProfile();
  const profiles = useSelector(selectProfiles);
  const setCurrentProfile = useSetCurrentProfile();

  const handleRemoveProfile = () => {
    if (!profile?.uuid) return;

    const defaultProfile = profiles?.find((profile) => profile.defaultProfile);

    if (profile.uuid === defaultProfile?.uuid) return;

    deleteProfile(profile.uuid)
      .then(() => {
        const newProfiles = profiles?.filter(
          (profileItem) => profileItem.uuid !== profile.uuid
        );

        if (newProfiles) {
          dispatch(setUserProfiles(newProfiles));
        }

        if (
          defaultProfile &&
          profile.uuid === currentProfile?.uuid &&
          !defaultProfile.pin
        ) {
          setCurrentProfile(defaultProfile);
        }

        if (onRemoveSuccess) {
          return onRemoveSuccess();
        }

        push(settingsPath);
      })
      .catch((e) => {
        setError(e.message);
        captureError(
          e as AxiosError,
          'ProfileRemove/deleteProfile',
          Severity.Warning,
          { extra: { profileUuid: profile.uuid } }
        );
      });
  };

  return (
    <LinearProfileWrapper>
      <div className={styles.wrapper}>
        <ProfileAvatar
          name={profile?.name}
          isKidsProfile={checkIsKidsProfile(profile)}
        />

        <div className={styles.header}>
          <ProfileModalHeader text={t('profile.deteleProfileQuestion')} />
        </div>
        <div className={styles.description}>
          {t('profile.deleteDescription')}
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            {t('profile.keepProfile')}
          </Button>

          <Button variant="primary" onClick={handleRemoveProfile}>
            {t('profile.deleteProfile')}
          </Button>
        </div>
      </div>
    </LinearProfileWrapper>
  );
};

export default ProfileRemove;

import {
  Asset,
  AssetPlayback,
  AssetToken,
  CatalogAsset,
  GetAssetPlaybackProps,
  GetAssetTokenType,
  RootAssetResponse,
} from '@laminar-product/client-commons-core/core';
import api from 'api';
import { getDeviceType } from 'utils/device';

interface GetAssetTokenProps {
  id: string;
  contentId?: string;
  deviceId?: string;
  type?: GetAssetTokenType;
}

export const getAsset = async (id: string) => {
  const { data } = await api().get<Asset>(`/asset/v2/${id}`);

  return data;
};

export const getAssetToken = async ({
  id,
  contentId,
  deviceId,
  type,
}: GetAssetTokenProps) => {
  const { data } = await api().post<AssetToken>(
    `/asset/${id}/token${contentId ? `?contentId=${contentId}` : ''}`,
    { deviceId, type }
  );

  return data;
};

//NOTE: Pathname (asset -> paths -> catalogPath) - it's a url for redirecting to catalog page
export const getAssetCatalogDetails = async (pathname: string) => {
  const { data } = await api().get<CatalogAsset>(pathname);

  return data;
};

export const getAssetPlayback = async ({
  assetId,
  contentId,
  deviceId,
  playbackId,
  type,
  streamType,
}: GetAssetPlaybackProps) => {
  const { data } = await api().post<AssetPlayback[]>(
    `/asset/${assetId}/playback?contentId=${contentId}&streamType=${streamType}`,
    {
      deviceId,
      type,
      playbackId,
      device: {
        deviceType: getDeviceType(),
      },
      adParams: {
        device: {
          deviceType: getDeviceType(),
        },
      },
    }
  );

  return data;
};

export const getAssetWatchDetails = async (assetId: string) => {
  const { data } = await api().get<RootAssetResponse>(
    `/asset/${assetId}/watch`
  );

  return data;
};

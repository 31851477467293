import {
  BillingAddress,
  BillingAddressValue,
  BillingName,
  BillingNameValue,
} from '@laminar-product/client-commons-core/core';
import { FieldErrors } from 'react-hook-form';
import styles from '../index.module.scss';

type BillingFormType = BillingAddress & BillingName;
interface BillingInputErrorProps {
  errors: FieldErrors<BillingAddress & BillingFormType>;
  label: BillingAddressValue | BillingNameValue;
}

const BillingInputError = ({ label, errors }: BillingInputErrorProps) => {
  return <div className={styles.error}>{errors[label]?.message || ''}</div>;
};

export default BillingInputError;

import { useEffect, useMemo, useState } from 'react';

export const secondsToMinutesLabel = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedSeconds = ('0' + remainingSeconds).slice(-2);
  return minutes + ':' + formattedSeconds;
};

const useCountdown = (initialValue: number) => {
  const [countdown, setCountdown] = useState(initialValue);

  const shouldDisplayCounter = useMemo(() => countdown > 0, [countdown]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  return { countdown, shouldDisplayCounter };
};

export default useCountdown;

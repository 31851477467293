import {
  Asset,
  CatalogAsset,
  getAssetDetailsPath,
} from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useMoveToAssetDetails = () => {
  const { replace, push } = useHistory();

  return useCallback(
    (asset: Asset | CatalogAsset, variant?: 'push' | 'replace') => {
      //From catalog to asset page if access to asset, need replace
      if ('nextPage' in asset) {
        replace(asset.nextPage);
        return;
      }

      //Autoredirect to catalog if asset not in region
      if (!asset.inRegion && !!asset.paths) {
        push(asset.paths.catalogPath);
        return;
      }

      if (asset.paths) {
        return variant === 'replace'
          ? replace(asset.paths.resourcePath)
          : push(asset.paths.resourcePath);
      }

      const { id, administrativeName, type } = asset;
      const path = getAssetDetailsPath({
        id,
        administrativeName,
        type,
      });

      variant === 'replace' ? replace(path) : push(path);
    },
    [push, replace]
  );
};

import { Button } from '@laminar-product/client-commons-core/web';
import {
  usePrevious,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-back.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-forward.svg';
import cn from 'classnames';
import PlanCard from 'components/PlanSelector/components/PlanCard';
import React, { useEffect, useRef } from 'react';
import { useCarousel } from 'utils/carouselHelpers';
import { PlanByIntervalWithPrice } from 'components/PlanSelector/types';
import useCarouselSwipe from 'utils/useCarouselSwipe';
import CarouselPagination from 'library/Carousel/CarouselPagination';
import styles from './index.module.scss';

export interface PlanCarouselProps {
  plans?: PlanByIntervalWithPrice[];
  activeIntervalKey: string;
}

const PlanCarousel = ({ plans, activeIntervalKey }: PlanCarouselProps) => {
  const { isTabletWindowSize } = useWindowSize();
  const carouselEl = useRef<HTMLDivElement>(null);
  const prevActiveIntervalKey = usePrevious(activeIntervalKey);

  const {
    goToPage,
    pages,
    currentPage,
    currentTransform,
    onSwipedLeft,
    onSwipedRight,
  } = useCarousel({
    wrapper: carouselEl?.current,
    itemsCount: plans?.length,
    isMobilePlan: isTabletWindowSize,
    carouselType: 'PlanCarousel',
  });

  const { swipeHandlers } = useCarouselSwipe({
    onSwipedLeft,
    onSwipedRight,
  });

  useEffect(() => {
    if (activeIntervalKey !== prevActiveIntervalKey) {
      goToPage(0);
    }
  }, [activeIntervalKey, goToPage, prevActiveIntervalKey]);

  return (
    <>
      <div className={cn(styles.plans, { [styles.center]: pages === 1 })}>
        {pages > 1 && (
          <Button
            variant="secondary-settings"
            round
            className={cn(styles.navigationButton, styles.leftNavigationButton)}
            onClick={() => goToPage(currentPage - 1)}
          >
            <ArrowLeft />
          </Button>
        )}

        <div
          className={styles.carouselWrapper}
          ref={carouselEl}
          id={activeIntervalKey}
        >
          <div
            className={cn(styles.carousel)}
            style={{ transform: `translate3d(${currentTransform}%,0,0)` }}
            {...swipeHandlers}
          >
            {plans?.map((plan, index) => {
              if (!plan.price) {
                return null;
              }

              return (
                <PlanCard
                  key={`${plan._id}-${index}`}
                  plan={plan}
                  data-testid={`PlanCard__${plan.name}`}
                />
              );
            })}
          </div>
        </div>

        {pages > 1 && (
          <Button
            variant="secondary-settings"
            round
            className={cn(
              styles.navigationButton,
              styles.rightNavigationButton
            )}
            onClick={() => goToPage(currentPage + 1)}
            data-testid="PlanCarousel__RightNavigationButton"
          >
            <ArrowRight />
          </Button>
        )}
      </div>

      <div className={styles.pagination}>
        <CarouselPagination
          goToPage={goToPage}
          currentPage={currentPage}
          pages={pages}
          itemClassName={styles.item}
          activeItemClassName={styles.itemActive}
        />
      </div>
    </>
  );
};

export default PlanCarousel;

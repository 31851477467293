import {
  PaymentGateway,
  SaveBillingAddressParams,
  TaxConfiguration,
  TaxEstimation,
  TaxEstimationParams,
} from '@laminar-product/client-commons-core/core';
import api from 'api';

export const getTaxConfiguration = async (
  paymentGateway: PaymentGateway
): Promise<TaxConfiguration> => {
  const { data } = await api().get(
    `tax/configuration-client?gateway=${paymentGateway}`
  );
  return data;
};

export const estimateTax = async (
  estimationData: TaxEstimationParams
): Promise<TaxEstimation> => {
  const { data } = await api().post('/tax/tax-estimation', estimationData);
  return data;
};

export const saveBillingAddress = async (
  saveBillingAddressData: SaveBillingAddressParams
): Promise<string> => {
  const { data } = await api().post('/tax/address', saveBillingAddressData);
  return data;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { selectAppLinks } from 'store/app/selectors';
import { Trans } from 'react-i18next';
import cn from 'classnames';
import styles from './index.module.scss';

interface PolicyNoteProps {
  light?: boolean;
}

const PolicyNote = ({ light }: PolicyNoteProps) => {
  const appLinks = useSelector(selectAppLinks);

  return (
    <p className={cn(styles.policyText, { [styles.policyTextLight]: light })}>
      <Trans i18nKey="common.acceptTerms">
        By proceeding you agree to our{' '}
        <a
          href={appLinks?.termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Terms of Services
        </a>
        and{' '}
        <a
          href={appLinks?.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Privacy Policy
        </a>
      </Trans>
    </p>
  );
};

export default PolicyNote;

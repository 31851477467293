export const CLOSE_MOBILE_ALERT = 'CLOSE_MOBILE_ALERT';
export const OPEN_MOBILE_ALERT = 'OPEN_MOBILE_ALERT';

export const openMobileAlert = () => ({
  type: OPEN_MOBILE_ALERT,
});

export const closeMobileAlert = () => ({
  type: CLOSE_MOBILE_ALERT,
});

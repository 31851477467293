import _times from 'lodash/times';
import { CarouselType, getCarouselTypeMinHeight } from 'utils/carouselHelpers';
import styles from './index.module.scss';

const CarouselSkeleton = () => {
  return (
    <div
      className={styles.container}
      style={{
        minHeight: getCarouselTypeMinHeight(CarouselType.CAROUSEL),
      }}
    >
      <div className={styles.title} />

      <div className={styles.carouselsContainer}>
        {_times(7, (i) => {
          return (
            <div className={styles.carouselItemWrapper} key={i}>
              <div className={styles.carouselItem} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CarouselSkeleton;

import { User } from '@laminar-product/client-commons-core/core';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getCard } from 'actions/payments';
import CardPaymentBox from 'components/CardPaymentBox';
import PromptEmailModal, { PromptEmailForm } from 'components/PromptEmailModal';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Subscription } from 'types/subscription';
import { canChangeSubscription } from 'utils/price';
import useExternalPayment, {
  PaymentActionType,
} from 'utils/useExternalPayment';
import SettingsHeading from 'components/SettingsHeading';
import styles from '../../index.module.scss';

interface AccountPaymentMethodProps {
  subscription: Subscription;
  userData?: User;
}

const AccountPaymentMethod = ({
  subscription,
  userData,
}: AccountPaymentMethodProps) => {
  const { t } = useTranslation();
  const [card, cardIsLoading, cardFetchError] = useFetchAction<string>(getCard);
  const {
    handlePaymentAction,
    cardChangingError,
    isPromptingEmail,
    setIsPromptingEmail,
  } = useExternalPayment();

  const onPromptEmailSubmit = useCallback(
    ({ email }: PromptEmailForm) => {
      setIsPromptingEmail(false);

      handlePaymentAction({
        email,
        actionType: PaymentActionType.PAYMENT_INFO_CHANGE,
      });
    },
    [handlePaymentAction, setIsPromptingEmail]
  );

  if (
    !card ||
    !canChangeSubscription(subscription) ||
    !subscription ||
    !userData
  )
    return null;

  return (
    <>
      <SettingsHeading title={t('settings.paymentMethod')} />

      <div className={styles.row}>
        <CardPaymentBox
          card={card}
          isError={!!cardFetchError}
          isLoading={cardIsLoading}
          gateway={subscription.gateway}
        />

        {!cardFetchError && (
          <div className={styles.paymentChange}>
            <button
              onClick={() =>
                handlePaymentAction({
                  actionType: PaymentActionType.PAYMENT_INFO_CHANGE,
                })
              }
              className={styles.link}
            >
              {t('settings.changePaymentInfo')}
            </button>

            {cardChangingError && (
              <span className={styles.errorMessage}>
                {t('errors.somethingWentWrong')}
              </span>
            )}
          </div>
        )}
      </div>

      {isPromptingEmail && (
        <PromptEmailModal
          onClose={() => setIsPromptingEmail(false)}
          onSubmit={onPromptEmailSubmit}
          actionType={PaymentActionType.PAYMENT_INFO_CHANGE}
        />
      )}
    </>
  );
};

export default AccountPaymentMethod;

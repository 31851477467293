import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import React from 'react';
import { Plan, Price } from '@laminar-product/client-commons-core/core';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/price';
import styles from './index.module.scss';

interface PlanDetailsProps {
  plan: Plan;
  price: Price;
}

const PlanDetails = ({ plan, price }: PlanDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.planDetails}>
      <div className={styles.icon}>
        <CrownIcon />
      </div>
      <div className={styles.planNameWrapper}>
        <span className={styles.planName} data-testid="OrderSummary__PlanName">
          {plan.name}
        </span>
        <span className={styles.billing}>
          {t('orderSummary.billed', {
            count: price.intervalMultiplier,
            interval: t(`common.intervals.${price.interval.toLowerCase()}`),
          })}
        </span>
      </div>
      <div
        className={styles.planPrice}
        data-testid="OrderSummary__BillingPrice"
      >
        {formatPrice(price.amount, price.currency)}
      </div>
    </div>
  );
};

export default PlanDetails;

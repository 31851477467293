import { useCallback, useRef } from 'react';

const useTimeout = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const callback = useCallback((callback: () => void, delay?: number) => {
    timeoutRef.current = setTimeout(() => callback(), delay || 500);
  }, []);

  return {
    callback,
    clear,
  };
};

export default useTimeout;

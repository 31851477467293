import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { allowedPlansIntervalsKeys } from 'components/PlanSelector/constants';
import { PlansByInterval } from 'components/PlanSelector/types';
import styles from './index.module.scss';

interface PlansIntervalSelectorProps {
  plansByInterval: PlansByInterval;
  activeIntervalKey?: string;
  onIntervalKeyChange: (key: string) => void;
}

const PlansIntervalSelector = ({
  plansByInterval,
  activeIntervalKey,
  onIntervalKeyChange,
}: PlansIntervalSelectorProps) => {
  const { t } = useTranslation();

  const availableIntervals = allowedPlansIntervalsKeys.filter((k) =>
    Object.keys(plansByInterval).some((key) => key === k)
  );

  const getIntervalTranslation = (key: string) => {
    switch (key) {
      case 'OTHER':
        return t('planSelector.otherInterval');
      default:
        return t(`planSelector.${key.toLowerCase()}Interval`);
    }
  };

  if (!availableIntervals?.length) {
    return null;
  }

  return (
    <div className={styles.intervalSelectorWrapper}>
      {availableIntervals.map((interval) => (
        <div
          className={cn(styles.intervalItem, {
            [styles.activeIntervalItem]: interval === activeIntervalKey,
          })}
          onClick={() => onIntervalKeyChange(interval)}
        >
          {getIntervalTranslation(interval)}
        </div>
      ))}
    </div>
  );
};

export default PlansIntervalSelector;

import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getOtpProvider } from 'actions/otpProvider';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPhoneNumber } from 'store/user/selectors';
import { Severity } from 'types/errors';
import { captureError } from 'utils/captureError';
import { ProfileConfirmationProps } from '../../ProfileIdentityConfirmation';
import CustomProviderPhoneConfirmation from './components/CustomProviderPhoneConfirmation';
import FirebasePhoneConfirmation from './components/FirebasePhoneConfirmation';

const PhoneConfirmationProvider = ({
  onClose,
  onConfirmationSuccess,
}: ProfileConfirmationProps) => {
  const phoneNumber = useSelector(selectUserPhoneNumber);

  const {
    data: otpProvider,
    isLoading,
    error,
  } = useFetchActionObj(
    useCallback(() => getOtpProvider(phoneNumber), [phoneNumber]),
    {
      onError: useCallback((error: Error) => {
        captureError(error, 'PhoneAuthProvider', Severity.Error);
      }, []),
    }
  );

  if (!phoneNumber) {
    onClose();
    return null;
  }

  if (isLoading || (!otpProvider && !error))
    return <AppLoadingIndicator fullScreen />;

  return !otpProvider?.next || error ? (
    <FirebasePhoneConfirmation
      onClose={onClose}
      onConfirmationSuccess={onConfirmationSuccess}
      phoneNumber={phoneNumber}
    />
  ) : (
    <CustomProviderPhoneConfirmation
      onClose={onClose}
      onConfirmationSuccess={onConfirmationSuccess}
      otpGenerationUrl={otpProvider.next}
    />
  );
};

export default PhoneConfirmationProvider;

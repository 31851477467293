import { AnyAction } from 'redux';
import {
  AssetPlaylist,
  Content,
  ContentFrame,
} from '@laminar-product/client-commons-core/core';
import shaka from 'shaka-player/dist/shaka-player.ui';
import {
  RESET_PLAYER,
  SET_PLAYER,
  SET_PLAYER_ASSET_ID,
  SET_PLAYER_BUFFER_ID,
  SET_PLAYER_CONTENT,
  SET_PLAYER_CURRENT_FRAME,
  SET_PLAYER_ERROR,
  SET_PLAYER_FRAMES,
  SET_PLAYER_INITIALIZING_STATE,
  SET_PLAYER_NEXT_EPISODE_ID,
  SET_PLAYER_PLAYBACK_ID,
  SET_PLAYER_PLAYLIST,
  SET_PLAYER_PLAYLIST_ACTIVE_ELEMENT,
  SET_PLAYER_STRATA_STATUS,
  SET_PLAYER_VIDEO_ELEMENT,
} from './actions';
import { PLAYER_INITIALIZING_STATE, PLAYER_STRATA_STATUS } from './types';

export interface PlayerState {
  assetId?: string;
  videoElement?: HTMLMediaElement | null;
  frames?: ContentFrame[];
  initializingState?: PLAYER_INITIALIZING_STATE;
  player?: shaka.Player | null;
  nextEpisodeId?: string;
  content?: Content;
  playbackId?: string;
  bufferId?: string;
  error?: {
    errorCode: string;
  };
  playlist?: AssetPlaylist[];
  playlistActiveElement?: AssetPlaylist;
  strataStatus?: PLAYER_STRATA_STATUS;
  currentFrame?: ContentFrame;
}

const playerReducer = (
  state: PlayerState = {},
  action: AnyAction
): PlayerState => {
  switch (action.type) {
    case RESET_PLAYER:
      return {};
    case SET_PLAYER_ASSET_ID:
      return {
        ...state,
        assetId: action.payload,
      };
    case SET_PLAYER_VIDEO_ELEMENT:
      return {
        ...state,
        videoElement: action.payload,
      };
    case SET_PLAYER_FRAMES:
      return {
        ...state,
        frames: action.payload,
      };
    case SET_PLAYER_INITIALIZING_STATE:
      return {
        ...state,
        initializingState: action.payload,
      };
    case SET_PLAYER:
      return {
        ...state,
        player: action.payload,
      };
    case SET_PLAYER_NEXT_EPISODE_ID:
      return {
        ...state,
        nextEpisodeId: action.payload,
      };
    case SET_PLAYER_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    case SET_PLAYER_PLAYBACK_ID:
      return {
        ...state,
        playbackId: action.payload,
      };
    case SET_PLAYER_BUFFER_ID:
      return {
        ...state,
        bufferId: action.payload,
      };
    case SET_PLAYER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_PLAYER_PLAYLIST:
      return {
        ...state,
        playlist: action.payload,
      };
    case SET_PLAYER_PLAYLIST_ACTIVE_ELEMENT:
      return {
        ...state,
        playlistActiveElement: action.payload,
      };
    case SET_PLAYER_STRATA_STATUS:
      return {
        ...state,
        strataStatus: action.payload,
      };
    case SET_PLAYER_CURRENT_FRAME:
      return {
        ...state,
        currentFrame: action.payload,
      };
    default:
      return state;
  }
};

export default playerReducer;

import Castjs from '../../../chromecast/cast';
import { AvailableTracks, ChromecastStatus } from './types';

export const SET_CHROMECAST_CAST_ELEMENT = 'SET_CHROMECAST_CAST_ELEMENT';
export const setChromecastCastElement = (castElement?: Castjs) => ({
  type: SET_CHROMECAST_CAST_ELEMENT,
  payload: castElement,
});

export const SET_CHROMECAST_ASSET_ID = 'SET_CHROMECAST_ASSET_ID';
export const setChromecastAssetId = (id?: string) => ({
  type: SET_CHROMECAST_ASSET_ID,
  payload: id,
});

export const SET_CHROMECAST_AVAILABLE = 'SET_CHROMECAST_AVAILABLE';
export const setChromecastAvailable = (available?: boolean) => ({
  type: SET_CHROMECAST_AVAILABLE,
  payload: available,
});

export const SET_CHROMECAST_STATUS = 'SET_CHROMECAST_STATUS';
export const setChromecastStatus = (status?: ChromecastStatus) => ({
  type: SET_CHROMECAST_STATUS,
  payload: status,
});

export const SET_CHROMECAST_CONTENT_ID = 'SET_CHROMECAST_CONTENT_ID';
export const setChromecastContentId = (id?: string) => ({
  type: SET_CHROMECAST_CONTENT_ID,
  payload: id,
});

export const SET_CHROMECAST_AVAILABLE_TRACKS =
  'SET_CHROMECAST_AVAILABLE_TRACKS';
export const setChromecastAvailableTracks = (
  availableTracks?: AvailableTracks
) => ({
  type: SET_CHROMECAST_AVAILABLE_TRACKS,
  payload: availableTracks,
});

export const SET_CHROMECAST_ACTIVE_TEXT_TRACK_ID =
  'SET_CHROMECAST_ACTIVE_TEXT_TRACK_ID';
export const setChromecastActiveTextTrackId = (activeTextTrackId?: number) => ({
  type: SET_CHROMECAST_ACTIVE_TEXT_TRACK_ID,
  payload: activeTextTrackId,
});

export const SET_CHROMECAST_ACTIVE_AUDIO_TRACK_ID =
  'SET_CHROMECAST_ACTIVE_AUDIO_TRACK_ID';
export const setChromecastActiveAudioTrackId = (
  activeAudioTrackId?: number
) => ({
  type: SET_CHROMECAST_ACTIVE_AUDIO_TRACK_ID,
  payload: activeAudioTrackId,
});

export const RESET_CHROMECAST_CONNECTION = 'RESET_CHROMECAST_CONNECTION';
export const resetChromecastConnection = () => ({
  type: RESET_CHROMECAST_CONNECTION,
});

export const SET_CHROMECAST_NEXT_EPISODE_ID = 'SET_CHROMECAST_NEXT_EPISODE_ID';
export const setChromecastNextEpisodeId = (nextEpisodeId?: string) => ({
  type: SET_CHROMECAST_NEXT_EPISODE_ID,
  payload: nextEpisodeId,
});

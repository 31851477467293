import qs from 'qs';
import {
  MoveToListingContext,
  MoveToListingParams,
} from 'utils/useMoveToListing';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { listingPagePath } from 'utils/constants';
import { getPurgedObj } from 'utils/purgeObject';

export const MoveToListingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { push, replace } = useHistory();
  const location = useLocation();

  const moveToListing = useCallback(
    (id: string, params?: MoveToListingParams) => {
      const targetListingPath = listingPagePath.replace(':id', id);

      const clearedParams =
        params && (getPurgedObj(params) as MoveToListingParams);

      if (location.pathname !== targetListingPath) {
        push({
          pathname: targetListingPath,
          search: qs.stringify(clearedParams),
        });
      } else {
        replace({
          pathname: targetListingPath,
          search: qs.stringify(clearedParams),
        });
      }
    },
    [location.pathname, push, replace]
  );

  return (
    <MoveToListingContext.Provider value={moveToListing}>
      {children}
    </MoveToListingContext.Provider>
  );
};

export default MoveToListingProvider;

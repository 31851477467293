import { Button } from '@laminar-product/client-commons-core/web';
import ProfileModalHeader from 'components/ProfileModalHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'store/user/selectors';
import CodeInput from 'react-code-input';
import { useResendOtpTimeout } from 'utils/useResentOtpTimeout';
import cn from 'classnames';
import { PROFILE_VERIFY_OTP_LENGTH } from 'utils/constants';
import ConfirmationIcon from '../../ConfirmationIcon';
import styles from '../../index.module.scss';

interface PhoneConfirmationProps {
  onClose: () => void;
  confirmOtp: (otpValue: string) => void;
  sendOtp: () => void;
  isConfirmationResultSent: boolean;
  errorMessage: string;
  isConfirming: boolean;
  isAuthenticationError?: boolean;
}

const PhoneConfirmation = ({
  onClose,
  confirmOtp,
  sendOtp,
  isConfirmationResultSent,
  errorMessage,
  isConfirming,
  isAuthenticationError,
}: PhoneConfirmationProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserDetails);
  const [pinValue, setPinValue] = useState('');
  const { resendOtpTimeout, setResendOtpTimeout } = useResendOtpTimeout();

  const shouldDisableContinueButton =
    isConfirmationResultSent &&
    (!pinValue || pinValue.length < PROFILE_VERIFY_OTP_LENGTH);

  const onResendOtp = () => {
    setResendOtpTimeout();
    sendOtp();
  };

  return (
    <div className={styles.container}>
      <div id="recaptcha-verify-container" />
      <ConfirmationIcon />
      <ProfileModalHeader text={t('profile.confirmWithPhone')} />
      <p className={styles.note}>
        {isConfirmationResultSent
          ? t('profile.phoneOTPSent', {
              phoneNumber: user?.phoneNumber,
            })
          : t('profile.phoneNote', {
              phoneNumber: user?.phoneNumber,
            })}
      </p>

      {isConfirmationResultSent && (
        <div className={styles.codeWrapper}>
          <CodeInput
            autoFocus={false}
            value={pinValue}
            onChange={setPinValue}
            inputMode="latin"
            name="oneTimeCode"
            type="number"
            fields={6}
            className={styles.codeInput}
          />
        </div>
      )}

      {!!errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <div className={styles.buttons}>
        <Button
          variant="secondary"
          onClick={onClose}
          className={styles.cancelBtn}
        >
          {t('common.cancel')}
        </Button>

        <Button
          onClick={
            isConfirmationResultSent ? () => confirmOtp(pinValue) : sendOtp
          }
          variant="primary"
          loading={isConfirming}
          disabled={shouldDisableContinueButton}
        >
          {isConfirmationResultSent
            ? t('common.continue')
            : t('profile.sendOTP')}
        </Button>
      </div>

      {isConfirmationResultSent &&
        !isAuthenticationError &&
        (resendOtpTimeout <= 0 ? (
          <div
            onClick={onResendOtp}
            className={cn(styles.resendText, {
              [styles.disabled]: resendOtpTimeout > 0,
            })}
          >
            {t('profile.resendOTP')}
          </div>
        ) : (
          <div className={styles.resendTimeout}>
            {t('profile.requestTime', {
              resendOtpTimeout,
            })}
          </div>
        ))}
    </div>
  );
};

export default PhoneConfirmation;

import React, { useCallback, useMemo, useState } from 'react';
import ProfileModalHeader from 'components/ProfileModalHeader';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import {
  SocialAuthenticationProviders,
  getSocialProviderName,
} from '@laminar-product/client-commons-core/core';
import { getAuth, reauthenticateWithPopup } from 'firebase/auth';
import { createSocialProviderInstance, useSocialErrors } from 'utils/auth';
import Popup from 'components/Popup';
import { AuthenticationFailedProps } from 'components/SocialLogin';
import { ReactComponent as FacebookIcon } from 'assets/icons/socialCustomBtn/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/socialCustomBtn/twitter.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/socialCustomBtn/google.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/socialCustomBtn/apple.svg';
import { Button } from '@laminar-product/client-commons-core/web';
import { ProfileConfirmationProps } from '../../ProfileIdentityConfirmation';
import ConfirmationIcon from '../ConfirmationIcon';
import styles from '../index.module.scss';

interface SocialConfirmationProps extends ProfileConfirmationProps {
  socialProviderId?: string;
}

const SocialConfirmation = ({
  onClose,
  onConfirmationSuccess,
  socialProviderId,
}: SocialConfirmationProps) => {
  const { t } = useTranslation();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { handleSocialError } = useSocialErrors();
  const [authenticationFailed, setAuthenticationFailed] =
    useState<AuthenticationFailedProps>();

  const providerType = useMemo(
    () => getSocialProviderName(socialProviderId),
    [socialProviderId]
  );

  const onConfirm = useCallback(() => {
    if (!providerType) {
      return;
    }

    const provider = createSocialProviderInstance(providerType);
    const firebaseUser = getAuth().currentUser;

    if (firebaseUser && provider) {
      setIsAuthenticating(true);

      reauthenticateWithPopup(firebaseUser, provider)
        .then(onConfirmationSuccess)
        .catch((error) =>
          handleSocialError({
            error,
            onError: setAuthenticationFailed,
            type: providerType,
          })
        )
        .finally(() => setIsAuthenticating(false));
    }
  }, [handleSocialError, onConfirmationSuccess, providerType]);

  const getSocialIcon = useCallback(() => {
    switch (providerType) {
      case SocialAuthenticationProviders.APPLE:
        return <AppleIcon />;
      case SocialAuthenticationProviders.FACEBOOK:
        return <FacebookIcon />;
      case SocialAuthenticationProviders.GOOGLE:
        return <GoogleIcon />;
      case SocialAuthenticationProviders.TWITTER:
        return <TwitterIcon />;
    }
  }, [providerType]);

  return (
    <div className={styles.container}>
      <ConfirmationIcon />
      <ProfileModalHeader text={t('profile.confirmWithSocial')} />
      <p className={styles.note}>{t('profile.socialNote')}</p>

      <div className={styles.buttons}>
        <Button
          variant="secondary"
          onClick={onClose}
          className={styles.cancelBtn}
        >
          {t('common.cancel')}
        </Button>

        <Button
          variant={
            providerType === SocialAuthenticationProviders.FACEBOOK
              ? 'social-facebook'
              : 'social'
          }
          onClick={onConfirm}
          loading={isAuthenticating}
        >
          {getSocialIcon()}
          {t('profile.continueWith', {
            socialProvider: _capitalize(
              getSocialProviderName(socialProviderId)
            ),
          })}
        </Button>
      </div>

      {authenticationFailed?.isFailed && (
        <Popup
          header={t('errors.authorizationFailed')}
          message={authenticationFailed.errorMessage}
          actionButtons={
            <Button
              variant="primary"
              onClick={() =>
                setAuthenticationFailed({ isFailed: false, errorMessage: '' })
              }
            >
              OK
            </Button>
          }
        />
      )}
    </div>
  );
};

export default SocialConfirmation;

import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  ProfileAvatarSize,
  checkIsKidsProfile,
  useSetCurrentProfile,
  useSignOut,
} from 'utils/profiles';
import {
  selectCanChangeProfiles,
  selectCurrentProfile,
  selectIsAnonymous,
} from 'store/user/selectors';
import { Button } from '@laminar-product/client-commons-core/web';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as UserIcon } from 'assets/icons/login-user.svg';
import { ReactComponent as WatchlistIcon } from 'assets/icons/add.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { selectMenuLinks } from 'store/app/selectors';
import useFeatureEnabled from 'components/FeatureEnabled/useFeatureEnabled';
import { FeatureFlag } from 'utils/constants';
import { ClickAway } from 'components/ClickAway';
import { useMenuContext } from 'utils/useMenuContext';
import MenuLink from 'components/MenuLink';
import ProfileAvatar from 'components/ProfileAvatar';
import { APP_PAGE_PATH } from 'utils/navigation';
import { setUserManagingProfilesVariant } from 'store/user/actions';
import { UserManagingProfilesVariant } from 'types/user';
import styles from './index.module.scss';

const SideMenu = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const currentProfile = useSelector(selectCurrentProfile);
  const isAnonymous = useSelector(selectIsAnonymous);
  const menuLinks = useSelector(selectMenuLinks);
  const { pathname } = useLocation();
  const showRegistration = useFeatureEnabled(FeatureFlag.showRegistration);
  const { isOpen, setIsOpen } = useMenuContext();
  const sideMenu = useRef<HTMLDivElement>(null);
  const setCurrentProfile = useSetCurrentProfile();
  const signOut = useSignOut();
  const canChangeProfile = useSelector(selectCanChangeProfiles);
  const dispatch = useDispatch();

  const onChangeProfileClick = useCallback(() => {
    if (!canChangeProfile) {
      return;
    }

    setCurrentProfile(null);
  }, [canChangeProfile, setCurrentProfile]);

  const onManageProfilesClick = () =>
    dispatch(
      setUserManagingProfilesVariant(UserManagingProfilesVariant.MANAGE)
    );

  useEffect(() => {
    sideMenu.current?.scrollTo?.(0, 0);
  }, [pathname]);

  return (
    <ClickAway
      data-testid="side-menu"
      className={cn(className, { [styles.scrollable]: isOpen })}
      onClick={() => setIsOpen(false)}
    >
      <aside ref={sideMenu} className={styles.root}>
        <div className={styles.userStrategy}>
          {!isAnonymous && currentProfile ? (
            <>
              <div
                className={cn(styles.profile, {
                  [styles.pointer]: canChangeProfile,
                })}
                onClick={onChangeProfileClick}
              >
                <ProfileAvatar
                  name={currentProfile.name}
                  size={ProfileAvatarSize.SMALL}
                  isKidsProfile={checkIsKidsProfile(currentProfile)}
                />

                <div className={styles.profileInfo}>
                  <p className={styles.profileName}>{currentProfile.name}</p>
                  {canChangeProfile && (
                    <span className={styles.changeProfile}>
                      {t('profile.changeProfile')}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  variant="text"
                  onClick={onManageProfilesClick}
                >
                  <PenIcon />
                  {t('profile.manageProfiles')}
                </Button>
                <Button
                  className={styles.button}
                  variant="text"
                  onClick={() => push('/settings')}
                >
                  <UserIcon />
                  {t('common.myAccount')}
                </Button>
                <Button
                  className={styles.button}
                  variant="text"
                  onClick={() => push('/watchlist')}
                >
                  <WatchlistIcon />
                  {t('common.watchlist')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.authContainer}>
                <p className={styles.info}>{t('common.dontHaveAnAccount')}</p>
                <div className={cn(styles.buttonWrapper, styles.authButtons)}>
                  {showRegistration && (
                    <Button
                      className={styles.customButton}
                      onClick={() => push(APP_PAGE_PATH.REGISTER)}
                      variant="cta"
                    >
                      {t('common.registerNow')}
                    </Button>
                  )}
                  <Button
                    className={styles.customButton}
                    variant="secondary"
                    onClick={() => push(APP_PAGE_PATH.LOGIN)}
                  >
                    {t('common.signIn')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles.menu}>
          {menuLinks?.map((menuItem, index) => (
            <MenuLink
              key={`${menuItem.uuid}_${index}`}
              menuItem={menuItem}
              activeClassName={styles.activeLink}
              className={styles.link}
            />
          ))}
        </div>
        {!isAnonymous && (
          <Button className={styles.button} variant="text" onClick={signOut}>
            {t('common.signOut')}
          </Button>
        )}
      </aside>
    </ClickAway>
  );
};

export default SideMenu;

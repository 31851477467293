import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

const Alert = ({
  children,
  type = 'info',
}: {
  children: ReactNode;
  type: 'info' | 'warning' | 'alert' | 'mobile';
}) => <div className={cn(styles.root, styles[type])}>{children}</div>;

export default Alert;

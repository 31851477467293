import {
  ERROR_CODE_ACCOUNT_EXISTS,
  ERROR_CODE_MISMATCH_ACCOUNT,
  SOCIAL_ERRORS_TO_OMIT_FOR_USER,
  SOCIAL_ERRORS_TO_OMIT_IN_SENTRY,
} from 'components/SocialLogin/SocialItem/constants';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import { AuthenticationFailedProps } from 'components/SocialLogin';
import { useSelector } from 'react-redux';
import { selectAppName } from 'store/app/selectors';
import { FirebaseAuthCodes, SocialAuthenticationProviders } from './constants';
import { isEmail, isPasswordValidLength, isPhoneNumber } from './validators';
import { captureError } from './captureError';
import { titleCase } from './helpers';

export enum AuthInputType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  PASSWORD = 'PASSWORD',
}

//NOTE: Default error messages, can be override in components
export const validateAuthInput = (inputValue: string, type: AuthInputType) => {
  if (!inputValue || (inputValue && inputValue.length === 0))
    return 'This field is required';

  if (type === AuthInputType.EMAIL && !isEmail(inputValue))
    return 'Please enter a valid email address';

  if (type === AuthInputType.PHONE && !isPhoneNumber(inputValue))
    return 'Please enter a valid phone number with country code';

  if (type === AuthInputType.PASSWORD && !isPasswordValidLength(inputValue))
    return 'Please enter a valid password';
};

export const isUserDisabled = (errorCode: string) =>
  errorCode === FirebaseAuthCodes.USER_DISABLED;

export const createSocialProviderInstance = (
  type: SocialAuthenticationProviders
) => {
  switch (type) {
    case SocialAuthenticationProviders.FACEBOOK:
      return new FacebookAuthProvider();
    case SocialAuthenticationProviders.GOOGLE:
      return new GoogleAuthProvider();
    case SocialAuthenticationProviders.TWITTER:
      return new TwitterAuthProvider();
    case SocialAuthenticationProviders.APPLE:
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      return provider;
    default:
      return null;
  }
};

interface GetSocialErrorProps {
  error: any;
  type: SocialAuthenticationProviders;
  register?: boolean;
  onError: (params: AuthenticationFailedProps) => void;
}

export const useSocialErrors = () => {
  const { t } = useTranslation();
  const appName = useSelector(selectAppName);

  const handleSocialError = useCallback(
    ({ error, type, register, onError }: GetSocialErrorProps) => {
      if (SOCIAL_ERRORS_TO_OMIT_FOR_USER.includes(error.code)) return;

      let errorMessage = register
        ? t('errors.registrationFailedMessage', {
            type: titleCase(type),
          })
        : t('errors.loginFailedMessage', {
            type: titleCase(type),
          });

      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        errorMessage = register
          ? t('errors.accountExistsWithDifferentCredentialsRegister')
          : t('errors.accountExistsWithDifferentCredentialsLogin');
      }

      if (error.code === ERROR_CODE_MISMATCH_ACCOUNT) {
        errorMessage = t('errors.authorizationFailedMismatchUser', {
          appName,
        });
      }

      if (isUserDisabled(error.code)) {
        errorMessage = t('errors.userDisabledSocialsError', {
          social: _capitalize(type),
        });
      }

      onError({ isFailed: true, socialType: type, errorMessage });

      if (SOCIAL_ERRORS_TO_OMIT_IN_SENTRY.includes(error.code)) return;

      captureError(error, 'socialItem/signInWithSocial', 'error', {
        extra: { socialType: type },
      });
    },
    [appName, t]
  );

  return { handleSocialError };
};

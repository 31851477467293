import {
  BillingAddressValue,
  BillingNameValue,
} from '@laminar-product/client-commons-core/core';

export const DEFAULT_BILLING_FORM_VALUES = {
  [BillingNameValue.NAME]: '',
  [BillingNameValue.SURNAME]: '',
  [BillingAddressValue.LINE_1]: '',
  [BillingAddressValue.LINE_2]: '',
  [BillingAddressValue.CITY]: '',
  [BillingAddressValue.STATE]: '',
  [BillingAddressValue.POSTAL_CODE]: '',
  [BillingAddressValue.COUNTRY]: '',
};

import { formatPrice } from 'utils/price';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ModalPlanCardProps } from '.';

interface PriceInfoProps {
  price: ModalPlanCardProps['price'];
}

const PriceInfo = ({ price }: PriceInfoProps) => {
  const { t } = useTranslation();
  const amount = price && 'netPrice' in price ? price.netPrice : price?.amount;
  const shouldShowPrice = amount !== undefined && amount !== null;

  return shouldShowPrice ? (
    <div className={styles.payment}>
      <span className={styles.price}>
        {formatPrice(amount, price?.currency)}
      </span>
      <span className={styles.interval}>
        {t('planSelector.per', {
          interval: price?.interval.toLowerCase(),
          count: price?.intervalMultiplier,
        })}
      </span>
    </div>
  ) : (
    <div>-</div>
  );
};

export default PriceInfo;

import React from 'react';
import { ReactComponent as FacebookIcon } from 'assets/icons/socialLogin/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/socialLogin/twitter.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/socialLogin/google.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/socialLogin/apple.svg';
import { registerUser } from 'actions/basic';
import { useDispatch } from 'react-redux';
import { setLoginMethod } from 'store/user/actions';
import { setUserCredentials } from 'store/login/actions';
import { useHistory } from 'react-router-dom';
import {
  SocialAuthenticationProviders,
  upgradePlanParamId,
} from 'utils/constants';
import usePathWithParams from 'utils/usePathWithParams';
import {
  getAdditionalUserInfo,
  getAuth,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth';
import { createSocialProviderInstance, useSocialErrors } from 'utils/auth';
import { LoginMethod } from 'store/user/reducer';
import styles from '../index.module.scss';
import { AuthenticationFailedProps } from '..';

interface SocialItemProps {
  type: SocialAuthenticationProviders;
  setAuthenticationFailed: (value: AuthenticationFailedProps) => void;
  setIsAuthenticating: (value: boolean) => void;
  register?: boolean;
}

const getSocialIcon = (type: SocialAuthenticationProviders) => {
  switch (type) {
    case SocialAuthenticationProviders.FACEBOOK:
      return <FacebookIcon />;

    case SocialAuthenticationProviders.GOOGLE:
      return <GoogleIcon />;

    case SocialAuthenticationProviders.TWITTER:
      return <TwitterIcon />;

    case SocialAuthenticationProviders.APPLE:
      return <AppleIcon />;
  }
};

const SocialItem = ({
  type,
  setAuthenticationFailed,
  setIsAuthenticating,
  register,
}: SocialItemProps) => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const getPath = usePathWithParams();
  const { handleSocialError } = useSocialErrors();

  const onSocialClick = () => {
    const provider = createSocialProviderInstance(type);
    const auth = getAuth();

    if (!provider) return;

    signInWithPopup(auth, provider)
      .then(async (confirmResult) => {
        setIsAuthenticating(true);

        dispatch(setLoginMethod(type.toLowerCase() as LoginMethod));

        dispatch(
          setUserCredentials({
            email: confirmResult.user?.email || '',
          })
        );

        const getUrl = (url: string) =>
          getPath(url, !!queryParams.get(upgradePlanParamId));

        const user = getAdditionalUserInfo(confirmResult);

        if (user?.isNewUser) {
          const token = await registerUser();
          await signInWithCustomToken(auth, token.data);
          push(getUrl('/register/plan'));
          return;
        }

        push(getUrl('/'));
      })
      .catch((error) =>
        handleSocialError({
          error,
          type,
          register,
          onError: setAuthenticationFailed,
        })
      )
      .finally(() => setIsAuthenticating(false));
  };

  return (
    <div className={styles.socialIcon} onClick={onSocialClick}>
      {getSocialIcon(type)}
    </div>
  );
};

export default SocialItem;

import React, { ReactNode } from 'react';
import Menu from 'library/Menu';
import Footer from 'components/Footer';
import { useLocation } from 'react-router-dom';
import SettingsFooter from 'components/Footer/SettingsFooter';
import { useMenuContext } from 'utils/useMenuContext';
import cn from 'classnames';
import { SubHeaderProps } from 'components/SubHeader';
import styles from './index.module.scss';

interface PageLayoutProps {
  subheader?: SubHeaderProps;
  children: ReactNode;
  menuPosition?: 'absolute' | 'static';
  className?: string;
  footerClassName?: string;
}

const PageLayoutWrapper = ({
  subheader,
  children,
  menuPosition,
  className,
  footerClassName,
}: PageLayoutProps) => {
  const { pathname } = useLocation();
  const isSettingsPage = pathname.includes('/settings');
  const { isOpen } = useMenuContext();

  return (
    <>
      <Menu subheader={subheader} position={menuPosition} />
      <main
        className={cn(styles.root, className, {
          [styles.menuOpen]: isOpen,
        })}
      >
        {children}

        {isSettingsPage ? (
          <SettingsFooter className={cn({ [styles.menuOpen]: isOpen })} />
        ) : (
          <Footer
            className={cn(footerClassName, { [styles.menuOpen]: isOpen })}
          />
        )}
      </main>
    </>
  );
};

export default PageLayoutWrapper;

import { Tab, Tabs } from '@laminar-product/client-commons-core/web';
import React, { useCallback } from 'react';
import { CustomAuthenticationProviders } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { selectCustomAuthenticationProviders } from 'store/app/selectors';
import SocialLogin from 'components/SocialLogin';
import { useSelector } from 'react-redux';
import PolicyNote from 'components/PolicyNote';
import SignUpEmailPassword from '../SignUpEmailPassword';
import SignUpPhoneNumber from '../SignUpPhoneNumber';
import styles from './index.module.scss';

export interface SignUpEmailFormData {
  userEmail: string;
  userPhoneNumber: string;
  password: string;
}

const SignUpSection = () => {
  const { t } = useTranslation();
  const allowedCustomAuthenticationProviders = useSelector(
    selectCustomAuthenticationProviders
  );

  const getRegisterTabs = useCallback(() => {
    return allowedCustomAuthenticationProviders?.map((provider) => {
      switch (provider) {
        case CustomAuthenticationProviders.EMAIL_PASSWORD:
          return (
            <Tab
              label="Email address"
              key={CustomAuthenticationProviders.EMAIL_PASSWORD}
            >
              <SignUpEmailPassword />
            </Tab>
          );
        case CustomAuthenticationProviders.PHONE_NUMBER:
          return (
            <Tab
              label="Phone number"
              key={CustomAuthenticationProviders.PHONE_NUMBER}
            >
              <SignUpPhoneNumber />
            </Tab>
          );
        default:
          return <></>;
      }
    });
  }, [allowedCustomAuthenticationProviders]);

  const authenticationTabs = getRegisterTabs();

  return (
    <div className={styles.signUpContent}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>{t('login.webLoginHeading')}</h1>
        <p className={styles.subheading}>{t('login.webLoginSubheading')}</p>
      </div>
      <div className={styles.signUpContainer}>
        <section className={styles.authFormContainer}>
          {!!authenticationTabs?.length && (
            <Tabs
              defaultActiveKey={CustomAuthenticationProviders.EMAIL_PASSWORD}
              navClassName={styles.tabs}
            >
              {authenticationTabs}
            </Tabs>
          )}
        </section>

        <SocialLogin
          customAuthenticationAllowed={!!authenticationTabs?.length}
          register
        />

        <PolicyNote light />
      </div>
    </div>
  );
};

export default SignUpSection;

import LinearProfileWrapper from 'components/LinearProfileWrapper';
import ProfileModalHeader from 'components/ProfileModalHeader';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CodeInput from 'react-code-input';
import CloseProfileModal from 'components/CloseProfileModal';
import { Modal } from '@laminar-product/client-commons-core/web';
import ProfileEdit from 'pages/Settings/components/Profiles/ProfileEdit';
import ProfileAvatar from 'components/ProfileAvatar';
import { Profile } from '@laminar-product/client-commons-core/core';
import {
  checkIsKidsProfile,
  useNavigateToProfileDetails,
} from 'utils/profiles';
import { useSelector } from 'react-redux';
import { selectIsTimesSSOProvider } from 'store/user/selectors';
import ProfileIdentityConfirmation from '../ForgotPin/ProfileIdentityConfirmation';
import styles from './index.module.scss';

interface ProfilePinCheckProps {
  profile?: Profile;
  isEditMode?: boolean;
  onClose: () => void;
  onPinCorrect: (profile: Profile) => void;
}

const ProfilePinCheck = ({
  onClose,
  profile,
  isEditMode,
  onPinCorrect,
}: ProfilePinCheckProps) => {
  const { t } = useTranslation();
  const [pinValue, setPinValue] = useState<string>('');
  const [isPinWrong, setIsPinWrong] = useState(false);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [forgotPinModalOpen, setForgotPinModalOpen] = useState(false);
  const navigateAfterPinSuccess = useNavigateToProfileDetails();
  const isTimesSSOProvider = useSelector(selectIsTimesSSOProvider);

  const validatePin = (value: string) => {
    setPinValue(value);
    setIsPinWrong(false);

    if (value.length === 4 && profile) {
      if (value !== profile.pin) {
        setIsPinWrong(true);
      }

      if (value === profile.pin) {
        if (isEditMode) {
          setEditProfileModalOpen(true);
        } else {
          onPinCorrect(profile);
          onClose();
        }
      }
    }
  };

  const onConfirmationSuccess = useCallback(() => {
    //NOTE: After each forgot pin confirmation, user is redirected to given profile details
    if (!profile) return;
    setEditProfileModalOpen(false);
    onClose();

    navigateAfterPinSuccess(profile);
  }, [navigateAfterPinSuccess, onClose, profile]);

  const handleForgotPin = () => {
    // Temporary solution for Times SSO provider - we need to establish how to handle reauthentication flow for this provider (https://laminarglobal.atlassian.net/browse/DDD-7691)
    if (isTimesSSOProvider) {
      onConfirmationSuccess();
      return;
    }

    setForgotPinModalOpen(true);
  };

  return (
    <LinearProfileWrapper>
      <div className={styles.wrapper}>
        <CloseProfileModal onClose={onClose} />
        <ProfileAvatar
          name={profile?.name}
          isKidsProfile={checkIsKidsProfile(profile)}
        />
        <div className={styles.pinDescription}>
          {t('profile.pinInformation')}
        </div>

        <ProfileModalHeader bold text={t('profile.enterPIN')} />

        <div className={styles.codeInputWrapper}>
          <CodeInput
            autoFocus
            value={pinValue}
            onChange={validatePin}
            inputMode="latin"
            name="oneTimeCode"
            type="number"
            fields={4}
            className={styles.codeInput}
          />

          {isPinWrong && (
            <div className={styles.error}>{t('profile.wrongPin')}</div>
          )}
        </div>

        <div onClick={handleForgotPin} className={styles.forgotText}>
          {t('profile.pinForgot')}
        </div>
      </div>

      <Modal
        wrapperId="profilePinConfirmation"
        isOpen={forgotPinModalOpen && !!profile}
      >
        <ProfileIdentityConfirmation
          profile={profile!}
          onClose={() => setForgotPinModalOpen(false)}
          onConfirmationSuccess={onConfirmationSuccess}
        />
      </Modal>

      <Modal wrapperId="profileEditModal" isOpen={editProfileModalOpen}>
        <ProfileEdit
          profile={profile}
          onClose={() => {
            setEditProfileModalOpen(false);
            onClose();
          }}
        />
      </Modal>
    </LinearProfileWrapper>
  );
};

export default ProfilePinCheck;

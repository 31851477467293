import { getWatchlist } from 'actions/likes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentProfileUuid,
  selectIsAnonymous,
} from 'store/user/selectors';
import {
  setWatchlistLoadingState,
  setWatchlistUuids,
} from 'store/watchlist/actions';
import { Severity } from 'types/errors';
import {
  selectWatchlistLoadingState,
  selectWatchlistUuids,
} from 'store/watchlist/selectors';
import { selectAssets } from 'store/assets/selectors';
import { getAsset } from 'actions/assets';
import { Asset } from '@laminar-product/client-commons-core/core';
import { setAssets } from 'store/assets/actions';
import { captureError } from './captureError';

const usePrepareWatchlist = () => {
  const isAnonymous = useSelector(selectIsAnonymous);
  const currentProfileId = useSelector(selectCurrentProfileUuid);
  const dispatch = useDispatch();
  const watchlistUuids = useSelector(selectWatchlistUuids);
  const watchlistLoadingState = useSelector(selectWatchlistLoadingState);
  const assets = useSelector(selectAssets);

  useEffect(() => {
    if (!watchlistUuids?.length || watchlistLoadingState === 'loaded') {
      return;
    }

    //Fetch asset details for uuids that weren't already fetched
    const assetsToLoad = watchlistUuids.filter((id) => !assets?.[id]);

    if (!assetsToLoad.length) {
      dispatch(setWatchlistLoadingState('loaded'));
      return;
    }

    Promise.allSettled(assetsToLoad.map(getAsset))
      .then((data) => {
        const assets = data
          .filter(
            (v): v is PromiseFulfilledResult<Asset> => v.status === 'fulfilled'
          )
          .map((v) => v.value);

        dispatch(setAssets(assets));
      })
      .catch((error) =>
        captureError(error, 'usePrepareWatchlist/getAssets', Severity.Error)
      )
      .finally(() => dispatch(setWatchlistLoadingState('loaded')));
  }, [assets, dispatch, watchlistLoadingState, watchlistUuids]);

  useEffect(() => {
    // Watchlist is unique for each profile and only for logged users
    if (isAnonymous || !currentProfileId) {
      return;
    }

    getWatchlist()
      .then(({ assetUuids }) => {
        if (!assetUuids?.length) {
          return dispatch(setWatchlistLoadingState('loaded'));
        }

        dispatch(setWatchlistUuids(assetUuids));
      })
      .catch((e) => {
        captureError(e, 'useWatchlist/getWatchlist', Severity.Error);
        dispatch(setWatchlistLoadingState('loaded')); //TODO: Do we want to display error if watchlist fetch failed?
      });
  }, [currentProfileId, dispatch, isAnonymous]);

  return { watchlistLoadingState };
};

export default usePrepareWatchlist;

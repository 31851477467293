import { selectPlayerVideoElement } from 'store/player/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { ContinueWatchingEvent } from '@laminar-product/client-commons-core/core';
import useVideoPlayerContinueWatching from './useVideoPlayerContinueWatching';
import useVideoPlayerNextEpisode from './useVideoPlayerNextEpisode';

const useSaveShowBookmark = () => {
  const [videoEnded, setVideoEnded] = useState(false);
  const closedEventSentOnEnded = useRef<boolean>(false);
  const videoMediaElement = useSelector(selectPlayerVideoElement);
  const { onVideoEnded } = useVideoPlayerNextEpisode();
  const { onSaveContinueWatching, checkIfFinished } =
    useVideoPlayerContinueWatching();

  useEffect(() => {
    if (!videoMediaElement) {
      return;
    }

    const onVideoEndListener = () => setVideoEnded(true);

    videoMediaElement.addEventListener('ended', onVideoEndListener);

    return () =>
      videoMediaElement.removeEventListener('ended', onVideoEndListener);
  }, [onVideoEnded, videoMediaElement]);

  useEffect(() => {
    const handleEpisodeEnd = async () => {
      closedEventSentOnEnded.current = true;
      await onSaveContinueWatching(ContinueWatchingEvent.FINISHED);
      onVideoEnded();
    };

    if (videoEnded) {
      handleEpisodeEnd();
    }
  }, [onSaveContinueWatching, onVideoEnded, videoEnded]);

  useEffect(() => {
    return () => {
      if (!closedEventSentOnEnded.current) {
        const finished = checkIfFinished();

        onSaveContinueWatching(
          finished
            ? ContinueWatchingEvent.FINISHED
            : ContinueWatchingEvent.CLOSED
        );
      }
    };
  }, [checkIfFinished, onSaveContinueWatching]);
};

export default useSaveShowBookmark;

import { useCallback, useEffect } from 'react';
import {
  SerializedMenu,
  livePath,
  moviePath,
  showPath,
} from '@laminar-product/client-commons-core/core';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import ListingView from 'components/ListingView';
import { MoveToAssetProvider } from 'components/MoveToAssetPlayerProvider';
import MoveToListingProvider from 'components/MoveToListingProvider';
import SearchResults from 'library/SearchResults';
import _get from 'lodash/get';
import AssetDetails from 'pages/AssetDetails';
import Login from 'pages/Login';
import PaymentFailed from 'pages/PaymentStatus/PaymentFailed';
import PaymentInProgress from 'pages/PaymentStatus/PaymentInProgress';
import PaymentCompleted from 'pages/PaymentStatus/PaymentSuccess';
import Settings from 'pages/Settings';
import Watchlist from 'pages/Watchlist';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RootState } from 'store/reducer';
import {
  FeatureFlag,
  collectionPagePath,
  listingPagePath,
  pagePath,
  watchLiveContentPath,
  watchLivePath,
  watchMovieContentPath,
  watchMoviePath,
  watchShowContentPath,
  watchShowPath,
} from 'utils/constants';
import {
  catalogLivePath,
  catalogMoviePath,
  catalogShowPath,
  getPageLink,
} from 'utils/routing';
import useFetchUserData from 'utils/useFetchUserData';
import { getProfiles } from 'actions/profiles';
import { setLoginMethod, setUserProfiles } from 'store/user/actions';
import ProfilesSelector from 'pages/ProfilesSelector';
import useShowProfileSelector from 'utils/useShowProfileSelector';
import SelectPlan from 'components/SelectPlan';
import { useNotifications } from 'utils/useNotifications';
import DesktopOnlyRoute from 'components/DesktopOnlyRoute';
import PageNotFoundComponent from 'components/ErrorPage/PageNotFoundComponent';
import {
  selectCannotChangePlanModalVisible,
  selectFirebaseUser,
  selectIsAnonymous,
  selectProfiles,
} from 'store/user/selectors';
import { selectSignInFromTv } from 'store/login/selectors';
import CatalogAssetDetails from 'pages/CatalogAssetDetails';
import PhoneAuthProvider from 'components/OtpProviders';
import { selectRegistrationPhoneNumber } from 'store/registration/selectors';
import VideoPlayerWrapper from 'library/VideoPlayer/VideoPlayerWrapper';
import { selectShouldDisplayChromecastController } from 'store/chromecast/selectors';
import ChromecastRemoteController from 'components/ChromecastRemoteController';
import useApplicationLoginTracking from 'utils/useApplicationLoginTracking';
import { getAuth, signInAnonymously } from '@firebase/auth';
import AddToHomeScreen from 'components/AddToHomeScreen';
import { APP_PAGE_PATH } from 'utils/navigation';
import Page from 'library/Page';
import CannotChangePlanModal from 'components/CannotChangePlanModal';
import ResetPasswordSuccess from 'pages/ResetPassword/components/ResetPasswordSuccess';
import { useQuery } from '@tanstack/react-query';
import styles from './App.module.scss';
import useFeatureEnabled from './components/FeatureEnabled/useFeatureEnabled';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './pages/LandingPage';
import Register from './pages/Register';
import SetPayment from './pages/Register/components/SetPayment';
import ResetPassword from './pages/ResetPassword';
import useAxiosResponseErrors from './utils/useAxiosResponseErrors';

const FreemiumApp = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation<{ redirectTo: string }>();
  const user = useSelector(selectFirebaseUser);
  const isAnonymous = useSelector(selectIsAnonymous);
  const { userData, refreshUserData } = useFetchUserData(user);
  const showRegistration = useFeatureEnabled(FeatureFlag.showRegistration);
  const { unauthorized } = useAxiosResponseErrors();
  const userVerified = useSelector<RootState>((state) => state.user.verified);
  const firstMenuOption = useSelector<RootState, SerializedMenu | undefined>(
    (state) => _get(state, 'app.appSettings.menuLinks[0]')
  );
  const showProfileSelector = useShowProfileSelector();
  const profiles = useSelector(selectProfiles);
  const cannotChangePlanModalVisible = useSelector(
    selectCannotChangePlanModalVisible
  );

  const fetchProfiles = useCallback(async () => {
    const profiles = await getProfiles();
    dispatch(setUserProfiles(profiles || []));
    return profiles;
  }, [dispatch]);

  useQuery({
    queryKey: ['profiles', userData?.uid],
    queryFn: fetchProfiles,
    enabled: !!userData && !!user && !!userVerified,
  });

  const profilesFetched = profiles && profiles.length;
  const isSignInFromTv = useSelector(selectSignInFromTv);
  const registrationPhoneNumber = useSelector(selectRegistrationPhoneNumber);
  const shouldDisplayChromecastController = useSelector(
    selectShouldDisplayChromecastController
  );

  useNotifications({
    isUserValidated: !!user && !isAnonymous && !!userVerified,
  });
  useApplicationLoginTracking();

  useEffect(() => {
    if (!user) {
      const auth = getAuth();
      signInAnonymously(auth).then(() => {
        dispatch(setLoginMethod('guest'));
      });
    }
  }, [dispatch, user]);

  if (!user || (!isAnonymous && !profilesFetched)) {
    return <AppLoadingIndicator />;
  }

  if (unauthorized && !!userData?.plan) {
    return (
      <Redirect
        to={{
          pathname: APP_PAGE_PATH.LOGIN,
          state: { redirectTo: pathname, action: search },
        }}
      />
    );
  }

  if (showProfileSelector) {
    return <ProfilesSelector />;
  }

  return (
    <MoveToListingProvider>
      <MoveToAssetProvider>
        <div className={styles.app}>
          <Switch>
            <Route exact path="/">
              <Redirect
                to={{
                  pathname: isSignInFromTv
                    ? '/settings/devices/register'
                    : getPageLink(firstMenuOption),
                }}
              />
            </Route>
            <ProtectedRoute
              path={APP_PAGE_PATH.REGISTER}
              exact
              isAllowed={!!showRegistration}
            >
              <LandingPage />
            </ProtectedRoute>
            <Route exact path="/:deviceTv/register" component={LandingPage} />
            <Route path="/register/phone-verify" exact>
              <Register>
                <PhoneAuthProvider
                  phoneNumber={registrationPhoneNumber}
                  redirectUrl="/"
                  isRegistering={true}
                />
              </Register>
            </Route>
            <ProtectedRoute
              path="/register/plan"
              isAllowed={!!showRegistration}
            >
              <Register>
                <SelectPlan />
              </Register>
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/register/payment"
              isAllowed={!!showRegistration}
            >
              <Register>
                <SetPayment isUpgradingPlan={false} />
              </Register>
            </ProtectedRoute>
            <ProtectedRoute
              path="/register/payment/in-progress"
              isAllowed={!!showRegistration}
            >
              <Register>
                <PaymentInProgress />
              </Register>
            </ProtectedRoute>
            <ProtectedRoute
              path="/register/payment/success"
              isAllowed={!!showRegistration}
            >
              <Register>
                <PaymentCompleted refreshUser={refreshUserData} />
              </Register>
            </ProtectedRoute>
            <ProtectedRoute
              path="/register/payment/failed"
              isAllowed={!!showRegistration}
            >
              <Register>
                <PaymentFailed />
              </Register>
            </ProtectedRoute>
            <Route path="/forgot-password">
              <ResetPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/reset-password-success">
              <ResetPasswordSuccess />
            </Route>
            <Route exact path={APP_PAGE_PATH.LOGIN} component={Login} />
            <ProtectedRoute
              path="/settings"
              redirectTo={{
                pathname: APP_PAGE_PATH.LOGIN,
                state: { redirectTo: '/settings' },
              }}
              isAllowed={!isAnonymous}
              component={Settings}
            />
            <DesktopOnlyRoute
              exact
              path={watchMovieContentPath}
              component={VideoPlayerWrapper}
            />
            <DesktopOnlyRoute
              exact
              path={watchShowContentPath}
              component={VideoPlayerWrapper}
            />
            <DesktopOnlyRoute
              exact
              path={watchMoviePath}
              component={VideoPlayerWrapper}
            />
            <DesktopOnlyRoute
              exact
              path={watchShowPath}
              component={VideoPlayerWrapper}
            />
            <DesktopOnlyRoute
              exact
              path={watchLivePath}
              component={VideoPlayerWrapper}
            />
            <DesktopOnlyRoute
              exact
              path={watchLiveContentPath}
              component={VideoPlayerWrapper}
            />
            <Route exact path={moviePath} component={AssetDetails} />
            <Route exact path={showPath} component={AssetDetails} />
            <Route exact path={livePath} component={AssetDetails} />

            <Route
              exact
              path={catalogMoviePath}
              component={CatalogAssetDetails}
            />
            <Route
              exact
              path={catalogShowPath}
              component={CatalogAssetDetails}
            />
            <Route
              exact
              path={catalogLivePath}
              component={CatalogAssetDetails}
            />
            <DesktopOnlyRoute
              exact
              path={listingPagePath}
              component={ListingView}
            />
            <DesktopOnlyRoute
              exact
              path={collectionPagePath}
              component={ListingView}
            />
            <DesktopOnlyRoute exact path="/search" component={SearchResults} />
            <DesktopOnlyRoute exact path="/watchlist" component={Watchlist} />
            <DesktopOnlyRoute exact path={pagePath} component={Page} />
            <Route path="/error" component={PageNotFoundComponent} />
            <Route path="*" component={PageNotFoundComponent} />
          </Switch>

          {shouldDisplayChromecastController ? (
            <ChromecastRemoteController />
          ) : null}

          <AddToHomeScreen />

          {cannotChangePlanModalVisible && <CannotChangePlanModal />}
        </div>
      </MoveToAssetProvider>
    </MoveToListingProvider>
  );
};

export default FreemiumApp;

import { Modal } from '@laminar-product/client-commons-core/web';
import React, { useCallback, useState } from 'react';
import {
  PollingUserPlanResultType,
  usePollingUserPlanPrepaid,
} from 'utils/polling';
import { useHistory } from 'react-router-dom';
import CompanyLogo from 'components/CompanyLogo';
import styles from './index.module.scss';
import RedeemCodeResult from './RedeemCodeResult';
import RedeemCodeEntry from './RedeemCodeEntry';

interface RedeemCodeModalProps {
  onClose: () => void;
}

const RedeemCodeModal = ({ onClose }: RedeemCodeModalProps) => {
  const [shouldRefreshUserPlan, setShouldRefreshUserPlan] = useState(false);
  const {
    fetchingPlanResult: refreshingPlanResult,
    pollingRunning: isRefreshingPlan,
  } = usePollingUserPlanPrepaid({ shouldRun: shouldRefreshUserPlan }); //success - pin was correctly redeemed and plan was refreshed

  const { push } = useHistory();

  const onRedeemResultConfirm = useCallback(() => {
    if (refreshingPlanResult === PollingUserPlanResultType.FAILURE) {
      return onClose();
    }

    push('/');
  }, [onClose, push, refreshingPlanResult]);

  return (
    <Modal wrapperId="redeemCodeModal" isOpen>
      <div className={styles.redeemModalWrapper}>
        <CompanyLogo className={styles.logo} />
        <div className={styles.redeemModalContent}>
          {refreshingPlanResult ? (
            <RedeemCodeResult
              isError={
                refreshingPlanResult === PollingUserPlanResultType.FAILURE
              }
              onOkClick={onRedeemResultConfirm}
            />
          ) : (
            <RedeemCodeEntry
              onClose={onClose}
              onPinRedeemSuccess={() => setShouldRefreshUserPlan(true)}
              isRefreshingPlan={isRefreshingPlan}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RedeemCodeModal;

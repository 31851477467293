import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Profile } from '@laminar-product/client-commons-core/core';
import { useHistory } from 'react-router-dom';
import { SettingsTabKey } from 'types/settings';
import { Button } from '@laminar-product/client-commons-core/web';
import { useUpdateProfilesState } from 'utils/profiles';
import ProfileGeneralDetails from '../../ProfileGeneralDetails';
import styles from './index.module.scss';

interface DetailsTabProps {
  profile?: Profile;
  saveProfile: (profileToSave: Profile) => void;
  isLoading?: boolean;
}

const DetailsTab = ({ profile, saveProfile, isLoading }: DetailsTabProps) => {
  const formMethods = useForm<Profile>({ mode: 'onSubmit' });
  const { handleSubmit, getValues, reset } = formMethods;
  const { t } = useTranslation();
  const updateProfilesInState = useUpdateProfilesState();
  const history = useHistory();

  const onSubmit = async () => {
    if (profile?.uuid) {
      const profileToSave = {
        ...getValues(),
        uuid: profile.uuid,
      };

      updateProfilesInState(profileToSave);
      await saveProfile(profileToSave);
    }
  };

  useEffect(() => {
    if (profile && !getValues('name')) {
      reset({
        ...profile,
      });
    }
  }, [reset, profile, getValues]);

  return (
    <>
      <div className={styles.container}>
        <FormProvider {...formMethods}>
          <form>
            <div className={styles.inputWrapper}>
              <ProfileGeneralDetails />
            </div>

            <div className={styles.buttons}>
              <Button
                variant="primary-settings"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                {t('common.save')}
              </Button>
              <Button
                onClick={() =>
                  history.push({
                    pathname: '/settings',
                    state: {
                      activeMenu: SettingsTabKey.PROFILES,
                    },
                  })
                }
                variant="secondary-settings"
              >
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default DetailsTab;

import { Asset, Content } from '@laminar-product/client-commons-core/core';
import { VideoPlayerLoadConfig } from 'library/VideoPlayer/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shaka from 'shaka-player/dist/shaka-player.ui';
import {
  setPlayer,
  setPlayerAssetId,
  setPlayerContent,
  setPlayerError,
  setPlayerFrames,
  setPlayerInitializingState,
  setPlayerPlaybackId,
  setPlayerPlaylist,
  setPlayerVideoElement,
} from 'store/player/actions';
import { PLAYER_INITIALIZING_STATE } from 'store/player/types';
import { Severity } from 'types/errors';
import { selectPlayerPlaybackId } from 'store/player/selectors';
import { captureError } from './captureError';
import useVideoPlayerStrataEvents from './useVideoPlayerStrataEvents';

interface UseVideoPlayerEventsProps {
  asset: Asset;
  content: Content;
  playerLoadConfiguration?: VideoPlayerLoadConfig;
}

const useVideoPlayerCommonEvents = ({
  asset,
  content,
  playerLoadConfiguration,
}: UseVideoPlayerEventsProps) => {
  const dispatch = useDispatch();
  const playbackId = useSelector(selectPlayerPlaybackId);
  const { onVideoFail: trackStrataVideoFail } = useVideoPlayerStrataEvents();

  const setPlayerState = useCallback(() => {
    if (!playerLoadConfiguration) {
      return;
    }
    const { playbackId, frames, playlist } = playerLoadConfiguration;

    dispatch(setPlayerContent(content));
    dispatch(setPlayerPlaybackId(playbackId));
    dispatch(setPlayerAssetId(asset.id));
    dispatch(setPlayerFrames(frames || []));
    dispatch(setPlayerPlaylist(playlist || []));
  }, [asset, content, dispatch, playerLoadConfiguration]);

  const onPlayerInitialized = useCallback(
    (player: shaka.Player) => {
      dispatch(setPlayer(player));
      dispatch(setPlayerVideoElement(player.getMediaElement()));
      dispatch(setPlayerInitializingState(PLAYER_INITIALIZING_STATE.LOADED));
    },
    [dispatch]
  );

  const onPlayerError = useCallback(
    (error: shaka.extern.Error | MediaError) => {
      const errorCode = String(error.code);

      const errorMessage = String(
        'message' in error ? error.message : error.category
      );

      captureError(error as any, 'videoPlayerError', Severity.Error, {
        extra: { errorCode, playbackId, errorMessage },
      });

      trackStrataVideoFail(errorCode, errorMessage);

      dispatch(setPlayerError(errorCode));
    },
    [dispatch, playbackId, trackStrataVideoFail]
  );

  return { onPlayerInitialized, onPlayerError, setPlayerState };
};

export default useVideoPlayerCommonEvents;

import AppLoadingIndicator from 'components/AppLoadingIndicator';
import PageNotFoundComponent from 'components/ErrorPage/PageNotFoundComponent';
import PageLayoutWrapper from 'layouts/PageLayoutWrapper';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFirebaseUser } from 'store/user/selectors';
import styles from './index.module.scss';

interface AssetDetailsBaseProps {
  children?: React.ReactNode;
  isCatalog: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

const AssetDetailsBase = ({
  children,
  isCatalog,
  isError,
  isLoading,
}: AssetDetailsBaseProps) => {
  const user = useSelector(selectFirebaseUser);
  const shouldDisplayLoader =
    isLoading || (!children && !isError) || (!isCatalog && !user);

  if (shouldDisplayLoader) return <AppLoadingIndicator fullScreen />;

  if (isError) {
    return <PageNotFoundComponent />;
  }

  return (
    <>
      <PageLayoutWrapper
        menuPosition="absolute"
        footerClassName={styles.footer}
      >
        {children}
      </PageLayoutWrapper>
    </>
  );
};

export default AssetDetailsBase;

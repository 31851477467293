import _isEmpty from 'lodash/isEmpty';

/**
 * Check if all properties in an object are empty
 *
 * @export
 * @param {Record<string, any>} obj
 * @returns boolean
 */
export function isEmpty(obj?: Record<string, any>) {
  if (!obj) return true;

  for (const key in obj) {
    if (!_isEmpty(obj[key])) {
      return false;
    }
  }
  return true;
}

export const titleCase = (str: string) =>
  `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;

export const getReleaseVersion = () => {
  const releaseVersionSplitArray = import.meta.env.VITE_RELEASE_VERSION?.split(
    '-release'
  );

  if (!releaseVersionSplitArray || releaseVersionSplitArray?.length === 1) {
    return '1.0.0';
  }

  return releaseVersionSplitArray?.[0];
};

//Lodash inRange doesn't return true if value === max
export const isValueInRange = (value: number, min: number, max: number) =>
  value >= min && value <= max;

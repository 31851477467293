import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectAppName, selectFavicon } from 'store/app/selectors';
import { RootState } from 'store/reducer';
import { Meta } from 'types/meta';
import { DEFAULT_SEO_DESCRIPTION } from 'utils/constants';

const AppMetaHeader = () => {
  const appName = useSelector(selectAppName);

  const meta = useSelector<RootState, Meta | undefined>(
    (state) => state.app.appSettings?.meta
  );
  const companyFavicon = useSelector(selectFavicon);

  const getPageTitle = (appName?: string, title?: string) => (
    <title>{appName ? `${appName}${title ? `: ${title}` : ''}` : '...'}</title>
  );

  const getMetaDescriptionTag = (description?: string) => (
    <meta name="description" content={description || DEFAULT_SEO_DESCRIPTION} />
  );

  const getMetaForTwitter = () => (
    <meta name="twitter:card" content="summary_large_image"></meta>
  );

  const getCanonicalUrl = (canonicalUrl?: string) =>
    canonicalUrl && <link rel="canonical" href={canonicalUrl} />;

  const getAdditionalMetaTags = (og?: Meta['og']) =>
    og &&
    Object.entries(og)
      .filter(([, value]) => value)
      .map(([key, value], index) => (
        <meta key={index} property={`og:${key}`} content={value} />
      ));

  const getSanitizedJSONFrom = (data: object) =>
    JSON.stringify(
      Object.fromEntries(Object.entries(data).filter(([, val]) => val))
    );

  const getSEOScript = (data: Meta['structuredData']) =>
    data?.name && (
      <script type="application/ld+json">
        {getSanitizedJSONFrom({
          ...data,
          '@context': 'https://schema.org',
          '@type': 'VideoObject',
        })}
      </script>
    );

  return (
    <Helmet>
      {getPageTitle(appName, meta?.og?.title)}
      {getMetaDescriptionTag(meta?.description)}
      {getAdditionalMetaTags(meta?.og)}
      {getSEOScript(meta?.structuredData)}
      {getMetaForTwitter()}
      {getCanonicalUrl(meta?.canonicalUrl)}
      <link rel="icon" type="image/png" href={companyFavicon} sizes="16x16" />
    </Helmet>
  );
};
export default AppMetaHeader;

import { Button } from '@laminar-product/client-commons-core/web';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { useState } from 'react';
import EpisodesListModal from 'components/EpisodesListModal';
import { useSelector } from 'react-redux';
import {
  selectPlayerAsset,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import { selectIsAnonymous } from 'store/user/selectors';
import styles from './index.module.scss';

const EpisodeSelector = () => {
  const [isEpisodeModalVisible, setIsEpisodeModalVisible] = useState(false);
  const asset = useSelector(selectPlayerAsset);
  const isAnonymous = useSelector(selectIsAnonymous);
  const videoElement = useSelector(selectPlayerVideoElement);
  const parentElement = videoElement?.parentElement;

  if (!asset) {
    return null;
  }

  return (
    <>
      <Button
        variant="secondary"
        round
        onClick={() => setIsEpisodeModalVisible(true)}
        className={styles.episodeSelectorBtn}
      >
        <MenuIcon className={styles.episodeIcon} />
      </Button>

      {isEpisodeModalVisible && (
        <EpisodesListModal
          asset={asset}
          seriesName={asset.root?.name}
          open={isEpisodeModalVisible}
          onClose={() => setIsEpisodeModalVisible(false)}
          isAnonymous={isAnonymous}
          parentElement={parentElement}
        />
      )}
    </>
  );
};

export default EpisodeSelector;

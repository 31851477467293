import { useDebounce } from '@laminar-product/client-commons-core/hooks';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import cn from 'classnames';
import { ClickAway } from 'components/ClickAway';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import styles from './index.module.scss';

interface ExpandableInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onSearchChange: (searchTerm: string) => void;
  hasBorder?: boolean;
  value?: string;
  onClear?: () => void;
}

const SearchInput = ({
  onSearchChange,
  className,
  placeholder,
  hasBorder = true,
  value,
  onClear,
}: ExpandableInputProps) => {
  const hasValue = value != null && value.length > 0;
  const [searchTerm, setSearchTerm] = useState(value ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isSearchVisible, toggleSearchVisibility] = useState(hasValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onSearchChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearchChange]);

  const onSearchClick = () => {
    const newValue = !isSearchVisible;
    toggleSearchVisibility(newValue);
    if (newValue && inputRef) {
      inputRef.current?.focus();
    }
  };

  const clearSearchValue = () => {
    if (!searchTerm.length) {
      return toggleSearchVisibility(false);
    }

    setSearchTerm('');
    if (onClear) onClear();
  };

  const rootClasses = cn(styles.root, className, {
    [styles.visible]: isSearchVisible,
    [styles.hidden]: !isSearchVisible,
    [styles.noBorder]: !hasBorder,
  });

  return (
    <ClickAway onClick={() => toggleSearchVisibility(false)}>
      <div className={rootClasses}>
        <SearchIcon
          className={styles.icon}
          aria-label="Toggle search"
          onClick={onSearchClick}
        />
        <input
          autoFocus={hasValue}
          className={cn(styles.searchInput, className, {
            [styles.inputVisible]: isSearchVisible,
          })}
          ref={inputRef}
          value={searchTerm}
          placeholder={placeholder}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CancelIcon onClick={clearSearchValue} className={styles.cancelIcon} />
      </div>
    </ClickAway>
  );
};

export default SearchInput;

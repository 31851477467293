import AuthenticationContainer from 'components/AuthenticationContainer';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { FirebaseAuthCodes } from 'utils/constants';
import { ReactComponent as EmailSentIcon } from 'assets/icons/email-sent.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/cancel.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { setAppMeta } from 'store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { prepareMetaForPage } from 'utils/meta';
import { selectAppLinks } from 'store/app/selectors';
import { getAuth, verifyPasswordResetCode } from 'firebase/auth';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordInfo from './components/ResetPasswordInfo';
import NewPasswordForm from './components/NewPasswordForm';
import styles from './index.module.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState<string>();
  const [resetPasswordStatus, setResetPasswordStatus] = useState('idle');
  const [errorCode, setErrorCode] = useState<FirebaseAuthCodes>();
  const { t } = useTranslation();
  const location = useLocation<{ action: string }>();
  const appLinks = useSelector(selectAppLinks);
  const isResetPasswordView =
    qs.parse(location.state?.action, {
      ignoreQueryPrefix: true,
    }).mode === 'resetPassword';

  const verificationCode = qs.parse(location.state?.action, {
    ignoreQueryPrefix: true,
  }).oobCode as string;

  useEffect(() => {
    dispatch(setAppMeta({ og: prepareMetaForPage(t('login.resetPassword')) }));
  }, [dispatch, t]);

  useEffect(() => {
    if (!location.state?.action) return;

    verifyPasswordResetCode(getAuth(), verificationCode).catch((e) =>
      setErrorCode(e.code)
    );
  }, [dispatch, location.state, t, verificationCode]);

  if (isResetPasswordView && verificationCode) {
    if (
      errorCode &&
      [
        FirebaseAuthCodes.EXPIRED_ACTION_CODE,
        FirebaseAuthCodes.INVALID_OOB_CODE,
      ].includes(errorCode)
    ) {
      return (
        <AuthenticationContainer>
          <ResetPasswordInfo
            icon={<ErrorIcon height={50} width={50} />}
            info={t('errors.oobExpired')}
            title={t('errors.errorPageTitle')}
            footer={
              <div className={styles.footer}>
                {t('login.noAccessToEmail')}
                <a
                  className={styles.link}
                  href={`mailto: ${appLinks?.support}`}
                >
                  {appLinks?.support}
                </a>
              </div>
            }
          />
        </AuthenticationContainer>
      );
    }

    return (
      <AuthenticationContainer>
        <NewPasswordForm verificationCode={verificationCode as string} />
      </AuthenticationContainer>
    );
  }
  return (
    <AuthenticationContainer>
      {resetPasswordStatus === 'resolved' ? (
        <ResetPasswordInfo
          icon={<EmailSentIcon />}
          title={t('login.emailSentTitle')}
          info={t('login.emailSent', { userLogin })}
        />
      ) : (
        <ForgotPasswordForm
          onResetPasswordStatusChange={setResetPasswordStatus}
          setUserLogin={setUserLogin}
        />
      )}
    </AuthenticationContainer>
  );
};

export default ResetPassword;

import api from 'api';

export const addNotificationToken = async (
  currentToken: string
): Promise<string> => {
  const { data } = await api().post('/users/device', {
    token: currentToken,
  });

  return data;
};

export const deleteNotificationToken = (token: string) =>
  api().delete(`/users/device/${token}`);

import { Asset, AssetType } from '@laminar-product/client-commons-core/core';
import { Severity } from 'types/errors';
import { getAsset } from 'actions/assets';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAsset } from 'store/assets/actions';
import {
  selectRedirectUrlFromLogin,
  selectSignInFromTv,
} from 'store/login/selectors';
import { RootState } from 'store/reducer';
import { selectRegistrationSelectedPlan } from 'store/registration/selectors';
import { selectIsVerified } from 'store/user/selectors';
import { captureError } from './captureError';
import { upgradePlanParamId } from './constants';
import usePathWithParams from './usePathWithParams';
import { shouldRedirectOnMobile } from './config';

const useRouteInitializeAfterLogin = () => {
  const credentials = useSelector<
    RootState,
    { email?: string; phone?: string }
  >((state) => state.login.userCredentials);
  const isSignInFromTv = useSelector(selectSignInFromTv);
  const isVerified = useSelector(selectIsVerified);
  const { push } = useHistory();
  const redirectTo = useSelector(selectRedirectUrlFromLogin);
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectRegistrationSelectedPlan);
  const getPath = usePathWithParams();
  const location = useLocation();

  useEffect(() => {
    if (!isVerified || (!credentials?.email && !credentials.phone)) return;

    if (isSignInFromTv) {
      push({
        pathname: '/settings/devices/register',
      });
      return;
    }

    if (redirectTo) {
      push(redirectTo, { cameFromLogin: true });
      return;
    }

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get(upgradePlanParamId)) {
      getAsset(queryParams.get(upgradePlanParamId) as string)
        .then((asset: Asset) => {
          if (asset && !asset.planUpgradeRequired) {
            dispatch(setAsset(asset.id, asset));
            const { type, id, administrativeName } = asset;
            push(
              `/${
                type === AssetType.MOVIE ? 'movie' : 'tv-show'
              }/${administrativeName}/${id}`
            );
            return;
          }
        })
        .catch((e) => captureError(e, 'passwordForm/getAsset', Severity.Error));

      selectedPlan ? push(getPath('/settings/change-plan/payment')) : push('/');
      return;
    }

    push(shouldRedirectOnMobile() ? '/settings' : '/');
  }, [
    dispatch,
    getPath,
    isSignInFromTv,
    push,
    redirectTo,
    selectedPlan,
    isVerified,
    credentials,
    location.search,
  ]);
};

export default useRouteInitializeAfterLogin;

import { RootState } from 'store/reducer';
import { ChromecastStatus } from './types';

export const selectIsChromecastConnected = (state: RootState) =>
  state.chromecast.status &&
  state.chromecast.status !== ChromecastStatus.DISCONNECTED;

export const selectIsChromecastAvailable = (state: RootState) =>
  state.chromecast.available;

export const selectChromecastCastElement = (state: RootState) =>
  state.chromecast.castElement;

export const selectChromecastAssetId = (state: RootState) =>
  state.chromecast.assetId;

export const selectChromecastAsset = (state: RootState) =>
  state.chromecast.assetId ? state.assets[state.chromecast.assetId] : null;

export const selectChromecastPaused = (state: RootState) =>
  state.chromecast.status === ChromecastStatus.PAUSED;

export const selectChromecastBuffering = (state: RootState) =>
  state.chromecast.status === ChromecastStatus.BUFFERING;

export const selectChromecastPlaying = (state: RootState) =>
  state.chromecast.status === ChromecastStatus.PLAYING;

export const selectChromecastContentId = (state: RootState) =>
  state.chromecast.contentId;

export const selectChromecastContent = (state: RootState) =>
  state.chromecast.contentId && state.chromecast.assetId
    ? state.assets[state.chromecast.assetId]?.contents?.find(
        (c) => c.slug === state.chromecast.contentId //Live assets don't have sourceUuid
      )
    : null;

export const selectChromecastAvailableTracks = (state: RootState) =>
  state.chromecast.availableTracks;

export const selectChromecastActiveTextTrackId = (state: RootState) =>
  state.chromecast.activeTextTrackId;

export const selectChromecastActiveAudioTrackId = (state: RootState) =>
  state.chromecast.activeAudioTrackId;

export const selectShouldDisplayChromecastController = (state: RootState) =>
  state.chromecast.status &&
  state.chromecast.status !== ChromecastStatus.DISCONNECTED &&
  state.chromecast.assetId &&
  state.chromecast.contentId;

export const selectChromecastNextEpisodeId = (state: RootState) =>
  state.chromecast.nextEpisodeId;

export const selectChromecastNextEpisode = (state: RootState) =>
  state.chromecast.nextEpisodeId
    ? state.assets[state.chromecast.nextEpisodeId]
    : null;

import { useCallback, useRef, useState } from 'react';
import { DropdownOption } from 'types/dropdown';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import cn from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import styles from './index.module.scss';

interface DropdownProps {
  selectedOption?: DropdownOption;
  options: DropdownOption[];
  onChange: (value: DropdownOption) => void;
  className?: string;
}

const Dropdown = ({
  onChange,
  options,
  selectedOption,
  className,
}: DropdownProps) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onOutSideClick = useCallback(() => setOptionsVisible(false), []);

  useOutsideClick({
    el: dropdownRef.current,
    onOutsideClick: onOutSideClick,
  });

  const onDropdownClick = () => setOptionsVisible((prev) => !prev);

  const onValueChange = (value?: string) => {
    const option = options.find((o) => o.value === value);

    if (option) {
      onChange(option);
    }
  };

  return (
    <div
      className={styles.dropdownWrapper}
      onClick={onDropdownClick}
      ref={dropdownRef}
    >
      {selectedOption?.icon && (
        <div className={styles.activeOptionIcon}>{selectedOption?.icon}</div>
      )}

      <span className={styles.dropdownLabel}>{selectedOption?.label}</span>

      <ArrowDownIcon />

      {optionsVisible && (
        <div className={styles.dropdownOptionsWrapper}>
          {options.map(({ value, label }) => (
            <div
              className={cn(styles.dropdownOption, {
                [styles.activeDropdownOption]: value === selectedOption?.value,
              })}
              onClick={() => onValueChange(value)}
            >
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

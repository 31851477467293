import {
  AssetType,
  SearchParams,
} from '@laminar-product/client-commons-core/core';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { mapURLParamToSearchParams } from './sorting';

const useSearchParams = (): SearchParams => {
  const searchString = useLocation().search;

  return useMemo(() => {
    const params = Object.fromEntries(
      new URLSearchParams(searchString).entries()
    );

    const genre = params['genre'];

    const sorting = mapURLParamToSearchParams(params['sort']) || {};

    return {
      query: params['query'],
      genres: genre ? [genre] : undefined,
      type: params['type'] as AssetType,
      ...sorting,
    };
  }, [searchString]);
};

export default useSearchParams;

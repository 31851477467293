import { FC, createContext, useContext } from 'react';
import { useMachine } from '@xstate/react';
import { ActorLogic, ActorRefFrom } from 'xstate';
import useTrackVideoPlayerEvent from 'utils/useTrackVideoPlayerEvent';
import { WithChildren } from 'types/react';
import { strataPlayerMachine } from './machine';
import { StrataPlayerEvent } from './types';

type StrataActorLogic = ActorLogic<any, StrataPlayerEvent>;

export const StrataContext =
  createContext<ActorRefFrom<StrataActorLogic> | null>(null);

export const useStrataStateMachine = () => {
  return useContext(StrataContext);
};

type EventData = StrataPlayerEvent['params']['data'];
const buildEventData = (data: EventData, context: { bufferId?: string }) => {
  if (context.bufferId) {
    return {
      ...data,
      buffer_id: context.bufferId,
    };
  }

  return data;
};

export const StrataStateMachineProvider: FC<WithChildren> = ({ children }) => {
  const trackVideoPlayerEvent = useTrackVideoPlayerEvent();

  const [, , machineRef] = useMachine(
    strataPlayerMachine.provide({
      actions: {
        trackVideoPlayerEvent: (_ctx) => {
          const event = _ctx.event;
          if (event && typeof event === 'object') {
            const data = 'data' in event.params ? event.params.data : undefined;
            const params = {
              ...event.params,
              data: buildEventData(data, _ctx.context),
            };
            trackVideoPlayerEvent(params);
          }
        },
      },
    })
  );

  return (
    <StrataContext.Provider value={machineRef}>
      {children}
    </StrataContext.Provider>
  );
};

import { useLayoutEffect } from 'react';
import muxjs from 'mux.js';
import { livePlayerUiConfig } from 'utils/useVideoPlayerUI';
import useVideoPlayerStrataEvents from 'utils/useVideoPlayerStrataEvents';
import useVideoPlayerPlaylistController from 'utils/useVideoPlayerPlaylistController';
import VideoPlayerComponent from '../VideoPlayerComponent';
import { VideoPlayerBasicProps } from '../types';

interface VideoPlayerLiveComponentProps extends VideoPlayerBasicProps {}

const VideoPlayerLiveComponent = (props: VideoPlayerLiveComponentProps) => {
  useVideoPlayerPlaylistController();
  const strataEventsHandlers = useVideoPlayerStrataEvents();

  useLayoutEffect(() => {
    window.muxjs = muxjs;

    return () => {
      delete window.muxjs;
    };
  }, []);

  return (
    <VideoPlayerComponent
      {...strataEventsHandlers}
      {...props}
      uiConfig={livePlayerUiConfig}
    />
  );
};

export default VideoPlayerLiveComponent;

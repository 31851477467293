import {
  Asset,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import { CarouselType } from './carouselHelpers';

interface ResponsiveImageSrc {
  srcSet: string;
  src: string;
}

export const getCarouselThumbnailSrc = (
  type?: CarouselType,
  imageUrl?: string
): ResponsiveImageSrc => {
  if (!imageUrl) return { src: '', srcSet: '' };
  if (
    type &&
    [
      CarouselType.POSTER_SCALE_UP,
      CarouselType.PORTRAIT_CAROUSEL,
      CarouselType.LARGE_PORTRAIT_CAROUSEL,
      CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
    ].includes(type)
  ) {
    return {
      srcSet: `
        ${imageUrl}_237x354,
        ${imageUrl}_316x472 1.5x,
        ${imageUrl}_474x708 2x,
      `,
      src: `${imageUrl}_474x708`,
    };
  }
  return {
    srcSet: `
      ${imageUrl}_350x197,
      ${imageUrl}_525x296 1.5x,
      ${imageUrl}_700x394 2x,
    `,
    src: `${imageUrl}_700x394`,
  };
};

export const getJumbotronThumbnailSrc = (
  imageUrl?: string
): ResponsiveImageSrc => {
  if (!imageUrl) return { src: '', srcSet: '' };
  return {
    srcSet: `
      ${imageUrl}_640x360,
      ${imageUrl}_960x640 1.5x,
      ${imageUrl}_1280x720 2x,
    `,
    src: `${imageUrl}_1280x720`,
  };
};

export const getPosterHoverUrl = (
  asset: Asset,
  carouselType?: CarouselType
) => {
  const url =
    carouselType !== CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL
      ? getAssetImageWithType(asset, 'thumbnail')?.url
      : getAssetImageWithType(asset, 'backgroundCentred')?.url;

  if (!url) return undefined;

  return carouselType !== CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL
    ? `${url}_1050x591`
    : `${url}_960x640`;
};

import React from 'react';
import { SORTING_OPTIONS } from 'utils/sorting';
import useGetSearchParams from 'utils/useGetSearchParams';
import { MoveToListingParams } from 'utils/useMoveToListing';
import { useHistory } from 'react-router';
import { Select } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectAppTheme } from 'store/app/selectors';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface SortingSelectorProps {
  onChange: (sorting: string) => void;
  value?: string;
}

const SortingSelector = ({ onChange, value }: SortingSelectorProps) => {
  const { bcpath, genre, audio, subtitles, mood } =
    useGetSearchParams() as MoveToListingParams;
  const appTheme = useSelector(selectAppTheme);
  const { t } = useTranslation();

  const history = useHistory();

  const checkIfSearchParams = () => {
    if (genre || audio || subtitles || mood) {
      return true;
    }

    return false;
  };

  return (
    <Select
      appTheme={appTheme}
      width="270px"
      options={SORTING_OPTIONS}
      placeholder={SORTING_OPTIONS[0].label}
      value={SORTING_OPTIONS.find((option) => option.value === value)}
      components={{
        ValueContainer: ({ children }: { children: any }) => (
          <div className={styles.selectValueContainer}>
            <div className={styles.sortHeader}>{t('filter.sortBy')}:</div>
            <div className={styles.selectValue}>{children}</div>
          </div>
        ),
      }}
      onChange={(option: any) => {
        const ifParams = checkIfSearchParams();
        if (ifParams || (!ifParams && option.value !== 'none')) {
          onChange(option.value);
        }

        if (!ifParams && bcpath && option.value === 'none') {
          history.push(bcpath);
        }
      }}
    />
  );
};

export default SortingSelector;

import { Collection } from '@laminar-product/client-commons-core/core';
import { AnyAction } from 'redux';
import { RESET_COLLECTIONS, SET_COLLECTION } from './actions';

export interface CollectionState {
  [key: string]: Collection;
}

const collectionsReducer = (state: CollectionState = {}, action: AnyAction) => {
  switch (action.type) {
    case SET_COLLECTION:
      return {
        ...state,
        [action.payload.id]: action.payload.collection,
      };
    case RESET_COLLECTIONS:
      return {};
    default:
      return state;
  }
};

export default collectionsReducer;

import { AssetType } from '@laminar-product/client-commons-core/core';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { selectPlayerAsset } from 'store/player/selectors';
import { useSelector } from 'react-redux';
import NextEpisodeButton from 'components/NextEpisodeComponents/NextEpisodeButton';
import { selectRootAsset } from 'store/app/selectors';
import usePlayerGoBack from 'utils/usePlayerGoBack';
import EpisodeSelector from '../EpisodeSelector';
import ChromecastButton from '../ChromecastButton';
import styles from './index.module.scss';

const TopControls = () => {
  const asset = useSelector(selectPlayerAsset);
  const rootNextAsset = useSelector(selectRootAsset(asset?.id))?.nextAsset;
  const isEpisode = asset?.type === AssetType.EPISODE;
  const { t } = useTranslation();
  const goBackFromPlayer = usePlayerGoBack(asset);

  if (!asset) {
    return null;
  }

  return (
    <div id="shaka-player-top-controls" className={styles.topControlsWrapper}>
      <div className={styles.backArrowWrapper} onClick={goBackFromPlayer}>
        <ArrowLeft className={styles.backArrow} />
      </div>

      <div className={styles.controlsWrapper}>
        <div className={styles.header}>
          <div className={styles.assetName}>
            {isEpisode ? asset.root?.name : asset.name}
          </div>

          <div className={styles.episodeButtons}>
            {isEpisode && <EpisodeSelector />}

            {rootNextAsset && (
              <NextEpisodeButton
                seriesName={asset.root?.name}
                rootNextAsset={rootNextAsset}
              />
            )}
          </div>

          <ChromecastButton />
        </div>

        {isEpisode && (
          <div className={styles.episodeInfoWrapper}>
            <h2 className={styles.seasonName}>
              {asset.parent?.name} <span className={styles.divider}>|</span>{' '}
              {t('common.episode')} {asset.order}
            </h2>

            <span className={styles.episodeName}>{asset.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopControls;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * This hook listens for history changes and scrolls the view to top on every change
 *
 */
const useScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unregister = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unregister();
    };
  }, [history]);
};

export default useScrollToTop;

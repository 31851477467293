export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
export const setUserCredentials = (data: {
  email?: string;
  phone?: string;
}) => ({
  type: SET_USER_CREDENTIALS,
  payload: data,
});

export const SET_REDIRECT_TO = 'SET_REDIRECT_TO';
export const setRedirectTo = (redirectTo: string) => ({
  type: SET_REDIRECT_TO,
  payload: { redirectTo },
});

export const SIGN_IN_FROM_TV = 'SIGN_IN_FROM_TV';
export const signInFromTv = () => ({
  type: SIGN_IN_FROM_TV,
});

export const SIGN_UP_FROM_TV = 'SIGN_UP_FROM_TV';
export const signUpFromTv = () => ({
  type: SIGN_UP_FROM_TV,
});

import { SSOProviderType } from '@laminar-product/client-commons-core/core';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import timesSSOIcon from 'assets/images/times-sso.png';

interface GetErrorMessageParams {
  t: TFunction;
  error?: AxiosError;
  provider: SSOProviderType;
}

export const getErrorMessage = ({
  t,
  error,
  provider,
}: GetErrorMessageParams) => {
  if (error?.response?.data?.message) {
    return error?.response.data.message;
  }
  return t('login.ssoDefaultError', { provider });
};

export const getSSOProviderName = (provider: SSOProviderType) => {
  switch (provider) {
    case 'TIMES_SSO':
      return 'timesplay';
    default:
      return provider;
  }
};

export const getSSOProviderIcon = (provider: SSOProviderType) => {
  switch (provider) {
    case 'TIMES_SSO':
      return timesSSOIcon;
    default:
      return provider;
  }
};

import React, { useEffect } from 'react';
import OneTimePasswordForm from 'components/OneTimePasswordForm';
import { useCustomOtpProvider } from 'utils/useOtp';

export interface CustomProviderPhoneAuthProps {
  phoneNumber: string;
  isRegistering?: boolean;
  otpGenerationUrl: string;
}

const CustomProviderPhoneAuth = ({
  phoneNumber,
  isRegistering,
  otpGenerationUrl,
}: CustomProviderPhoneAuthProps) => {
  const {
    isLoading,
    errorOtpMessage,
    isConfirmationResultSent,
    signIn,
    clearError,
    sendOtp,
  } = useCustomOtpProvider({ isRegistering, otpGenerationUrl });

  useEffect(() => {
    if (phoneNumber && !isConfirmationResultSent) {
      sendOtp();
    }
  }, [isConfirmationResultSent, phoneNumber, sendOtp]);

  return (
    <>
      <OneTimePasswordForm
        isDarkMode={!isRegistering}
        isRegistering={isRegistering}
        isLoginForm
        hasRememberMe={!isRegistering}
        onVerify={signIn}
        phoneNumber={phoneNumber}
        isLoading={isLoading}
        errorMessage={errorOtpMessage}
        clearError={clearError}
        sendOtp={sendOtp}
      />
    </>
  );
};

export default CustomProviderPhoneAuth;

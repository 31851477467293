import React from 'react';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import styles from './index.module.scss';

interface CloseProfileModalProps {
  onClose: () => void;
}

const CloseProfileModal = ({ onClose }: CloseProfileModalProps) => (
  <Close className={styles.closeIcon} onClick={onClose} />
);

export default CloseProfileModal;

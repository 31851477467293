import { Button } from '@laminar-product/client-commons-core/web';
import Popup from 'components/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export type PaymentMethodModalVariant = 'confirm-change' | 'success-change';

interface PaymentMethodModalProps {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  variant?: PaymentMethodModalVariant;
}

const PaymentMethodModal = ({
  onClose,
  onSubmit,
  isLoading,
  variant,
}: PaymentMethodModalProps) => {
  const { t } = useTranslation();
  const isConfirmChangeVariant = variant === 'confirm-change';

  return (
    <Popup
      header={t(
        isConfirmChangeVariant
          ? 'payment.changePaymentMethodModalHeader'
          : 'payment.changePaymentMethodSuccessHeader'
      )}
      message={t(
        isConfirmChangeVariant
          ? 'payment.changePaymentMethodModalDescription'
          : 'payment.changePaymentMethodSuccessDescription'
      )}
      wrapperClassName={styles.confirmationModal}
      actionButtons={
        <>
          {isConfirmChangeVariant && (
            <Button variant="secondary-settings" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          )}

          <Button variant="cta" onClick={onSubmit} loading={isLoading}>
            {t(isConfirmChangeVariant ? 'common.continue' : 'common.OK')}
          </Button>
        </>
      }
    />
  );
};

export default PaymentMethodModal;

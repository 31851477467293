import { useEffect, useState } from 'react';

const useScroll = () => {
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    const onScroll = (e: Event) => {
      setX((e?.target as Document).documentElement.scrollLeft);
      setY((e?.target as Document).documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { x, y };
};

export default useScroll;

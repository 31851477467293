import FeatureEnabled from 'components/FeatureEnabled';
import {
  CustomAuthenticationProviders,
  FeatureFlag,
  upgradePlanParamId,
} from 'utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRedirectTo } from 'store/login/actions';
import { Tab, Tabs } from '@laminar-product/client-commons-core/web';
import usePathWithParams from 'utils/usePathWithParams';
import {
  selectAppName,
  selectCustomAuthenticationProviders,
} from 'store/app/selectors';
import useRouteInitializeAfterLogin from 'utils/useRouteInitializeAfterLogin';
import SocialLogin from 'components/SocialLogin';
import useRedirectToHomePage from 'utils/useRedirectToHomePage';
import { APP_PAGE_PATH } from 'utils/navigation';
import styles from '../index.module.scss';
import EmailPassword from './EmailPassword';
import PhoneInput from './PhoneInput';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { location } = useHistory<{ redirectTo: string }>();
  const { t } = useTranslation();
  const appName = useSelector(selectAppName);
  const queryParams = new URLSearchParams(location.search);
  const getPath = usePathWithParams();
  useRouteInitializeAfterLogin();
  useRedirectToHomePage();
  const allowedCustomAuthenticationProviders = useSelector(
    selectCustomAuthenticationProviders
  );

  useEffect(() => {
    if (location.state?.redirectTo) {
      dispatch(setRedirectTo(location.state.redirectTo));
    }
  }, [dispatch, location.state]);

  const getSignInTabs = useCallback(() => {
    return allowedCustomAuthenticationProviders?.map((provider) => {
      switch (provider) {
        case CustomAuthenticationProviders.EMAIL_PASSWORD:
          return (
            <Tab
              label="Email address"
              key={CustomAuthenticationProviders.EMAIL_PASSWORD}
            >
              <EmailPassword />
            </Tab>
          );
        case CustomAuthenticationProviders.PHONE_NUMBER:
          return (
            <Tab
              label="Phone number"
              key={CustomAuthenticationProviders.PHONE_NUMBER}
            >
              <PhoneInput />
            </Tab>
          );
        default:
          return <></>;
      }
    });
  }, [allowedCustomAuthenticationProviders]);

  const authenticationTabs = getSignInTabs();
  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>{t('login.heading')}</h1>

      <section className={styles.authFormContainer}>
        {!!authenticationTabs?.length ? (
          <Tabs
            defaultActiveKey={CustomAuthenticationProviders.EMAIL_PASSWORD}
            navClassName={styles.tabs}
            navActiveClassName={styles.tabsActive}
          >
            {authenticationTabs}
          </Tabs>
        ) : null}
      </section>

      <SocialLogin customAuthenticationAllowed={!!authenticationTabs?.length} />
      <FeatureEnabled featureFlag={FeatureFlag.showRegistration}>
        <p className={styles.footNote}>
          <Trans i18nKey="login.footNote" values={{ appName }}>
            New to {{ appName }}?
            <Link
              to={getPath(
                APP_PAGE_PATH.REGISTER,
                !!queryParams.get(upgradePlanParamId)
              )}
            >
              Sign up now.
            </Link>
            <br />
          </Trans>
        </p>
        <p className={styles.footNoteRecaptcha}>
          <Trans i18nKey="login.footNoteRecaptcha" />
        </p>
      </FeatureEnabled>
    </div>
  );
};

export default LoginForm;

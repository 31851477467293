import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import FeatureEnabled from 'components/FeatureEnabled';
import PopOver from 'components/PopOver';
import ProfileAvatar from 'components/ProfileAvatar';
import ProfilePinCheck from 'pages/ProfilesSelector/ProfilePinCheck';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FeatureFlag } from 'utils/constants';
import {
  checkIsKidsProfile,
  useGetCurrentProfile,
  useSetCurrentProfile,
  useSignOut,
} from 'utils/profiles';
import { Profile } from '@laminar-product/client-commons-core/core';
import { selectProfiles } from 'store/user/selectors';
import { Modal } from '@laminar-product/client-commons-core/web';
import { UserManagingProfilesVariant } from 'types/user';
import { setUserManagingProfilesVariant } from 'store/user/actions';
import styles from './index.module.scss';
import ProfileMenuItem from './ProfileMenuItem';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const profileButtonRef = useRef(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profilePinCheckOpen, setProfilePinCheckOpen] =
    useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const toggleProfileMenuOpen = () => setProfileMenuOpen(!profileMenuOpen);
  const signOut = useSignOut();
  const setCurrentProfile = useSetCurrentProfile();
  const profiles = useSelector(selectProfiles);
  const currentProfile = useGetCurrentProfile();
  const dispatch = useDispatch();

  const onProfileSelect = (profile: Profile) => {
    if (profile) {
      setSelectedProfile(profile);
      if (profile.pin) {
        setProfilePinCheckOpen(true);
        return;
      }
      setCurrentProfile(profile);
    }
  };

  const onPinCorrect = (profile: Profile) => {
    setCurrentProfile(profile);
    setProfilePinCheckOpen(false);
  };

  const onManageProfilesClick = () =>
    dispatch(
      setUserManagingProfilesVariant(UserManagingProfilesVariant.MANAGE)
    );

  return (
    <>
      <div
        onClick={toggleProfileMenuOpen}
        ref={profileButtonRef}
        className={styles.profileButtonWrapper}
        data-testid="Menu__ProfileButton"
      >
        <ProfileAvatar
          name={currentProfile?.name}
          isNavbarAvatar
          isKidsProfile={checkIsKidsProfile(currentProfile)}
        />
        <ArrowDownIcon className={styles.arrowDownIcon} />
      </div>
      <PopOver
        anchorEl={profileButtonRef.current}
        placement="bottom-end"
        isOpen={profileMenuOpen}
        onClose={() => setProfileMenuOpen(false)}
      >
        <div className={styles.profileMenu}>
          {profiles?.map(
            (profile, index) =>
              profile.uuid !== currentProfile?.uuid && (
                <ProfileMenuItem
                  key={index}
                  onProfileSelect={onProfileSelect}
                  profile={profile}
                />
              )
          )}

          {profiles && profiles?.length > 1 && (
            <div className={styles.divider} />
          )}

          <div onClick={onManageProfilesClick} className={styles.item}>
            {t('profile.manageProfiles')}
          </div>

          <FeatureEnabled featureFlag={FeatureFlag.showRegistration}>
            <div className={styles.item}>
              <Link to="/settings">{t('common.accountSettings')}</Link>
            </div>
          </FeatureEnabled>
          <div className={styles.item} onClick={signOut}>
            {t('common.signOut')}
          </div>
        </div>
      </PopOver>

      <Modal
        wrapperId="profilePin"
        isOpen={profilePinCheckOpen && !!selectedProfile}
      >
        <ProfilePinCheck
          onPinCorrect={onPinCorrect}
          profile={selectedProfile}
          onClose={() => setProfilePinCheckOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ProfileMenu;

import { ReactComponent as ChromecastDisconnected } from 'assets/icons/chromecast-disconnected.svg';
import { ReactComponent as ChromecastConnected } from 'assets/icons/chromecast-connected.svg';
import { useSelector } from 'react-redux';
import {
  selectChromecastAssetId,
  selectChromecastCastElement,
  selectIsChromecastAvailable,
  selectIsChromecastConnected,
} from 'store/chromecast/selectors';
import { useCallback, useEffect } from 'react';
import {
  selectPlayerAsset,
  selectPlayerContent,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import usePlayerGoBack from 'utils/usePlayerGoBack';
import useChromecastCast from 'utils/useChromecastCast';
import { selectUserIsAllowedToCast } from 'store/user/selectors';
import styles from './index.module.scss';

const ChromecastButton = () => {
  const isChromecastAvailable = useSelector(selectIsChromecastAvailable);
  const isChromecastConnected = useSelector(selectIsChromecastConnected);
  const castElement = useSelector(selectChromecastCastElement);
  const asset = useSelector(selectPlayerAsset);
  const content = useSelector(selectPlayerContent);
  const videoElement = useSelector(selectPlayerVideoElement);
  const goBackFromPlayer = usePlayerGoBack(asset);
  const chromecastAssetId = useSelector(selectChromecastAssetId);
  const castWithChromecast = useChromecastCast();
  const isAllowedToCast = useSelector(selectUserIsAllowedToCast);

  const onCastButtonClick = useCallback(async () => {
    if (!castElement || !asset || !content) {
      return;
    }

    //Fallback for refreshing; if connection is established but user want to start session again from player
    if (isChromecastConnected) {
      castElement.disconnect();
    }

    await castWithChromecast({
      assetId: asset.id,
      contentId: content.sourceUuid,
      startTime: videoElement?.currentTime,
    });
  }, [
    castElement,
    asset,
    content,
    isChromecastConnected,
    castWithChromecast,
    videoElement,
  ]);

  //Exit the player after connection
  useEffect(() => {
    if (!isChromecastConnected || !asset || !chromecastAssetId) {
      return;
    }

    goBackFromPlayer();
  }, [asset, chromecastAssetId, goBackFromPlayer, isChromecastConnected]);

  if (!isChromecastAvailable || !isAllowedToCast) {
    return null;
  }

  return (
    <div onClick={onCastButtonClick}>
      {isChromecastConnected ? (
        <ChromecastConnected className={styles.chromecastIcon} />
      ) : (
        <ChromecastDisconnected className={styles.chromecastIcon} />
      )}
    </div>
  );
};

export default ChromecastButton;

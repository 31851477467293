import { getSSOAuthToken } from 'actions/sso';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { registerUser } from 'actions/basic';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { setLoginMethod } from 'store/user/actions';

const TICKET_ID_KEY = 'ticketId';
const PROVIDER_KEY = 'channel';

export const useSSOAuth = () => {
  const isAnonymous = useSelector(selectIsAnonymous);
  const { search } = useLocation();
  const { push } = useHistory();
  const [isLoadingSSOUser, setIsLoadingSSOUser] = useState(false);
  const dispatch = useDispatch();

  const handleSignInWithSSO = useCallback(
    async (ticketId: string, provider: string) => {
      setIsLoadingSSOUser(true);
      try {
        const { customToken } = await getSSOAuthToken({
          ticketId,
          provider,
        });

        if (!customToken) {
          return;
        }

        const auth = getAuth();

        await signInWithCustomToken(auth, customToken);
        const tokenResult = await getAuth().currentUser?.getIdTokenResult();

        const isNewUser = tokenResult?.claims.isNewUser;
        if (isNewUser) {
          const token = await registerUser();
          await signInWithCustomToken(auth, token?.data);
          push('/register/plan');
          return;
        }
        push('/');
      } catch (error: any) {
        captureError(error, `useSSOAuth: ${provider}`, Severity.Error);
      } finally {
        setIsLoadingSSOUser(false);
      }
    },
    [push]
  );

  useEffect(() => {
    if (!isAnonymous || !search.includes(TICKET_ID_KEY)) {
      return;
    }

    const searchParams = new URLSearchParams(search);

    const ticketId = searchParams.get(TICKET_ID_KEY);
    const provider = searchParams.get(PROVIDER_KEY);

    if (ticketId && provider) {
      handleSignInWithSSO(ticketId, provider);
      dispatch(setLoginMethod('sso'));
    }
  }, [dispatch, handleSignInWithSSO, isAnonymous, search]);

  return { isLoadingSSOUser };
};

import React from 'react';
import { useCustomOtpProvider } from 'utils/useOtp';
import { ProfileConfirmationProps } from '../../../ProfileIdentityConfirmation';
import PhoneConfirmation from './PhoneConfirmation';

interface CustomProviderPhoneConfirmationProps
  extends ProfileConfirmationProps {
  otpGenerationUrl: string;
}

const CustomProviderPhoneConfirmation = ({
  onClose,
  onConfirmationSuccess,
  otpGenerationUrl,
}: CustomProviderPhoneConfirmationProps) => {
  const {
    isConfirmationResultSent,
    errorOtpMessage,
    sendOtp,
    signIn: confirmOtp,
    isLoading: isConfirming,
  } = useCustomOtpProvider({ onConfirmationSuccess, otpGenerationUrl });

  return (
    <PhoneConfirmation
      onClose={onClose}
      isConfirmationResultSent={isConfirmationResultSent}
      errorMessage={errorOtpMessage}
      isConfirming={isConfirming}
      sendOtp={sendOtp}
      confirmOtp={confirmOtp}
    />
  );
};

export default CustomProviderPhoneConfirmation;

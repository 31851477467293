import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import styles from './index.module.scss';

export interface TabsProps {
  defaultActiveKey: string;
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
  onChange?: (key: string) => void;
  className?: string;
  navClassName?: string;
  navActiveClassName?: string;
}

const Tabs = ({
  defaultActiveKey,
  children,
  className,
  navClassName,
  navActiveClassName = '',
}: TabsProps) => {
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);

  // Children can be a single element, so we want to correct it
  const childrenArray = Array.isArray(children) ? children : [children];

  const activeTab = childrenArray.find((tab) => tab.key === activeKey);

  const tabs = childrenArray.map((tab) => (
    <button
      key={tab.key}
      onClick={() => setActiveKey(String(tab.key))}
      className={cn(styles.tab, {
        [styles.active]: activeKey === tab.key,
        [navActiveClassName]: activeKey === tab.key,
      })}
    >
      {tab.props.label}
    </button>
  ));

  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.nav, navClassName)}>{tabs}</div>
      {activeTab}
    </div>
  );
};

export interface TabProps {
  children?: ReactNode;
  label: ReactNode;
  key: string;
}

export const Tab = ({ children }: TabProps) => <div>{children}</div>;

export default Tabs;

import {
  changeSubscriptionPaymentMethod,
  createSubscription,
} from 'actions/payments';
import { useCallback, useState } from 'react';
import { Severity } from 'types/errors';
import {
  CreateRazorpaySubscriptionForm,
  CreateSubscriptionRazorpayData,
} from 'types/subscription';
import { captureError } from './captureError';
import { RAZORPAY_ERROR_CODES } from './payments';

interface HandleRazorpayPaymentMethodChangeProps {
  onSuccessChange: () => void;
}

const useHandleRazorpayPayment = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [razorpayPaymentErrorCode, setRazorpayPaymentErrorCode] =
    useState<string>();

  const loadRazorpayScript = () =>
    new Promise((resolve) => {
      const script = document.createElement('script');
      const scriptSource = import.meta.env.VITE_RAZORPAY_SCRIPT_URL;
      script.async = true;

      if (!scriptSource) {
        return Promise.reject('No value for razorpay script url provided');
      }

      script.src = scriptSource;
      document.body.appendChild(script);

      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
    });

  const submitRazorpayAction = useCallback(
    async (options: CreateRazorpaySubscriptionForm) => {
      const scriptResponse = await loadRazorpayScript();

      if (!scriptResponse) {
        return;
      }

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    },
    []
  );

  const handleRazorpayAttributedCheckoutPayment = useCallback(
    async (data: CreateSubscriptionRazorpayData) => {
      try {
        const key = import.meta.env.VITE_RAZORPAY_KEY;
        const callback_url = import.meta.env.VITE_RAZORPAY_SUCCESS_CALLBACK_URL;

        if (!key || !callback_url) {
          return Promise.reject(
            'No values for razorpay key or callback_url provided'
          );
        }

        const razorpayForm = {
          ...data,
          key,
          callback_url,
        };

        await submitRazorpayAction(razorpayForm);
      } catch (error) {
        captureError(
          error as any,
          'razorpayAttributedCheckoutPayment/paymentAction',
          Severity.Error
        );
      }
    },
    [submitRazorpayAction]
  );

  const handleRazorpayPayment = useCallback(
    async (priceId: string) => {
      try {
        const { data } = await createSubscription(priceId);
        const key = import.meta.env.VITE_RAZORPAY_KEY;
        const callback_url = import.meta.env.VITE_RAZORPAY_SUCCESS_CALLBACK_URL;

        if (!key || !callback_url) {
          return Promise.reject(
            'No values for razorpay key or callback_url provided'
          );
        }

        const razorpayForm = {
          ...data,
          key,
          callback_url,
        };

        await submitRazorpayAction(razorpayForm);
      } catch (error) {
        const errorCode =
          (error as any)?.response?.data?.code ||
          RAZORPAY_ERROR_CODES.DEFAULT_ERROR;

        setRazorpayPaymentErrorCode(errorCode);

        captureError(
          error as any,
          'razorpayHandlePayment/createSubscription',
          Severity.Error
        );
      }
    },
    [submitRazorpayAction]
  );

  const handleRazorpayPaymentMethodChange = useCallback(
    async ({ onSuccessChange }: HandleRazorpayPaymentMethodChangeProps) => {
      try {
        setIsProcessing(true);
        const { data } = await changeSubscriptionPaymentMethod();
        const key = import.meta.env.VITE_RAZORPAY_KEY;

        //If subscription_id exists in data, endpoint returns correct data for razorpay form
        if (!key || !('subscription_id' in data)) {
          return Promise.reject('No value for razorpay key provided');
        }

        const razorpayForm = {
          ...data,
          key,
          handler: onSuccessChange,
        };

        await submitRazorpayAction(razorpayForm);
      } catch (error) {
        captureError(
          error as any,
          'razorpayChangePaymentMethod/changeSubscriptionMethod',
          Severity.Error
        );
      } finally {
        setIsProcessing(false);
      }
    },
    [submitRazorpayAction]
  );

  return {
    handleRazorpayPayment,
    handleRazorpayPaymentMethodChange,
    isProcessing,
    handleRazorpayAttributedCheckoutPayment,
    razorpayPaymentErrorCode,
  };
};

export default useHandleRazorpayPayment;

import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

const createCaptchaElement = () => {
  const recaptchaElement = document.createElement('div');
  recaptchaElement.id = 'recaptcha-verify-element';
  document.body.appendChild(recaptchaElement);
};

const useRecaptchaElement = () => {
  const [appVerifier, setAppVerifier] = useState<RecaptchaVerifier>();
  const auth = getAuth();

  // Create recaptcha element
  useEffect(() => {
    createCaptchaElement();
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-verify-element',
      {
        size: 'invisible',
      },
      auth
    );
    window.recaptchaVerifier = recaptchaVerifier;
    setAppVerifier(recaptchaVerifier);

    return () => {
      document.body.querySelector('#recaptcha-verify-element')?.remove();
    };
  }, [auth]);
  return appVerifier;
};

export default useRecaptchaElement;

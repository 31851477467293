import { DropdownOption } from 'types/dropdown';
import Dropdown from 'components/Dropdown';
import cn from 'classnames';
import styles from './index.module.scss';
interface SettingsDropdownItemProps {
  title: string;
  options: DropdownOption[];
  selectedOption?: DropdownOption;
  onChange: (value: DropdownOption) => void;
  className?: string;
}

const SettingsDropdownItem = ({
  title,
  onChange,
  options,
  selectedOption,
  className,
}: SettingsDropdownItemProps) => (
  <>
    <div className={cn(styles.row, className)}>
      <div className={styles.titleContainer}>{title}</div>

      <Dropdown
        onChange={onChange}
        options={options}
        selectedOption={selectedOption}
      />
    </div>

    <div className={styles.subtitle}>{selectedOption?.subLabel}</div>
  </>
);

export default SettingsDropdownItem;

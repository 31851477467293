import {
  AssetType,
  transformAssetName,
} from '@laminar-product/client-commons-core/core';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { AssetLocationState } from 'types/location';
import {
  watchDefaultPath,
  watchLiveContentPath,
  watchMovieContentPath,
  watchShowContentPath,
} from 'utils/constants';
import { MoveToAssetPlayerContext } from 'utils/useMoveToAssetPlayer';
import { useSelector } from 'react-redux';
import {
  selectChromecastCastElement,
  selectShouldDisplayChromecastController,
} from 'store/chromecast/selectors';
import styles from './index.module.scss';

export const getAssetWatchUrl = (type: AssetType) => {
  switch (type) {
    case AssetType.MOVIE:
      return watchMovieContentPath;
    case AssetType.LIVE_CHANNEL:
    case AssetType.LIVE_EVENT:
      return watchLiveContentPath;
    case AssetType.EPISODE:
    case AssetType.SEASON:
    case AssetType.SHOW:
      return watchShowContentPath;
    default:
      return watchDefaultPath;
  }
};

export const MoveToAssetProvider: FC<PropsWithChildren> = ({ children }) => {
  const { push } = useHistory();
  const timeout = useRef<NodeJS.Timeout>();
  const shouldDisplayChromecastController = useSelector(
    selectShouldDisplayChromecastController
  );
  const castElement = useSelector(selectChromecastCastElement);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  const moveToAssetPlayer = useCallback(
    (
      id: string,
      type: AssetType,
      administrativeName: string,
      options?: { contentId?: string; state?: AssetLocationState }
    ) => {
      if (shouldDisplayChromecastController) {
        castElement?.disconnect();
      }

      const path = getAssetWatchUrl(type);
      document.body.classList?.add(styles.moveToAssetPlayer__transition);
      timeout.current = setTimeout(() => {
        push(
          path
            .replace(':id', id)
            .replace(':type', type)
            .replace(':name', transformAssetName(administrativeName) ?? '')
            .replace(':contentId', options?.contentId ?? ''),
          options?.state
        );
        document.body.classList?.remove(styles.moveToAssetPlayer__transition);
      }, 800);
    },
    [castElement, push, shouldDisplayChromecastController]
  );

  return (
    <MoveToAssetPlayerContext.Provider value={moveToAssetPlayer}>
      {children}
    </MoveToAssetPlayerContext.Provider>
  );
};

import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectLogo } from 'store/app/selectors';
import styles from './index.module.scss';

interface CompanyLogoProps {
  className?: string;
  onClick?: () => void;
}

const CompanyLogo = ({ className, onClick }: CompanyLogoProps) => {
  const logoUrl = useSelector(selectLogo) || '';

  return (
    <img
      alt=""
      src={logoUrl}
      className={cn(styles.root, className, onClick && styles.pointer)}
      onClick={onClick}
    />
  );
};

export default CompanyLogo;

//https://shaka-player-demo.appspot.com/docs/api/tutorial-ui-customization.html
import shaka from 'shaka-player/dist/shaka-player.ui'; //Temporary: Incorrect export from shaka-package - https://gist.github.com/Security2431/2b28f17e11870bb4b0e347673e16d5ba
import SkipForward from 'assets/icons/skipForward.svg';
import { VIDEO_PLAYER_SEEK_TIME } from 'utils/constants';

// Use shaka.ui.Element as a base class
class VideoPlayerSkipRightButton extends shaka.ui.Element {
  button_: HTMLButtonElement | null = null;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    // The actual button that will be displayed
    this.button_ = document.createElement('button');
    this.button_.classList.add('shaka-small-skip-right-button');
    const img = document.createElement('img');
    img.src = SkipForward;

    this.button_.appendChild(img);
    this.parent?.appendChild(this.button_);

    // Listen for clicks on the button
    this.eventManager?.listen(this.button_, 'click', () => {
      if (!this.video) {
        return;
      }

      this.video.currentTime = this.video.currentTime + VIDEO_PLAYER_SEEK_TIME;
    });
  }
}

class VideoPlayerSkipButtonRightFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new VideoPlayerSkipRightButton(rootElement, controls);
  }
}

// Register our factory with the controls, so controls can create button instances.
shaka.ui.Controls.registerElement(
  // This name will serve as a reference to the button in the UI configuration object
  'video_player_skip_right_button',
  new VideoPlayerSkipButtonRightFactory()
);

export default VideoPlayerSkipRightButton;

import React, { useState } from 'react';
import { Button } from '@laminar-product/client-commons-core/web';
import {
  useSendActionObj,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { useTranslation } from 'react-i18next';
import { registerDevice } from 'actions/basic';
import { AxiosError } from 'axios';
import { captureError } from 'utils/captureError';
import Input from 'components/Input';
import { Severity } from 'types/errors';
import { ReactComponent as TVIcon } from 'assets/icons/tvSuccess.svg';
import { Controller, useForm } from 'react-hook-form';
import GoBackLink from 'components/GoBackLink';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

interface RegisterDeviceForm {
  code: string;
}

const RegisterDevice = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);

  const { handleSubmit, control } = useForm<RegisterDeviceForm>({
    defaultValues: { code: '' },
  });
  const {
    sendAction: register,
    isLoading,
    error: registerDeviceError,
  } = useSendActionObj(registerDevice, {
    onDone: () => setIsSuccess(true),
    onError: (err) =>
      captureError(err as AxiosError, 'registerDevice', Severity.Error),
  });
  const { isTabletWindowSize } = useWindowSize();

  const registerHandler = ({ code }: RegisterDeviceForm) =>
    register(code.toLowerCase());

  return (
    <>
      <GoBackLink
        text="settings.goBackToSettings"
        className={styles.goBackLink}
        onClick={() => push('/settings')}
      />
      <div className={styles.wrapper}>
        {isSuccess && <TVIcon className={styles.tvIcon} />}
        <h2 className={styles.header}>
          {t(
            isSuccess
              ? 'settings.devices.successRegister'
              : 'settings.devices.registerNewDevice'
          )}
        </h2>

        {!isSuccess && (
          <>
            <p className={styles.description}>
              {t('settings.devices.registerDescription')}
            </p>

            <form onSubmit={handleSubmit(registerHandler)}>
              <div className={styles.codeInputWrapper}>
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <Input
                      className={styles.input}
                      type="number"
                      light
                      {...field}
                    />
                  )}
                />
                {registerDeviceError && (
                  <div className={styles.error}>
                    {t('settings.devices.wrongCode')}
                  </div>
                )}
              </div>

              <Button
                type="submit"
                loading={isLoading}
                style={{
                  minWidth: isTabletWindowSize ? '100%' : '350px',
                }}
                className={styles.registerButton}
              >
                {t('settings.devices.registerButtonLabel')}
              </Button>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default RegisterDevice;

import {
  ContinueWatchingItem,
  Profile,
} from '@laminar-product/client-commons-core/core';
import api from 'api';
import { ProfileBasic } from 'types/profiles';
import { LikeResponse } from './likes';

export const getProfiles = async (): Promise<Profile[]> => {
  const { data } = await api().get('/users/profile');

  return data;
};

export const getProfileById = async (id: string): Promise<Profile> => {
  const { data } = await api().get(`/users/profile/${id}`);
  // TODO: remove after backend fix
  const profileWithTrimmedPin = {
    ...data,
    pin: data.pin?.trim(),
  };
  return profileWithTrimmedPin;
};

export const saveProfile = async (profile: ProfileBasic): Promise<Profile> => {
  const { data } = await api().post('/users/profile', profile);

  return data;
};

export const updateProfile = async (profile: Profile): Promise<Profile> => {
  const { data } = await api().patch(`/users/profile/${profile.uuid}`, profile);
  return data;
};

export const deleteProfile = (id: string) =>
  api().delete(`/users/profile/${id}`);

export const getWatchHistoryByProfile = async (
  profileUuid: string
): Promise<ContinueWatchingItem[]> => {
  const { data } = await api().get(`/favorites/watching`, {
    params: {
      profile: profileUuid,
    },
  });
  return data;
};

export const getLikesByProfile = async (
  profileUuid: string
): Promise<LikeResponse[]> => {
  const { data } = await api().get(`/favorites/like`, {
    params: {
      profile: profileUuid,
    },
  });
  return data;
};

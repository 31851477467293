import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getOtpProvider } from 'actions/otpProvider';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Severity } from 'types/errors';
import { captureError } from 'utils/captureError';
import useRouteInitializeAfterLogin from 'utils/useRouteInitializeAfterLogin';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import CustomProviderPhoneAuth from 'components/OtpProviders/CustomProviderPhoneAuth';
import FirebasePhoneAuth from 'components/OtpProviders/FirebasePhoneAuth';
import cn from 'classnames';
import styles from './index.module.scss';

interface PhoneAuthProviderProps {
  phoneNumber?: string;
  redirectUrl: string;
  isRegistering?: boolean;
}

const PhoneAuthProvider = ({
  phoneNumber,
  redirectUrl,
  isRegistering,
}: PhoneAuthProviderProps) => {
  useRouteInitializeAfterLogin();
  const { push } = useHistory();
  const { t } = useTranslation();

  const {
    data: otpProvider,
    isLoading,
    error,
  } = useFetchActionObj(
    useCallback(() => getOtpProvider(phoneNumber), [phoneNumber]),
    {
      onError: useCallback((error: Error) => {
        captureError(error, 'PhoneAuthProvider', Severity.Error);
      }, []),
    }
  );

  if (!phoneNumber) return <Redirect to={redirectUrl} />;

  if (isLoading || (!otpProvider && !error)) return <AppLoadingIndicator />;

  return (
    <>
      {!isRegistering ? (
        <div className={styles.headingContainer}>
          <ArrowBackIcon
            className={styles.headingIcon}
            onClick={() => push(redirectUrl)}
          />
          <h1 className={styles.heading}>{t('login.headingPassword')}</h1>
        </div>
      ) : null}
      <div className={styles.root}>
        {isRegistering ? (
          <h1 className={cn(styles.heading, styles.registerHeading)}>
            Please enter the One-Time Password to verify your account
          </h1>
        ) : null}
        <p
          className={cn(
            isRegistering ? styles.subheading : styles.phoneCodeInfo
          )}
        >
          A One-Time password has been sent to {phoneNumber}
        </p>
        {!otpProvider?.next || error ? (
          <FirebasePhoneAuth
            phoneNumber={phoneNumber}
            isRegistering={isRegistering}
          />
        ) : (
          <CustomProviderPhoneAuth
            phoneNumber={phoneNumber}
            isRegistering={isRegistering}
            otpGenerationUrl={otpProvider.next}
          />
        )}
      </div>
    </>
  );
};

export default PhoneAuthProvider;

import cn from 'classnames';
import styles from './index.module.scss';

interface TimeInfoLabelProps {
  timeLabel: string;
  durationLabel: string;
  className?: string;
}

const TimeInfoLabel = ({
  durationLabel,
  timeLabel,
  className,
}: TimeInfoLabelProps) => (
  <div className={cn(styles.timeWrapper, className)}>
    <span className={styles.currentTime}>{timeLabel} /</span>{' '}
    <span className={styles.duration}>{durationLabel}</span>
  </div>
);

export default TimeInfoLabel;

import { Asset } from '@laminar-product/client-commons-core/core';
import { isLive } from '@laminar-product/client-commons-core/web';

interface ShouldDisableSeekProps {
  asset?: Asset | null;
  isPlayingAds?: boolean;
}

export const shouldDisableChromecastSeek = ({
  asset,
  isPlayingAds,
}: ShouldDisableSeekProps) => isPlayingAds || !!(asset && isLive(asset));

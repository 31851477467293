import { Button } from '@laminar-product/client-commons-core/web';
import React from 'react';
import { ReactComponent as PaymentMaintenanceIcon } from 'assets/icons/payment-maintenance.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

const PaymentMaintenanceMode = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className={styles.root}>
      <PaymentMaintenanceIcon />
      <h1 className={styles.heading}>{t('paymentMaintenanceMode.heading')}</h1>
      <p className={styles.description}>
        <Trans key="paymentMaintenanceMode.description">
          Our application is currently undergoing scheduled maintenance and
          payments can't be processed. We will be back shortly.
          <p className={styles.innerDescription}>
            Thank you for your patience.
          </p>
        </Trans>
      </p>
      <Button onClick={() => push('/')}>
        {t('paymentMaintenanceMode.button')}
      </Button>
    </div>
  );
};

export default PaymentMaintenanceMode;

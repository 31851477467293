import ProfileModalHeader from 'components/ProfileModalHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordInput from 'components/PasswordInput';
import { Button } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'store/user/selectors';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { ProfileConfirmationProps } from '../../ProfileIdentityConfirmation';
import styles from '../index.module.scss';
import ConfirmationIcon from '../ConfirmationIcon';

const PasswordConfirmation = ({
  onClose,
  onConfirmationSuccess,
}: ProfileConfirmationProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserDetails);
  const [error, setError] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);

  const onConfirm = () => {
    if (!user?.email) return;

    if (!passwordValue) {
      setError(t('profile.passwordRequired'));
      return;
    }

    setIsConfirming(true);

    const credientails = EmailAuthProvider.credential(
      user.email,
      passwordValue
    );

    const firebaseUser = getAuth().currentUser;

    if (!firebaseUser) return;

    reauthenticateWithCredential(firebaseUser, credientails)
      .then(() => onConfirmationSuccess())
      .catch((error) => {
        setError(t('profile.incorrectPassword'));
        captureError(error, 'forgotPin/invalidPassword', Severity.Warning);
      })
      .finally(() => setIsConfirming(false));
  };

  return (
    <div className={styles.container}>
      <ConfirmationIcon />
      <ProfileModalHeader text={t('profile.confirmWithPassword')} />
      <p className={styles.note}>{t('profile.passwordNote')}</p>
      <div className={styles.passwordContainer}>
        <PasswordInput
          onChange={(e) => setPasswordValue(e.target.value)}
          value={passwordValue}
        />
      </div>

      {!!error && <div className={styles.error}>{error}</div>}

      <div className={styles.buttons}>
        <Button
          variant="secondary"
          onClick={onClose}
          className={styles.cancelBtn}
        >
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm} loading={isConfirming}>
          {t('profile.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default PasswordConfirmation;

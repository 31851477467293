import {
  AppThemeColors,
  Theme,
  setThemeColors,
} from '@laminar-product/client-commons-core/core';
import { useEffect } from 'react';
import rgbHex from 'rgb-hex';
import { hexToRGBNums, isRgbColor } from './colors';
import useFonts from './useFonts';

const useAppTheme = (theme?: Theme) => {
  const { fonts, colors, components, global } = theme || {};

  const customFont = useFonts(fonts);

  // Set company Theme
  useEffect(() => {
    if (!theme) return;
    const root = document.documentElement;

    root.style.setProperty('--font-family', customFont ?? 'Roboto');

    const appThemeColors = {
      ...setThemeColors(colors!),
    } as AppThemeColors;

    // Set company colors
    Object.entries(appThemeColors).forEach(([key, value]) => {
      let valueToSet = value;

      if (typeof value === 'string' && isRgbColor(value)) {
        valueToSet = `#${rgbHex(value)}`; // We have to convert rgb to hex because we can't pass rgb color definitions to css as strings
      }

      if (key === 'gradient') {
        root.style.setProperty(
          `--color-${key}`,
          `linear-gradient(135deg, ${valueToSet[0]} 0%, ${valueToSet[1]} 100%)`
        );
        return;
      }
      if (['accent', 'background'].includes(key)) {
        // We have to convert hex to RGB to be able to use it in the linear-gradient
        root.style.setProperty(`--color-${key}-RGB`, hexToRGBNums(valueToSet));
      }
      root.style.setProperty(`--color-${key}`, valueToSet);
    });

    // Components
    root.style.setProperty(
      `--promo-label-font-color`,
      `${components?.promoLabel.fontColor}`
    );

    // Set border radius
    root.style.setProperty(`--radius-button`, `${components?.button.radius}px`);
    root.style.setProperty(`--radius-global`, `${global?.radius}px`);
    root.style.setProperty(`--radius-input`, `${components?.input.radius}px`);

    // Set styles for buttons
    Object.entries(components?.button || {}).forEach(([key, value]) => {
      if (
        typeof value === 'number' //exclude global radius property
      ) {
        return;
      }

      root.style.setProperty(
        `--${key}-button-font-color`,
        `${value.fontColor}`
      );

      root.style.setProperty(
        `--${key}-button-background-color`,
        `${value.backgroundColor}`
      );

      root.style.setProperty(
        `--${key}-button-background-hover-color`,
        `${value.backgroundHover}`
      );

      root.style.setProperty(
        `--${key}-button-border-color`,
        `${value.borderColor}`
      );
    });
  }, [colors, components, customFont, fonts, global, theme]);
};

export default useAppTheme;

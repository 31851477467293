import {
  Asset,
  Config,
  ContinueWatchingItem,
  MenuModel,
  RootAssetResponse,
} from '@laminar-product/client-commons-core/core';
import { Meta } from 'types/meta';

export const SET_APP_DATA = 'SET_APP_DATA';
export const setAppData = (
  dataType: string,
  slug: string,
  data: Asset | Asset[] | MenuModel | RootAssetResponse
) => ({
  type: SET_APP_DATA,
  payload: { dataType, slug, data },
});

export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';
export const setAppSettings = (payload: Config) => ({
  type: SET_APP_SETTINGS,
  payload,
});

export const PUT_ELEMENT_TO_CONTINUE_WATCHING =
  'PUT_ELEMENT_TO_CONTINUE_WATCHING';
export const putElementToContinueWatching = (
  payload: Partial<ContinueWatchingItem>
) => ({
  type: PUT_ELEMENT_TO_CONTINUE_WATCHING,
  payload,
});

export const SET_APP_META = 'SET_APP_META';
export const setAppMeta = (payload: Meta) => ({
  type: SET_APP_META,
  payload,
});

export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const setDeviceId = (payload: string) => ({
  type: SET_DEVICE_ID,
  payload,
});

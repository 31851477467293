import qs from 'qs';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import PlayerMuteButtonRenderer from 'components/PlayerMuteButtonRenderer';
import useLikeActions from 'utils/useLikeActions';
import {
  Asset,
  AssetLocationState,
  AssetType,
  MovieConfig,
  ShowConfig,
  calculateWatchingPercent,
} from '@laminar-product/client-commons-core/core';
import { getAsset } from 'actions/assets';
import { useEffect, useState } from 'react';
import { calculateWatchingProgress } from 'utils/watchingProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { selectIsAnonymous } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import VideoPlayerRendererComponent from 'library/VideoPlayer/VideoPlayerRerenderComponent';
import useFetchAssetWatchingProgress from './useFetchAssetWatchingProgress';
import { useGetCurrentProfile } from './profiles';
import { captureError } from './captureError';
import useEpisodesProgresses from './useEpisodesProgresses';
import useWatchlist from './useWatchlist';

const useAssetConfig = (currentAsset?: Asset) => {
  const isAnonymous = useSelector(selectIsAnonymous);
  const moveToAssetPlayer = useMoveToAssetPlayer();
  const { watchlistActions } = useWatchlist();
  const location = useLocation<AssetLocationState>();
  const isShow = currentAsset && currentAsset.type === AssetType.SHOW;
  const [lastWatchedEpisodeDetails, setLastWatchedEpisodeDetails] =
    useState<Asset>();
  const [lastWatchedSeason, setWatchedLastSeason] = useState<Asset>();
  const currentProfileUuid = useGetCurrentProfile()?.uuid;
  const { episodesProgresses, fetchEpisodesProgresses } = useEpisodesProgresses(
    {
      seasonId: lastWatchedSeason?.id,
      skipFetch: currentAsset?.type !== AssetType.SHOW,
    }
  );

  const { push } = useHistory();

  const progress = useFetchAssetWatchingProgress(currentAsset);

  //Handle profile changes for show
  useEffect(() => {
    if ((progress && progress.currentSecond > 0) || !currentProfileUuid) return;

    setLastWatchedEpisodeDetails(undefined);
    setWatchedLastSeason(undefined);
  }, [progress, currentProfileUuid]);

  // Get last watched episode details for entire show
  useEffect(() => {
    if (!isShow || !progress || !progress?.currentSecond || isAnonymous) return;

    getAsset(progress.assetUuid)
      .then(setLastWatchedEpisodeDetails)
      .catch((e) => {
        captureError(
          e as AxiosError,
          'hooks/useAssetConfig/getLastWatchedEpisodeDetails',
          Severity.Warning
        );
      });
  }, [isShow, progress, isAnonymous]);

  //Get parent - season of last watched episode
  useEffect(() => {
    if (
      !isShow ||
      !lastWatchedEpisodeDetails ||
      !lastWatchedEpisodeDetails.parentId ||
      isAnonymous
    )
      return;

    getAsset(lastWatchedEpisodeDetails.parentId)
      .then(setWatchedLastSeason)
      .catch((e) => {
        captureError(
          e as AxiosError,
          'hooks/useAssetConfig/getLastWatchedEpisodeParent',
          Severity.Warning
        );
      });
  }, [isAnonymous, isShow, lastWatchedEpisodeDetails]);

  const assetDuration = () => {
    if (isShow && lastWatchedEpisodeDetails)
      return lastWatchedEpisodeDetails.duration;

    return currentAsset ? currentAsset.duration : null;
  };

  const assetProgress = progress && {
    watchingPercent: calculateWatchingPercent({
      progress: progress.currentSecond,
      duration: assetDuration(),
    }),
    watchingProgress: calculateWatchingProgress({
      progress: progress.currentSecond,
    }),
  };

  const onSignInToWatchClick = () => {
    push(`/login`);
  };

  const moveToSearch = (query: string) =>
    push({
      pathname: '/search',
      search: qs.stringify({ query }),
    });

  const assetMovieConfig: MovieConfig = {
    moveToSearch,
    moveToAssetPlayer,
    VideoPlayerComponent: VideoPlayerRendererComponent,
    playerMuteButtonRenderer: PlayerMuteButtonRenderer,
    likeActions: useLikeActions,
    watchlistActions,
    progress: assetProgress,
    isFinished: progress?.finished,
    isUserAnonymous: isAnonymous,
    onSignInToWatchClick,
  };

  const liveDetailsConfig = assetMovieConfig;

  const assetShowConfig: ShowConfig = {
    ...assetMovieConfig,
    selectedSeasonId: lastWatchedSeason?.id,
    lastWatchedSeason,
    lastWatchedEpisodeDetails,
    location,
    episodesProgresses,
    refreshEpisodesProgresses: fetchEpisodesProgresses,
  };

  useEffect(() => {
    return () => {
      if (currentAsset) {
        setWatchedLastSeason(undefined);
        setLastWatchedEpisodeDetails(undefined);
      }
    };
  }, [currentAsset]);

  return { assetMovieConfig, assetShowConfig, liveDetailsConfig };
};

export default useAssetConfig;

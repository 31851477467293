import { Button } from '@laminar-product/client-commons-core/web';
import { ReactComponent as VolumeOff } from 'assets/icons/volume-off.svg';
import { ReactComponent as VolumeUp } from 'assets/icons/volume-up.svg';
import cn from 'classnames';
import React, { useState } from 'react';
import { useGetCurrentProfile } from 'utils/profiles';
import styles from './index.module.scss';

export interface PlayerMuteButtonProps {
  className?: string;
  width?: number;
  height?: number;
  playerId?: string;
  variant?: 'small' | 'medium';
  source?: 'poster' | 'jumbotron';
}

const PlayerMuteButton = ({
  className,
  playerId,
  variant,
  source = 'jumbotron',
}: PlayerMuteButtonProps) => {
  const currentProfile = useGetCurrentProfile();
  const [isVideoMuted, toggleVideoSound] = useState(true);

  const buttonClassNames = cn(className, styles.root, {
    [styles.small]: variant === 'small',
    [styles.medium]: variant === 'medium',
  });

  const onVolumeControlClick = (e: React.MouseEvent) => {
    if (!playerId) {
      return;
    }

    e.stopPropagation();

    const videoEl = document.getElementById(playerId) as HTMLVideoElement;

    if (!videoEl) {
      return;
    }

    const mutedValue = !videoEl.muted;

    videoEl.muted = mutedValue;
    toggleVideoSound(mutedValue);
  };

  if (
    (!!currentProfile && !currentProfile.playbackSettings?.playTrailer) ||
    !playerId
  )
    return null;

  return (
    <div className={styles.container}>
      <Button
        onClick={onVolumeControlClick}
        round
        variant="secondary"
        className={buttonClassNames}
      >
        {isVideoMuted ? <VolumeOff /> : <VolumeUp />}
      </Button>
    </div>
  );
};

export default PlayerMuteButton;

import { appCheck } from 'SetupApp';
import { getToken } from 'firebase/app-check';
import { Severity } from 'types/errors';
import { captureError } from './captureError';

const getAppCheckToken = async () => {
  try {
    if (!appCheck)
      return Promise.reject({ message: 'AppCheck is not initialized' });

    const appCheckTokenResult = await getToken(appCheck);

    return appCheckTokenResult.token;
  } catch (error) {
    captureError(error as any, 'getAppCheckToken', Severity.Error);
  }
};

export const getAppCheckHeaders = async () => {
  const appCheckToken = await getAppCheckToken();
  return {
    'X-Firebase-AppCheck': appCheckToken,
  };
};

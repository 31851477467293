import cn from 'classnames';
import Breadcrumbs from 'components/Breadcrumbs';
import FiltersSelector from 'components/FiltersSelector';
import GenreSelector from 'components/GenreSelector';
import SortingSelector from 'components/SortingSelector';
import { useMoveToListing } from 'utils/useMoveToListing';
import useSubheader from 'utils/useSubheader';
import styles from './index.module.scss';

export interface SubHeaderProps {
  fixed?: boolean;
  assetType?: string;
  title?: string;
}

const SubHeader = ({ title, fixed, assetType }: SubHeaderProps) => {
  const moveToListing = useMoveToListing();

  const {
    searchParams,
    filters,
    onGenreSelect,
    onGenreReset,
    shouldShowPageOptions,
    pageType,
    nextBcPath,
    subheaderTitle,
  } = useSubheader({ assetType, title });

  const { sort, genre, bcpath } = searchParams;

  return (
    <div
      data-testid="subheader"
      className={cn(styles.root, {
        [styles.fixed]: fixed,
        [styles.background]: fixed,
      })}
    >
      <Breadcrumbs
        pageConfig={{ title: subheaderTitle, bcpath }}
        genre={genre as string}
      />

      {(!genre || !subheaderTitle) && shouldShowPageOptions && (
        <GenreSelector
          selectedGenre={genre as string}
          isTitleDisplayed={!!subheaderTitle}
          onGenreSelect={onGenreSelect}
          onGenreReset={onGenreReset}
        />
      )}
      <div className={styles.spacer} />
      {shouldShowPageOptions && (
        <>
          <FiltersSelector
            onApply={(filters) => {
              moveToListing(pageType as string, {
                ...searchParams,
                ...filters,
                bc: subheaderTitle,
                bcpath: nextBcPath,
                type: pageType,
              });
            }}
            className={styles.marginRight}
            filters={filters}
          />
          <SortingSelector
            value={sort}
            onChange={(sort: string) => {
              moveToListing(pageType as string, {
                ...searchParams,
                sort: sort !== 'none' ? sort : '',
                bcpath: nextBcPath,
                type: pageType,
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default SubHeader;

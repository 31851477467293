import {
  planDetailsHTMLAttributesToRender,
  planDetailsHTMLTagsToRender,
} from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Tooltip from 'components/Tooltip';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import _isEmpty from 'lodash/isEmpty';
import { PlanByIntervalWithPrice } from 'components/PlanSelector/types';
import { PlanMetadata } from 'types/subscription';
import _truncate from 'lodash/truncate';
import { MAX_PLAN_FEATURE_DESCRIPTION_LENGTH } from 'components/PlanSelector/constants';
import styles from '../../index.module.scss';

interface PlanDetailsProps {
  plan?: PlanMetadata &
    Pick<PlanByIntervalWithPrice, 'featuredDetails' | 'details'>;
  className?: string;
}
const PlanDetails = ({ plan, className }: PlanDetailsProps) => {
  const parseTextToHtml = (text: string) => {
    const htmlText = sanitizeHtml(text, {
      allowedTags: planDetailsHTMLTagsToRender,
      allowedAttributes: {
        '*': planDetailsHTMLAttributesToRender,
      },
    });

    return parse(htmlText);
  };

  const renderListElement = (value: string, moreInfo?: string) => {
    let tooltipData;

    if (moreInfo) {
      tooltipData = parseTextToHtml(moreInfo);
    }

    return (
      <li>
        <CheckIcon className={styles.bulletIcon} />
        <div className={styles.bulletText}>
          {_truncate(value, {
            length: MAX_PLAN_FEATURE_DESCRIPTION_LENGTH,
          })}

          {!_isEmpty(tooltipData) && (
            <Tooltip
              data={tooltipData}
              contentClassName={styles.moreInfoTooltip}
              indicatorClassName={styles.moreInfoTooltipWrapper}
              placement="auto"
            >
              <InfoIcon className={styles.infoIcon} />
            </Tooltip>
          )}
        </div>
      </li>
    );
  };

  if (plan?.featuredDetails?.length) {
    return (
      <ul className={cn(styles.bulletPoints, className)}>
        {plan?.featuredDetails
          ?.filter((f) => !!f)
          ?.map(({ moreInfo, value }) =>
            value ? renderListElement(value, moreInfo) : null
          )}
      </ul>
    );
  }

  return (
    <ul className={cn(styles.bulletPoints, className)}>
      {plan?.details
        ?.filter((d) => !!d)
        .map((detail) => renderListElement(detail))}
    </ul>
  );
};

export default PlanDetails;

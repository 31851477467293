import { Asset } from '@laminar-product/client-commons-core/core';
import { Severity } from 'types/errors';
import { getAsset } from 'actions/assets';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store';
import { setAsset } from 'store/assets/actions';
import { captureError } from './captureError';

const useGetAssetRoot = () => {
  const dispatch = useDispatch();

  const getRootAsset = useCallback(
    async (item: Asset) => {
      if (!item.rootId) return;

      const cachedRoot = store.getState()?.assets?.[item.rootId];

      if (cachedRoot) return cachedRoot;

      try {
        const fetchedAsset = await getAsset(item.rootId);
        if (!fetchedAsset) return;

        dispatch(setAsset(fetchedAsset.id, fetchedAsset));

        return fetchedAsset;
      } catch (error) {
        captureError(
          error as AxiosError,
          'hooks/useGetAssetRoot/getRootAsset',
          Severity.Error,
          { extra: { rootId: item.rootId } }
        );
      }
    },
    [dispatch]
  );

  return getRootAsset;
};

export default useGetAssetRoot;

import cn from 'classnames';
import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './index.module.scss';

interface PosterHoverProps {
  isActive: boolean;
  children: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
}

const PosterHover = ({
  children,
  isActive,
  isFirst,
  isLast,
}: PosterHoverProps) => {
  const nodeRef = useRef<HTMLDivElement>(null); // To eliminate findDOMNode warning
  const hoverClassName = cn({
    [styles.firstItem]: isFirst,
    [styles.lastItem]: isLast,
  });

  return (
    <div className={cn(styles.root, hoverClassName)}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isActive}
        timeout={{ exit: 600, enter: 600 }}
        classNames={{ ...styles }}
        unmountOnExit
      >
        <div className={styles.inner} ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

export default PosterHover;

import {
  Asset,
  AssetType,
  formatDuration,
} from '@laminar-product/client-commons-core/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  AvailableFromTag,
  Tag,
  isLive,
} from '@laminar-product/client-commons-core/web';
import styles from '../index.module.scss';

interface PosterSubinfoProps {
  asset: Asset;
  progress?: number;
  getGenresTranslations: (limitValue: number) => string[] | undefined;
}

const PosterSubinfo = ({
  asset,
  progress,
  getGenresTranslations,
}: PosterSubinfoProps) => {
  const { duration, censorships, parent, order } = asset;
  const { t } = useTranslation();
  const isAssetLive = isLive(asset);

  const getAssetSubInfo = () => {
    switch (asset.type) {
      case AssetType.MOVIE:
        return (
          <span className={styles.withDot}>
            {duration ? formatDuration(duration) : ''}
          </span>
        );
      case AssetType.SEASON:
        return (
          <span className={styles.withDot}>
            {asset?.order && `${t('common.season')} ${asset?.order}`}
          </span>
        );
      case AssetType.EPISODE:
        return (
          <span className={styles.withDot}>
            {parent?.order && `${t('common.season')} ${parent?.order} | `}
            {t('common.episode')} {order}
          </span>
        );
      case AssetType.LIVE_EVENT:
        return <AvailableFromTag availableFrom={asset.availableFrom} />;
    }
  };

  return (
    <div className={styles.subTitle}>
      {censorships?.name && !isAssetLive && (
        <Tag type="box" className={styles.censorship}>
          {censorships.name}
        </Tag>
      )}

      {getAssetSubInfo()}

      {!progress &&
        !isAssetLive &&
        getGenresTranslations(3)?.map((genre, index) => (
          <span
            key={`${genre}-${index}`}
            className={cn(styles.genres, styles.withDot)}
          >
            {genre}
          </span>
        ))}
    </div>
  );
};

export default PosterSubinfo;

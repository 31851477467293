import { useCallback } from 'react';
import { createCCBILLPayment } from 'actions/payments';
import { HandlePaymentParams } from 'types/payment';
import { Severity } from 'types/errors';
import { captureError } from './captureError';

const useHandleCCBILLPayment = () => {
  const handleCCBILLPayment = useCallback(
    async ({ priceId, email, couponId, offerId }: HandlePaymentParams) => {
      try {
        const ccbill = await createCCBILLPayment({
          priceId,
          couponId,
          email,
          offerId,
        });

        if (!ccbill.data.checkoutUrl) {
          throw new Error('CCBILL checkoutUrl is empty');
        }

        // redirect to ccbillUrl
        window.location.href = ccbill.data.checkoutUrl;
      } catch (error) {
        console.error('CCBILL payment error', error);
        captureError(error as any, 'handleCCBILLPayment', Severity.Error);
      }
    },
    []
  );

  return {
    handleCCBILLPayment,
  };
};

export default useHandleCCBILLPayment;

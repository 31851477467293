import {
  Asset,
  ContentType,
  Watchable,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';
import { isLive } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import {
  ASSET_TYPES_POSTER_ACTION_BUTTONS,
  ASSET_TYPES_POSTER_WATCH_BUTTON,
} from './constants';

const useRenderPosterButtons = (asset: Asset) => {
  const isAnonymous = useSelector(selectIsAnonymous);

  const displayActionButtons =
    !isAnonymous && ASSET_TYPES_POSTER_ACTION_BUTTONS.includes(asset.type);

  const displayWatchButton = () => {
    if (
      (isAnonymous && asset.watchable !== Watchable.YES_ANONYMOUS_ACCESS) ||
      !ASSET_TYPES_POSTER_WATCH_BUTTON.includes(asset.type) ||
      asset.planUpgradeRequired
    )
      return false;

    return !!getAssetContentWithType(
      asset,
      isLive(asset) ? ContentType.LIVE : ContentType.MAIN
    );
  };

  return { displayActionButtons, displayWatchButton };
};

export default useRenderPosterButtons;

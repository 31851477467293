import React, { useContext } from 'react';
import { SearchParamsFromUrl } from 'types/search';

export interface MoveToListingParams
  extends NodeJS.Dict<string | string[]>,
    SearchParamsFromUrl {}

export type MoveToListing = (id: string, params?: MoveToListingParams) => void;

export const MoveToListingContext = React.createContext<MoveToListing>(
  () => {}
);

export const useMoveToListing = () => {
  return useContext(MoveToListingContext);
};

import Axios from 'axios';
import { getAppCheckHeaders } from 'utils/appCheck';

export const getSSOProviderUrl = async (provider: string) => {
  const headers = await getAppCheckHeaders();

  const { data } = await Axios.get<{ link: string }>(
    `${
      import.meta.env.VITE_BACKEND_URL
    }/provider/sso/auth?provider=${provider}`,
    {
      headers,
    }
  );

  return data;
};

export interface GetSSOAuthToken {
  ticketId: string;
  provider: string;
}

export const getSSOAuthToken = async ({
  ticketId,
  provider,
}: GetSSOAuthToken) => {
  const headers = await getAppCheckHeaders();

  const { data } = await Axios.post<{ customToken: string }>(
    `${
      import.meta.env.VITE_BACKEND_URL
    }/provider/sso/auth?provider=${provider}&ticketId=${ticketId}`,
    {},
    {
      headers,
    }
  );

  return data;
};

import { ContinueWatchingItem } from '@laminar-product/client-commons-core/core';
import { getAssetsWatchingProgressByParent } from 'actions/watchingProgress';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAnonymous } from 'store/user/selectors';
import { Severity } from 'types/errors';
import { captureError } from './captureError';
import { useGetCurrentProfile } from './profiles';

interface UseEpisodesProgressesProps {
  seasonId?: string;
  skipFetch?: boolean;
}

const useEpisodesProgresses = ({
  seasonId,
  skipFetch,
}: UseEpisodesProgressesProps) => {
  const [episodesProgresses, setEpisodesProgresses] =
    useState<ContinueWatchingItem[]>();
  const isAnonymous = useSelector(selectIsAnonymous);
  const currentProfileUuid = useGetCurrentProfile()?.uuid;

  const fetchEpisodesProgresses = useCallback((seasonId: string) => {
    getAssetsWatchingProgressByParent(seasonId)
      .then((progresses) => setEpisodesProgresses(progresses))
      .catch((e) =>
        captureError(
          e,
          'hooks/useEpisodesProgresses/getAssetsWatchingProgressByParent',
          Severity.Error
        )
      );
  }, []);

  useEffect(() => {
    if (skipFetch || isAnonymous || !currentProfileUuid || !seasonId) {
      return;
    }

    fetchEpisodesProgresses(seasonId);
  }, [
    isAnonymous,
    fetchEpisodesProgresses,
    currentProfileUuid,
    seasonId,
    skipFetch,
  ]);

  return { episodesProgresses, fetchEpisodesProgresses };
};

export default useEpisodesProgresses;

import {
  BillingAddress,
  BillingName,
} from '@laminar-product/client-commons-core/core';

export enum PaymentMethod {
  Payu = 'payu',
  Stripe = 'stripe',
}

export interface PaymentStatusData {
  status: PaymentStatus;
  _id: string;
}

export enum PaymentStatus {
  CREATED = 'CREATED',
  PAYED = 'PAYED',
  FAILED = 'FAILED',
}

export interface StripePaymentResponse {
  id: string;
}

export interface HandlePaymentParams {
  priceId: string;
  email: string;
  couponId: string;
  offerId?: string;
  billingAddress?: BillingAddress;
  billingName?: BillingName;
}

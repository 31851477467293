import { Collection } from '@laminar-product/client-commons-core/core';

export const SET_COLLECTION = 'SET_COLLECTION';
export const setCollection = (id: string, collection: Collection) => ({
  type: SET_COLLECTION,
  payload: { id, collection },
});

export const RESET_COLLECTIONS = 'RESET_COLLECTIONS';
export const resetCollections = () => ({
  type: RESET_COLLECTIONS,
});

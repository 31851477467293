import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFirebaseMessagingToken,
  setNotifications,
} from 'store/user/actions';
import { addNotificationToken } from 'actions/notifications';
import { RootState } from 'store/reducer';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import {
  FIREBASE_MESSAGING_CHANNEL,
  FIREBASE_MESSAGING_TYPE,
  NotificationType,
} from './notifications';
import { SESSION_STORAGE_NOTIFICATION_KEY } from './constants';
import { captureError } from './captureError';

interface UseNotificationProps {
  isUserValidated?: boolean;
}

const getFirebaseMessaging = async () => {
  try {
    const messagingIsSupported = await isSupported();
    if (!messagingIsSupported) return null;
    return getMessaging();
  } catch (error) {
    captureError(
      error as any,
      'useNotifications/getFirebaseMessaging',
      Severity.Error
    );
    return null;
  }
};

export const useNotifications = ({ isUserValidated }: UseNotificationProps) => {
  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const dispatch = useDispatch();
  const broadcastChannelRef = useRef(
    messaging ? new BroadcastChannel(FIREBASE_MESSAGING_CHANNEL) : null
  );
  const notifications = useSelector<RootState, NotificationType[] | undefined>(
    (state) => state.user.notifications
  );

  useEffect(() => {
    getFirebaseMessaging().then((messaging) => {
      setMessaging(messaging);
      broadcastChannelRef.current = new BroadcastChannel(
        FIREBASE_MESSAGING_CHANNEL
      );
    });
  }, []);

  const saveNotifications = useCallback(
    (newNotification: NotificationType) => {
      const filteredNotifications = notifications?.filter(
        (notification) =>
          notification.title !== newNotification.title ||
          notification.description !== newNotification.description
      );

      const notificationsToSave = [newNotification].concat(
        filteredNotifications || []
      );

      dispatch(setNotifications(notificationsToSave));
      window.sessionStorage.setItem(
        SESSION_STORAGE_NOTIFICATION_KEY,
        JSON.stringify(notificationsToSave)
      );
    },
    [dispatch, notifications]
  );

  const sendToken = useCallback(
    async (currentToken: string) => {
      if (!currentToken) Notification.requestPermission();

      await addNotificationToken(currentToken)
        .then(() => {
          dispatch(setFirebaseMessagingToken(currentToken));
        })
        .catch((e) => {
          captureError(
            e as AxiosError,
            'hooks/useNotifications/sendToken',
            Severity.Error,
            { extra: { currentToken } }
          );
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isUserValidated || !broadcastChannelRef.current) return;

    broadcastChannelRef.current.onmessage = (event: MessageEvent) => {
      if (!event.data || event.data.type !== FIREBASE_MESSAGING_TYPE) return;

      const { title, body } = event.data.message.notification;

      const newNotification: NotificationType = {
        title,
        description: body,
        seen: false,
      };

      saveNotifications(newNotification);
    };
  }, [messaging, isUserValidated, dispatch, notifications, saveNotifications]);

  useEffect(() => {
    if (!isUserValidated || !messaging) return;

    onMessage(messaging, (payload) => {
      const newNotification: NotificationType = {
        title: payload?.notification?.title || '',
        description: payload?.notification?.body || '',
        seen: false,
      };

      saveNotifications(newNotification);
    });
  }, [messaging, isUserValidated, dispatch, notifications, saveNotifications]);

  useEffect(() => {
    if (
      !isUserValidated ||
      !messaging ||
      !import.meta.env.VITE_FIREBASE_NOTIFICATION_TOKEN
    ) {
      return;
    }

    getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_NOTIFICATION_TOKEN,
    })
      .then((currentToken) => {
        sendToken(currentToken);
      })
      .catch((e) => {
        captureError(
          e as AxiosError,
          'hooks/useNotifications/getToken',
          Severity.Error
        );
      });
  }, [messaging, isUserValidated, dispatch, sendToken]);
};

import {
  generateProfileColor,
  generateProfileInitials,
} from '@laminar-product/client-commons-core/core';
import { ProfileAvatarSize } from 'utils/profiles';
import cn from 'classnames';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as KidsLabel } from 'assets/icons/kidsLabel.svg';
import styles from './index.module.scss';

interface ProfileAvatarProps {
  size?: ProfileAvatarSize;
  name?: string;
  isNavbarAvatar?: boolean;
  newProfile?: boolean;
  enableHover?: boolean;
  isEditMode?: boolean;
  isKidsProfile?: boolean;
  className?: string;
}

const ProfileAvatar = ({
  name,
  size = ProfileAvatarSize.BIG,
  isNavbarAvatar,
  newProfile,
  enableHover = false,
  isEditMode,
  isKidsProfile,
  className,
}: ProfileAvatarProps) => {
  const color = generateProfileColor(name);
  const initials = generateProfileInitials(name);

  return (
    <div
      style={{ backgroundColor: !newProfile ? color : undefined }}
      className={cn(styles.avatar, className, {
        [styles.big]: size === ProfileAvatarSize.BIG && !isNavbarAvatar,
        [styles.medium]: size === ProfileAvatarSize.MEDIUM,
        [styles.small]: size === ProfileAvatarSize.SMALL,
        [styles.navbar]: isNavbarAvatar,
        [styles.newProfile]: newProfile,
        [styles.hover]: enableHover,
      })}
    >
      {newProfile ? '+' : initials}

      {isEditMode && (
        <div className={styles.editIconWrapper}>
          <EditIcon />
        </div>
      )}

      {isKidsProfile && (
        <KidsLabel
          width="90%"
          height="90%"
          className={styles.kidsLabelWrapper}
        />
      )}
    </div>
  );
};

export default ProfileAvatar;

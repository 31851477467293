import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Severity } from 'types/errors';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { captureError } from './captureError';

export const AUTH_TOKEN_HASH_PARAM_NAME = 'authToken';

export const useAuthHashTokenLogin = () => {
  const { hash, pathname } = useLocation();
  const { replace } = useHistory();
  const [isLogging, setLogging] = useState(false);

  useEffect(() => {
    if (!hash?.includes(AUTH_TOKEN_HASH_PARAM_NAME) || isLogging) {
      return;
    }
    const hashParameters = new URLSearchParams(hash.substring(1));
    const token = hashParameters.get(AUTH_TOKEN_HASH_PARAM_NAME);

    if (!token) {
      captureError(
        new Error('EMPTY_AUTH_TOKEN'),
        'auth/signInWithHashToken',
        Severity.Warning
      );
      return;
    }

    const auth = getAuth();

    setLogging(true);
    signInWithCustomToken(auth, token)
      .catch((e) => {
        captureError(e, 'auth/signInWithHashToken', Severity.Warning);
      })
      .finally(() => {
        replace(pathname);
        setLogging(false);
      });
  }, [hash, isLogging, replace, pathname]);
};

import cn from 'classnames';
import { ProgressIndicator } from '@laminar-product/client-commons-core/web';
import { PaymentGateway } from '@laminar-product/client-commons-core/core';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import styles from './index.module.scss';

export interface CheckboxProps {
  card?: string;
  isError?: boolean;
  isLoading?: boolean;
  gateway?: PaymentGateway;
}

const getLastFourSymbols = (str: string) => {
  if (str.length <= 4) {
    return str;
  }

  return str.slice(-4);
};

const CardPaymentBox = ({
  card,
  isError,
  isLoading,
  gateway,
}: CheckboxProps) => {
  const { t } = useTranslation();

  const parseCardNumber = useCallback(
    (cardNumber: string) => {
      if (!cardNumber.length) return t('payment.bankCard');
      const lastFourDigits = getLastFourSymbols(cardNumber.replaceAll('"', ''));
      const spacedCardNumber = `**** **** **** ${lastFourDigits}`;

      return spacedCardNumber;
    },
    [t]
  );

  const gatewayCardInfo = useMemo(() => {
    const gateways = new Map([
      [PaymentGateway.Payu, parseCardNumber(card || '')],
      [PaymentGateway.CCBILL, t('payment.bankCard')],
    ]);

    if (!gateway) return '';

    if (!gateways.has(gateway)) return parseCardNumber(card || '');

    return gateways.get(gateway);
  }, [card, gateway, parseCardNumber, t]);

  if (isError)
    return <div className={styles.root}>{t('errors.cardFetchingError')}</div>;

  if (!card && isLoading)
    return (
      <div data-testid="loader" className={cn(styles.root, styles.center)}>
        <ProgressIndicator variant="circular" />
      </div>
    );

  return (
    <div className={styles.root}>
      <span data-testid="card-details">{gatewayCardInfo}</span>
    </div>
  );
};

export default CardPaymentBox;

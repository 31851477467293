import {
  Asset,
  ContentType,
  ItemSelected,
  SchemaVersion,
  TrackEvent,
  Watchable,
  formatDuration,
  getAssetContentWithType,
} from '@laminar-product/client-commons-core/core';
import {
  ActionButtons,
  AssetProgressBar,
  AssetTags,
  AvailableFromTag,
  Button,
  FadeOutTransition,
  LiveIndicator,
  VideoContainer,
  isLive,
  isLiveActive,
  isMobileOrTablet,
} from '@laminar-product/client-commons-core/web';
import { ReactComponent as PlayButton } from 'assets/icons/play-button.svg';
import cn from 'classnames';
import PosterHover from 'components/PosterHover';
import _throttle from 'lodash/throttle';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CarouselType,
  getFallbackCarouselTitle,
  isContinueWatchingCarousel,
} from 'utils/carouselHelpers';
import { countWidthRatioFromHeight } from 'utils/resolutionHelpers';
import useLikeActions from 'utils/useLikeActions';
import { useTracker } from 'utils/useTracker';
import {
  calculateWatchingPercent,
  calculateWatchingProgress,
} from 'utils/watchingProgress';
import AssetProgressCircle from 'components/AssetProgressCircle';
import useExpandablePortrait from 'utils/useExpandablePortrait';
import useTimeout from 'utils/useTimeout';
import { useSelector } from 'react-redux';
import { selectFirebaseUser, selectIsAnonymous } from 'store/user/selectors';
import { getCarouselThumbnailSrc } from 'utils/images';
import useRedirectToRootAsset, {
  shouldRedirectToRoot,
} from 'utils/useRedirectToRootAsset';
import useRedirectOnWatch from 'utils/useRedirectOnPosterWatch';
import useRenderPosterButtons from 'utils/useRenderPosterButtons';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import Image from 'components/Image';
import PlayerMuteButtonRenderer from 'components/PlayerMuteButtonRenderer';
import { useUniquePlayerId } from '@laminar-product/client-commons-core/hooks';
import VideoPlayerRendererComponent from 'library/VideoPlayer/VideoPlayerRerenderComponent';
import useWatchlist from 'utils/useWatchlist';
import styles from './index.module.scss';
import PosterSubinfo from './PosterSubinfo';

function getHardcodedSizeForPortraitImage(type: CarouselType) {
  if (
    [
      CarouselType.PORTRAIT_CAROUSEL,
      CarouselType.LARGE_PORTRAIT_CAROUSEL,
      CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
    ].includes(type)
  )
    return '630x945';

  return '1050x591';
}

const MOUSE_MOVE_THROTTLE_TIME = 500;

interface PosterProps {
  posterUrl?: string;
  hoverUrl?: string;
  className?: string;
  style?: React.CSSProperties;
  role?: string;
  data: Asset;
  progress?: number;
  onVideoPlay?: () => void;
  index: number;
  onItemClick: (data: Asset) => void;
  type?: CarouselType;
  onPosterHeight?: (height: number) => void;
  posterHeight?: number;
  currentPage?: number;
  lastPage?: boolean;
  items?: Asset[];
  onActive?: (active: boolean, index: number) => void;
  strataCollectionData?: Omit<ItemSelected, 'asset_id' | 'asset_name'>;
}

const Poster = (props: PosterProps) => {
  const {
    posterUrl,
    hoverUrl,
    className,
    data,
    progress,
    onVideoPlay,
    index,
    onItemClick,
    type,
    onPosterHeight,
    posterHeight,
    currentPage,
    lastPage,
    items,
    onActive,
    strataCollectionData,
    ...otherProps
  } = props;
  const user = useSelector(selectFirebaseUser);
  const isAnonymous = useSelector(selectIsAnonymous);
  const playerId = useUniquePlayerId();
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const elRef = useRef<HTMLDivElement>(null);
  const moveToAsset = useMoveToAssetDetails();
  const { t } = useTranslation();
  const [showPopover, setPopover] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showExpandedInfo, setShowExpandedInfo] = useState(true);
  const [shouldLoadVideo, setShouldLoadVideo] = useState<boolean>();
  const { trackEvent } = useTracker();
  const showExpandedInfoMsTimeout = 3000;
  const getGenresTranslations = useCallback(
    (limitValue: number) =>
      data?.genres
        ?.slice(0, limitValue)
        .map((g: string) => t(`genre.${g}`, { defaultValue: g })),
    [data, t]
  );
  const fadeTimeout = 600;
  const redirectToRootAsset = useRedirectToRootAsset();
  const { displayActionButtons, displayWatchButton } =
    useRenderPosterButtons(data);

  const {
    callback: showDetailsTimeoutCallback,
    clear: showDetailsTimeoutClear,
  } = useTimeout();
  const {
    callback: shouldLoadVideoTimeoutCallback,
    clear: shouldLoadVideoTimeoutClear,
  } = useTimeout();

  let watchingProgress = '0s';
  let watchingPercent = 0;
  const missingPosterUrlForExpandableCarousel =
    type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL &&
    !expanded &&
    !posterUrl;
  const [mouseEnterTimeout, setMouseEnterTimeout] = useState<NodeJS.Timeout>();
  const { watchlistActions } = useWatchlist();

  const { positionToAlign, resetedIndex, resetAlign } = useExpandablePortrait({
    perPage: itemsPerPage,
    expanded,
    currentPage,
    lastPage,
    items,
    item: data,
  });

  const onPosterPlay = useRedirectOnWatch();

  const toggleExpandedInfo = useCallback(() => {
    showDetailsTimeoutClear();

    if (!expanded) {
      setShowExpandedInfo(true);
      return;
    }

    showDetailsTimeoutCallback(
      () => setShowExpandedInfo(false),
      showExpandedInfoMsTimeout
    );
  }, [showDetailsTimeoutCallback, showDetailsTimeoutClear, expanded]);

  const onMouseMove = useMemo(
    () =>
      _throttle(() => {
        if (type !== CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL) return;
        toggleExpandedInfo();
      }, MOUSE_MOVE_THROTTLE_TIME),
    [type, toggleExpandedInfo]
  );

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const parentEl = elRef.current?.parentElement;
      const parentWidth = parentEl?.getBoundingClientRect()?.width || 0;
      if (
        type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL &&
        elRef.current &&
        onPosterHeight
      ) {
        onPosterHeight(elRef.current?.getBoundingClientRect().height);
      }
      if (parentWidth !== 0) {
        const elWidth = Math.ceil(
          elRef.current?.getBoundingClientRect()?.width || 0
        );
        setItemsPerPage(Math.round(parentWidth / elWidth));
      }
    };

    calculateItemsPerPage();

    window.addEventListener('resize', calculateItemsPerPage);

    return () => {
      window.removeEventListener('resize', calculateItemsPerPage);
    };
  }, [data, onPosterHeight, type]);

  useEffect(() => {
    if (expanded && positionToAlign === 'left') {
      const parent = elRef.current?.parentElement?.parentElement;

      if (parent) {
        parent.style.transform = `translateX(-${resetedIndex * 95}px)`;
      }
    }
  }, [expanded, positionToAlign, resetedIndex]);

  const playAsset = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (data.planUpgradeRequired) return;

    onPosterPlay(data);
  };

  const onDetailsButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (shouldRedirectToRoot(data.type)) {
      redirectToRootAsset({ asset: data, type: 'Details' });
      return;
    }

    moveToAsset(data);
  };

  const duration = data.duration ? formatDuration(data.duration) : undefined;

  if (data.duration && data.duration > 0 && progress) {
    watchingProgress = calculateWatchingProgress({ progress });

    watchingPercent = calculateWatchingPercent({
      progress: progress,
      duration: data.duration,
    });
  }

  const [showImage, toggleShowImage] = useState(true);

  const videoFile = getAssetContentWithType(data, ContentType.TRAILER);

  const shouldDisplayLiveIndicator = isLiveActive(data);

  const logoFile = data.images?.find((image) => image.type === 'logo');

  const trackPosterEvent = (
    event: TrackEvent.ITEM_FOCUS | TrackEvent.ITEM_SELECTED
  ) => {
    if (!strataCollectionData) return;
    trackEvent<TrackEvent.ITEM_FOCUS | TrackEvent.ITEM_SELECTED>({
      event,
      data: {
        asset_id: data.id ?? '',
        asset_name: data.administrativeName ?? '',
        ...strataCollectionData,
        source_name: isContinueWatchingCarousel(type)
          ? null
          : strataCollectionData.source_name,
      },
      version: SchemaVersion.V120,
    });
  };

  const onMouseEnter = () => {
    if (type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL) {
      setMouseEnterTimeout(
        setTimeout(() => {
          setExpanded(true);
          trackPosterEvent(TrackEvent.ITEM_FOCUS);
        }, 500)
      );
      shouldLoadVideoTimeoutCallback(() => setShouldLoadVideo(true), 2000);
      toggleExpandedInfo();
      return;
    }

    setMouseEnterTimeout(
      setTimeout(() => {
        setPopover(true);
        trackPosterEvent(TrackEvent.ITEM_FOCUS);
        if (onActive) {
          onActive(true, index);
        }
      }, fadeTimeout)
    );
  };

  const itemClickHandler = () => {
    trackPosterEvent(TrackEvent.ITEM_SELECTED);

    onItemClick(data);
  };

  const onMouseLeave = () => {
    if (mouseEnterTimeout) {
      clearTimeout(mouseEnterTimeout);
    }

    setExpanded(false);
    toggleShowImage(true);
    setShowExpandedInfo(true);
    setShouldLoadVideo(false);
    setPopover(false);

    if (onActive) {
      setTimeout(() => {
        onActive(false, index);
      }, fadeTimeout);
    }

    shouldLoadVideoTimeoutClear();

    if (
      type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL &&
      elRef.current?.parentElement?.parentElement
    ) {
      resetAlign();
      elRef.current.parentElement.parentElement.style.transform =
        'translateX(0)';
    }
  };

  return (
    <div
      {...otherProps}
      className={cn(
        [
          styles.root,
          {
            [styles.expandableRoot]:
              type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
          },
        ],
        className
      )}
      {...(!isMobileOrTablet() && { onMouseEnter, onMouseLeave, onMouseMove })}
      onClick={itemClickHandler}
      ref={elRef}
      data-testid="Poster"
      style={{
        width:
          expanded &&
          type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL &&
          elRef.current
            ? countWidthRatioFromHeight(
                elRef.current.getBoundingClientRect().height,
                [16, 9]
              )
            : undefined,
      }}
    >
      <div
        style={{
          backgroundImage:
            type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL && !expanded
              ? `url(${posterUrl}_${getHardcodedSizeForPortraitImage(type)})`
              : undefined,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
        className={cn(
          styles.posterImageWrapper,
          {
            [styles.portraitPosterImageWrapper]:
              type === CarouselType.PORTRAIT_CAROUSEL ||
              type === CarouselType.LARGE_PORTRAIT_CAROUSEL,
          },
          {
            [styles.portraitPosterImageWrapperExpanded]:
              type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
            [styles.noImagePlaceholder]: missingPosterUrlForExpandableCarousel,
          }
        )}
      >
        {missingPosterUrlForExpandableCarousel && (
          <span className={styles.imagePlaceholderText}>
            {getFallbackCarouselTitle(data.name)}
          </span>
        )}
        {type !== CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL && (
          <Image
            {...getCarouselThumbnailSrc(type, posterUrl)}
            alt={data.name}
            title={data.name}
            className={styles.posterImage}
          />
        )}

        {shouldDisplayLiveIndicator && (
          <LiveIndicator className={styles.liveIndicator} />
        )}
      </div>

      {type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL && expanded && (
        <div className={styles.infoContainer} onMouseMove={onMouseMove}>
          <FadeOutTransition isOpen={showExpandedInfo}>
            {logoFile ? (
              <img src={`${logoFile.url}_294x124`} alt="logo" />
            ) : null}

            {shouldDisplayLiveIndicator && (
              <LiveIndicator className={styles.liveIndicator} />
            )}

            {isLive(data) ? (
              <AvailableFromTag availableFrom={data.availableFrom} />
            ) : (
              <AssetTags asset={data} />
            )}
            <p className={styles.infoDescription}>{data.description}</p>

            {(!isAnonymous ||
              data.watchable === Watchable.YES_ANONYMOUS_ACCESS) && (
              <div className={styles.actionButtonsWrapper}>
                {displayWatchButton() && (
                  <Button
                    round
                    className={styles.actionButtonPlay}
                    tooltip={t('common.watch')}
                    onClick={playAsset}
                  >
                    <PlayButton />
                  </Button>
                )}

                {displayActionButtons && (
                  <ActionButtons
                    asset={data}
                    user={user}
                    likeActions={useLikeActions}
                    watchlistActions={watchlistActions}
                  />
                )}

                {!showImage && (
                  <PlayerMuteButtonRenderer
                    playerId={playerId}
                    source="poster"
                    className={styles.muteButton}
                    variant="small"
                  />
                )}
              </div>
            )}
          </FadeOutTransition>
        </div>
      )}

      {expanded && type === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL && (
        <VideoContainer
          playerId={playerId}
          toggleShowImage={toggleShowImage}
          showImage={showImage}
          imageUrl={hoverUrl}
          videoFile={videoFile}
          VideoPlayerComponent={VideoPlayerRendererComponent}
          canPlayTrailer={shouldLoadVideo}
          assetId={data.id}
        />
      )}

      <PosterHover
        isActive={showPopover}
        isFirst={index % itemsPerPage === 0}
        isLast={(index + 1) % itemsPerPage === 0}
      >
        <div className={styles.posterInner}>
          <>
            {showPopover && (
              <VideoContainer
                playerId={playerId}
                toggleShowImage={toggleShowImage}
                showImage={showImage}
                imageUrl={hoverUrl}
                videoFile={videoFile}
                logo={logoFile}
                VideoPlayerComponent={VideoPlayerRendererComponent}
                canPlayTrailer
                assetId={data.id}
              />
            )}

            {!showImage && (
              <PlayerMuteButtonRenderer
                playerId={playerId}
                source="poster"
                className={styles.muteButton}
                variant="small"
              />
            )}
          </>

          {shouldDisplayLiveIndicator && (
            <LiveIndicator
              className={cn(styles.liveIndicator, styles.popoverLive)}
            />
          )}
        </div>

        <div className={styles.content}>
          <div className={styles.header}>
            {displayWatchButton() && (
              <div className={styles.watchButtonWrapper}>
                <Button
                  round
                  variant="secondary"
                  onClick={playAsset}
                  className={styles.play}
                >
                  <PlayButton className={styles.playIcon} />
                </Button>

                {progress && (
                  <AssetProgressCircle
                    height={39}
                    width={39}
                    percent={watchingPercent}
                  />
                )}
              </div>
            )}

            <div className={styles.nameDetails}>
              <span className={styles.title}>{data.name}</span>
              <PosterSubinfo
                asset={data}
                progress={progress}
                getGenresTranslations={getGenresTranslations}
              />
            </div>
          </div>

          {progress && (
            <div className={styles.progress}>
              <span>{watchingProgress}</span>
              of {duration}
            </div>
          )}

          <div className={styles.actions}>
            {displayActionButtons && (
              <ActionButtons
                user={user}
                buttonClassName={styles.roundBtn}
                asset={data}
                likeActions={useLikeActions}
                watchlistActions={watchlistActions}
              />
            )}

            <Button
              className={styles.detailsButton}
              onClick={onDetailsButtonClick}
              variant="secondary"
            >
              {t('common.details')}
            </Button>
          </div>
        </div>
      </PosterHover>

      {progress && (
        <AssetProgressBar
          asset={data}
          showDuration={false}
          variant="poster"
          progress={{ watchingPercent, watchingProgress }}
        />
      )}
    </div>
  );
};

export default Poster;

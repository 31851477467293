import { Price } from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { PlanMetadata, SubscriptionPrice } from 'types/subscription';
import { Button } from '@laminar-product/client-commons-core/web';
import _truncate from 'lodash/truncate';
import { MAX_PLAN_DESCRIPTION_LENGTH } from 'components/PlanSelector/constants';
import PlanDetails from 'components/PlanSelector/components/PlanCard/components/PlanDetails';
import styles from './index.module.scss';
import PriceInfo from './PriceInfo';

export interface ModalPlanCardProps {
  plan?: PlanMetadata;
  price?: SubscriptionPrice | Price;
  variant: 'current' | 'new';
  title: string;
  open?: boolean;
  onCollapseClick?: () => void;
}

const ModalPlanCard = ({
  plan,
  price,
  variant,
  title,
  open,
  onCollapseClick,
}: ModalPlanCardProps) => {
  return (
    <div
      className={cn(styles.modalPlanCard, {
        [styles.current]: variant === 'current',
        [styles.new]: variant === 'new',
      })}
    >
      <p className={styles.badge}>{title}</p>
      <div
        className={cn(styles.modalPlanCardBody, {
          [styles.collapsed]: !open,
          [styles.expanded]: open,
        })}
      >
        <div className={styles.row}>
          <div className={styles.planName}>{plan?.name}</div>
          <div className={styles.priceCol}>
            <PriceInfo price={price} />
            <Button
              round
              className={styles.collapseButton}
              onClick={onCollapseClick}
            >
              {open ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </div>
        </div>
        <div className={styles.description}>
          {_truncate(plan?.description, {
            length: MAX_PLAN_DESCRIPTION_LENGTH,
          })}
        </div>
        <PlanDetails className={styles.planDetails} plan={plan} />
      </div>
    </div>
  );
};

export default ModalPlanCard;

import { useMemo } from 'react';
import PhoneInputWithCountry, {
  Country,
  Value as CountryValue,
  DefaultInputComponentProps,
  getCountries,
} from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { selectAllowedOtpCountryCodes } from 'store/app/selectors';

interface CountryCodePhoneInputProps extends DefaultInputComponentProps {
  value: CountryValue;
  onChange: (value: CountryValue) => void;
  className?: string;
}

const CountryCodePhoneInput = ({
  onChange,
  value,
  className,
}: CountryCodePhoneInputProps) => {
  const allowedOtpCountryCodes = useSelector(selectAllowedOtpCountryCodes);
  const allCountries = getCountries();

  //If no allowed otp provided, all countries are displayed
  const countryCodes = useMemo(() => {
    if (allowedOtpCountryCodes?.length) {
      return allowedOtpCountryCodes;
    }

    return allCountries;
  }, [allCountries, allowedOtpCountryCodes]);

  return (
    <PhoneInputWithCountry
      value={value}
      onChange={onChange}
      className={className}
      defaultCountry="IN"
      international
      addInternationalOption={false}
      countryCallingCodeEditable={false}
      id="phone-input"
      countries={countryCodes as Country[]}
    />
  );
};

export default CountryCodePhoneInput;

import {
  Interests,
  SerializedMenu,
} from '@laminar-product/client-commons-core/core';
import api from 'api';

export const getAvailableInterests = async () => {
  const { data } = await api().get<Interests>('/interests');

  return data;
};

export const fetchMenuBasedOnInterests = async (interests: string[]) => {
  const queryParams = new URLSearchParams({
    interests: interests.join(','),
  });
  const { data } = await api().get<SerializedMenu[]>('/menu', {
    params: queryParams,
  });

  return data;
};

import React from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import styles from './index.module.scss';

interface ConfirmationMethodButtonProps {
  text: string;
  onClick: () => void;
}

const ConfirmationMethodButton = ({
  text,
  onClick,
}: ConfirmationMethodButtonProps) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.text}>{text}</div>
      <ArrowRight className={styles.rightArrow} />
    </div>
  );
};

export default ConfirmationMethodButton;

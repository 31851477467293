export const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

export const SOCIAL_ERRORS_TO_OMIT_FOR_USER = [
  'auth/cancelled-popup-request',
  'auth/popup-closed-by-user',
];

export const SOCIAL_ERRORS_TO_OMIT_IN_SENTRY = [
  ...SOCIAL_ERRORS_TO_OMIT_FOR_USER,
  ERROR_CODE_ACCOUNT_EXISTS,
];

export const ERROR_CODE_MISMATCH_ACCOUNT = 'auth/user-mismatch';

import { useCallback, useEffect, useState } from 'react';
import { Asset } from '@laminar-product/client-commons-core/core';

const useExpandablePortrait = ({
  perPage,
  expanded,
  currentPage,
  lastPage,
  items,
  item,
}: {
  perPage: number;
  expanded: boolean;
  currentPage?: number;
  lastPage?: boolean;
  items?: Asset[];
  item?: Asset;
}) => {
  const [positionToAlign, setPositionToAlign] = useState<'left' | 'right'>();
  const [resetedIndex, setResetedIndex] = useState(0);

  const findAndSetResetedIndex = useCallback(
    (items: Asset[]) =>
      items.forEach((i, index) =>
        i.id === item?.id ? setResetedIndex(index) : undefined
      ),
    [item]
  );

  const getAndSetAlignPosition = useCallback(
    () => setPositionToAlign(perPage / 2 <= resetedIndex ? 'left' : 'right'),
    [perPage, resetedIndex]
  );

  useEffect(() => {
    if (typeof currentPage === 'undefined' || !expanded || !items) return;

    if (lastPage) {
      const lastItems = items.slice(
        Math.max(items.length - (perPage % items.length), 0)
      );

      findAndSetResetedIndex(lastItems);
      getAndSetAlignPosition();
      return;
    }

    const findPageItems = items.slice(
      perPage * currentPage,
      perPage * currentPage + perPage
    );

    findAndSetResetedIndex(findPageItems);
    getAndSetAlignPosition();
  }, [
    currentPage,
    expanded,
    findAndSetResetedIndex,
    getAndSetAlignPosition,
    items,
    lastPage,
    perPage,
  ]);

  const resetAlign = () => {
    setResetedIndex(0);
    setPositionToAlign('right');
  };

  return { positionToAlign, resetedIndex, resetAlign };
};

export default useExpandablePortrait;

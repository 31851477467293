import { Asset, Collection } from '@laminar-product/client-commons-core/core';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import Carousel from 'library/Carousel/Carousel';
import { useTranslation } from 'react-i18next';
import { CarouselType } from 'utils/carouselHelpers';
import { useMoveToAssetDetails } from 'utils/useMoveToAssetDetails';
import styles from './index.module.scss';

interface RecommendationCarouselProps {
  assets: Asset[];
  currentAsset: Asset;
}

const RecommendationCarousel = ({
  assets,
  currentAsset,
}: RecommendationCarouselProps) => {
  const moveToAsset = useMoveToAssetDetails();
  const { t } = useTranslation();
  const { isTabletWindowSize } = useWindowSize();

  const mapAssetsToCollection = (): Collection => ({
    title: t('common.moreLikeThis'),
    administrativeName: 'recommendation',
    assets,
  });

  return (
    <div className={styles.carouselContainer}>
      <Carousel
        collection={mapAssetsToCollection()}
        onItemClick={moveToAsset}
        shouldDisplayTitle={false}
        carouselType={
          isTabletWindowSize
            ? CarouselType.PORTRAIT_CAROUSEL
            : CarouselType.CAROUSEL
        }
        strataMetadata={{
          source_type: 'recommendation_more_like',
          source_referrer_item: {
            asset_id: currentAsset.id,
            asset_name: currentAsset.administrativeName,
          },
          source_name: null,
        }}
      />
    </div>
  );
};

export default RecommendationCarousel;

export enum ChromecastEvents {
  AVAILABLE = 'available',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  ERROR = 'error',
  TIMEUPDATE = 'timeupdate',
  PLAYING = 'playing',
  PAUSE = 'pause',
  END = 'end',
  BUFFERING = 'buffering',
}

export enum ChromecastStatus {
  CONNECTED = 'CONNECTED',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  BUFFERING = 'BUFFERING',
  DISCONNECTED = 'DISCONNECTED',
}

export enum ChromecastControllerEvents {
  CURRENT_TIME_CHANGED = 'currentTimeChanged',
  MEDIA_INFO_CHANGED = 'mediaInfoChanged',
}

export interface Track {
  name: string;
  language?: string;
  trackId: number;
  type: 'AUDIO' | 'TEXT';
}

export interface AvailableTracks {
  textTracks?: Track[];
  audioTracks?: Track[];
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import PopOver from 'components/PopOver';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationType } from 'utils/notifications';
import { setNotifications } from 'store/user/actions';
import {
  NOTIFICATION_LIMIT_COUNT,
  SESSION_STORAGE_NOTIFICATION_KEY,
} from 'utils/constants';
import { usePrevious } from '@laminar-product/client-commons-core/hooks';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';
import { selectNotifications } from 'store/user/selectors';
import styles from './index.module.scss';

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationButtonRef = useRef<HTMLDivElement>(null);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const toggleNotificationMenuOpen = useCallback(
    () => setNotificationMenuOpen(!notificationMenuOpen),
    [notificationMenuOpen]
  );
  const previousNotificationModalOpen = usePrevious(notificationMenuOpen);
  const [notificationList, setNotificationList] = useState<NotificationType[]>(
    []
  );
  const { t } = useTranslation();

  const notificationsState = useSelector(selectNotifications);

  const unseenNotificationsLength = useMemo(
    () => notificationList.filter((notification) => !notification.seen).length,
    [notificationList]
  );

  const onNotificationModalClose = useCallback(() => {
    if (!previousNotificationModalOpen && notificationMenuOpen) {
      const seenNotifications = notificationList.map((notification) => {
        return { ...notification, seen: true };
      });

      if (!seenNotifications) return;

      dispatch(setNotifications(seenNotifications));
      window.sessionStorage.setItem(
        SESSION_STORAGE_NOTIFICATION_KEY,
        JSON.stringify(seenNotifications)
      );
    }
  }, [
    previousNotificationModalOpen,
    notificationMenuOpen,
    dispatch,
    notificationList,
  ]);

  useEffect(() => {
    if (!notificationsState) {
      const sessionNotifications =
        window.sessionStorage.getItem('notifications');

      if (!sessionNotifications) return;

      dispatch(setNotifications(JSON.parse(sessionNotifications)));
      return;
    }

    setNotificationList(notificationsState);
  }, [notificationsState, dispatch]);

  return (
    <>
      <div
        className={styles.container}
        ref={notificationButtonRef}
        onClick={() => {
          toggleNotificationMenuOpen();
          onNotificationModalClose();
        }}
      >
        <Tooltip
          popoverClassName={styles.popover}
          data={t('common.noNewNotifications')}
          onHoverEnabled={notificationList?.length === 0}
          placement="bottom"
        >
          <NotificationsIcon />
        </Tooltip>

        {unseenNotificationsLength > 0 && (
          <div className={styles.notificationBadge}>
            {unseenNotificationsLength > 3 ? '3' : unseenNotificationsLength}
          </div>
        )}
      </div>

      <PopOver
        anchorEl={notificationButtonRef?.current}
        placement="bottom-end"
        isOpen={notificationMenuOpen && notificationList?.length > 0}
        onClose={() => onNotificationModalClose}
      >
        <div className={styles.notificationList}>
          {notificationList
            .slice(0, NOTIFICATION_LIMIT_COUNT)
            .map((notificationItem, index) => (
              <div key={index}>
                <div className={styles.notificationItem}>
                  <p className={styles.title}>{notificationItem.title}</p>
                  <div className={styles.description}>
                    {notificationItem.description}
                  </div>
                </div>

                {notificationList.length > 1 &&
                  index !== notificationList.length - 1 &&
                  index !== NOTIFICATION_LIMIT_COUNT - 1 && (
                    <hr className={styles.divider} />
                  )}
              </div>
            ))}
        </div>
      </PopOver>
    </>
  );
};

export default Notifications;

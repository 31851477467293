import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFirebaseUser } from 'store/user/selectors';
import { shouldRedirectOnMobile } from 'utils/config';

const DesktopOnlyRoute = ({ ...props }: RouteProps) => {
  const user = useSelector(selectFirebaseUser);

  return shouldRedirectOnMobile() ? (
    <Redirect to={user && !user.isAnonymous ? '/settings' : '/mobile/about'} />
  ) : (
    <Route {...props} />
  );
};

export default DesktopOnlyRoute;

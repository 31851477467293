export interface ProfileBasic {
  name: string;
  maturityRating: number;
  language: string;
}

export enum ProfileItemType {
  LIST = 'list',
  HEADER = 'header',
}

export enum SettingSwitchItemType {
  LIST = 'list',
  MODAL = 'modal',
}

export enum ProfileConfirmationMethods {
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
  SOCIAL = 'SOCIAL',
}

export enum ProfileDetailsTabKeys {
  PROFILE_DETAILS = 'PROFILE_DETAILS',
  PLAYBACK = 'PLAYBACK',
  PARENTAL_CONTROL = 'PARENTAL_CONTROL',
}

export enum ProfileContentRating {
  ALL = 'ALL',
  KIDS = 'KIDS',
}

import { Asset } from '@laminar-product/client-commons-core/core';
import { Severity } from 'types/errors';
import { getAssetRecommendation } from 'actions/recommendation';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureError } from './captureError';
import { AssetDetailsParams } from './props';

const useRecommendation = () => {
  const [recommendationData, setRecommendationData] = useState<Asset[]>();
  const { id } = useParams<AssetDetailsParams>();

  useEffect(() => {
    if (!id) return;

    // NOTE: As requested for now, recommendation call has to be done on each entry on asset details
    getAssetRecommendation(id)
      .then((recommendation) => {
        setRecommendationData(recommendation?.assets);
      })
      .catch((error) => {
        captureError(
          error as AxiosError,
          'Recommendation/getAssetRecommendation',
          Severity.Warning
        );
      });
  }, [id]);

  return { recommendationData };
};

export default useRecommendation;

import { AnyAction } from 'redux';
import { Plan, Price } from '@laminar-product/client-commons-core/core';
import {
  SET_PAYMENT_EMAIL,
  SET_REGISTRATION_METHOD,
  SET_SELECTED_PRICE,
  SET_SUBSCRIPTION_PLAN,
  SET_USER_LOGIN,
} from './actions';

export interface RegistrationState {
  userLogin?: string;
  selectedPlan?: Plan;
  selectedPrice?: Price;
  method?: string;
  paymentEmail?: string;
}

const registrationReducer = (
  state: RegistrationState = {},
  action: AnyAction
): RegistrationState => {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {
        ...state,
        userLogin: action.payload.userLogin,
      };
    case SET_REGISTRATION_METHOD:
      return {
        ...state,
        method: action.payload.method,
      };
    case SET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        selectedPlan: action.payload.plan,
      };
    case SET_PAYMENT_EMAIL:
      return {
        ...state,
        paymentEmail: action.payload.email,
      };
    case SET_SELECTED_PRICE:
      return {
        ...state,
        selectedPrice: action.payload.price,
      };
    default:
      return state;
  }
};

export default registrationReducer;

import { combineReducers } from 'redux';
import appReducer, { AppState } from './app/reducer';
import pagesReducer, { PagesState } from './pages/reducer';
import registrationReducer, { RegistrationState } from './registration/reducer';
import userReducer, { UserState } from './user/reducer';
import loginReducer, { LoginState } from './login/reducer';
import collectionsReducer, { CollectionState } from './collections/reducer';
import assetsReducer, { AssetsState } from './assets/reducer';
import mobileAlertReducer, { MobileAlertState } from './mobileAlert/reducer';
import playerReducer, { PlayerState } from './player/reducer';
import remoteConfigReducer, { RemoteConfigState } from './remoteConfig/reducer';
import chromecastReducer, { ChromecastState } from './chromecast/reducer';
import watchlistReducer, { WatchlistState } from './watchlist/reducer';

export type RootState = {
  app: AppState;
  registration: RegistrationState;
  login: LoginState;
  user: UserState;
  pages: PagesState;
  collections: CollectionState;
  assets: AssetsState;
  mobileAlert: MobileAlertState;
  player: PlayerState;
  remoteConfig: RemoteConfigState;
  chromecast: ChromecastState;
  watchlist: WatchlistState;
};

const createRootReducer = () =>
  combineReducers<RootState>({
    app: appReducer,
    registration: registrationReducer,
    user: userReducer,
    pages: pagesReducer,
    login: loginReducer,
    collections: collectionsReducer,
    assets: assetsReducer,
    mobileAlert: mobileAlertReducer,
    player: playerReducer,
    remoteConfig: remoteConfigReducer,
    chromecast: chromecastReducer,
    watchlist: watchlistReducer,
  });

export default createRootReducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedPrice } from 'components/PlanSelector/components/PlanCard/index';
import styles from './index.module.scss';

export interface PlanTitleProps {
  planName: string;
  selectedPrice?: SelectedPrice;
  isCurrentPlan: boolean;
}

const PlanTitle = ({
  planName,
  selectedPrice,
  isCurrentPlan,
}: PlanTitleProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.titleGroup}>
      <h2 className={styles.title} data-testid="PlanCard__PlanTitle">
        {planName}
      </h2>

      {!isCurrentPlan && selectedPrice?.discount && selectedPrice.netPrice ? (
        <div className={styles.discount}>
          <div className={styles.oldPrice}>{selectedPrice.price}</div>
          <div className={styles.price}>{selectedPrice.netPrice}</div>
        </div>
      ) : (
        <div className={styles.price}>{selectedPrice?.price}</div>
      )}

      <div className={styles.interval}>
        {t('planSelector.per', {
          interval: t(
            `common.intervals.${selectedPrice?.interval?.toLowerCase()}`
          ),
          count: selectedPrice?.intervalMultiplier,
        })}
      </div>
    </div>
  );
};

export default PlanTitle;

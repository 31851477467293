import Input from 'components/Input';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AuthInputType, isUserDisabled, validateAuthInput } from 'utils/auth';
import { ReactComponent as LoginUserIcon } from 'assets/icons/login-user.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLoginMethod } from 'store/user/actions';
import PasswordInput from 'components/PasswordInput';
import { Button } from '@laminar-product/client-commons-core/web';
import Checkbox from 'components/Checkbox';
import FeatureEnabled from 'components/FeatureEnabled';
import { FeatureFlag } from 'utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { setUserCredentials } from 'store/login/actions';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import Popup from 'components/Popup';
import styles from '../index.module.scss';

interface EmailPasswordForm {
  userEmail: string;
  password: string;
}

const EmailPassword = () => {
  const { t } = useTranslation();
  const form = useForm<EmailPasswordForm>();
  const [rememberMe, toggleRememberMe] = useState(true);
  const [invalidCredentialsError, setInvalidCredentialsError] =
    useState<string>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
  } = form;
  const dispatch = useDispatch();

  const handleInvalidCredentialsPopup = (e: any) => {
    if (isUserDisabled(e.code)) {
      setInvalidCredentialsError(t('errors.userDisabledLoginError'));
      return;
    }

    setInvalidCredentialsError(
      t('errors.invalidCredentialsMessage', {
        account: getValues('userEmail'),
      })
    );
  };

  const history = useHistory();

  const onSubmit = ({ userEmail, password }: EmailPasswordForm) => {
    const auth = getAuth();
    const persistentType = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    setPersistence(auth, persistentType)
      .then(() => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, userEmail, password)
          .then(() => {
            dispatch(setUserCredentials({ email: userEmail }));
            dispatch(setLoginMethod('email'));
          })
          .catch(handleInvalidCredentialsPopup);
      })
      .catch(handleInvalidCredentialsPopup);
  };

  const onForgotPasswordClick = () => {
    history.push('/forgot-password');
    setInvalidCredentialsError(undefined);
  };

  return (
    <div className={styles.inputGroup}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.input}>
            <Controller
              control={control}
              name="userEmail"
              defaultValue=""
              rules={{
                validate: (value) =>
                  validateAuthInput(value, AuthInputType.EMAIL),
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  type="email"
                  onChange={(e) => onChange(e.target.value.trim())}
                  placeholder={t('common.placeholders.email')}
                  startIcon={<LoginUserIcon />}
                />
              )}
            />
            {!!errors.userEmail && (
              <p className={styles.error}>{errors.userEmail?.message}</p>
            )}
          </div>

          <div className={styles.input}>
            <PasswordInput
              {...register('password', {
                validate: (value) =>
                  validateAuthInput(value, AuthInputType.PASSWORD),
              })}
              autoFocus={false}
              placeholder={t('common.placeholders.enterPassword')}
            />

            {!!errors.password && (
              <p className={styles.error}>{errors.password?.message} </p>
            )}
          </div>

          <div className={styles.actions}>
            <Checkbox
              variant="primary"
              label={t('login.rememberMe')}
              checked={rememberMe}
              className={styles.rememberMe}
              onChange={(e) => toggleRememberMe(e.target.checked)}
            />
            <FeatureEnabled featureFlag={FeatureFlag.showRegistration}>
              <Link className={styles.link} to="/forgot-password">
                {t('login.forgotPassword')}
              </Link>
            </FeatureEnabled>
          </div>

          <Button
            className={styles.loginButton}
            type="submit"
            variant="primary"
          >
            {t('login.signInButton')}
          </Button>
        </form>
      </FormProvider>

      {!!invalidCredentialsError && (
        <Popup
          header={t('errors.invalidCredentialsHeader')}
          message={invalidCredentialsError}
          actionButtons={
            <>
              <Button variant="secondary" onClick={onForgotPasswordClick}>
                Reset your password
              </Button>
              <Button
                variant="primary"
                onClick={() => setInvalidCredentialsError(undefined)}
              >
                Try again
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};

export default EmailPassword;

import AuthenticationMenu from 'components/AuthenticationMenu';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { prepareMetaForPage } from 'utils/meta';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import AuthPageContainer from 'components/AuthPageContainer';
import { APP_PAGE_PATH } from 'utils/navigation';
import useRedirectToHomePage from 'utils/useRedirectToHomePage';
import SignUpSection from './components/SignUpSection';

const LandingPage = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { deviceTv } = useParams<{ deviceTv: string }>();
  useRedirectToHomePage();

  useEffect(() => {
    dispatch(setAppMeta({ og: prepareMetaForPage('') }));

    if (!deviceTv) return;

    const utms = {
      utm_source: deviceTv,
      utm_medium: 'tv',
      utm_content: 'qrcode',
    };
    replace({
      pathname: APP_PAGE_PATH.REGISTER,
      search: new URLSearchParams(utms).toString(),
    });
  }, [deviceTv, dispatch, replace]);

  return (
    <AuthPageContainer menu={<AuthenticationMenu variant="signUp" />}>
      <SignUpSection />
    </AuthPageContainer>
  );
};

export default LandingPage;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlayerCurrentFrame,
  selectPlayerCurrentFrameIsAd,
  selectPlayerFrames,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import _throttle from 'lodash/throttle';
import SlideTransition from 'components/SlideTransition';
import { VIDEO_PLAYER_FRAME_ANIMATION_TIMEOUT } from 'utils/player';
import {
  useCurrentSkipFrame,
  useFrameSkip,
} from '@laminar-product/client-commons-core/hooks';
import {
  FrameType,
  isPlayingAd,
} from '@laminar-product/client-commons-core/core';
import SkipFrameButton from 'components/SkipFrameButton';
import AdsCounter from 'components/AdsCounter';
import { AdTrackEventPayload } from 'types/player';
import { setPlayerCurrentFrame } from 'store/player/actions';
import styles from './index.module.scss';
import VideoPlayerEndingFrame from './components/VideoPlayerEndingFrame';

interface VideoPlayerSkipFramesProps {
  onAdStart: (data: AdTrackEventPayload) => void;
  onAdEnd: (data: AdTrackEventPayload) => void;
  onAdPause: (data: AdTrackEventPayload) => void;
}

const VideoPlayerSkipFrames = ({
  onAdEnd,
  onAdStart,
  onAdPause,
}: VideoPlayerSkipFramesProps) => {
  const dispatch = useDispatch();
  const frames = useSelector(selectPlayerFrames);
  const videoElement = useSelector(selectPlayerVideoElement);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const [readyToPlay, setReadyToPlay] = useState(false);

  const playerCurrentFrame = useSelector(selectPlayerCurrentFrame);
  const isPlayingAds = useSelector(selectPlayerCurrentFrameIsAd);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCurrentPlayerTime = useCallback(
    _throttle(() => {
      if (!videoElement) {
        return;
      }

      setPlayerCurrentTime(videoElement.currentTime);
    }, 1000),
    [videoElement]
  );

  const setPlayerTime = useCallback(
    (time: number) => {
      if (!videoElement) {
        return;
      }

      videoElement.currentTime = time;
    },
    [videoElement]
  );

  const { currentFrame, shouldUnmountCurrentFrame } = useCurrentSkipFrame({
    frames: frames || [],
    animationTimeout: 1,
    playerCurrentTime,
  });

  const { onFrameSkip, shouldHideFrame, onFrameHide } = useFrameSkip({
    setPlayerTime,
  });

  const onWatchCredits = () => {
    if (videoElement?.paused) {
      videoElement.play();
    }

    onFrameHide(FrameType.CAPTIONS);
  };

  const isCurrentFrameAdFrame = playerCurrentFrame
    ? isPlayingAd(playerCurrentFrame.type)
    : false;

  const getSkipFrameBody = () => {
    switch (playerCurrentFrame?.type) {
      case FrameType.INTRO:
      case FrameType.RECAP:
        return (
          <SkipFrameButton
            frame={playerCurrentFrame}
            onFrameSkip={onFrameSkip}
          />
        );
      case FrameType.CAPTIONS:
        return <VideoPlayerEndingFrame onWatchCredits={onWatchCredits} />;
    }
  };

  const shouldDisplayFrame =
    !!playerCurrentFrame &&
    !isCurrentFrameAdFrame &&
    !shouldHideFrame(playerCurrentFrame.type) &&
    !shouldUnmountCurrentFrame;

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    videoElement.addEventListener('timeupdate', updateCurrentPlayerTime);

    return () =>
      videoElement.removeEventListener('timeupdate', updateCurrentPlayerTime);
  }, [updateCurrentPlayerTime, videoElement]);

  useEffect(() => {
    const listener = () => setReadyToPlay(true);
    videoElement?.addEventListener('canplaythrough', listener);

    return () => videoElement?.removeEventListener('canplaythrough', listener);
  }, [videoElement]);

  useEffect(() => {
    //Info about current frame is needed also in another components
    dispatch(setPlayerCurrentFrame(currentFrame));
  }, [currentFrame, dispatch]);

  useEffect(() => {
    const controls = document.getElementsByClassName(
      'shaka-controls-container'
    )[0];

    if (isPlayingAds) {
      controls?.classList?.add('shaka-controls-ads-container');
    }

    return () => {
      controls?.classList?.remove('shaka-controls-ads-container');
    };
  }, [isPlayingAds]);

  return (
    <>
      <div className={styles.skipFramesRoot}>
        <SlideTransition
          unmountOnExit
          className={styles.backgroundTransition}
          isOpen={shouldDisplayFrame}
          timeout={{
            enter: VIDEO_PLAYER_FRAME_ANIMATION_TIMEOUT,
            exit: VIDEO_PLAYER_FRAME_ANIMATION_TIMEOUT,
          }}
          deviation={{ vertical: false, enter: 0, exit: 150, unit: '%' }}
        >
          {getSkipFrameBody()}
        </SlideTransition>
      </div>

      {/* Ads are separate frame, there is only a counter displayed */}
      {playerCurrentFrame && isCurrentFrameAdFrame ? (
        <AdsCounter
          frame={playerCurrentFrame}
          playerCurrentTime={playerCurrentTime}
          onAdStart={onAdStart}
          onAdEnd={onAdEnd}
          className={styles.adsFrame}
          onAdPause={onAdPause}
          canSendEvents={readyToPlay}
          isPlayerPaused={readyToPlay && videoElement?.paused}
        />
      ) : null}
    </>
  );
};

export default VideoPlayerSkipFrames;

import {
  Resolution,
  getHighestAvailableResolutionOnPlan,
} from '@laminar-product/client-commons-core/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPlanQuality } from 'store/user/selectors';

//TODO: MOVE TO COMMONS
export const getResolutionWidth = (resolution: Resolution): number => {
  switch (resolution) {
    case Resolution.SD270:
      return 480;
    case Resolution.SD360:
      return 640;
    case Resolution.SD540:
      return 960;
    case Resolution.HD:
      return 1280;
    case Resolution.FHD:
      return 1920;
    case Resolution.UHD:
      return 3840;
    default:
      return 1920;
  }
};

const useVideoPlayerQuality = () => {
  const qualities = useSelector(selectUserPlanQuality);

  const quality = useMemo(() => {
    let resolution = getHighestAvailableResolutionOnPlan(qualities);

    // todo this is temporary, there are some buffering issues experienced by client, and this is until we figure out what is the issue
    if (resolution > 720) {
      resolution = 720;
    }

    return {
      maxHeight: resolution,
      maxWidth: getResolutionWidth(resolution),
    };
  }, [qualities]);

  return quality;
};

export default useVideoPlayerQuality;

import React from 'react';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import styles from '../index.module.scss';

const ConfirmationIcon = () => (
  <div className={styles.header}>
    <hr className={styles.line} />
    <div className={styles.lockIconWrapper}>
      <LockIcon className={styles.lockIcon} />
    </div>
    <hr className={styles.line} />
  </div>
);

export default ConfirmationIcon;

import { Button } from '@laminar-product/client-commons-core/web';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import Input from 'components/Input';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React from 'react';
import { useForm } from 'react-hook-form';
import { captureError } from 'utils/captureError';
import { isEmail } from 'utils/validators';
import styles from './index.module.scss';

interface ResetPasswordFormState {
  userLogin: string;
}

interface ResetPasswordFormProps {
  onResetPasswordStatusChange: (status: string) => void;
  setUserLogin: (value: string) => void;
}

const getResetErrorMessage = (code: string) => {
  switch (code) {
    case 'auth/user-not-found':
      return "The email account doesn't exist";
    default:
      return 'Unknown error, please contact support.';
  }
};
const ResetPasswordForm = ({
  setUserLogin,
  onResetPasswordStatusChange,
}: ResetPasswordFormProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ResetPasswordFormState>({
    mode: 'onSubmit',
  });

  const onSubmit = async ({ userLogin }: ResetPasswordFormState) => {
    try {
      setUserLogin(userLogin);
      onResetPasswordStatusChange('pending');
      await sendPasswordResetEmail(getAuth(), userLogin);
      onResetPasswordStatusChange('resolved');
    } catch (e) {
      const err = e as any;
      onResetPasswordStatusChange('rejected');
      setError('userLogin', {
        type: 'manual',
        message: getResetErrorMessage(err.code),
      });
      captureError(
        err as AxiosError,
        'ResetPasswordForm/onSubmit',
        Severity.Error
      );
    }
  };

  const validate = (val: string) => {
    if (!isEmail(val)) {
      return 'Please provide a valid email address';
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className={styles.heading}>Forgot Password?</h1>
      <p className={styles.description}>
        We'll send you an email with a verification code that will allow you to
        reset your password.
      </p>
      <div className={styles.inputWrapper}>
        <Input
          {...register('userLogin', { required: true, validate })}
          placeholder="Enter your email"
        />
        <span className={styles.errorMessage}>{errors.userLogin?.message}</span>
      </div>
      <Button className={styles.button}>Send</Button>
    </form>
  );
};

export default ResetPasswordForm;

import { useMemo, useState } from 'react';
import WebFont from 'webfontloader';
import { Fonts } from '@laminar-product/client-commons-core/core';

const useFonts = (fonts?: Fonts) => {
  const [customFont, setCustomFont] = useState<string>();
  useMemo(() => {
    if (!fonts) return;
    const { urls, families } = Object.values(fonts).reduce(
      (prev, { fontUrl, fontFamily }) => ({
        ...prev,
        families: [...prev.families, fontFamily],
        urls: [...prev.urls, fontUrl],
      }),
      { urls: [], families: [] }
    ) as { urls: string[]; families: string[] };
    WebFont.load({
      custom: {
        families,
        urls,
      },
      fontactive: (familyName) => setCustomFont(familyName),
    });
  }, [fonts]);

  return customFont;
};
export default useFonts;

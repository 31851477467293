import { Button } from '@laminar-product/client-commons-core/web';
import Input from 'components/Input';
import Popup from 'components/Popup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthInputType, validateAuthInput } from 'utils/auth';
import { PaymentActionType } from 'utils/useExternalPayment';
import styles from './index.module.scss';

export interface PromptEmailForm {
  email: string;
}

interface PromptEmailModalProps {
  onClose: () => void;
  onSubmit: (form: PromptEmailForm) => void;
  actionType: PaymentActionType;
}

const PromptEmailModal = ({
  onClose,
  onSubmit,
  actionType,
}: PromptEmailModalProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PromptEmailForm>();

  return (
    <Popup
      header={
        actionType === PaymentActionType.PAYMENT_INFO_CHANGE
          ? t('settings.promptEmailHeaderChangePaymentInfo')
          : t('settings.promptEmailHeaderPay')
      }
      message={t('settings.promptEmailMessage')}
      actionButtons={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            Continue
          </Button>
        </>
      }
      extraContent={
        <>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              validate: (value) =>
                validateAuthInput(value, AuthInputType.EMAIL),
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={(e) => onChange(e.target.value.trim())}
                placeholder={t('common.placeholders.emailAddress')}
              />
            )}
          />

          {!!errors.email && (
            <p className={styles.errorMessage}>{errors.email?.message}</p>
          )}
        </>
      }
    />
  );
};

export default PromptEmailModal;

import { Tab, Tabs } from '@laminar-product/client-commons-core/web';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { useTranslation } from 'react-i18next';
import { SettingsTabKey } from 'types/settings';
import { prepareMetaForPage } from 'utils/meta';
import SelectPlan from 'components/SelectPlan';
import ProtectedRoute from 'components/ProtectedRoute';
import { format, parseISO } from 'date-fns';
import { selectIsPartnerUser, selectProfiles } from 'store/user/selectors';
import useFetchUserSubscription from 'utils/useFetchUserSubscription';
import PageLayoutWrapper from 'layouts/PageLayoutWrapper';
import AccountDetails from './components/AccountDetails';
import ChangePassword from './components/ChangePassword';
import styles from './index.module.scss';
import ProfileList from './components/Profiles/ProfilesList';
import ProfileDetails from './components/Profiles/ProfileDetails';
import RegisterDevice from './components/Devices/components/RegisterDevice';
import PaymentPlanPage from './components/PaymentPlanPage';
import CancelMembership from './components/CancelMembership';
import Devices from './components/Devices';

const Settings = () => {
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profiles = useSelector(selectProfiles);
  const location = useLocation<{ activeMenu: SettingsTabKey }>();

  const activeMenuKey: SettingsTabKey =
    location?.state?.activeMenu || SettingsTabKey.ACCOUNT_AND_SUBSCRIPTION;

  const { subscription, subscriptionError, refreshSubscription } =
    useFetchUserSubscription();

  useEffect(() => {
    dispatch(
      setAppMeta({ og: prepareMetaForPage(t('common.accountSettings')) })
    );
  }, [dispatch, t]);

  const tabs = useMemo(
    () =>
      [
        <Tab
          key={SettingsTabKey.ACCOUNT_AND_SUBSCRIPTION}
          label={t('settings.accountAndSubscription')}
        >
          <AccountDetails
            subscription={subscription}
            isSubscriptionError={subscriptionError}
          />
        </Tab>,
        <Tab key={SettingsTabKey.DEVICES} label={t('settings.devices.header')}>
          <Devices />
        </Tab>,
        <Tab key={SettingsTabKey.PROFILES} label={t('settings.profiles')}>
          <ProfileList profiles={profiles} />
        </Tab>,
      ].filter((v) => !!v),
    [profiles, subscription, subscriptionError, t]
  );

  const protectedRouteProps = {
    isAllowed: !isPartnerUser,
    redirectTo: `${path}/settings`,
  };
  return (
    <>
      <PageLayoutWrapper>
        <div className={styles.root}>
          <Switch>
            <Route path={path} exact>
              <div className={styles.wrapper}>
                <h1 className={styles.heading}>
                  {t('settings.accountAndSubscription')}
                </h1>
                <Tabs
                  defaultActiveKey={activeMenuKey}
                  navActiveClassName={styles.tabsActiveNavigation}
                  navClassName={styles.tabsNavigation}
                  settingsTab
                >
                  {tabs}
                </Tabs>
              </div>
            </Route>
            <ProtectedRoute
              path={`${path}/cancel-plan`}
              {...protectedRouteProps}
            >
              <CancelMembership
                onCancelMembership={refreshSubscription}
                subscriptionEndDate={
                  subscription &&
                  subscription.nextPaymentDate &&
                  format(parseISO(subscription.nextPaymentDate), 'dd/MM/yyyy')
                }
              />
            </ProtectedRoute>
            <ProtectedRoute
              path={`${path}/change-plan/payment`}
              {...protectedRouteProps}
            >
              <PaymentPlanPage />
            </ProtectedRoute>
            <ProtectedRoute
              path={`${path}/change-password`}
              {...protectedRouteProps}
            >
              <ChangePassword />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={`${path}/change-plan`}
              {...protectedRouteProps}
            >
              <SelectPlan />
            </ProtectedRoute>

            <Route exact path={`${path}/devices/register`}>
              <RegisterDevice />
            </Route>
            <Route path={`${path}/profile/:id`}>
              <ProfileDetails />
            </Route>
          </Switch>
        </div>
      </PageLayoutWrapper>
    </>
  );
};

export default Settings;

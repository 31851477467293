import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface ProfileModalHeaderProps {
  text: string;
  bold?: boolean;
}

const ProfileModalHeader = ({ text, bold }: ProfileModalHeaderProps) => {
  return (
    <div
      className={cn(styles.header, {
        [styles.bold]: bold,
      })}
    >
      {text}
    </div>
  );
};

export default ProfileModalHeader;

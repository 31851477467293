import React from 'react';
import { SerializedMenu } from '@laminar-product/client-commons-core/core';
import { getPageLink } from 'utils/routing';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import _get from 'lodash/get';
import ErrorPage from '..';

const PageNotFoundComponent = () => {
  const { t } = useTranslation();
  const firstMenuOption = useSelector<RootState, SerializedMenu | undefined>(
    (state) => _get(state, 'app.appSettings.menuLinks[0]')
  );

  return (
    <ErrorPage
      reason={t('errors.pageDoesntExist')}
      linkName="home"
      linkTo={getPageLink(firstMenuOption)}
    />
  );
};

export default PageNotFoundComponent;

import { VideoRecommendationOverlay } from 'components/VideoRecommendationOverlay/VideoRecommendationOverlay';
import { setShakaControlsBarVisibility } from 'library/VideoPlayer/helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectPlayerAsset,
  selectPlayerNextEpisode,
} from 'store/player/selectors';
import {
  Watchable,
  isAssetWatchable,
} from '@laminar-product/client-commons-core/core';
import UpgradeNextEpisode from 'components/NextEpisodeComponents/UpgradeNextEpisode';
import AutoplayNextEpisode from 'components/NextEpisodeComponents/AutoplayNextEpisode';
import { useMoveToAssetPlayer } from 'utils/useMoveToAssetPlayer';
import useUpgradeToWatch from 'utils/useUpgradeToWatch';
import styles from './index.module.scss';
interface VideoPlayerEndingFrameProps {
  onWatchCredits: () => void;
}

const VideoPlayerEndingFrame = ({
  onWatchCredits,
}: VideoPlayerEndingFrameProps) => {
  const asset = useSelector(selectPlayerAsset);
  const nextEpisode = useSelector(selectPlayerNextEpisode);
  const { handleUpgradeToWatch } = useUpgradeToWatch();
  const moveToAssetPlayer = useMoveToAssetPlayer();

  const getCreditsFrameBody = () => {
    if (!asset) {
      return;
    }

    if (isAssetWatchable(nextEpisode?.watchable) && nextEpisode) {
      return (
        <AutoplayNextEpisode
          nextEpisodeDetails={nextEpisode}
          onWatchCredits={onWatchCredits}
          showName={asset.root?.name}
          showId={asset.rootId}
          moveToAssetPlayer={moveToAssetPlayer}
        />
      );
    }

    if (nextEpisode?.watchable === Watchable.PLAN_UPGRADE_REQUIRED) {
      return (
        <UpgradeNextEpisode
          onUpgradeToWatch={handleUpgradeToWatch}
          onWatchCredits={onWatchCredits}
        />
      );
    }

    return (
      <VideoRecommendationOverlay
        asset={asset}
        setRecommendationOverlayVisible={onWatchCredits}
        overlayClassName={styles.recommendationOverlay}
      />
    );
  };

  useEffect(() => {
    setShakaControlsBarVisibility(false);
    return () => setShakaControlsBarVisibility(true);
  }, []);

  return <>{getCreditsFrameBody()}</>;
};

export default VideoPlayerEndingFrame;

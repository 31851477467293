import {
  PlayerSourceServiceParams,
  PrepareAssetPlaybackProps,
} from './utils/types';

export class LiveSourceService implements PlayerSourceServiceParams {
  canSeek = false;
  shouldDisplayDuration = false;
  canChangeTracks = false;
  shouldDisplayTimeRemaining = false;
  isLive = true;
  shouldDisplayCurrentTime = false;
  prepareAssetPlayback = ({
    videoFile,
    playbackData,
  }: PrepareAssetPlaybackProps) =>
    playbackData
      ? {
          source: {
            src: playbackData?.url,
            type: videoFile.hls.type,
          },
        }
      : undefined;
}

import PageLayoutWrapper from 'layouts/PageLayoutWrapper';
import Jumbotron from 'library/Jumbotron';
import CarouselWrapper from 'library/Carousel/CarouselWrapper';
import cn from 'classnames';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import { getDynamicCarouselType } from 'utils/carouselHelpers';
import CarouselSkeleton from 'library/Carousel/CarouselSkeleton';
import {
  PAGE_COMPONENTS_FOOTER_GAP,
  PAGE_COMPONENTS_PAGINATION_LIMIT,
} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { PAGE_FETCHING_STATUS, isFinalFetchingStatus } from 'store/pages/types';
import useFetchPage from './helpers/useFetchPage';
import usePageDetails from './helpers/usePageDetails';
import usePageScroll from './helpers/usePageScroll';
import styles from './index.module.scss';

const Page = () => {
  const { t } = useTranslation();
  const {
    isLoadingPage,
    handleFetchNextComponentsPage,
    page,
    pageFetchingStatus,
  } = useFetchPage();

  const { carousels, jumbotron, subheader } = usePageDetails();

  usePageScroll({
    handleFetchNextComponentsPage,
    bottomOffset: PAGE_COMPONENTS_FOOTER_GAP,
  });

  //Display skeleton only if there more than one page of components
  const shouldDisplaySkeleton =
    page &&
    page.components.count > PAGE_COMPONENTS_PAGINATION_LIMIT &&
    !isFinalFetchingStatus(pageFetchingStatus);

  //First loader when entering the page
  if (isLoadingPage) {
    return (
      <PageLayoutWrapper>
        <AppLoadingIndicator className={styles.pageLoading} />
      </PageLayoutWrapper>
    );
  }

  if (pageFetchingStatus === PAGE_FETCHING_STATUS.FAILED) {
    return (
      <PageLayoutWrapper>
        <div
          className={styles.pageErrorWrapper}
          data-testid="Page__ErrorMessage"
        >
          <h2>{t('errors.pageNotAvailableHeader')}</h2>
          <p className={styles.pageErrorMessage}>
            {t('errors.pageNotAvailable')}
          </p>
        </div>
      </PageLayoutWrapper>
    );
  }

  return (
    <PageLayoutWrapper
      subheader={subheader}
      menuPosition={jumbotron ? 'absolute' : 'static'}
    >
      {jumbotron?.body && <Jumbotron asset={jumbotron.body} />}

      <div
        className={cn(styles.carouselsWrapper, {
          [styles.carouselsWithJumbotronWrapper]: !!jumbotron,
        })}
      >
        {carousels.map(({ body, uuid, type }, i) => (
          <CarouselWrapper
            carouselType={getDynamicCarouselType(type)}
            key={uuid ? uuid + i : i}
            collection={body}
            id={uuid}
            testId={`Collection__${uuid}`}
          />
        ))}

        {shouldDisplaySkeleton && <CarouselSkeleton />}
      </div>
    </PageLayoutWrapper>
  );
};

export default Page;

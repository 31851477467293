import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { Filters } from 'types/filters';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useGetSearchParams from 'utils/useGetSearchParams';
import { MoveToListingParams } from 'utils/useMoveToListing';
import { Button } from '@laminar-product/client-commons-core/web';
import {
  useFetchAction,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { getMoods } from 'actions/search';
import FilterModal from './FilterModal';
import styles from './index.module.scss';

export interface SortingSelectorProps {
  onApply: (filters: Filters) => void;
  filters: Filters;
}

const FiltersSelector = ({
  onApply,
  filters,
  ...props
}: SortingSelectorProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  const { isTabletWindowSize } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<Filters>(filters);
  const { t } = useTranslation();
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const history = useHistory();
  const { bcpath, sort, genre } = useGetSearchParams() as MoveToListingParams;
  const [fetchedMood] = useFetchAction(getMoods);
  const countFilters = useCallback(
    () => Object.values(selectedFilters).flat().length,
    [selectedFilters]
  );

  useEffect(() => {
    setFiltersCount(countFilters);
  }, [countFilters]);

  return (
    <>
      <div {...props}>
        <Button
          className={styles.root}
          variant="secondary"
          onClick={() => setIsModalOpen(true)}
        >
          {!isTabletWindowSize && t('filter.filters')} <FilterIcon />
          {filtersCount > 0 && (
            <span className={styles.filtersBadge}>{filtersCount}</span>
          )}
        </Button>
      </div>

      {isModalOpen && (
        <FilterModal
          onSelectFilters={setSelectedFilters}
          onModalClose={() => setIsModalOpen(false)}
          selectedFilters={selectedFilters}
          initialFilters={filters}
          onApply={() => {
            if (filtersCount > 0 || (filtersCount === 0 && (genre || sort))) {
              onApply(selectedFilters);
            }

            if (filtersCount === 0 && !genre && !sort && bcpath) {
              history.push(bcpath);
            }

            setIsModalOpen(false);
          }}
          moods={fetchedMood}
        />
      )}
    </>
  );
};
export default FiltersSelector;

import { Controller, useForm } from 'react-hook-form';
import { AuthInputType, validateAuthInput } from 'utils/auth';
import { useDispatch } from 'react-redux';
import {
  setRegistrationMethod,
  setUserLogin,
} from 'store/registration/actions';
import usePathWithParams from 'utils/usePathWithParams';
import { upgradePlanParamId } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import CountryCodePhoneInput from 'components/CountryCodePhoneInput';
import styles from './index.module.scss';

export interface SignUpPhoneFormData {
  userPhoneNumber: string;
}

const SignUpPhoneNumber = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpPhoneFormData>();
  const { push, location } = useHistory();
  const dispatch = useDispatch();
  const getPath = usePathWithParams();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const onSubmit = ({ userPhoneNumber }: SignUpPhoneFormData) => {
    dispatch(setUserLogin(userPhoneNumber));
    dispatch(setRegistrationMethod('phone'));

    const getUrl = (url: string) =>
      getPath(url, !!queryParams.get(upgradePlanParamId));

    push(getUrl('/register/phone-verify'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.input}>
        <Controller
          name="userPhoneNumber"
          defaultValue=""
          control={control}
          rules={{
            validate: (value) => validateAuthInput(value, AuthInputType.PHONE),
          }}
          render={({ field: { onChange, value } }) => (
            <CountryCodePhoneInput
              value={value}
              onChange={onChange}
              className={styles.phoneField}
            />
          )}
        />
      </div>

      {!!errors.userPhoneNumber && (
        <p className={styles.errorMessage}>{errors.userPhoneNumber?.message}</p>
      )}

      <Button className={styles.registerButton} variant="primary" type="submit">
        {t('common.continue')}
      </Button>
    </form>
  );
};

export default SignUpPhoneNumber;

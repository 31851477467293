import { User } from '@sentry/react';

export interface PlatformPartnerMetadata {
  id: string;
  name: string;
}

export interface UserDetails {
  data: User;
  country: string;
  platformPartner?: PlatformPartnerMetadata;
  isPartnerUser?: boolean;
}

export enum UserManagingProfilesVariant {
  MANAGE = 'MANAGE',
}

import { useCallback } from 'react';
import { useLocation } from 'react-router';

const usePathWithParams = () => {
  const location = useLocation();

  return useCallback(
    (path: string, withParams = true) =>
      withParams && location.search ? `${path}${location.search}` : path,
    [location.search]
  );
};

export default usePathWithParams;

import { Asset, CatalogAsset } from '@laminar-product/client-commons-core/core';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMoveToAssetDetails } from './useMoveToAssetDetails';

export interface UseRedirectToCatalogProps {
  asset?: Asset;
  isValidationError?: boolean;
}

export const getAssetCatalogPath = (asset?: Asset, currentPath?: string) =>
  asset?.paths?.catalogPath || `/catalog${currentPath}`;

export const useRedirectToCatalog = ({
  asset,
  isValidationError,
}: UseRedirectToCatalogProps) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isValidationError) return;

    //Note: Create manually path if no paths property in asset (e.g. enter manually to asset URL)
    const path = getAssetCatalogPath(asset, pathname);

    replace(path);
  }, [isValidationError, replace, asset, pathname]);
};

export interface UseRedirectToAssetProps {
  asset?: Asset | CatalogAsset;
}

export const useRedirectToAsset = ({ asset }: UseRedirectToAssetProps) => {
  const moveToAsset = useMoveToAssetDetails();

  useEffect(() => {
    if (!asset || !asset.inRegion) {
      return;
    }

    moveToAsset(asset, 'replace');
  }, [asset, moveToAsset]);
};

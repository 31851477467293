import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import {
  ContentFrame,
  adCountdown,
} from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import { AdTrackEventPayload } from 'types/player';
import { isValueInRange } from 'utils/helpers';
import { usePrevious } from '@laminar-product/client-commons-core/hooks';
import styles from './index.module.scss';

interface AdsCounterProps {
  frame: ContentFrame;
  playerCurrentTime: number;
  onAdStart: (data: AdTrackEventPayload) => void;
  onAdEnd: (data: AdTrackEventPayload) => void;
  className?: string;
  isPlayerPaused?: boolean;
  canSendEvents?: boolean;
  onAdPause?: (data: AdTrackEventPayload) => void;
}

const AdsCounter = ({
  frame,
  playerCurrentTime,
  onAdStart,
  onAdEnd,
  className,
  isPlayerPaused,
  canSendEvents,
  onAdPause,
}: AdsCounterProps) => {
  const { t } = useTranslation();
  //One ad pod can contain multiple ads inside
  const currentAdId = useMemo(
    () =>
      frame.ads?.find((ad) =>
        isValueInRange(playerCurrentTime, ad.start, ad.end)
      )?.id,
    [frame.ads, playerCurrentTime]
  );
  const startedAdId = usePrevious(currentAdId); // this is necessary as when ad ends, the currentAdId is already null

  const adCountdownTime = adCountdown(frame, playerCurrentTime);

  useEffect(() => {
    if (isPlayerPaused || !frame.id || !currentAdId || !canSendEvents) {
      return;
    }

    const config = { adId: currentAdId, adPodId: frame.id };
    onAdStart(config);
  }, [onAdStart, currentAdId, frame.id, canSendEvents, isPlayerPaused]);

  useEffect(() => {
    const shouldCallAdEnd =
      !!startedAdId && currentAdId !== startedAdId && !!frame.id;

    if (!shouldCallAdEnd) return;
    const config = { adId: startedAdId, adPodId: frame.id! };
    onAdEnd(config);
  }, [currentAdId, startedAdId, frame.id, onAdEnd]);

  useEffect(() => {
    if (!isPlayerPaused || !currentAdId || !frame.id) {
      return;
    }

    onAdPause?.({ adId: currentAdId, adPodId: frame.id });
  }, [currentAdId, frame.id, isPlayerPaused, onAdPause]);

  return (
    <div className={cn(styles.overlay, className)} data-testid="AdsCounter">
      <div className={styles.wrapper}>
        <span className={cn(styles.adInfoText, styles.adTimeCounter)}>
          {t('player.avod.adLabel')} {format(adCountdownTime * 1000, 'mm:ss')}
        </span>
        <span className={styles.adInfoText}>
          {t('player.avod.adExtraInfo')}
        </span>
      </div>
    </div>
  );
};

export default AdsCounter;

import { useEffect } from 'react';
import { ContinueWatchingEvent } from '@laminar-product/client-commons-core/core';
import useVideoPlayerContinueWatching from './useVideoPlayerContinueWatching';

const useSaveMovieBookmark = () => {
  const { onSaveContinueWatching, checkIfFinished } =
    useVideoPlayerContinueWatching();

  useEffect(() => {
    return () => {
      const finished = checkIfFinished();
      onSaveContinueWatching(
        finished ? ContinueWatchingEvent.FINISHED : ContinueWatchingEvent.CLOSED
      );
    };
  }, [checkIfFinished, onSaveContinueWatching]);
};

export default useSaveMovieBookmark;

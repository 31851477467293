import React, { ReactNode } from 'react';
import Alert from 'components/Alert';
import { useSelector } from 'react-redux';
import { selectAppStoreLinks } from 'store/app/selectors';
import { isIos } from 'utils/getUserOs';
import { useTranslation } from 'react-i18next';
import googlePlayStoreBadge from '../../assets/images/google-play-badge.png';
import appStoreBadge from '../../assets/images/app-store-badge.png';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import styles from './index.module.scss';

const LinkToStore = ({
  link,
  children,
}: {
  link?: string;
  children?: ReactNode;
}) => (
  <a className={styles.mobileStoreLink} href={link}>
    {children}
  </a>
);

interface MobileStoreBadgeProps {
  onClose: () => void;
}

const MobileStoreBadge = React.forwardRef<
  HTMLDivElement,
  MobileStoreBadgeProps
>(({ onClose }, ref) => {
  const appStoreLinks = useSelector(selectAppStoreLinks);
  const { t } = useTranslation();
  const isIosPlatform = isIos();

  return (
    <div
      ref={ref}
      data-testid="mobile-alert"
      className={styles.mobileAlertWrapper}
    >
      <Alert type="mobile">
        <div className={styles.alertContainer}>
          <div>
            <h2 className={styles.title}>{t('mobile.alert.getTheApp')}</h2>
            <p className={styles.subTitle}>
              {t('mobile.alert.toWatchOnMobile')}
            </p>
          </div>
          <div className={styles.badgeContainer}>
            <LinkToStore
              link={
                isIosPlatform
                  ? appStoreLinks?.appstore
                  : appStoreLinks?.playstore
              }
            >
              <img
                src={isIosPlatform ? appStoreBadge : googlePlayStoreBadge}
                height="36px"
                alt={isIosPlatform ? 'app store' : 'google play store'}
              />
            </LinkToStore>
            <CloseIcon
              data-testid="close-button"
              onClick={onClose}
              className={styles.closeButton}
            />
          </div>
        </div>
      </Alert>
    </div>
  );
});

export default MobileStoreBadge;

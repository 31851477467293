import {
  CalculatePriceCouponResponse,
  Currency,
  EXTERNAL_PAYMENT_PROVIDERS,
  IntervalType,
  PromotionType,
} from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import { selectUserSubscription } from 'store/user/selectors';
import { Subscription } from 'types/subscription';

interface FormatPrice {
  amount: number;
  currency: Currency;
  interval?: IntervalType;
}

export const formatPriceWithInterval = (
  price?: FormatPrice,
  intervalLabel?: string
) => {
  if (!price || !price.amount || !price.currency) return '';

  let formattedPrice = `${price.amount / 100} ${price.currency}`;
  if (price.interval) {
    formattedPrice += `/${intervalLabel}`;
  }
  return formattedPrice;
};

export const formatPrice = (price?: number, currency?: string) => {
  if (price === undefined || !currency) return '';

  const dividedPrice = price / 100;

  const formattedPrice = dividedPrice.toFixed(
    Number.isInteger(dividedPrice) ? 0 : 2
  );

  return `${formattedPrice} ${currency.toUpperCase()}`;
};

export const checkForCouponApplied = (
  calculatedPriceCoupon: CalculatePriceCouponResponse
) => {
  const isNetPrice =
    calculatedPriceCoupon &&
    calculatedPriceCoupon.netPrice !== undefined &&
    calculatedPriceCoupon.netPrice !== null;

  const isCouponDiscount =
    calculatedPriceCoupon &&
    calculatedPriceCoupon.discounts?.find(
      (discount) => discount.promotionType === PromotionType.COUPON
    );

  if (isNetPrice && isCouponDiscount) {
    return true;
  }

  return false;
};

export const canChangeSubscription = (subscription?: Subscription) =>
  !!subscription && !!subscription.gateway
    ? !EXTERNAL_PAYMENT_PROVIDERS.includes(subscription?.gateway)
    : true;

export const canRedeemPin = (
  subscription?: Subscription,
  isPartnerUser?: boolean
) => (!subscription || canChangeSubscription(subscription)) && !isPartnerUser;

interface useCheckIfCurrentPlanProps {
  planUuid?: string;
  priceUuid?: string;
}

export const useCheckIfCurrentPlan = ({
  planUuid,
  priceUuid,
}: useCheckIfCurrentPlanProps) => {
  const userCurrentSubscription = useSelector(selectUserSubscription);

  return (
    !!priceUuid &&
    !!planUuid &&
    userCurrentSubscription?.plan?.uuid === planUuid &&
    userCurrentSubscription?.price?.uuid === priceUuid
  );
};

import { getConfiguration } from 'actions/basic';
import {
  fetchMenuBasedOnInterests,
  getAvailableInterests,
} from 'actions/interests';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentProfile,
  selectCurrentProfileHasInterests,
  selectCurrentProfileInterests,
} from 'store/user/selectors';
import { Config } from '@laminar-product/client-commons-core/core';
import { useQuery } from '@tanstack/react-query';

const getConfigAndInterests = async () => {
  const [config, interests] = await Promise.all([
    getConfiguration(),
    getAvailableInterests(),
  ]);

  return {
    ...config,
    interests,
  };
};

const usePrepareAppSettings = () => {
  const [finalConfig, setFinalConfig] = useState<Config>();
  const {
    data: fetchedConfig,
    isLoading: loadingConfig,
    error: loadingConfigurationError,
  } = useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const config = await getConfigAndInterests();
      setFinalConfig(config);
      return config;
    },
  });
  const currentProfile = useSelector(selectCurrentProfile);
  const profileHasInterests = useSelector(selectCurrentProfileHasInterests);
  const interests = useSelector(selectCurrentProfileInterests);
  const handleProfileInterests = useCallback(async () => {
    if (!interests || !fetchedConfig) return;
    const menu = await fetchMenuBasedOnInterests(interests);
    setFinalConfig(() => ({ ...fetchedConfig, menuLinks: menu }));
    return menu;
  }, [fetchedConfig, interests]);

  const { isLoading: menuIsLoading, refetch: reloadMenu } = useQuery({
    queryKey: ['menu', currentProfile?.uuid],
    queryFn: handleProfileInterests,
    enabled:
      fetchedConfig?.interestEnabled &&
      fetchedConfig?.interests &&
      profileHasInterests &&
      !!interests?.length,
  });

  useEffect(() => {
    reloadMenu();
  }, [interests, reloadMenu]);

  return {
    config: finalConfig,
    isLoadingConfiguration: !finalConfig || loadingConfig || menuIsLoading,
    loadingConfigurationError,
  };
};

export default usePrepareAppSettings;

import { Asset, AssetType } from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import useRedirectToRootAsset, {
  shouldRedirectToRoot,
} from './useRedirectToRootAsset';
import { useMoveToAssetPlayer } from './useMoveToAssetPlayer';

const useRedirectOnWatch = () => {
  const redirectToRootAsset = useRedirectToRootAsset();
  const moveToAssetPlayer = useMoveToAssetPlayer();

  const redirectOnWatch = useCallback(
    async (item: Asset) => {
      const locationState = {
        state: {
          showId: item?.type !== AssetType.MOVIE ? item.id : undefined,
        },
      };

      if (shouldRedirectToRoot(item.type)) {
        redirectToRootAsset({ asset: item, type: 'Player' });
        return;
      }

      const { id, type, administrativeName } = item;

      moveToAssetPlayer(id, type, administrativeName, locationState);
    },
    [moveToAssetPlayer, redirectToRootAsset]
  );

  return redirectOnWatch;
};

export default useRedirectOnWatch;

import { User, getAuth, onIdTokenChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFirebaseUser, setUserVerified } from 'store/user/actions';
import { parseJwt } from 'utils/parseJWT';
import useChangeProfileLanguage from './useChangeProfileLanguage';

const useFirebaseAuth = () => {
  const dispatch = useDispatch();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const auth = getAuth();
  const { changeProfileLanguage } = useChangeProfileLanguage();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, listener);
    return unsubscribe;

    async function listener(user: User | null) {
      dispatch(setFirebaseUser(user));
      user?.getIdToken().then((token) => {
        const decodedToken = parseJwt(token);
        dispatch(setUserVerified(!!decodedToken?.userVerified));
      });

      //Handles language for anonymous user and after sign out
      if (user?.isAnonymous) {
        await changeProfileLanguage(null);
      }

      setIsLoadingUser(false);
    }
  }, [auth, changeProfileLanguage, dispatch]);

  return { isLoadingUser };
};

export default useFirebaseAuth;

import React, { useCallback, useState } from 'react';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { useTranslation } from 'react-i18next';
import ProfileCreate from 'pages/Settings/components/Profiles/ProfileCreate';
import { Modal } from '@laminar-product/client-commons-core/web';
import ProfileEdit from 'pages/Settings/components/Profiles/ProfileEdit';
import ProfileName from 'components/ProfileNameWrapper';
import ProfileAvatar from 'components/ProfileAvatar';
import { checkIsKidsProfile, useSetCurrentProfile } from 'utils/profiles';
import { Profile } from '@laminar-product/client-commons-core/core';
import ProfilePinCheck from '../ProfilePinCheck';
import styles from './index.module.scss';

interface ProfileSelectorItemProps {
  profile?: Profile;
  newProfile?: boolean;
  onProfileCreate?: (profile: Profile) => void;
  isEditMode?: boolean;
}

const ProfileSelectorItem = ({
  newProfile,
  profile,
  onProfileCreate,
  isEditMode,
}: ProfileSelectorItemProps) => {
  const { t } = useTranslation();
  const [newProfileModalOpen, setNewProfileOpen] = useState<boolean>(false);
  const [pinCheckModalOpen, setPinCheckModalOpen] = useState<boolean>(false);
  const [editProfileModalOpen, setEditProfileModalOpen] =
    useState<boolean>(false);
  const setCurrentProfile = useSetCurrentProfile();

  const handleProfileClick = () => {
    if (newProfile) {
      setNewProfileOpen(true);
    } else {
      checkIfLocked();
    }
  };

  const checkIfLocked = () => {
    if (profile) {
      if (profile.pin) {
        setPinCheckModalOpen(true);
      } else {
        if (isEditMode) {
          setEditProfileModalOpen(true);
        } else {
          setCurrentProfile(profile);
        }
      }
    }
  };

  const onPinCorrect = useCallback(() => {
    if (!profile) return;

    setCurrentProfile(profile);
    setPinCheckModalOpen(false);
  }, [profile, setCurrentProfile]);

  return (
    <>
      <div className={styles.profileItem} onClick={handleProfileClick}>
        {newProfile ? (
          <ProfileAvatar newProfile enableHover />
        ) : (
          <ProfileAvatar
            isEditMode={isEditMode}
            name={profile?.name}
            enableHover
            isKidsProfile={checkIsKidsProfile(profile)}
          />
        )}

        {profile?.name ? (
          <div className={styles.profileName}>
            <ProfileName text={profile.name} />
          </div>
        ) : (
          newProfile && (
            <div className={styles.profileName}>
              {t('profile.addNewProfile')}
            </div>
          )
        )}
        {profile?.pin && <LockIcon className={styles.lockIcon} />}
      </div>

      <Modal wrapperId="profileCreateModal" isOpen={newProfileModalOpen}>
        <ProfileCreate
          onClose={() => setNewProfileOpen(false)}
          onProfileCreate={onProfileCreate}
        />
      </Modal>

      <Modal wrapperId="profilePinCheckModal" isOpen={pinCheckModalOpen}>
        <ProfilePinCheck
          onPinCorrect={onPinCorrect}
          isEditMode={isEditMode}
          profile={profile}
          onClose={() => setPinCheckModalOpen(false)}
        />
      </Modal>
      <Modal wrapperId="profileEditModal" isOpen={editProfileModalOpen}>
        <ProfileEdit
          profile={profile}
          onClose={() => setEditProfileModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ProfileSelectorItem;

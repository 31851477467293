import { Modal } from '@laminar-product/client-commons-core/web';
import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
interface PopupProps {
  header: string;
  message: string;
  actionButtons?: React.ReactNode;
  extraContent?: React.ReactNode;
  wrapperClassName?: string;
  buttonsWrapperClassName?: string;
  zIndex?: number;
}

const Popup = ({
  header,
  message,
  actionButtons,
  extraContent,
  wrapperClassName,
  buttonsWrapperClassName,
  zIndex,
}: PopupProps) => (
  <Modal
    zIndex={zIndex}
    className={styles.modal}
    wrapperId={`popup_${header}`}
    isOpen
  >
    <div className={cn(styles.wrapper, wrapperClassName)}>
      <p className={styles.header}>{header}</p>
      <div className={styles.message}>{message}</div>
      {extraContent && (
        <div className={styles.extraContent}>{extraContent}</div>
      )}
      <div className={cn(styles.buttons, buttonsWrapperClassName)}>
        {actionButtons}
      </div>
    </div>
  </Modal>
);

export default Popup;

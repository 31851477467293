import { getUserData, getUserProvider } from 'actions/basic';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from 'store/user/actions';
import { User as FirebaseUser } from 'firebase/auth';
import { selectIsVerified, selectUserDetails } from 'store/user/selectors';
import { cancelable } from 'cancelable-promise';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import {
  PartnerUserProvider,
  User,
} from '@laminar-product/client-commons-core/core';
import { useQuery } from '@tanstack/react-query';
import { captureError } from './captureError';

const useFetchUserData = (firebaseUser?: FirebaseUser) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserDetails);
  const verified = useSelector(selectIsVerified);

  const getUserDetails = useCallback(async (): Promise<
    | ({ data: User; country: string } & Partial<{
        platformPartner: PartnerUserProvider;
      }>)
    | undefined
  > => {
    try {
      const userData = await getUserData();
      const providerData = userData.data.isPartnerUser
        ? await getUserProvider()
        : undefined;
      return {
        ...userData,
        country: userData.country,
        ...(providerData && {
          platformPartner: providerData,
        }),
      };
    } catch (e) {
      captureError(e as AxiosError, 'getUserDetails', Severity.Error);
    }
  }, []);

  const refreshUserData = useCallback(() => {
    getUserDetails().then((payload) => {
      if (!payload) return;
      dispatch(setUserDetails(payload));
    });
  }, [dispatch, getUserDetails]);

  const setUserDetailsBasedOnFirebase = useCallback(() => {
    const fetch = cancelable(getUserDetails());
    if (firebaseUser && !firebaseUser.isAnonymous && verified) {
      fetch.then((payload) => {
        if (!payload) return;
        dispatch(setUserDetails(payload));
      });

      return () => {
        fetch.cancel();
      };
    }
    dispatch(setUserDetails());
  }, [dispatch, firebaseUser, getUserDetails, verified]);

  useQuery({
    queryKey: ['setUserDetailsBasedOnFirebase', firebaseUser?.uid],
    queryFn: setUserDetailsBasedOnFirebase,
    enabled: !userData,
  });

  return {
    userData,
    refreshUserData,
  };
};

export default useFetchUserData;

import { useTranslation } from 'react-i18next';
import { RadioInput } from '@laminar-product/client-commons-core/web';
import cn from 'classnames';
import { AvailableTracks, Track } from 'store/chromecast/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChromecastActiveAudioTrackId,
  selectChromecastActiveTextTrackId,
  selectChromecastCastElement,
} from 'store/chromecast/selectors';
import {
  setChromecastActiveAudioTrackId,
  setChromecastActiveTextTrackId,
} from 'store/chromecast/actions';
import {
  CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE,
  CHROMECAST_TRACK_LABEL_MAX_LENGTH,
} from 'utils/constants';
import _truncate from 'lodash/truncate';
import styles from './index.module.scss';

interface TrackSelectorProps {
  availableTracks: AvailableTracks;
}

const TrackSelector = ({ availableTracks }: TrackSelectorProps) => {
  const { t } = useTranslation();
  const { audioTracks, textTracks } = availableTracks;
  const castElement = useSelector(selectChromecastCastElement);
  const dispatch = useDispatch();
  const activeTextTrackId = useSelector(selectChromecastActiveTextTrackId);
  const activeAudioTrackId = useSelector(selectChromecastActiveAudioTrackId);

  const onTextTrackChange = useCallback(
    (newTextTrackId: number) => {
      if (!castElement) {
        return;
      }

      dispatch(setChromecastActiveTextTrackId(newTextTrackId));
      const audioArray = activeAudioTrackId ? [activeAudioTrackId] : [];

      //Disable text track, pass only audio if exists
      if (newTextTrackId === CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE) {
        return castElement.changeCurrentTracks(audioArray);
      }

      //Update text track ids
      castElement.changeCurrentTracks([...audioArray, newTextTrackId]);
    },
    [castElement, dispatch, activeAudioTrackId]
  );

  const onAudioTrackChange = useCallback(
    (newAudioTrackId: number) => {
      if (!castElement) {
        return;
      }

      dispatch(setChromecastActiveAudioTrackId(newAudioTrackId));

      castElement.changeCurrentTracks(
        activeTextTrackId &&
          activeTextTrackId !== CHROMECAST_TEXT_TRACK_OFF_INDEX_VALUE
          ? [activeTextTrackId, newAudioTrackId]
          : [newAudioTrackId]
      );
    },
    [activeTextTrackId, castElement, dispatch]
  );

  return (
    <div className={styles.trackSelectorWrapper}>
      {textTracks && (
        <div>
          <strong>{t('chromecast.subtitles')}</strong>

          <ul className={styles.tracks}>
            {textTracks.map(({ name, trackId }: Track) => (
              <li
                key={trackId}
                className={cn(styles.track, {
                  [styles.activeTrack]: trackId === activeTextTrackId,
                })}
              >
                <RadioInput
                  checked={trackId === activeTextTrackId}
                  onChange={() => onTextTrackChange(trackId)}
                  label={_truncate(name, {
                    length: CHROMECAST_TRACK_LABEL_MAX_LENGTH,
                  })}
                  classes={{ icon: styles.radioIcon }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      {audioTracks && (
        <div>
          <strong>{t('chromecast.audio')}</strong>
          <ul className={styles.tracks}>
            {audioTracks.map(({ trackId, name }: Track) => (
              <li
                key={trackId}
                className={cn(styles.track, {
                  [styles.activeTrack]: trackId === activeAudioTrackId,
                })}
              >
                <RadioInput
                  checked={trackId === activeAudioTrackId}
                  onChange={() => onAudioTrackChange(trackId)}
                  label={_truncate(name, {
                    length: CHROMECAST_TRACK_LABEL_MAX_LENGTH,
                  })}
                  classes={{ icon: styles.radioIcon }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TrackSelector;

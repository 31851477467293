import React from 'react';
import Checkbox from 'components/Checkbox';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const FilterList = ({
  title,
  data,
  translationPrefix,
  onSelect,
  selectedFilters,
}: {
  title: string;
  onSelect: (data: string) => void;
  selectedFilters: string[];
  translationPrefix?: string;
  data?: string[];
}) => {
  const { t } = useTranslation();
  const prefix = translationPrefix ? `${translationPrefix}.` : '';

  if (!data || data?.length < 1) return null;

  return (
    <div className={styles.modalList}>
      <span className={styles.modalListTitle}>{title}</span>
      <ul className={styles.modalListElements}>
        {data.map((d: string) => (
          <li key={d} className={styles.modalListElement}>
            <Checkbox
              defaultChecked={selectedFilters.includes(d)}
              label={t(`${prefix}${d}`, { defaultValue: d })}
              onClick={() => onSelect(d)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default FilterList;

import PosterList from 'components/PosterList';
import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { prepareMetaForPage } from 'utils/meta';
import PageLayoutWrapper from 'layouts/PageLayoutWrapper';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import useWatchlist from 'utils/useWatchlist';
import styles from './index.module.scss';
import EmptyWatchlist from './components/EmptyWatchlist';

const Watchlist = () => {
  const { t } = useTranslation();
  const { watchlistLoadingState, watchlistAssets } = useWatchlist();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppMeta({ og: prepareMetaForPage(t('watchlist.title')) }));
  }, [dispatch, t]);

  if (watchlistLoadingState === 'loading') {
    return (
      <PageLayoutWrapper className={cn(styles.root, styles.center)}>
        <AppLoadingIndicator />
      </PageLayoutWrapper>
    );
  }

  return (
    <PageLayoutWrapper className={styles.root}>
      <h1 className={styles.heading}>{t('watchlist.title')}</h1>
      {!watchlistAssets?.length ? (
        <EmptyWatchlist />
      ) : (
        <PosterList assets={watchlistAssets} total={watchlistAssets.length} />
      )}
    </PageLayoutWrapper>
  );
};

export default Watchlist;

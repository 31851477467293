import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import { ProfileBasic, ProfileContentRating } from 'types/profiles';
import { saveProfile } from 'actions/profiles';
import {
  DEFAULT_MATURITY_VALUE,
  DEFAULT_USER_LANGUAGE,
  MATURITY_KIDS_VALUE,
} from 'utils/constants';
import LinearProfileWrapper from 'components/LinearProfileWrapper';
import ProfileModalHeader from 'components/ProfileModalHeader';
import ProfileAvatar from 'components/ProfileAvatar';
import { Profile } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import useLanguages from 'utils/useLanguages';
import { Button } from '@laminar-product/client-commons-core/web';
import { getContentRatingOptions } from 'utils/profiles';
import { DropdownOption } from 'types/dropdown';
import SettingsDropdownItem from 'components/SettingsDropdownItem';
import CloseProfileModal from 'components/CloseProfileModal';
import styles from './index.module.scss';

interface ProfileModalProps {
  onClose: () => void;
  onProfileCreate?: (profile: Profile) => void;
}

const ProfileCreateModal = ({
  onClose,
  onProfileCreate,
}: ProfileModalProps) => {
  const { t } = useTranslation();
  const [profileContentRating, setProfileContentRating] =
    useState<DropdownOption>();
  const [profileName, setProfileName] = useState<string>('');
  const [profileLanguage, setProfileLanguage] = useState<string>(
    DEFAULT_USER_LANGUAGE
  );
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { availableLanguageSelectOptions, formatLanguageSelectValue } =
    useLanguages();
  const contentRatingOptions = getContentRatingOptions();

  const createProfile = () => {
    if (!profileName) {
      setError(t('common.validations.required'));
      return;
    }
    setIsLoading(true);

    //for now (backend requirements): we set the default language to the one returned from i18next,
    //we set the default maturityRating - for kidProfile 7 or 21 otherwise
    const profileToSave: ProfileBasic = {
      name: profileName,
      language: profileLanguage,
      maturityRating:
        profileContentRating?.value === ProfileContentRating.KIDS
          ? MATURITY_KIDS_VALUE
          : DEFAULT_MATURITY_VALUE,
    };

    saveProfile(profileToSave)
      .then((result) => {
        setIsLoading(false);
        if (onProfileCreate) {
          onProfileCreate(result);
        }
        onClose();
      })
      .catch((e) => {
        captureError(
          e as AxiosError,
          'ProfileCreate/saveProfile',
          Severity.Warning,
          { extra: { profileToSave } }
        );
        setIsLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    if (!contentRatingOptions || profileContentRating) {
      return;
    }

    setProfileContentRating(contentRatingOptions[0]);
  }, [contentRatingOptions, profileContentRating]);

  return (
    <LinearProfileWrapper contentClassName={styles.wrapper}>
      <ProfileModalHeader text={t('profile.newProfile')} />
      <CloseProfileModal onClose={onClose} />

      {profileName ? (
        <ProfileAvatar
          name={profileName}
          isKidsProfile={
            profileContentRating?.value === ProfileContentRating.KIDS
          }
          className={styles.profileAvatar}
        />
      ) : (
        <ProfileAvatar newProfile className={styles.profileAvatar} />
      )}

      <Input
        className={styles.input}
        id="profileName"
        placeholder={t('payment.placeholder.cardholderName')}
        value={profileName}
        onChange={(e) => setProfileName(e.target.value)}
      />
      {error && <div className={styles.errorMessage}>{error}</div>}

      <SettingsDropdownItem
        title={t('settings.appLanguage')}
        options={availableLanguageSelectOptions}
        onChange={(value) => setProfileLanguage(value?.value)}
        selectedOption={formatLanguageSelectValue(profileLanguage)}
      />

      <span className={styles.divider} />

      <SettingsDropdownItem
        title={t('profile.contentRating')}
        options={contentRatingOptions}
        selectedOption={profileContentRating}
        onChange={setProfileContentRating}
      />

      <div className={styles.buttons}>
        <Button variant="secondary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button loading={isLoading} variant="primary" onClick={createProfile}>
          {t('common.save')}
        </Button>
      </div>
    </LinearProfileWrapper>
  );
};

export default ProfileCreateModal;

import { useSwipeable } from 'react-swipeable';

interface UseCarouselSwipeProps {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
}

const useCarouselSwipe = ({
  onSwipedLeft,
  onSwipedRight,
}: UseCarouselSwipeProps) => {
  const swipeHandlers = useSwipeable({
    onSwipedRight,
    onSwipedLeft,
    preventScrollOnSwipe: true,
  });

  return { swipeHandlers };
};

export default useCarouselSwipe;

import { AuthenticationProvider } from '@laminar-product/client-commons-core/core';
import { User, getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useAuthUserClaims = () => {
  const user = getAuth().currentUser;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    setClaims(user);

    async function setClaims(firebaseUser: User) {
      try {
        const result = await firebaseUser.getIdTokenResult();

        dispatch({
          type: 'SET_USER_AUTH_CLAIMS',
          payload: {
            isTimesSSOProvider: result.claims.authProvider?.includes(
              AuthenticationProvider.TIMES_SSO
            ),
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, user]);
};

export default useAuthUserClaims;

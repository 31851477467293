import { getDashConfig, getHlsConfig } from 'utils/drm';
import {
  PlayerSourceServiceParams,
  PrepareAssetPlaybackProps,
} from './utils/types';

export class VODSourceService implements PlayerSourceServiceParams {
  canSeek = true;
  shouldDisplayDuration = true;
  canChangeTracks = true;
  shouldDisplayTimeRemaining = true;
  isLive = false;
  shouldDisplayCurrentTime = true;
  prepareAssetPlayback = ({
    playbackData,
    videoFile,
  }: PrepareAssetPlaybackProps) => {
    if (!playbackData) {
      return;
    }

    if (playbackData.type === 'HLS') {
      return getHlsConfig(playbackData, videoFile);
    }

    return getDashConfig(playbackData, videoFile);
  };
}

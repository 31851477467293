import {
  Asset,
  AssetType,
  Content,
  ContentType,
} from '@laminar-product/client-commons-core/core';
import VideoPlayerComponent from 'library/VideoPlayer/VideoPlayerComponent';
import useVideoPlayerConfiguration from 'utils/useVideoPlayerConfiguration';
import useVideoPlayerCommonEvents from 'utils/useVideoPlayerCommonEvents';
import useVideoPlayerUI from 'utils/useVideoPlayerUI';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import { resetPlayer } from 'store/player/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectPlayerError } from 'store/player/selectors';
import usePlayerGoBack from 'utils/usePlayerGoBack';
import useBackHandler from 'utils/useBackHandler';
import VideoPlayerMovieComponent from '../VideoPlayerMovieComponent';
import { VideoPlayerBasicProps } from '../types';
import VideoPlayerShowComponent from '../VideoPlayerShowComponent';
import VideoPlayerLiveComponent from '../VideoPlayerLiveComponent';
import VideoPlayerError from '../VideoPlayerError';
import VideoPlayerOverlay from '../VideoPlayerOverlay';

interface VideoPlayerFactoryProps {
  content: Content;
  asset: Asset;
  loop?: boolean;
  playerId: string;
}

const VideoPlayerFactory = ({
  content,
  asset,
  loop,
  playerId,
}: VideoPlayerFactoryProps) => {
  const dispatch = useDispatch();
  const uiConfig = useVideoPlayerUI();
  const videoError = useSelector(selectPlayerError);
  const playerLoadConfiguration = useVideoPlayerConfiguration({
    assetId: asset.id,
    content,
    variant: 'primary',
  });

  const { onPlayerInitialized, onPlayerError, setPlayerState } =
    useVideoPlayerCommonEvents({
      asset,
      content,
      playerLoadConfiguration,
    });

  const goBackFromPlayer = usePlayerGoBack(asset);
  useBackHandler(goBackFromPlayer);

  const getVideoPlayerComponent = () => {
    if (!playerLoadConfiguration) {
      return null;
    }

    const commonProps: VideoPlayerBasicProps = {
      loadConfig: playerLoadConfiguration,
      loop,
      playerId,
      uiConfig,
      onPlayerInitialized,
      onPlayerError,
      setPlayerState,
      Overlay: <VideoPlayerOverlay />,
    };

    if (![ContentType.MAIN, ContentType.LIVE].includes(content.type)) {
      return <VideoPlayerComponent {...commonProps} />;
    }

    switch (asset.type) {
      case AssetType.MOVIE:
        return <VideoPlayerMovieComponent {...commonProps} />;
      case AssetType.EPISODE:
        return <VideoPlayerShowComponent {...commonProps} />;
      case AssetType.LIVE_EVENT:
      case AssetType.LIVE_CHANNEL:
        return <VideoPlayerLiveComponent {...commonProps} />;
      default:
        return <VideoPlayerComponent {...commonProps} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetPlayer());
    };
  }, [dispatch]);

  if (videoError) {
    return (
      <VideoPlayerError
        errorCode={videoError.errorCode}
        onClose={goBackFromPlayer}
      />
    );
  }

  if (!playerLoadConfiguration || !uiConfig) {
    return <AppLoadingIndicator fullScreen />;
  }

  return getVideoPlayerComponent();
};

export default VideoPlayerFactory;

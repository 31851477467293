import { ContentType } from '@laminar-product/client-commons-core/core';
import { VODSourceService } from './vod';
import { LiveSourceService } from './live';
import {
  PlayerSourceServiceParams,
  PlayerSourceServiceProps,
} from './utils/types';

const playerSourceService: (
  props: PlayerSourceServiceProps
) => PlayerSourceServiceParams = ({ contentType }) =>
  contentType === ContentType.LIVE
    ? new LiveSourceService()
    : new VODSourceService();

export default playerSourceService;

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { FeatureFlag } from 'utils/constants';
import _get from 'lodash/get';

const useFeatureEnabled = (featureFlag: FeatureFlag) => {
  const isFeatureEnabled = useSelector<RootState, boolean | undefined>(
    (state) => _get(state, `app.appSettings.${featureFlag}`)
  );

  return isFeatureEnabled;
};

export default useFeatureEnabled;

import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import createRootReducer from './reducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const composeEnhancers = composeWithDevTools({
  name: 'client',
});

export function configureStore() {
  const store = createStore(
    createRootReducer(),
    composeEnhancers(sentryReduxEnhancer)
  );
  return store;
}

const state = configureStore();
export default state;

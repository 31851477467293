import { Button } from '@laminar-product/client-commons-core/web';
import Popup from 'components/Popup';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCannotChangePlanModalVisible } from 'store/user/actions';
import { selectUserSubscription } from 'store/user/selectors';
import styles from './index.module.scss';

const CannotChangePlanModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subscription = useSelector(selectUserSubscription);

  const onConfirm = () => dispatch(setCannotChangePlanModalVisible(false));

  return (
    <Popup
      header={t('subscription.cannotChangeExternalPlanHeader')}
      message={t('settings.managePlan', {
        gateway: capitalize(subscription?.gateway),
      })}
      wrapperClassName={styles.modalWrapper}
      actionButtons={
        <Button variant="primary" onClick={onConfirm}>
          {t('common.OK')}
        </Button>
      }
      buttonsWrapperClassName={styles.buttonsWrapper}
      zIndex={1001} //More than episode list modal
    />
  );
};

export default CannotChangePlanModal;

import React from 'react';
import cn from 'classnames';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import ProfileName from 'components/ProfileNameWrapper';
import ProfileAvatar from 'components/ProfileAvatar';
import { ProfileAvatarSize, checkIsKidsProfile } from 'utils/profiles';
import { Profile } from '@laminar-product/client-commons-core/core';
import styles from '../index.module.scss';

interface ProfileMenuItemProps {
  profile?: Profile;
  onProfileSelect: (profile: Profile) => void;
}

const ProfileMenuItem = ({
  profile,
  onProfileSelect,
}: ProfileMenuItemProps) => {
  const profileSelectHandler = () => {
    if (profile) {
      onProfileSelect(profile);
    }
  };

  return (
    <div
      className={cn(styles.item, styles.profileItem)}
      onClick={profileSelectHandler}
    >
      <div className={styles.avatar}>
        <ProfileAvatar
          name={profile?.name}
          size={ProfileAvatarSize.SMALL}
          isKidsProfile={checkIsKidsProfile(profile)}
        />
      </div>
      <ProfileName text={profile?.name} />
      {profile?.pin && <LockIcon className={styles.lockIcon} />}
    </div>
  );
};

export default ProfileMenuItem;

import { RootState } from './../reducer';

export const selectRedirectUrlFromLogin = (state: RootState) =>
  state.login.redirectTo;

export const selectSignInFromTv = (state: RootState) =>
  state.login.signInFromTv;

export const selectSignUpFromTv = (state: RootState) =>
  state.login.signUpFromTv;

export const selectLoginPhoneNumber = (state: RootState) =>
  state.login.userCredentials.phone;

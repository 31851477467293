import React from 'react';
import OneTimePasswordForm from 'components/OneTimePasswordForm';
import { useFirebaseOtp } from 'utils/useOtp';

export interface FirebasePhoneAuthProps {
  phoneNumber: string;
  isRegistering?: boolean;
}

const FirebasePhoneAuth = ({
  phoneNumber,
  isRegistering,
}: FirebasePhoneAuthProps) => {
  const { isLoading, errorOtpMessage, signIn, clearError, sendOtp } =
    useFirebaseOtp({
      phoneNumber,
      isRegistering,
    });

  return (
    <>
      <OneTimePasswordForm
        isDarkMode={!isRegistering}
        isRegistering={isRegistering}
        isLoginForm
        hasRememberMe={!isRegistering}
        onVerify={signIn}
        phoneNumber={phoneNumber}
        isLoading={isLoading}
        clearError={clearError}
        errorMessage={errorOtpMessage}
        sendOtp={sendOtp}
      />
    </>
  );
};

export default FirebasePhoneAuth;

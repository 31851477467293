import PaymentMethodModal, {
  PaymentMethodModalVariant,
} from 'components/PaymentMethodModal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHandleRazorpayPayment from 'utils/useHandleRazorpayPayment';
import styles from '../../index.module.scss';

const RazorpayPaymentMethod = () => {
  const [paymentMethodModalVariant, setPaymentMethodModalVariant] =
    useState<PaymentMethodModalVariant>();
  const { handleRazorpayPaymentMethodChange, isProcessing } =
    useHandleRazorpayPayment();
  const { t } = useTranslation();

  const handleClosePaymentModal = () => setPaymentMethodModalVariant(undefined);

  const onPaymentMethodChangeSubmit = useCallback(() => {
    handleRazorpayPaymentMethodChange({
      onSuccessChange: () => setPaymentMethodModalVariant('success-change'),
    });

    handleClosePaymentModal();
  }, [handleRazorpayPaymentMethodChange]);

  const onPaymentModalSubmit = () =>
    paymentMethodModalVariant === 'confirm-change'
      ? onPaymentMethodChangeSubmit()
      : handleClosePaymentModal();

  return (
    <>
      <div className={styles.paymentChange}>
        <button
          onClick={() => setPaymentMethodModalVariant('confirm-change')}
          className={styles.link}
        >
          {t('settings.changePaymentInfo')}
        </button>
      </div>

      {paymentMethodModalVariant && (
        <PaymentMethodModal
          onClose={handleClosePaymentModal}
          onSubmit={onPaymentModalSubmit}
          isLoading={isProcessing}
          variant={paymentMethodModalVariant}
        />
      )}
    </>
  );
};

export default RazorpayPaymentMethod;

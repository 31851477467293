import { useCallback, useState } from 'react';

const useCardAccordion = (defaultCardId: string) => {
  const [expanded, setExpanded] = useState<string>(defaultCardId);
  // todo should be able to have both closed but only can be open at a time
  const toggle = useCallback((cardId: string) => {
    setExpanded((prevVal) => {
      if (prevVal === cardId) {
        return '';
      }
      return cardId;
    });
  }, []);
  return { expanded, toggle };
};

export default useCardAccordion;

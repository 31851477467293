import { MutableRefObject, useEffect, useState } from 'react';

export interface IntersectionObserverParams<T extends Element> {
  ref: T | null;
  rootMargin?: string;
  threshold?: number;
}

// This hook will stop observing Element as soon as Elment will showup on the screen
export function useLazyLoadIntersectionObserver<T extends Element>(
  elementRef: MutableRefObject<T | null>
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!elementRef.current) return;
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && elementRef.current) {
          setIsVisible(true);
          observer.unobserve(elementRef.current);
        }
      });
    });
    observer.observe(elementRef.current);
  }, [elementRef]);

  return isVisible;
}

// This hook will update everytime when Element will showup on the screen
export function useIntersectionObserver<T extends Element>({
  ref,
  rootMargin = '0px',
  threshold = 0,
}: IntersectionObserverParams<T>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(() => {
    let observerRefValue: Element | null = null; // <-- variable to hold ref value
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
        threshold,
      }
    );
    if (ref) {
      observer.observe(ref);
      observerRefValue = ref;
    }
    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [ref, rootMargin, threshold]);
  return isIntersecting;
}

import { useCallback } from 'react';
import {
  ContentType,
  EventContext,
  SchemaVersion,
  StrataEventData,
  StrataEvents,
  TrackEvent,
} from '@laminar-product/client-commons-core/core';
import { useSelector } from 'react-redux';
import {
  selectPlayer,
  selectPlayerAsset,
  selectPlayerContent,
  selectPlayerPlaybackId,
  selectPlayerVideoElement,
} from 'store/player/selectors';
import { useTracker } from './useTracker';

const useTrackVideoPlayerEvent = () => {
  const asset = useSelector(selectPlayerAsset);
  const content = useSelector(selectPlayerContent);
  const playbackId = useSelector(selectPlayerPlaybackId);
  const videoElement = useSelector(selectPlayerVideoElement);
  const player = useSelector(selectPlayer);
  const { trackEvent } = useTracker();

  const getAdditionalPlayerEventData = useCallback(
    (event: TrackEvent) => {
      if (event === TrackEvent.PLAYER_READY || !videoElement) {
        return;
      }
      const { duration } = asset || {};
      const { currentTime } = videoElement;
      const width = player?.getStats()?.width;

      const defaultVideoFormat =
        videoElement.canPlayType('application/vnd.apple.mpegURL') === 'maybe'
          ? 'hls'
          : 'dash';

      const video_format =
        content?.type === ContentType.LIVE
          ? 'hls'
          : (defaultVideoFormat as 'hls' | 'dash');

      return {
        video_format,
        ...(content?.type !== ContentType.LIVE && {
          playback_position: Math.abs(Math.round(currentTime || 0)),
          video_duration: Math.round(duration || 1), // duration 1 it necessary for strata to work
        }),
        ...(width && { video_quality: width }),
      };
    },
    [asset, content?.type, player, videoElement]
  );

  return useCallback(
    <T extends keyof StrataEvents>({
      event,
      data,
      version = SchemaVersion.V200,
    }: {
      event: TrackEvent;
      data?: Omit<StrataEventData<T>, 'playback_id'>;
      version?: SchemaVersion;
    }) => {
      if (!asset || !content) {
        return;
      }

      trackEvent<T, EventContext.PLAYBACK_CONTEXT>({
        event,
        data: { ...data, playback_id: playbackId } as StrataEventData<T>,
        eventContext: {
          context: EventContext.PLAYBACK_CONTEXT,
          version: SchemaVersion.V102,
          data: {
            asset_id: asset.id,
            asset_name: asset.administrativeName,
            content_id: content.slug,
            content_type: content.type.toLowerCase() as any,
            source_id: content.sourceUuid,
            ...getAdditionalPlayerEventData(event),
          },
        },
        version,
      });
    },
    [asset, content, trackEvent, playbackId, getAdditionalPlayerEventData]
  );
};

export default useTrackVideoPlayerEvent;

import { Config } from '@laminar-product/client-commons-core/core';
import { ReactComponent as SnapchatIcon } from 'assets/icons/social/snapchat.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/social/instagram.svg';
import { ReactComponent as PinterestIcon } from 'assets/icons/social/pinterest.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/social/twitter.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/social/youtube.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/social/facebook.svg';

interface SocialLink {
  Icon: React.ElementType;
  link: keyof Config['social'];
}

interface AppLink {
  text: string;
  link: keyof Config['links'];
}

export const links: AppLink[] = [
  {
    text: 'footer.termsOfUse',
    link: 'termsAndConditions',
  },
  {
    text: 'footer.privacyPolicy',
    link: 'privacyPolicy',
  },
  {
    text: 'footer.help',
    link: 'helpDesk',
  },
  {
    text: 'footer.grievanceRedressal',
    link: 'grievanceRedressal',
  },
  // {
  //   text: 'footer.contactUs',
  //   link: '',
  // },
];

export const socials: SocialLink[] = [
  {
    Icon: SnapchatIcon,
    link: 'snapchat',
  },
  {
    Icon: InstagramIcon,
    link: 'instagram',
  },
  {
    Icon: PinterestIcon,
    link: 'pinterest',
  },
  {
    Icon: TwitterIcon,
    link: 'twitter',
  },
  {
    Icon: FacebookIcon,
    link: 'facebook',
  },
  {
    Icon: YoutubeIcon,
    link: 'youtube',
  },
];

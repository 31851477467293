import {
  Currency,
  DiscountType,
} from '@laminar-product/client-commons-core/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface PromotionDiscountLabelProps {
  discountType?: DiscountType;
  currency?: Currency;
  amount?: number;
  summaryLabel?: boolean;
}

interface CouponDiscountLabelProps {
  currency?: Currency;
  amount?: number;
}

export const useGetPromotionDiscountLabel = () => {
  const { t } = useTranslation();

  const getPromotionDiscountLabel = useCallback(
    ({
      discountType,
      currency,
      amount,
      summaryLabel = false,
    }: PromotionDiscountLabelProps) => {
      if (!discountType || amount === null || amount === undefined) return;

      if (discountType === DiscountType.AMOUNT) {
        return `-${amount / 100} ${currency}`;
      }

      if (discountType === DiscountType.PERCENTAGE) {
        return `-${amount}%`;
      }

      if (discountType === DiscountType.PERIOD) {
        return !summaryLabel
          ? `${amount} ${t('payment.daysFree', { count: amount })}`
          : `${amount} ${t('payment.days', { count: amount })}`;
      }
    },
    [t]
  );

  const getCouponDiscountLabel = useCallback(
    ({ amount, currency }: CouponDiscountLabelProps) => {
      if (amount && currency) {
        return `-${amount / 100} ${currency}`;
      }
    },
    []
  );

  return { getPromotionDiscountLabel, getCouponDiscountLabel };
};

import {
  Button,
  ButtonToDisplay,
  WatchButton,
} from '@laminar-product/client-commons-core/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Play } from 'assets/icons/play-button.svg';

interface AssetButtonsProps {
  shouldDisplayTrailerButton: boolean;
  moveToAssetPlayerHandler: () => void;
  moveToAssetHandler: () => void;
  isLiveStreamActive?: boolean;
  isWatchingProgress: boolean;
  isFinished?: boolean;
  buttonToDisplay: ButtonToDisplay | null;
}

const AssetButtons = ({
  shouldDisplayTrailerButton,
  moveToAssetHandler,
  moveToAssetPlayerHandler,
  isFinished,
  isLiveStreamActive,
  isWatchingProgress,
  buttonToDisplay,
}: AssetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {shouldDisplayTrailerButton ? (
        <Button
          jumbotron
          variant="secondary"
          onClick={moveToAssetPlayerHandler}
        >
          {t('common.trailer')}
          <Play />
        </Button>
      ) : (
        <WatchButton
          onClick={moveToAssetPlayerHandler}
          watchText={
            isLiveStreamActive ? t('common.watchLive') : t('common.watch')
          }
          upgradeToWatchText={t('common.upgradeToWatch')}
          continueWatchingText={t('common.continueWatching')}
          isContinueWatching={isWatchingProgress}
          isFinished={isFinished}
          finishedText={t('common.watchFromBeginning')}
          signInToWatchText={t('common.signInToWatch')}
          buttonToDisplay={buttonToDisplay}
        />
      )}

      <Button jumbotron variant="secondary" onClick={moveToAssetHandler}>
        {t('jumbotron.moreDetails')}
      </Button>
    </>
  );
};

export default AssetButtons;

import { User } from '@laminar-product/client-commons-core/core';
import SettingsHeading from 'components/SettingsHeading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSocialUser from 'utils/useSocialUser';
import { PlatformPartnerMetadata } from 'types/user';
import styles from '../../index.module.scss';

interface AccountUserDataProps {
  userData?: User;
  platformPartner?: PlatformPartnerMetadata;
}

const AccountUserData = ({
  userData,
  platformPartner,
}: AccountUserDataProps) => {
  const { t } = useTranslation();
  const checkForUserDetails =
    userData && (userData.email || userData.phoneNumber || userData.uid);
  const shouldDisplayAccountId =
    userData?.uid && !userData?.email && !userData?.phoneNumber;
  const { currentSocialIdProvider } = useSocialUser();

  if (!checkForUserDetails) return null;

  if (platformPartner)
    return (
      <>
        <SettingsHeading title={t('settings.accountDetails')} />
        <div className={styles.row}>
          <div className={styles.label}>
            {t('settings.provider')}:{' '}
            <span className={styles.value}>{platformPartner?.name}</span>
          </div>
        </div>
      </>
    );

  return (
    <div>
      <SettingsHeading title={t('settings.accountDetails')} />
      {shouldDisplayAccountId && (
        <div className={styles.row}>
          <div className={styles.label}>
            {t('common.placeholders.accountId')}:{' '}
            <span className={styles.value}>{userData?.uid}</span>
          </div>
        </div>
      )}

      {userData?.email && (
        <div className={styles.row}>
          <div className={styles.label}>
            Email:{' '}
            <span
              data-testid="AccountSettings__UserEmail"
              className={styles.value}
            >
              {userData?.email}
            </span>
          </div>
        </div>
      )}

      {userData?.email && !currentSocialIdProvider && (
        <>
          <div className={styles.row}>
            <div>Password: **********</div>
          </div>
          <div className={styles.row}>
            <Link to="/settings/change-password" className={styles.link}>
              {t('settings.changePassword')}
            </Link>
          </div>
        </>
      )}

      {userData?.phoneNumber && (
        <div className={styles.row}>
          <div>Phone number: {userData?.phoneNumber}</div>
        </div>
      )}
    </div>
  );
};

export default AccountUserData;

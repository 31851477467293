import cn from 'classnames';
import range from 'lodash/range';
import React from 'react';
import styles from './index.module.scss';

interface CarouselPaginationProps {
  goToPage: (page: number) => void;
  pages: number;
  currentPage: number;
  itemClassName?: string;
  activeItemClassName?: string;
}

const CarouselPagination = ({
  goToPage,
  pages,
  currentPage,
  itemClassName,
  activeItemClassName,
}: CarouselPaginationProps) => {
  const pagesArray = range(pages);

  if (pages === 1) return null;
  return (
    <div className={styles.root}>
      {pagesArray.map((page) => (
        <button
          key={`page-${page}`}
          onClick={() => goToPage(page)}
          className={cn(
            styles.item,
            itemClassName,
            currentPage === page && activeItemClassName,
            {
              [styles.itemActive]: currentPage === page,
            }
          )}
        />
      ))}
    </div>
  );
};

export default CarouselPagination;

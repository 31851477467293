import React, { useCallback } from 'react';
import SettingsHeading from 'components/SettingsHeading';
import { useTranslation } from 'react-i18next';
import { canChangeSubscription, canRedeemPin } from 'utils/price';
import { Subscription } from 'types/subscription';
import { parseISO } from 'date-fns';
import Alert from 'components/Alert';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import capitalize from 'lodash/capitalize';
import { User } from '@laminar-product/client-commons-core/core';
import { PlanDetailsVariant } from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from 'store/user/selectors';
import styles from '../../index.module.scss';
import PlanDetails from './components/PlanDetails';
import PlanActions from './components/PlanActions';

interface AccountSubscriptionProps {
  subscription?: Subscription;
  userData?: User;
  isSubscriptionError?: boolean;
}

const AccountSubscription = ({
  subscription,
  userData,
  isSubscriptionError,
}: AccountSubscriptionProps) => {
  const { t } = useTranslation();
  const hasAssignedPlan = !!subscription || !!userData?.plan;
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const getPlanVariant = useCallback(() => {
    if (userData?.plan && !subscription) {
      return PlanDetailsVariant.PREPAID;
    }
  }, [subscription, userData?.plan]);

  if (isSubscriptionError) {
    return (
      <>
        <SettingsHeading title={t('settings.subscription')} />
        <span className={styles.error}>{t('payment.subscriptionFailed')}</span>
      </>
    );
  }

  return (
    <>
      <SettingsHeading title={t('settings.subscription')} />

      <div className={styles.subscriptionDetailsContainer}>
        {!hasAssignedPlan && (
          <div className={styles.row}>
            <div className={styles.value}>
              {t('settings.noActiveSubscription')}
            </div>
          </div>
        )}
        {hasAssignedPlan && (
          <PlanDetails
            screenLimit={
              userData?.plan?.screenLimit || subscription?.plan?.screenLimit
            }
            planName={
              userData?.planMetadata?.name || subscription?.planMetadata?.name
            }
            price={subscription?.price}
            paymentDate={
              (subscription?.nextPaymentDate &&
                parseISO(subscription?.nextPaymentDate)) ||
              userData?.plan?.expireAt
            }
            isCancelationScheduled={subscription?.isCancelationScheduled}
            variant={getPlanVariant()}
          />
        )}
        <PlanActions
          hasNoPlan={!hasAssignedPlan}
          canChangePlan={!!subscription && canChangeSubscription(subscription)}
          canCancelPlan={
            !!subscription &&
            !subscription.isCancelationScheduled &&
            canChangeSubscription(subscription)
          }
          canRedeemPin={canRedeemPin(subscription, isPartnerUser)}
        />
      </div>
      {subscription && !canChangeSubscription(subscription) && (
        <div className={styles.infoApple}>
          <Alert type="info">
            <div className={styles.infoWrapper}>
              <InfoIcon className={styles.infoIcon} />
              {t('settings.managePlan', {
                gateway: capitalize(subscription?.gateway),
              })}
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default AccountSubscription;

import { WatchlistLoadingState } from './types';

export const SET_WATCHLIST_UUIDS = 'SET_WATCHLIST_UUIDS';
export const setWatchlistUuids = (watchlistUuids: string[]) => ({
  type: SET_WATCHLIST_UUIDS,
  payload: watchlistUuids,
});

export const SET_WATCHLIST_LOADING_STATE = 'SET_WATCHLIST_LOADING_STATE';
export const setWatchlistLoadingState = (
  loadingState: WatchlistLoadingState
) => ({
  type: SET_WATCHLIST_LOADING_STATE,
  payload: loadingState,
});

export const ADD_ASSET_TO_WATCHLIST = 'ADD_ASSET_TO_WATCHLIST';
export const addAssetToWatchlist = (uuid: string) => ({
  type: ADD_ASSET_TO_WATCHLIST,
  payload: uuid,
});

export const REMOVE_ASSET_FROM_WATCHLIST = 'REMOVE_ASSET_FROM_WATCHLIST';
export const removeAssetFromWatchlist = (uuid: string) => ({
  type: REMOVE_ASSET_FROM_WATCHLIST,
  payload: uuid,
});

export const RESET_WATCHLIST = 'RESET_WATCHLIST';
export const resetWatchlist = () => ({
  type: RESET_WATCHLIST,
});

import AuthenticationContainer from 'components/AuthenticationContainer';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { setAppMeta } from 'store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prepareMetaForPage } from 'utils/meta';
import PhoneAuthProvider from 'components/OtpProviders';
import { selectLoginPhoneNumber } from 'store/login/selectors';
import { APP_PAGE_PATH } from 'utils/navigation';
import LoginForm from './components/LoginForm';

const Login = () => {
  const location = useLocation<{ action: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginPhoneNumber = useSelector(selectLoginPhoneNumber);

  useEffect(() => {
    dispatch(setAppMeta({ og: prepareMetaForPage(t('login.heading')) }));
  }, [dispatch, t]);

  if (location.state?.action?.includes('resetPassword')) {
    return (
      <Redirect
        to={{
          pathname: '/forgot-password',
          state: { action: location.state.action },
        }}
      />
    );
  }

  return (
    <AuthenticationContainer>
      <Switch>
        <Route
          exact
          path={[APP_PAGE_PATH.LOGIN, '/tv2']}
          component={LoginForm}
        />
        <Route
          exact
          path="/login/phone-verify"
          render={() => (
            <PhoneAuthProvider
              phoneNumber={loginPhoneNumber}
              redirectUrl={APP_PAGE_PATH.LOGIN}
            />
          )}
        />
      </Switch>
    </AuthenticationContainer>
  );
};

export default Login;

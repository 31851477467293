import React, { useRef, useState } from 'react';
import { Button } from '@laminar-product/client-commons-core/web';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getGenres } from 'actions/search';
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import cn from 'classnames';
import PopOver from 'components/PopOver';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface GenreSelectorProps {
  onGenreSelect: (genre: string) => void;
  onGenreReset: () => void;
  isTitleDisplayed?: boolean;
  selectedGenre?: string;
}

const GenreSelector = ({
  onGenreSelect,
  isTitleDisplayed,
  selectedGenre,
  onGenreReset,
}: GenreSelectorProps) => {
  const genreButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [genreSelectionActive, setGenreSelectorActive] =
    useState<boolean>(false);

  const [genres] = useFetchAction(getGenres);

  const onGenreButtonClick = (genre: string) => () => {
    setGenreSelectorActive(false);
    onGenreSelect(genre);
  };

  return (
    <>
      <Button
        data-testid="GenreSelector__button"
        className={cn(styles.button, { [styles.noMargin]: !isTitleDisplayed })}
        variant="secondary"
        ref={genreButtonRef}
        onClick={() => setGenreSelectorActive((prev) => !prev)}
      >
        {selectedGenre
          ? t(`genre.${selectedGenre}`, { defaultValue: selectedGenre })
          : t('asset.allGenres')}
        <DropdownIcon className={styles.buttonIcon} />
      </Button>
      <PopOver
        placement="bottom-start"
        anchorEl={genreButtonRef.current}
        isOpen={genreSelectionActive}
        onClose={() => setGenreSelectorActive(false)}
      >
        <div className={styles.genres}>
          {selectedGenre && (
            <button onClick={onGenreReset} className={styles.genre}>
              {t('asset.allGenres')}
            </button>
          )}

          {(genres || []).map((genre) => (
            <button
              key={genre}
              className={cn(styles.genre, {
                [styles.active]: genre === selectedGenre,
              })}
              onClick={onGenreButtonClick(genre)}
            >
              {t(`genre.${genre}`, { defaultValue: genre })}
            </button>
          ))}
        </div>
      </PopOver>
    </>
  );
};

export default GenreSelector;

import { Button } from '@laminar-product/client-commons-core/web';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ATTRIBUTED_CHECKOUT_ERRORS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectSupportPhoneNumber } from 'store/app/selectors';
import styles from '../../index.module.scss';

interface PaymentErrorProps {
  errorCode: string;
  onClose: () => void;
}

const PaymentError = ({ errorCode, onClose }: PaymentErrorProps) => {
  const { t } = useTranslation();
  const supportNumber = useSelector(selectSupportPhoneNumber);

  const getErrorComponent = useCallback(() => {
    const translationsNamespace = supportNumber
      ? 'payment.attributedCheckoutMessagesWithSupportContact'
      : 'payment.attributedCheckoutMessages';

    if (supportNumber) {
      switch (errorCode) {
        case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_PROCESSING_ERROR_CODE:
          return (
            <Trans i18nKey={`${translationsNamespace}.existingProcess`}>
              There was a problem when processing your payment. Please try again
              or contact our support for more help at{' '}
              <a className={styles.supportLink} href={`tel:${supportNumber}`}>
                {{ supportNumber } as any}
              </a>
              .
            </Trans>
          );
        case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_AUTONOMIC_GATEWAY_ERROR_CODE:
          return t('payment.attributedCheckoutMessages.autonomicGateway');
        case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_INVALID_CODE_ERROR:
          return t(`payment.attributedCheckoutMessages.invalidCodeMessage`);
        default:
          return (
            <Trans i18nKey={`${translationsNamespace}.defaultFailed`}>
              It seems there is a pending transaction in your account. Please
              wait 15 minutes to start a new purchase or contact our support for
              more help at{' '}
              <a className={styles.supportLink} href={`tel:${supportNumber}`}>
                {{ supportNumber } as any}
              </a>
              .
            </Trans>
          );
      }
    }

    switch (errorCode) {
      case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_PROCESSING_ERROR_CODE:
        return t(`${translationsNamespace}.existingProcess`);
      case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_AUTONOMIC_GATEWAY_ERROR_CODE:
        return t('payment.attributedCheckoutMessages.autonomicGateway');
      case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_INVALID_CODE_ERROR:
        return t(`payment.attributedCheckoutMessages.invalidCodeMessage`);
      default:
        return t(`${translationsNamespace}.defaultFailed`);
    }
  }, [errorCode, supportNumber, t]);

  const getErrorHeader = useCallback(() => {
    switch (errorCode) {
      case ATTRIBUTED_CHECKOUT_ERRORS.ATTRIBUTED_CHECKOUT_INVALID_CODE_ERROR:
        return t('payment.attributionCheckoutInvalidCodeHeader');
      default:
        return t('payment.attributionCheckoutFailedHeader');
    }
  }, [errorCode, t]);

  return (
    <div>
      <h1 className={styles.heading}>{getErrorHeader()}</h1>
      <div className={styles.description}>{getErrorComponent()}</div>

      <Button variant="primary" className={styles.button} onClick={onClose}>
        {t('common.OK')}
      </Button>
    </div>
  );
};

export default PaymentError;

import {
  AssetType,
  CatalogAsset,
  CatalogPageProps,
} from '@laminar-product/client-commons-core/core';
import {
  AssetDetailsCatalog,
  ShowCatalog,
} from '@laminar-product/client-commons-core/web';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getAssetCatalogDetails } from 'actions/assets';
import AssetDetailsBase from 'components/AssetDetailsBase';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRedirectToAsset } from 'utils/useRedirectAssetCatalog';
import { useDispatch } from 'react-redux';
import { setAppMeta } from 'store/app/actions';
import { prepareMetaForAsset } from 'utils/meta';

const CatalogAssetDetails = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    data: asset,
    isLoading,
    error,
  } = useFetchActionObj(
    useCallback(() => {
      if (pathname) {
        return getAssetCatalogDetails(pathname);
      }

      return Promise.resolve({} as CatalogAsset);
    }, [pathname])
  );

  useRedirectToAsset({ asset });

  useEffect(() => {
    if (!asset) {
      return;
    }

    const canonicalUrl = window.location.origin + pathname;

    dispatch(
      setAppMeta({
        description: asset.description,
        og: prepareMetaForAsset(asset),
        structuredData: {
          name: asset.name,
          description: asset.description,
        },
        canonicalUrl,
      })
    );
  }, [asset, dispatch, pathname]);

  const renderComponent = () => {
    if (!asset) return;

    const commonProps: CatalogPageProps = {
      asset: asset,
    };

    switch (asset.type) {
      case AssetType.MOVIE:
      case AssetType.LIVE_CHANNEL:
      case AssetType.LIVE_EVENT:
        return <AssetDetailsCatalog {...commonProps} />;
      case AssetType.SHOW:
        return <ShowCatalog {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <AssetDetailsBase
        isCatalog
        isError={!!error}
        isLoading={isLoading}
        children={renderComponent()}
      />
    </>
  );
};

export default CatalogAssetDetails;

import {
  Asset,
  Collection,
  ItemsPresented,
  SchemaVersion,
  TrackEvent,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import _omit from 'lodash/omit';
import PosterComponent from 'components/Poster';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  CarouselType,
  getCarouselTypeMinHeight,
  getItemsForItemsPresentedEvents,
  isContinueWatchingCarousel,
  useCarousel,
} from 'utils/carouselHelpers';
import { getPageLink } from 'utils/routing';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useCarouselContinueWatchingData from 'utils/useCarouselContinueWatchingData';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import { useTracker } from 'utils/useTracker';
import { useIntersectionObserver } from 'utils/useIntersectionObserver';
import { ScaleUpPoster } from 'components/ScaleUpPoster/ScaleUpPoster';
import { getPosterHoverUrl } from 'utils/images';
import { useWindowSize } from '@laminar-product/client-commons-core/hooks';
import useCarouselSwipe from 'utils/useCarouselSwipe';
import CarouselPagination from '../CarouselPagination';
import CarouselNavigation from '../CarouselNavigation';
import styles from './index.module.scss';

export interface StrataMetadata {
  source_type: ItemsPresented['source_type'];
  source_id?: ItemsPresented['source_id'];
  source_referrer_item?: ItemsPresented['source_referrer_item'];
  source_name?: ItemsPresented['source_name'];
}

interface CarouselProps {
  onItemClick: (item: Asset) => void;
  shouldDisplayTitle?: boolean;
  strataMetadata: StrataMetadata;
  carouselType?: CarouselType;
  collection?: Collection;
  testId?: string;
}

const Carousel = ({
  onItemClick,
  shouldDisplayTitle = true,
  strataMetadata,
  carouselType,
  collection,
  testId,
}: CarouselProps) => {
  const [isPosterActive, setIsPosterActive] = useState<{
    active: boolean;
    index: number;
  }>();
  const { t } = useTranslation();
  const [wrapper, setWrapper] = useState<Element | null>(null);
  const { current: isMobileOrTabletRef } = useRef(isMobileOrTablet());

  const { continueWatchingData } =
    useCarouselContinueWatchingData(carouselType);

  const { isTabletWindowSize } = useWindowSize();

  const renderedData = isContinueWatchingCarousel(carouselType)
    ? continueWatchingData
    : collection;

  const { administrativeName, assets, title, link } = renderedData || {};
  const isVisible = useIntersectionObserver({
    ref: wrapper,
    threshold: 0.8,
  });

  const wrapperPagination = useMemo(() => {
    if (isTabletWindowSize) {
      return 40; //NOTE: This value is based on CSS rule only-mobile, commons-ui/mixins/responsive
    }

    return 120;
  }, [isTabletWindowSize]);

  const {
    currentPage,
    goToPage,
    pages,
    currentTransform,
    lastPage,
    itemsPerPage,
    onSwipedLeft,
    onSwipedRight,
  } = useCarousel({
    wrapper,
    itemsCount: assets?.length,
    carouselType,
    wrapperPagination,
  });

  const { trackEvent } = useTracker();

  const { swipeHandlers } = useCarouselSwipe({
    onSwipedLeft,
    onSwipedRight,
  });

  const collectionLink = {
    type: link ? link.type : 'COLLECTION',
    uuid: link ? link.uuid : '',
    administrativeName: administrativeName ?? '',
  };

  const strataCollectionCommonData = useMemo(
    () => ({
      source_name: strataMetadata.source_name ?? title,
      ...strataMetadata,
    }),
    [strataMetadata, title]
  );

  useEffect(() => {
    if (!isVisible || !itemsPerPage || !assets) return;

    const itemsToSend = getItemsForItemsPresentedEvents(
      assets,
      lastPage,
      currentPage,
      itemsPerPage
    );

    if (itemsToSend.length) {
      trackEvent<TrackEvent.ITEMS_PRESENTED>({
        event: TrackEvent.ITEMS_PRESENTED,
        data: {
          ...strataCollectionCommonData,
          source_name: isContinueWatchingCarousel(carouselType)
            ? null
            : strataCollectionCommonData.source_name,
          items: itemsToSend,
        },
        version: SchemaVersion.V110,
      });
    }
  }, [
    assets,
    currentPage,
    isVisible,
    itemsPerPage,
    lastPage,
    strataCollectionCommonData,
    trackEvent,
    carouselType,
  ]);

  const handleOnActive = useCallback(
    (active: boolean, index: number) => setIsPosterActive({ active, index }),
    []
  );
  const Poster =
    carouselType === CarouselType.POSTER_SCALE_UP
      ? ScaleUpPoster
      : PosterComponent;

  if (!renderedData?.assets?.length) return null;

  return (
    <section
      className={styles.root}
      style={{ minHeight: getCarouselTypeMinHeight(carouselType) }}
      data-testid={testId}
    >
      {title && assets && assets?.length > 0 && shouldDisplayTitle && (
        <div className={styles.header}>
          <h3 className={styles.title}>
            {link ? (
              <Link to={{ pathname: getPageLink(collectionLink) }}>
                <div className={styles.linkContainer}>
                  {title}
                  <ArrowRight className={styles.iconRight} />
                  <span className={styles.carouselHoverText}>
                    {t('common.seeMore')}
                  </span>
                </div>
              </Link>
            ) : (
              <span data-testid="Collection__Title">{title}</span>
            )}
          </h3>
          {!isMobileOrTablet() && (
            <CarouselPagination
              goToPage={goToPage}
              currentPage={currentPage}
              pages={pages}
            />
          )}
        </div>
      )}

      {renderedData && !title && assets && assets.length > 0 && (
        <div className={styles.headerNoTitle}>
          <CarouselPagination
            goToPage={goToPage}
            currentPage={currentPage}
            pages={pages}
          />
        </div>
      )}

      <div className={styles.slider}>
        {pages > 1 && isMobileOrTabletRef === false ? (
          <CarouselNavigation goToPage={goToPage} currentPage={currentPage} />
        ) : null}

        <div className={styles.sliderInner} {...swipeHandlers}>
          <div
            ref={setWrapper}
            className={styles.sliderItems}
            style={{
              transform: `translate3d(${currentTransform}%,0,0)`,
            }}
          >
            {assets?.map((item, i) => (
              <Poster
                items={assets}
                lastPage={lastPage}
                currentPage={currentPage}
                key={`${item.id}-${i}`}
                onItemClick={onItemClick}
                role="link"
                className={cn(styles.sliderItem, {
                  [styles.sliderItemScaleUp]:
                    carouselType === CarouselType.POSTER_SCALE_UP,
                  [styles.sliderItemPortrait]:
                    carouselType === CarouselType.PORTRAIT_CAROUSEL,
                  [styles.sliderItemPortraitLarge]:
                    carouselType &&
                    [
                      CarouselType.LARGE_PORTRAIT_CAROUSEL,
                      CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
                    ].includes(carouselType),
                  [styles.sliderItemPortraitExpandable]:
                    carouselType === CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
                  [styles.sliderItemOnTop]:
                    isPosterActive?.active && isPosterActive.index === i,
                })}
                data={item}
                onActive={handleOnActive}
                posterUrl={
                  getAssetImageWithType(
                    item,
                    carouselType &&
                      [
                        CarouselType.PORTRAIT_CAROUSEL,
                        CarouselType.LARGE_PORTRAIT_CAROUSEL,
                        CarouselType.PORTRAIT_EXPANDABLE_CAROUSEL,
                        CarouselType.POSTER_SCALE_UP,
                      ].includes(carouselType)
                      ? 'portrait'
                      : 'thumbnail'
                  )?.url
                }
                hoverUrl={getPosterHoverUrl(item, carouselType)}
                index={i}
                progress={item.progress}
                type={carouselType}
                strataCollectionData={{
                  ..._omit(strataCollectionCommonData, 'source_referrer_item'),
                  source_item_index: i + 1,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;

import { useEffect, useState } from 'react';
import api from 'api';

const useAxiosResponseErrors = () => {
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    api().interceptors.response.use(
      async (response) => {
        setUnauthorized(false);
        return response;
      },
      (e) => {
        setUnauthorized(e.response?.status === 401);
        return Promise.reject(e);
      }
    );
  }, []);

  const resetErrors = () => setUnauthorized(false);

  return { unauthorized, resetErrors };
};

export default useAxiosResponseErrors;

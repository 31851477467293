import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  dislikeAsset,
  getLikeForAsset,
  likeAsset,
  removeLikeForAsset,
} from 'actions/likes';
import { useCallback, useEffect, useState } from 'react';

const useLikeActions = (assetId: string) => {
  const [likeData, , , refreshLike] = useFetchAction(
    useCallback(() => getLikeForAsset({ id: assetId }), [assetId])
  );
  const [likeStatus, setLikeStatus] = useState<
    'LIKE' | 'DISLIKE' | undefined
  >();

  // Derrive state from fetched value for positive updates
  useEffect(() => {
    if (likeData?.like === 'LIKE') {
      setLikeStatus('LIKE');
    } else if (likeData?.like === 'DISLIKE') {
      setLikeStatus('DISLIKE');
    } else {
      setLikeStatus(undefined);
    }
  }, [likeData]);

  const [like] = useSendAction(
    useCallback(() => likeAsset({ id: assetId }), [assetId]),
    { onDone: refreshLike }
  );

  const [dislike] = useSendAction(
    useCallback(() => dislikeAsset({ id: assetId }), [assetId]),
    { onDone: refreshLike }
  );

  const [removeLike] = useSendAction(
    useCallback(() => removeLikeForAsset({ id: assetId }), [assetId]),
    { onDone: refreshLike }
  );

  return {
    likeStatus,
    dislike: () => {
      dislike();
      setLikeStatus('DISLIKE');
    },
    like: () => {
      like();
      setLikeStatus('LIKE');
    },
    removeLike: () => {
      removeLike();
      setLikeStatus(undefined);
    },
  };
};

export default useLikeActions;

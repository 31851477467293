import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAnonymous,
  selectUserSubscription,
} from 'store/user/selectors';
import { setCannotChangePlanModalVisible } from 'store/user/actions';
import { canChangeSubscription } from './price';

const useUpgradeToWatch = () => {
  const dispatch = useDispatch();
  const isAnonymous = useSelector(selectIsAnonymous);
  const { push } = useHistory();
  const userSubscription = useSelector(selectUserSubscription);

  const canChangePlan = useMemo(
    () => canChangeSubscription(userSubscription),
    [userSubscription]
  );

  const handleUpgradeToWatch = useCallback(() => {
    if (!canChangePlan) {
      return dispatch(setCannotChangePlanModalVisible(true));
    }

    push(isAnonymous ? `/login` : `/settings/change-plan`);
  }, [canChangePlan, dispatch, isAnonymous, push]);

  return { handleUpgradeToWatch };
};

export default useUpgradeToWatch;

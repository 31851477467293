import React, { useEffect, useMemo, useState } from 'react';
import SettingsHeader from 'components/SettingsHeader';
import SettingsPageContainer from 'components/SettingsPageContainer';
import PlanSelector from 'components/PlanSelector';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import usePlans, { PlansFilteringOption } from 'utils/usePlans';
import {
  selectAppLinks,
  selectIsInterestsEnabled,
  selectIsPaymentsMaintenance,
} from 'store/app/selectors';
import { ReactComponent as Stars } from 'assets/icons/stars.svg';
import { groupPlansByInterval } from 'components/PlanSelector/helpers';
import PlansIntervalSelector from 'components/PlanSelector/components/PlansIntervalSelector';
import { allowedPlansIntervalsKeys } from 'components/PlanSelector/constants';
import { Button } from '@laminar-product/client-commons-core/web';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import {
  selectCurrentProfile,
  selectCurrentProfileHasInterests,
  selectIsAnonymous,
  selectUserHasPlan,
} from 'store/user/selectors';
import RedeemCodeModal from 'components/RedeemCodeModal';
import InterestsModal from 'components/InterestsModal';
import { NO_PREFERENCE } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';
import ResetPlanFilterButton from './ResetFiltersButton';
import PaymentMaintenanceMode from './PaymentMaintenanceMode';
import EmptyPlan from './EmptyPlans';

const SelectPlan = () => {
  const {
    filteredPlans: plans,
    isLoading: isLoadingPlans,
    filteringOption,
    reset: resetPlansFilter,
  } = usePlans();
  const isTVPlansFilterOptions = filteringOption === PlansFilteringOption.TV;
  const [activeIntervalKey, setActiveIntervalKey] = useState<string>('');
  const [isRedeemCodeModalVisible, setIsRedeemCodeModalVisible] =
    useState(false);
  const interestsEnabled = useSelector(selectIsInterestsEnabled);
  const profileHasInterests = useSelector(selectCurrentProfileHasInterests);
  const isAnonymous = useSelector(selectIsAnonymous);
  const [shouldShowInterestsModal, setShouldShowInterestsModal] = useState(
    !isAnonymous && interestsEnabled && !profileHasInterests
  );
  const plansByInterval = groupPlansByInterval(plans);
  const selectedPlansByInterval = plansByInterval[activeIntervalKey];
  const appLinks = useSelector(selectAppLinks);
  const userHasCurrentPlan = useSelector(selectUserHasPlan);
  const currentProfile = useSelector(selectCurrentProfile);
  const { push } = useHistory();
  const { t } = useTranslation();
  const isMaintenanceMode = useSelector(selectIsPaymentsMaintenance);
  const onSkipClick = () => push('/');

  const getPageTitle = () => {
    if (isTVPlansFilterOptions) {
      return t('settings.plansTV');
    }

    return userHasCurrentPlan
      ? t('settings.plansTitleChangePlan')
      : t('settings.plansTitle');
  };

  useEffect(() => {
    if (activeIntervalKey || shouldShowInterestsModal || isLoadingPlans) {
      return;
    }

    const keyToSet = allowedPlansIntervalsKeys.find((key) =>
      Object.keys(plansByInterval).includes(key)
    );

    if (keyToSet) {
      setActiveIntervalKey(keyToSet);
    }
  }, [
    activeIntervalKey,
    isLoadingPlans,
    plansByInterval,
    shouldShowInterestsModal,
  ]);

  const listedInterests: string = useMemo(
    () =>
      currentProfile?.interests?.includes(NO_PREFERENCE)
        ? t('interests.noPreference')
        : currentProfile?.interests?.join(', ') || '',
    [currentProfile?.interests, t]
  );

  if (shouldShowInterestsModal) {
    return (
      <InterestsModal setOpen={setShouldShowInterestsModal} type="register" />
    );
  }

  if (isMaintenanceMode) {
    return <PaymentMaintenanceMode />;
  }

  if (isLoadingPlans) {
    return <AppLoadingIndicator fullScreen />;
  }

  if (!isLoadingPlans && !plans?.length) {
    return <EmptyPlan />;
  }

  return (
    <SettingsPageContainer>
      <div className={styles.settingsHeaderWrapper}>
        <div className={styles.settingsHeader} data-testid="SelectPlan__Header">
          <SettingsHeader title={getPageTitle()} />
          {interestsEnabled && (
            <h4 className={styles.interestBar}>
              <span className={styles.interestIcon}>
                <Stars />
              </span>
              <span>
                {t('settings.plansInterestBar')}
                <b>{listedInterests}</b>
              </span>
              <span
                className={styles.linkToModal}
                onClick={() => setShouldShowInterestsModal(true)}
              >
                {t('common.change')}
              </span>
            </h4>
          )}
          {isTVPlansFilterOptions && (
            <ResetPlanFilterButton onClick={resetPlansFilter} />
          )}
        </div>

        <PlansIntervalSelector
          plansByInterval={plansByInterval}
          activeIntervalKey={activeIntervalKey}
          onIntervalKeyChange={setActiveIntervalKey}
        />
      </div>

      <PlanSelector
        plans={selectedPlansByInterval}
        activeIntervalKey={activeIntervalKey}
      />

      <div className={styles.footer}>
        <div className={styles.notes}>
          <Trans i18nKey="planSelector.regionLockNote">
            Plans and videos will not work outside the region of your purchase.
            Plan bought in a region is exclusive to that region only.
          </Trans>{' '}
          <Trans i18nKey="planSelector.availabilityNote">
            The availability of HD (720p), Full HD (1080p) and Ultra HD (4K) is
            dependent on your Internet service and capabilities of your device.
            It should be noted that not all content is accessible in HD, Full HD
            or Ultra HD. For further information, please refer to{' '}
            <a href={`${appLinks?.termsAndConditions}`}>Terms of Use</a>.
          </Trans>
        </div>

        {!userHasCurrentPlan && (
          <div className={styles.actionButtonsWrapper}>
            <div className={styles.redeemWrapper}>
              <span>{t('planSelector.havePrepaidCard')}</span>

              <span
                className={styles.redeemCode}
                onClick={() => setIsRedeemCodeModalVisible(true)}
              >
                {t('planSelector.redeemCode')}
              </span>
            </div>

            <Button
              onClick={onSkipClick}
              variant="secondary-settings"
              className={styles.skipBtn}
            >
              {t('common.skipStep')}
            </Button>
          </div>
        )}

        {isRedeemCodeModalVisible && (
          <RedeemCodeModal onClose={() => setIsRedeemCodeModalVisible(false)} />
        )}
      </div>
    </SettingsPageContainer>
  );
};

export default SelectPlan;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ClickAway } from 'components/ClickAway';
import styles from './index.module.scss';

interface TravelTooltipProps {
  onClose: () => void;
  countries?: string[];
  currentCountryCode?: string;
}

const TravelModal = ({
  countries,
  currentCountryCode,
  onClose,
}: TravelTooltipProps) => {
  const { t } = useTranslation();

  if (!countries || countries.length === 0) return null;

  return (
    <ClickAway onClick={onClose}>
      <div
        className={styles.tooltipWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose();
          }}
          className={styles.closeIcon}
        />
        <div className={styles.tooltipHeader}>
          {t('planSelector.watchContentTravel', {
            country: t(`languagesCodes.${currentCountryCode}`),
            count: countries.length,
          })}
          :
        </div>
        <div className={styles.countryList}>
          {countries.map((country, index) => {
            return (
              <span key={index} className={styles.listItem}>
                • {t(`languagesCodes.${country}`)}
              </span>
            );
          })}
        </div>
      </div>
    </ClickAway>
  );
};

export default TravelModal;

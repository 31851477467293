import React from 'react';
import { ReactComponent as PcIcon } from 'assets/icons/pc.svg';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface SubscriptionDevicesProps {
  screenLimit: number;
}

const SubscriptionDevices = ({ screenLimit }: SubscriptionDevicesProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.center}>
      <PcIcon className={styles.planDevicesIcon} />
      <span>
        {t('settings.devices.devicesCount', {
          count: screenLimit,
        })}
      </span>
    </div>
  );
};

export default SubscriptionDevices;

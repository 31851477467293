import React, { FC, PropsWithChildren, useEffect } from 'react';
import AuthenticationMenu from 'components/AuthenticationMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAppMeta } from 'store/app/actions';
import { prepareMetaForPage } from 'utils/meta';
import SettingsFooter from 'components/Footer/SettingsFooter';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import styles from './index.module.scss';

const Register: FC<PropsWithChildren> = ({ children }) => {
  const auth = getAuth();
  const isSignedIn = auth.currentUser;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-verify-container',
      {
        size: 'invisible',
      },
      auth
    );
  }, [auth]);

  useEffect(() => {
    dispatch(
      setAppMeta({ og: prepareMetaForPage(t('registration.steps.signUp')) })
    );
  }, [dispatch, t]);

  const getRegisterStep = () => {
    switch (pathname) {
      case '/register/phone-verify':
        return 0;
      case '/register/plan':
        return 1;
      case '/register/payment':
        return 2;
      case '/register/payment/in-progress':
      case '/register/payment/success':
      case '/register/payment/failed':
      case '/mobile/payment/success':
      case '/mobile/payment/failed':
        return undefined;
      default:
        return undefined;
    }
  };
  const step = getRegisterStep();
  return (
    <div className={styles.root}>
      <div id="recaptcha-verify-container" />
      <AuthenticationMenu
        variant="signUp"
        isSignedIn={!isSignedIn?.isAnonymous}
        registerStep={step}
        backgroundColor="black"
      />
      <div className={styles.content}>{children}</div>
      <SettingsFooter />
    </div>
  );
};

export default Register;

// This util will convert hex color code to rgb values. For example #000 => 0,0,0
export const hexToRGBNums = (h: string) => {
  type RgbNum = string | number;

  let r: RgbNum = 0,
    g: RgbNum = 0,
    b: RgbNum = 0;

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return +r + ' ' + +g + ' ' + +b;
};

export const isRgbColor = (color: string) => color.startsWith('rgb');

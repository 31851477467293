import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface ProfileNameProps {
  text?: string;
  type?: 'header';
}

const ProfileName = ({ text, type }: ProfileNameProps) => (
  <div
    className={cn(styles.profileNameWrapper, {
      [styles.header]: type === 'header',
    })}
  >
    {text}
  </div>
);

export default ProfileName;

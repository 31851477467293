import {
  Asset,
  getAssetDetailsPath,
} from '@laminar-product/client-commons-core/core';

export const buildAssetDynamicLink = (asset: Asset) => {
  const dynamicLink = import.meta.env.VITE_FIREBASE_DYNAMIC_URL;
  const ibi = /\.(.+)/.exec(import.meta.env.VITE_MOBILE_IOS_APP_ID ?? '')?.[1];
  const apn = import.meta.env.VITE_MOBILE_ANDROID_APP_ID;
  const isi = import.meta.env.VITE_IOS_APP_ID;
  const webLink = `${window.location.origin}/${getAssetDetailsPath({
    id: asset.id,
    type: asset.type,
    administrativeName: asset.administrativeName,
  })}`;
  const link = `${dynamicLink}?apn=${apn}&ibi=${ibi}&isi=${isi}&link=${webLink}`;

  return link;
};

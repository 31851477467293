import { AssetContentType } from '@laminar-product/client-commons-core/core';
import shaka from 'shaka-player/dist/shaka-player.ui';
import {
  VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY,
  VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY,
} from 'utils/constants';
const SUPPORTED_DRMS = [
  VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY,
  VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY,
];
export const getStreamType = async (): Promise<AssetContentType> => {
  const support = await shaka.Player.probeSupport();
  const doesNotSupportDRM = SUPPORTED_DRMS.every((drm) => !support.drm[drm]);

  if (doesNotSupportDRM) {
    return 'DASH'; // this should return dash to return correct DRM for Chrome in incognito mode
  }

  const shouldUseDash = support.drm[VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY];
  return shouldUseDash ? 'DASH' : 'HLS';
};

import { Plan, Price } from '@laminar-product/client-commons-core/core';
import i18n from 'i18n';
import { formatPrice } from 'utils/price';
import _omit from 'lodash/omit';
import { PlanByIntervalWithPrice, PlansByInterval } from '../types';
import { SelectedPrice } from '../components/PlanCard';

export const groupPlansByInterval = (plans: Plan[] = []) => {
  const plansByInterval = plans.reduce((acc, plan) => {
    plan.prices.forEach((price) => {
      const defaultKey = price.interval;

      const planWithSinglePrice = {
        ..._omit(plan, 'prices'),
        price,
      } as PlanByIntervalWithPrice;

      //Display only month, year and others tabs
      if (
        ['MONTH', 'YEAR'].includes(price.interval.toUpperCase()) &&
        price.intervalMultiplier === 1
      ) {
        if (!acc[defaultKey]) {
          acc[defaultKey] = [];
        }

        acc[defaultKey].push(planWithSinglePrice);
        return acc;
      }

      if (!acc.OTHER) {
        acc.OTHER = [];
      }

      acc.OTHER.push(planWithSinglePrice);
    });

    return acc;
  }, {} as PlansByInterval);

  return plansByInterval;
};

export const buildPriceOption = (p: Price): SelectedPrice => ({
  label: i18n.t('planSelector.priceSelector.label', {
    interval: i18n.t(`common.intervals.${p.interval?.toLowerCase()}`),
    count: p.intervalMultiplier,
  }),
  intervalMultiplier: p.intervalMultiplier,
  price: formatPrice(p.amount, p.currency),
  value: p.uuid,
  netPrice: formatPrice(p.netPrice, p.currency),
  interval: p.interval,
  currency: p.currency,
  ...(p.promotion
    ? ({
        discount: {
          type: p.promotion?.discountType,
          amount: p.promotion?.amount ?? undefined,
        },
      } as SelectedPrice)
    : {}),
});

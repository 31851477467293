import React from 'react';
import { useFirebaseReauthenticateOtp } from 'utils/useOtp';
import { ProfileConfirmationProps } from '../../../ProfileIdentityConfirmation';
import PhoneConfirmation from './PhoneConfirmation';

interface FirebasePhoneConfirmationProps extends ProfileConfirmationProps {
  phoneNumber: string;
}

const FirebasePhoneConfirmation = ({
  onClose,
  onConfirmationSuccess,
  phoneNumber,
}: FirebasePhoneConfirmationProps) => {
  const {
    isConfirmationResultSent,
    errorOtpMessage,
    sendOtp,
    confirmOtp,
    isConfirming,
    isAuthenticationError,
  } = useFirebaseReauthenticateOtp({ onConfirmationSuccess, phoneNumber });

  return (
    <PhoneConfirmation
      onClose={onClose}
      isConfirmationResultSent={isConfirmationResultSent}
      errorMessage={errorOtpMessage}
      isConfirming={isConfirming}
      isAuthenticationError={isAuthenticationError}
      sendOtp={sendOtp}
      confirmOtp={confirmOtp}
    />
  );
};

export default FirebasePhoneConfirmation;

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProfileItemType } from 'types/profiles';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { Profile } from '@laminar-product/client-commons-core/core';
import { checkForProfileLimit, useUpdateProfilesState } from 'utils/profiles';
import { selectUserDetails } from 'store/user/selectors';
import styles from '../index.module.scss';
import ProfileListItem from '../ProfileListItem';
import ProfileCreate from '../ProfileCreate';

const ProfilesList = ({ profiles }: { profiles?: Profile[] }) => {
  const { t } = useTranslation();
  const [displayProfileModal, setDisplayProfileModal] =
    useState<boolean>(false);
  const userData = useSelector(selectUserDetails);
  const onProfileCreate = useUpdateProfilesState();

  const isBelowProfileLimit = useMemo(
    () => checkForProfileLimit(profiles, userData),
    [profiles, userData]
  );

  return (
    <div className={styles.listContainer}>
      {profiles?.map((profile, index) => (
        <ProfileListItem
          key={index}
          profile={profile}
          type={ProfileItemType.LIST}
        />
      ))}

      {isBelowProfileLimit && (
        <div className={styles.newButton}>
          <Button
            variant="primary-settings"
            onClick={() => setDisplayProfileModal(true)}
          >
            {t('profile.addNewProfile')}
          </Button>
        </div>
      )}

      <Modal wrapperId="profileCreateModal" isOpen={displayProfileModal}>
        <ProfileCreate
          onProfileCreate={onProfileCreate}
          onClose={() => setDisplayProfileModal(false)}
        />
      </Modal>
    </div>
  );
};

export default ProfilesList;

import {
  Asset,
  Collection,
  PageComponentType,
} from '@laminar-product/client-commons-core/core';
import { SubHeaderProps } from 'components/SubHeader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPage } from 'store/pages/selectors';
import { isCarouselComponentType, isJumbotronComponentType } from 'utils/page';
import { RouteIdParams } from 'utils/props';

interface BasicComponent {
  type: PageComponentType;
  uuid?: string;
}
interface CarouselComponentType extends BasicComponent {
  body?: Collection; //Body is undefined for continue watching carousel
}

interface JumbotronComponentType extends BasicComponent {
  body?: Asset;
}

const usePageDetails = () => {
  const { id: pageUuid } = useParams<RouteIdParams>();
  const page = useSelector(selectPage(pageUuid));

  const carousels = useMemo(
    () =>
      page?.components?.data?.filter(({ type }) =>
        isCarouselComponentType(type)
      ) || [],
    [page]
  ) as CarouselComponentType[];

  const jumbotron = useMemo(
    () =>
      page?.components?.data?.find(({ type }) =>
        isJumbotronComponentType(type)
      ),
    [page]
  ) as JumbotronComponentType;

  const subheader: SubHeaderProps | undefined = useMemo(() => {
    if (page?.settings?.assetType) {
      return {
        assetType: page.settings.assetType,
        title: page.settings.showTitle ? page?.title : '',
      };
    }
  }, [page]);

  return { jumbotron, carousels, subheader };
};
export default usePageDetails;

import shaka from 'shaka-player/dist/shaka-player.ui';
import QualitySelectorIcon from 'assets/icons/player-quality-settings.svg?raw';

class QualitySelector extends shaka.ui.Element {
  private button: HTMLButtonElement;
  private menu: HTMLDivElement;
  player: shaka.Player | null;
  private qualities: { label: string; track: shaka.extern.Track }[] = [];
  private autoQualityTrackId = -9;
  private selectedTrackId: number = this.autoQualityTrackId;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.player = controls.getPlayer();

    this.button = document.createElement('button');
    this.button.classList.add('laminar-shaka-quality-selector');
    this.button.innerHTML = QualitySelectorIcon;
    this.parent?.appendChild(this.button);

    this.menu = document.createElement('div');
    this.menu.classList.add('laminar-shaka-quality-selector__menu');
    this.hideMenu(); // Initially hidden
    this.parent?.appendChild(this.menu);

    this.button.addEventListener('click', () => this.toggleMenu());
    document.addEventListener('click', (event) =>
      this.handleOutsideClick(event)
    );

    this.player?.addEventListener('trackschanged', () =>
      this.updateAvailableQualities()
    );
  }

  private getQualityLabel(
    minHeight: number | null,
    maxHeight: number | null,
    label: string
  ): { label: string; track: shaka.extern.Track } | null {
    const tracks = this.player?.getVariantTracks();

    const filteredTracks = tracks?.filter((track) => {
      if (track.height === null) return false;
      return (
        (minHeight === null || track.height >= minHeight) &&
        (maxHeight === null || track.height < maxHeight)
      );
    });

    const highestTrack = filteredTracks?.reduce((prev, current) => {
      if (prev.height === null) return current;
      if (current.height === null) return prev;

      return prev.height > current.height ? prev : current;
    }, filteredTracks[0]);

    return highestTrack ? { label, track: highestTrack } : null;
  }

  private updateAvailableQualities(): void {
    this.menu.innerHTML = '';

    this.addAutoQualityBtn();

    const qualityMap = [
      this.getQualityLabel(null, 720, 'SD'),
      this.getQualityLabel(720, 1081, 'HD'),
      this.getQualityLabel(1081, null, '4K'),
    ];

    this.qualities = qualityMap.filter((quality) => quality !== null) as {
      label: string;
      track: shaka.extern.Track;
    }[];

    this.qualities.forEach((quality) => {
      const qualityButton = document.createElement('button');
      qualityButton.textContent = quality.label;

      if (quality.track.id === this.selectedTrackId) {
        qualityButton.classList.add(
          'laminar-shaka-quality-selector__button--selected'
        );
      }

      qualityButton.addEventListener('click', () => {
        this.selectedTrackId = quality.track.id;

        this.selectQuality(quality.track);
        this.updateAvailableQualities();
        this.hideMenu();
      });

      this.menu.appendChild(qualityButton);
    });
  }

  private selectQuality(track: shaka.extern.Track): void {
    this.player?.configure({ abr: { enabled: false } });

    this.player?.selectVariantTrack(track, /* clearBuffer= */ true);
  }

  private addAutoQualityBtn(): void {
    const autoQualityButton = document.createElement('button');
    autoQualityButton.textContent = 'Auto';
    if (this.selectedTrackId === this.autoQualityTrackId) {
      autoQualityButton.classList.add(
        'laminar-shaka-quality-selector__button--selected'
      );
    }
    autoQualityButton.addEventListener('click', () => {
      this.selectedTrackId = this.autoQualityTrackId;

      this.player?.configure({ abr: { enabled: true } });
      this.updateAvailableQualities();
      autoQualityButton.classList.add(
        'laminar-shaka-quality-selector__button--selected'
      );
      this.hideMenu();
    });

    this.menu.appendChild(autoQualityButton);
  }
  private toggleMenu(): void {
    this.isMenuHidden() ? this.showMenu() : this.hideMenu();
    const buttonRect = this.button.getBoundingClientRect();
    const menuRect = this.menu.getBoundingClientRect();
    const menuHeight = menuRect.height;

    this.menu.style.top = `${buttonRect.top + window.scrollY - menuHeight}px`;
    this.menu.style.left = `${
      buttonRect.left + window.scrollX - menuRect.width / 2
    }px`;
  }

  private isMenuHidden(): boolean {
    const isHidden = this.menu.classList.contains('laminar-shaka--hidden');
    return isHidden;
  }

  private hideMenu(): void {
    this.menu.classList.add('laminar-shaka--hidden');
  }

  private showMenu(): void {
    this.menu.classList.remove('laminar-shaka--hidden');
  }

  private handleOutsideClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      !this.isMenuHidden() &&
      !this.menu.contains(target) &&
      !this.button.contains(target)
    ) {
      this.hideMenu();
    }
  }
}

class VideoPlayerQualitySelectorFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new QualitySelector(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(
  'video_player_quality_selector',
  new VideoPlayerQualitySelectorFactory()
);

import {
  Asset,
  Config,
  ContinueWatchingItem,
  DataType,
  Interests,
  RootAssetResponse,
} from '@laminar-product/client-commons-core/core';
import _omitBy from 'lodash/omitBy';
import { AnyAction } from 'redux';
import { Meta } from 'types/meta';
import {
  PUT_ELEMENT_TO_CONTINUE_WATCHING,
  SET_APP_DATA,
  SET_APP_META,
  SET_APP_SETTINGS,
  SET_DEVICE_ID,
} from './actions';

export interface AppState {
  assetRoot?: { [key: string]: RootAssetResponse };
  assetChildren?: { [key: string]: Asset[] };
  appSettings?: Config & {
    meta?: Meta;
    interests?: Interests;
  };
  continueWatching: { [key: string]: ContinueWatchingItem };
  device?: { deviceId: string };
}

const appReducer = (
  state: AppState = { continueWatching: {} },
  action: AnyAction
): AppState => {
  switch (action.type) {
    case SET_APP_DATA:
      if (action.payload?.dataType === DataType.CONTINUE_WATCHING) {
        return {
          ...state,
          continueWatching: Object.fromEntries(
            action.payload.data.map(
              (continueWatching: ContinueWatchingItem) => [
                continueWatching.assetUuid,
                continueWatching,
              ]
            )
          ),
        };
      }

      return {
        ...state,
        [action.payload.dataType]: {
          ...state[action.payload.dataType as keyof AppState],
          [action.payload.slug]: action.payload.data,
        },
      };
    case SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      };
    case SET_APP_META:
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          meta: action.payload,
        } as Config & { meta?: Meta },
      };
    case PUT_ELEMENT_TO_CONTINUE_WATCHING:
      const continueWatchingPayload = action.payload as ContinueWatchingItem;

      const filteredContinueWatching = _omitBy(
        state.continueWatching,
        (item) => item.rootId && item.rootId === continueWatchingPayload.rootId
      );

      const continueWatchingToSave = {
        ...filteredContinueWatching,
        [continueWatchingPayload.assetUuid]: {
          ...continueWatchingPayload,
          createdAt: Date.now(),
        },
      };

      return {
        ...state,
        continueWatching: continueWatchingToSave,
      };

    case SET_DEVICE_ID:
      return {
        ...state,
        device: { deviceId: action.payload },
      };
    default:
      return state;
  }
};

export default appReducer;

import React, { useState } from 'react';
import { ProfileItemType } from 'types/profiles';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import ProfileName from 'components/ProfileNameWrapper';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { Modal } from '@laminar-product/client-commons-core/web';
import ProfilePinCheck from 'pages/ProfilesSelector/ProfilePinCheck';
import ProfileAvatar from 'components/ProfileAvatar';
import { ProfileAvatarSize, checkIsKidsProfile } from 'utils/profiles';
import { Profile } from '@laminar-product/client-commons-core/core';
import { MATURITY_VALUES_ALL_RATINGS } from 'utils/constants';
import { getProfileDetailsRoute } from 'utils/routing';
import styles from '../index.module.scss';

interface ProfileItemProps {
  type: ProfileItemType;
  profile?: Profile;
}

const ProfileListItem = ({ profile, type }: ProfileItemProps) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [pinModalOpen, setPinModalOpen] = useState<boolean>(false);

  const redirectToDetails = () => {
    if (!profile) return;

    push(getProfileDetailsRoute(profile));
  };

  const checkPin = () => {
    if (type === 'list') {
      if (profile?.pin) {
        setPinModalOpen(true);
        return;
      }
      redirectToDetails();
    }
  };

  const onPinCorrect = () => {
    setPinModalOpen(false);
    redirectToDetails();
  };

  return (
    <>
      <div
        className={cn(styles.row, {
          [styles.noBorder]: type === 'header',
          [styles.listItemHover]: type === 'list',
        })}
        onClick={checkPin}
      >
        <ProfileAvatar
          name={profile?.name}
          size={ProfileAvatarSize.MEDIUM}
          isKidsProfile={checkIsKidsProfile(profile)}
        />

        <div className={styles.meta}>
          <div
            className={cn(styles.bold, {
              [styles.nameHeader]: type === 'header',
            })}
          >
            <div className={styles.metaNameContainer}>
              <ProfileName text={profile?.name} type="header" />
              {profile?.pin && <LockIcon className={styles.lockIcon} />}
            </div>
          </div>
          {profile?.maturityRating && (
            <div className={styles.rating}>
              {MATURITY_VALUES_ALL_RATINGS.includes(profile?.maturityRating)
                ? `${t('profile.allMaturityRatings')}`
                : `${profile?.maturityRating} ${t('profile.andBelow')}`}
            </div>
          )}
        </div>
      </div>

      <Modal wrapperId="profilePinCheckModal" isOpen={pinModalOpen}>
        <ProfilePinCheck
          onPinCorrect={onPinCorrect}
          profile={profile}
          onClose={() => setPinModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ProfileListItem;

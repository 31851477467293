import { useCallback, useEffect, useState } from 'react';

export const useResendOtpTimeout = () => {
  const [resendOtpTimeout, setResendOtpTimeout] = useState(0);

  useEffect(() => {
    if (resendOtpTimeout === 0) {
      return;
    }
    const intervalId = setInterval(() => {
      if (resendOtpTimeout === 0 && intervalId) {
        clearInterval(intervalId);
      }
      if (resendOtpTimeout > 0) {
        setResendOtpTimeout(resendOtpTimeout - 1);
      }
    }, 1000);

    return () => {
      if (!intervalId) {
        return;
      }
      clearInterval(intervalId);
    };
  }, [resendOtpTimeout]);

  const setTimeout = useCallback(() => {
    setResendOtpTimeout(60);
  }, []);

  useEffect(() => {
    setTimeout();
  }, [setTimeout]);

  return { resendOtpTimeout, setResendOtpTimeout: setTimeout };
};

import useGetSearchParams from 'utils/useGetSearchParams';
import { MoveToListingParams } from 'utils/useMoveToListing';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { MobileSelect } from '@laminar-product/client-commons-core/web';
import { SORTING_OPTIONS } from 'utils/sorting';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export interface MobileSortingSelectorProps {
  onChange: (sort: string) => void;
  value?: string;
}

const MobileSortingSelector = ({
  onChange,
  value,
}: MobileSortingSelectorProps) => {
  const { bcpath, genre, audio, subtitles, mood } =
    useGetSearchParams() as MoveToListingParams;

  const history = useHistory();

  const sortValue =
    SORTING_OPTIONS.find((o) => o.value === value) || SORTING_OPTIONS[0];

  const onSortValueChange = useCallback(
    (option: { label: string; value: string }) => {
      const isParams = genre || audio || subtitles || mood;

      if (isParams) {
        return onChange(option.value);
      }

      option.value === 'none' && bcpath
        ? history.push(bcpath)
        : onChange(option.value);
    },
    [audio, bcpath, genre, history, mood, onChange, subtitles]
  );

  return (
    <MobileSelect
      options={SORTING_OPTIONS}
      value={sortValue}
      onChange={onSortValueChange}
      labelIcon={<SortIcon />}
    />
  );
};

export default MobileSortingSelector;

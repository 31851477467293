import {
  LiveDetails,
  Movie,
  Show,
} from '@laminar-product/client-commons-core/web';
import {
  Asset,
  AssetType,
  ContentType,
  getAssetContentWithType,
  getAssetImageWithType,
} from '@laminar-product/client-commons-core/core';
import { getAsset } from 'actions/assets';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { setAppMeta } from 'store/app/actions';
import { AssetDetailsParams } from 'utils/props';
import useAssetConfig from 'utils/useAssetConfig';
import { prepareMetaForAsset } from 'utils/meta';
import { selectFirebaseUser } from 'store/user/selectors';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import useCanPlayTrailer from 'utils/useCanPlayTrailer';
import AssetDetailsBase from 'components/AssetDetailsBase';
import {
  getAssetCatalogPath,
  useRedirectToCatalog,
} from 'utils/useRedirectAssetCatalog';
import RecommendationCarousel from 'components/RecommendationCarousel';
import useRecommendation from 'utils/useRecommendation';
import { selectAsset } from 'store/assets/selectors';
import { setAsset } from 'store/assets/actions';
import { VALIDATION_ERROR_CODE } from 'utils/player';
import { selectAppTheme } from 'store/app/selectors';
import { buildAssetDynamicLink } from 'utils/dynamicLinks';
import useUpgradeToWatch from 'utils/useUpgradeToWatch';

const AssetDetails = () => {
  const { id } = useParams<AssetDetailsParams>();
  const user = useSelector(selectFirebaseUser);
  const currentAsset = useSelector(selectAsset(id));
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { assetMovieConfig, assetShowConfig, liveDetailsConfig } =
    useAssetConfig(currentAsset);
  const { canPlayTrailer } = useCanPlayTrailer();
  const [isValidationError, setIsValidationError] = useState(false);
  const { recommendationData } = useRecommendation();
  const appTheme = useSelector(selectAppTheme);
  const { handleUpgradeToWatch } = useUpgradeToWatch();
  const { pathname } = useLocation();

  useRedirectToCatalog({ asset: currentAsset, isValidationError });

  useEffect(() => {
    const dispatchAppMeta = (asset: Asset) => {
      const videoUrl = getAssetContentWithType(asset, ContentType.MAIN)?.video
        .dash.url;
      const thumbnailUrl = getAssetImageWithType(asset, 'thumbnail')?.url;

      const canonicalUrl = `${window.location.origin}${getAssetCatalogPath(
        asset,
        pathname
      )}`;

      dispatch(
        setAppMeta({
          description: asset.description,
          og: prepareMetaForAsset(asset),
          structuredData: {
            name: asset.name,
            description: asset.description,
            contentUrl: videoUrl,
            embedUrl: videoUrl,
            uploadDate: asset?.uploadDate
              ? new Date(asset?.uploadDate)
              : undefined,
            duration: asset.duration
              ? new Date(asset.duration).toISOString()
              : '',
            thumbnailUrl,
          },
          canonicalUrl,
        })
      );
    };

    getAsset(id)
      .then((asset) => {
        dispatch(setAsset(id, asset));
        dispatchAppMeta(asset);
      })
      .catch((e: AxiosError) => {
        if (e.response?.data?.code === VALIDATION_ERROR_CODE) {
          setIsValidationError(true);
        }

        captureError(e as AxiosError, 'AssetDetails/getAsset', Severity.Error);
        const error: string = e.isAxiosError
          ? e.response?.data?.status
          : 'We are currently investigating the issue';
        setError(error);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, id, pathname]);

  const renderRecommendationComponent = () => {
    if (!recommendationData?.length || !currentAsset) {
      return null;
    }

    return (
      <RecommendationCarousel
        assets={recommendationData}
        currentAsset={currentAsset}
      />
    );
  };

  const openAssetInApp = () => {
    if (!currentAsset) {
      return;
    }

    const link = buildAssetDynamicLink(currentAsset);
    window.location.href = link;
  };

  const renderComponent = () => {
    if (!currentAsset) return null;

    const commonDetailsViewProps = {
      asset: currentAsset,
      user,
      canPlayTrailer,
      onPlanUpgradeRequired: handleUpgradeToWatch,
      recommendationComponent: renderRecommendationComponent(),
      appTheme,
      openAssetInApp,
    };

    switch (currentAsset.type) {
      case AssetType.MOVIE:
        return <Movie config={assetMovieConfig} {...commonDetailsViewProps} />;
      case AssetType.SHOW:
        return <Show config={assetShowConfig} {...commonDetailsViewProps} />;
      case AssetType.LIVE_CHANNEL:
      case AssetType.LIVE_EVENT:
        return (
          <LiveDetails config={liveDetailsConfig} {...commonDetailsViewProps} />
        );
      default:
        return null;
    }
  };

  return (
    <AssetDetailsBase
      isCatalog={false}
      isError={!!error}
      isLoading={isLoading}
      children={renderComponent()}
    />
  );
};

export default AssetDetails;

import {
  Content,
  ContentType,
  GetAssetTokenType,
} from '@laminar-product/client-commons-core/core';
import { getAssetPlayback } from 'actions/assets';
import playerSourceService from 'library/VideoPlayer/VideoPlayerSourceService';
import { getStreamType } from 'library/VideoPlayer/VideoPlayerSourceService/utils';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectDeviceId } from 'store/app/selectors';
import { v4 as uuidv4 } from 'uuid';

interface PrepareVideoPlayerConfigProps {
  content: Content;
  assetId: string;
  tokenType?: GetAssetTokenType;
}

const useVideoPlayerPlayback = () => {
  const deviceId = useSelector(selectDeviceId);

  const prepareVideoPlayerConfig = useCallback(
    async ({ assetId, content, tokenType }: PrepareVideoPlayerConfigProps) => {
      const playbackId = uuidv4();
      const { type, slug, video } = content;

      const playerSourceServiceParams = playerSourceService({
        contentType: type,
      });

      const streamType =
        type === ContentType.LIVE ? 'HLS' : await getStreamType();

      const playback = await getAssetPlayback({
        assetId,
        contentId: slug,
        playbackId,
        deviceId,
        streamType,
        type: tokenType,
      });

      if (!playback?.length) return;

      const playbackData = playback?.find(
        (playback) => playback.type === streamType
      );

      const assetPlaybackDraft =
        playerSourceServiceParams?.prepareAssetPlayback({
          videoFile: video,
          playbackData,
        });

      const assetPlayback = {
        playbackId,
        ...assetPlaybackDraft,
      };

      return assetPlayback;
    },
    [deviceId]
  );

  return prepareVideoPlayerConfig;
};

export default useVideoPlayerPlayback;

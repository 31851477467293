import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import { secondsToHoursLabel } from 'utils/player';
import cn from 'classnames';
import styles from './index.module.scss';
interface SeekBarProps {
  playerCurrentTime: number;
  onEndSeeking: (seekedTime: number) => void;
  onStartSeeking: () => void;
  minValue?: number;
  maxValue: number;
  buffering?: boolean;
  disabled?: boolean;
}

const SeekBar = ({
  playerCurrentTime,
  onEndSeeking,
  minValue = 0,
  maxValue,
  onStartSeeking,
  buffering,
  disabled,
}: SeekBarProps) => {
  const [seekbarTime, setSeekbarTime] = useState<number>(playerCurrentTime);
  const [isSeeking, setIsSeeking] = useState(false);

  useEffect(() => {
    if (playerCurrentTime) {
      setSeekbarTime(playerCurrentTime);
    }
  }, [playerCurrentTime]);

  return (
    <>
      {/* Library requires to pass inline styles */}
      <Slider
        min={minValue}
        max={maxValue}
        value={seekbarTime}
        handleRender={(renderProps) => (
          <div
            {...renderProps.props}
            style={{
              ...renderProps.props.style,
              backgroundColor: 'white',
              border: 'none',
              opacity: 1,
              boxShadow: '0 0 0 2px white',
            }}
          >
            {isSeeking ? (
              <span className={styles.tooltip}>
                {secondsToHoursLabel(seekbarTime)}
              </span>
            ) : null}
          </div>
        )}
        onBeforeChange={() => {
          onStartSeeking();
          setIsSeeking(true);
        }}
        onAfterChange={(value) => {
          onEndSeeking(value as number);
          setIsSeeking(false);
        }}
        onChange={(value) => setSeekbarTime(value as number)}
        railStyle={{ backgroundColor: 'gray' }}
        trackStyle={[{ backgroundColor: 'white' }]}
        className={cn(buffering && styles.seekbarBuffering)}
        disabled={disabled}
      />
    </>
  );
};

export default SeekBar;
